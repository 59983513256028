import React from 'react';

import { type ModalActionsContextType } from './types';

const initialContextValue: ModalActionsContextType = {
    register: () => {
        throw new Error('Cannot register a modal outside of a `ModalProvider`!');
    },
    unregister: () => {},
    show: () => {},
    hide: () => {},
    updateProps: () => {},
};

const ModalActionsContext = React.createContext<ModalActionsContextType>(initialContextValue);

export default ModalActionsContext;
