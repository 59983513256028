import { type RouteObject } from 'react-router-dom';

export const routes: RouteObject[] = [
    {
        path: ':id',
        lazy: async () => {
            const { Layout } = await import(
                /* webpackChunkName: "maintenancenotes" */ '@@routes/maintenancenotes'
            );

            return { Component: Layout };
        },
    },
];
