import { useMemo } from 'react';

import useDeepCompareMemoize, { type DeepCompareDependency } from './useDeepCompareMemoize';

export const useMemoWithOptions = <T>(
    callback: () => T,
    dependencies: Array<DeepCompareDependency>,
): T => useMemo<T>(callback, dependencies.map(useDeepCompareMemoize));

export default useMemoWithOptions;
