import { merge } from 'lodash';
import { Transforms } from 'slate';

import { BLOCK_META_STATES } from '@@editor/constants';
import { Editor, type Node, ReactEditor } from '@@editor/helpers';
import { type EmbedBaseData } from '@@editor/helpers/Element';

export const updateLoadingState = (data: EmbedBaseData): EmbedBaseData => {
    const isLoadingFile = data[BLOCK_META_STATES.LOADING_FILE];
    const isUploadingFile = data[BLOCK_META_STATES.UPLOADING_FILE];

    return {
        ...data,
        isLoading: Boolean(isUploadingFile || isLoadingFile),
    };
};

export const updateFileNodeData = (editor: Editor, outerNode: Node, data: EmbedBaseData) => {
    const id = ReactEditor.findKey(editor, outerNode).id;

    const [firstMatch] = Editor.nodes<Node>(editor, {
        at: [],
        match: (node) => ReactEditor.findKey(editor, node).id === id,
    });

    if (firstMatch) {
        const [node, path] = firstMatch;

        Transforms.setNodes(
            editor,
            { ...node, data: updateLoadingState(merge({}, node.data, data)) },
            { at: path },
        );
    }
};
