import { styled } from '@mui/material';
import React, { type HTMLAttributes, type ReactNode } from 'react';

import { type Editor } from '@@editor/helpers';
import renderLeaf from '@@editor/plugins/utils/renderLeaf';
import { type PluginOptions } from '@@editor/typings/UnityPlugins';

export const ColorScheme = {
    GREEN_AND_RED: 'GREEN_AND_RED',
    YELLOW_AND_BLUE: 'YELLOW_AND_BLUE',
} as const;

export type ColorScheme = ValueOf<typeof ColorScheme>;

const StyledAddedYellowAndBlue = styled('span')(({ theme }) => ({
    backgroundColor: theme.fixed.compare.yourVersionColor,
    color: theme.palette.common.white,
}));

const StyledRemovedYellowAndBlue = styled('span')(({ theme }) => ({
    color: theme.fixed.compare.masterColor,
    textDecoration: 'line-through',
}));

const StyledAddedGreenAndRed = styled('span')(({ theme }) => ({
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
}));

const StyledRemovedGreenAndRed = styled('span')(({ theme }) => ({
    color: theme.palette.error.main,
    textDecoration: 'line-through',
}));

type Props = {
    attributes: HTMLAttributes<HTMLElement>;
    children: ReactNode;
};

type TextDiffPluginOptions = {
    colorScheme: ColorScheme;
};

const ColorSchemeToComponents = {
    [ColorScheme.YELLOW_AND_BLUE]: {
        Added: ({ attributes, children }: Props) => (
            <StyledAddedYellowAndBlue {...attributes}>{children}</StyledAddedYellowAndBlue>
        ),
        Removed: ({ attributes, children }: Props) => (
            <StyledRemovedYellowAndBlue {...attributes}>{children}</StyledRemovedYellowAndBlue>
        ),
    },
    [ColorScheme.GREEN_AND_RED]: {
        Added: ({ attributes, children }: Props) => (
            <StyledAddedGreenAndRed {...attributes}>{children}</StyledAddedGreenAndRed>
        ),
        Removed: ({ attributes, children }: Props) => (
            <StyledRemovedGreenAndRed {...attributes}>{children}</StyledRemovedGreenAndRed>
        ),
    },
};

export const withTextDiff = (
    editor: Editor,
    options: PluginOptions & TextDiffPluginOptions,
): Editor => {
    const { colorScheme = ColorScheme.YELLOW_AND_BLUE } = options;

    return Object.assign(editor, {
        renderLeaf: renderLeaf<Props>(editor, [
            ['added', ColorSchemeToComponents[colorScheme].Added],
            ['removed', ColorSchemeToComponents[colorScheme].Removed],
        ]),
    });
};

export default withTextDiff;
