import { styled } from '@mui/material';
import React, { useMemo } from 'react';
import { type Descendant } from 'slate';

import { type Tenant } from '@@api/services/tenant/schemas';
import { PLUGIN_CONFIG_TEMPLATES } from '@@editor/constants';
import { getInputPlaceholderStyle } from '@@editor/plugins/serializable/styles';
import setupPlugins from '@@editor/plugins/setup';
import { PLUGIN_NAMES, type PluginList } from '@@editor/typings/UnityPlugins';
import usePostSpellCheck from '@@form/hooks/usePostSpellCheck';

import RichTextEditorBase from './RichTextEditorBase';

type Props = {
    multiline?: boolean;
    spellCheck?: boolean;
    tenantIds?: Tenant['id'][];
    value?: Descendant[];
    readOnly?: boolean;
};

const SimpleRichTextEditor: React.FC<Props> = ({ tenantIds, ...props }) => {
    const { multiline } = props;

    const pluginConfig: PluginList[] = [
        PLUGIN_CONFIG_TEMPLATES.autoReplaceText,
        PLUGIN_NAMES.PARAGRAPH,
        PLUGIN_CONFIG_TEMPLATES.specialCharacters,
        PLUGIN_CONFIG_TEMPLATES.softHyphen,
    ];

    if (!multiline) {
        pluginConfig.push(PLUGIN_NAMES.SINGLE_LINE);
    }

    if (props.spellCheck) {
        pluginConfig.push(PLUGIN_NAMES.SPELL_CHECKER);
    }

    const stickyToolbarButtons = [
        PLUGIN_CONFIG_TEMPLATES.specialCharacters,
        PLUGIN_CONFIG_TEMPLATES.softHyphen,
    ];

    const spellCheck = usePostSpellCheck();

    const plugins = useMemo(
        () =>
            setupPlugins(pluginConfig, {
                optionsPerPlugin: {
                    [PLUGIN_NAMES.SPELL_CHECKER]: {
                        spellCheck,
                        tenantIds,
                    },
                },
            }),
        [],
    );

    const conditionalProps: { minRows?: number } = {};

    if (!multiline) {
        conditionalProps.minRows = 1;
    }

    return (
        <RichTextEditorBase
            {...conditionalProps}
            {...props}
            plugins={plugins}
            stickyToolbarButtons={stickyToolbarButtons}
            hideFloatingToolbar
        />
    );
};

// Because of how plugins and editors are imported and loaded
// we need to initialize all these styled editors here, to avoid the
// "Cannot access before initialization" error

export const EmbedHeadingEditor = styled(SimpleRichTextEditor, {
    shouldForwardProp: (prop: string) => !['placeholderText'].includes(prop),
})<{ $templateElement?: boolean; placeholderText?: string }>(
    ({ $templateElement, placeholderText, theme }) => ({
        ...theme.typography.editorSecondaryMedium,
        fontWeight: 'bold',
        padding: `0 0 ${theme.spacing(2)}`,
        ...($templateElement && getInputPlaceholderStyle({ theme, placeholderText })),
    }),
);

export default SimpleRichTextEditor;
