import { type ZodErrorMap, ZodIssueCode, ZodParsedType } from 'zod';

import i18n from '@@lib/i18n/i18n';

/**
 * Custom errors for form validation, allowing for translated messages
 * If an error message is missing, please add it here.
 * Each error type has some options, that allow for more specific messages.
 * Add only messages that are used in our forms, not all possible Zod errors.
 */
export const formValidationErrorMap: ZodErrorMap = (issue, ctx) => {
    if (issue.code === ZodIssueCode.invalid_type) {
        if (issue.received === ZodParsedType.undefined || issue.received === ZodParsedType.null) {
            return { message: i18n.t('validator.message.required') };
        }
    }

    if (issue.code === ZodIssueCode.invalid_date) {
        return { message: i18n.t('validator.message.date') };
    }

    if (issue.code === ZodIssueCode.too_small) {
        if (issue.type === 'string') {
            return { message: i18n.t('validator.message.minLength', { minLength: issue.minimum }) };
        } else if (issue.type === 'date') {
            return { message: i18n.t('validator.message.isNotFutureDate') };
        }
    }

    if (issue.code === ZodIssueCode.too_big) {
        if (issue.type === 'string') {
            return { message: i18n.t('validator.message.maxLength', { maxLength: issue.maximum }) };
        } else if (issue.type === 'date') {
            return { message: i18n.t('validator.message.isPastDate') };
        }
    }

    if (issue.code === ZodIssueCode.custom) {
        return { message: i18n.t(issue.message || '') };
    }

    return { message: ctx.defaultError };
};
