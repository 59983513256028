import 'moment/locale/de-ch';
import 'moment/locale/fr-ch';
import 'moment/locale/en-gb';
import { CssBaseline, GlobalStyles, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import moment from 'moment';
import pluralize from 'pluralize';
import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

import '@@utils/logRender';
import ApiClientProvider from '@@api/ApiClientProvider';
import queryClient from '@@api/queryClient';
import config from '@@config';
import { AppRouter } from '@@containers/App/AppRouter';
import IconProvider from '@@containers/App/IconProvider';
import { PageLayoutProvider } from '@@containers/App/PageLayout/PageLayoutContext';
import { ImageAltTextProvider } from '@@containers/ImageAltText/ImageAltTextContext';
import { SnackbarItem, SnackbarProvider } from '@@containers/Snackbar';
import IFrameContext from '@@hooks/IFrameContext';
import CONFIG from '@@lib/dateTime/config';
import styles from '@@scripts/themes/mui/GlobalStyles';
import theme from '@@themes/mui';

import { store } from './store/store';
import { initializeGoogleAnalytics } from './utils/analyticsTracker';
import { addDatadogError, initializeDataDogRUM } from './utils/dataDog';

const ENVS = {
    NODE_ENV: config.nodeEnv,
    CDII_ENV: config.env,
    API_ENV: config.apiEnv,
};

window.VERSION = VERSION;
window.ENVS = ENVS;

window.addEventListener('unhandledrejection', (e) => {
    console.warn('WARNING: Unhandled promise rejection:', e.reason);

    addDatadogError(e, {
        message: 'Unhandled promise rejection',
    });
});

pluralize.addSingularRule(/^metadata$/, 'metadata');
pluralize.addSingularRule(/^breakingnews$/, 'breakingnews');

initializeGoogleAnalytics();

initializeDataDogRUM();

export const persistor = persistStore(store, null, () => {
    store.subscribe(() => {
        const locale = store.getState().i18n.locale;

        moment.updateLocale(locale.toLowerCase(), CONFIG[locale.toLowerCase()]);
    });

    if (module.hot) {
        module.hot.accept('./containers/App/AppRouter', () => {
            render(document.querySelectorAll('.app-container')[0], {
                store,
                queryClient,
            });
        });
    }

    render(document.querySelectorAll('.app-container')[0], {
        store,
        queryClient,
    });
});

// https://stackoverflow.com/a/326076
const inIFrame = () => {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
};

const render = (container, props) => {
    const helmetContext = {};

    const root = createRoot(container);

    root.render(
        <IFrameContext.Provider value={inIFrame()}>
            <Provider store={props.store}>
                <PersistGate loading={null} persistor={persistor}>
                    <ApiClientProvider queryClient={props.queryClient}>
                        <HelmetProvider context={helmetContext}>
                            <ThemeProvider theme={theme}>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DndProvider backend={HTML5Backend}>
                                        <PageLayoutProvider>
                                            <IconProvider>
                                                <SnackbarProvider
                                                    anchorOrigin={{
                                                        horizontal: 'right',
                                                        vertical: 'top',
                                                    }}
                                                    // The native `autoHideDuration` option is buggy
                                                    // (snackbar is hidden too early) and we also want to
                                                    // display a progress bar. Therefore we use our custom
                                                    // implementation.
                                                    autoHideDuration={null}
                                                    maxSnack={config.snackbar.maxSnack}
                                                    preventDuplicate
                                                    Components={{
                                                        error: SnackbarItem,
                                                        warning: SnackbarItem,
                                                        success: SnackbarItem,
                                                        info: SnackbarItem,
                                                    }}
                                                >
                                                    <ImageAltTextProvider>
                                                        <CssBaseline />
                                                        <GlobalStyles styles={styles} />

                                                        {!config.isVisualTest && (
                                                            <ReactQueryDevtools
                                                                initialIsOpen={false}
                                                            />
                                                        )}

                                                        <AppRouter {...props} />
                                                    </ImageAltTextProvider>
                                                </SnackbarProvider>
                                            </IconProvider>
                                        </PageLayoutProvider>
                                    </DndProvider>
                                </LocalizationProvider>
                            </ThemeProvider>
                        </HelmetProvider>
                    </ApiClientProvider>
                </PersistGate>
            </Provider>
        </IFrameContext.Provider>,
    );
};
