import type {} from '@mui/x-date-pickers/themeAugmentation';
import { alpha, createTheme } from '@mui/material';
import { amber, green, lightBlue, orange, red } from '@mui/material/colors';
import { stripUnit } from 'polished';
import invariant from 'tiny-invariant';

import components from './components/index';
import devices from './devices';
import { baseConfig } from './typography';
import zIndexes from './zIndexes';

const defaultTheme = createTheme();

const fontFamily = defaultTheme.typography.fontFamily
    ?.split(',')
    .map((str) => str.trim())
    .toString();

// We do not want MUI to use a custom letter spacing. Overwriting the fontFamiy on the theme, causes MUI to
// not use a custom letter spacing (as long as it does not shallow equal the default value, which is a comma
// separated string).
invariant(
    defaultTheme.typography.fontFamily !== fontFamily,
    '`fontFamily` must not be equal to the default `fontFamily` theme option!',
);

const opacityFactors = {
    low: 0.25,
    medium: 0.5,
    high: 0.75,
};

const baseColors = {
    BLACK: '#000000',
    WHITE: '#FFFFFF',
    PRIMARY_BLACK: '#303030',
    SECONDARY: '#5B7BC0',
    PRIMARY: '#898A91',
};

const publishedColor = '#2F5C28';

const primaryColor = {
    50: '#F7F7F7',
    100: '#EBEBEB',
    200: '#DCDCDC',
    300: '#CFD0D7',
    400: '#AAABB2',
    500: baseColors.PRIMARY,
    600: '#626369',
    700: '#4F5056',
    800: '#313238',
    900: '#111218',
    A100: '#EBEBEB',
    A200: '#DCDCDC',
    A400: '#AAABB2',
    A700: '#4F5056',
};

// Generated with https://m2.material.io/inline-tools/color/
const primary = defaultTheme.palette.augmentColor({
    color: primaryColor,
    lightShade: '300',
    mainShade: '500',
    darkShade: '700',
});

const secondary = defaultTheme.palette.augmentColor({
    color: {
        50: '#E8ECF6',
        100: '#C6D0E9',
        200: '#A0B2DA',
        300: '#7A94CB',
        400: baseColors.SECONDARY,
        500: '#3A64B6',
        600: '#335BAC',
        700: '#2951A0',
        800: '#214794',
        900: '#0E367F',
        A100: '#E8ECF6',
        A200: '#C6D0E9',
        A400: '#3A64B6',
        A700: '#2951A0',
    },
    lightShade: '200',
    mainShade: '400',
    darkShade: '600',
});

const warning = defaultTheme.palette.augmentColor({
    color: orange,
    lightShade: '400',
    mainShade: '800',
    darkShade: '900',
});

const info = defaultTheme.palette.augmentColor({
    color: lightBlue,
    lightShade: '500',
    mainShade: '700',
    darkShade: '900',
});

const spacing = [0, '4px', '8px', '16px', '24px', '36px', '64px'];

// https://mui.com/material-ui/customization/default-theme/
export default createTheme({
    // We do not use breakpoints yet, therefore disable them by setting `xs` to 0.
    breakpoints: {
        values: {
            xs: 0,
        },
    },
    opacityFactors,
    palette: {
        // We do not support dark mode for now.
        mode: 'light',
        common: {
            white: baseColors.WHITE,
            black: baseColors.BLACK,
        },
        primary,
        secondary,
        success: defaultTheme.palette.augmentColor({
            color: green,
            lightShade: '300',
            mainShade: '600',
            darkShade: '800',
        }),
        info,
        warning,
        error: defaultTheme.palette.augmentColor({
            color: red,
            lightShade: '300',
            mainShade: '600',
            darkShade: '800',
        }),
        amber: defaultTheme.palette.augmentColor({
            color: amber,
        }),
        gold: {
            light: '#F8F3EC',
            main: '#EAE0D5',
            dark: '#DBC09E',
            contrastText: '#C4965C',
        },
        background: {
            // `default` is automatically applied to the html body element and to `&::backdrop` by `CSSBaseline`
            default: primary['50'],
        },
        divider: 'rgba(0, 0, 0, 0.06)',
        text: {
            // `primary` is automatically applied to the html body element by `CSSBaseline`
            primary: baseColors.PRIMARY_BLACK,
            secondary: primary['400'],
        },
        grey: primaryColor,
        tonalOffset: 0.1,
    },
    borders: [0, '1px', '2px', '4px'],
    typography: {
        // `fontFamily` is automatically applied to the html body element
        fontFamily,
        // `body1` is automatically applied to the html body element by `CSSBaseline`
        body1: baseConfig.body.M,
        h1: baseConfig.title.XL,
        h2: baseConfig.title.L,
        h3: baseConfig.title.M,
        h4: baseConfig.title.S,
        h5: baseConfig.title.XS,
        h6: undefined,
        title1: baseConfig.title.XL,
        title2: baseConfig.title.L,
        title3: baseConfig.title.M,
        title4: baseConfig.title.S,
        title5: baseConfig.title.XS,
        subtitle1: baseConfig.subtitle.XL,
        subtitle2: baseConfig.subtitle.L,
        subtitle3: baseConfig.subtitle.M,
        subtitle4: baseConfig.subtitle.S,
        subtitle5: baseConfig.subtitle.XS,
        xlarge: baseConfig.body.XL,
        large: baseConfig.body.L,
        medium: baseConfig.body.M,
        small: baseConfig.body.S,
        xsmall: baseConfig.body.XS,
        editorPrimaryXlarge: baseConfig.editor.primary.XL,
        editorPrimaryLarge: baseConfig.editor.primary.L,
        editorPrimaryMedium: baseConfig.editor.primary.M,
        editorPrimarySmall: baseConfig.editor.primary.S,
        editorSecondaryXxlarge: baseConfig.editor.secondary.XXL,
        editorSecondaryXlarge: baseConfig.editor.secondary.XL,
        editorSecondaryLarge: baseConfig.editor.secondary.L,
        editorSecondaryMedium: baseConfig.editor.secondary.M,
        editorSecondarySmall: baseConfig.editor.secondary.S,
        editorSecondaryXsmall: baseConfig.editor.secondary.XS,
        editorSubtitleLarge: baseConfig.editor.subtitle.L,
        editorSubtitleMedium: baseConfig.editor.subtitle.M,
        editorSubtitleSmall: baseConfig.editor.subtitle.S,
        editorSubtitleXsmall: baseConfig.editor.subtitle.XS,
        button: {
            ...baseConfig.subtitle.M,
            letterSpacing: '0.2px',
        },
    },
    spacing,
    shape: { borderRadius: 2 },
    zIndex: {
        layer1: zIndexes.LAYER1,
        layer2: zIndexes.LAYER2,
        layer3: zIndexes.LAYER3,
        pageLayout: {
            nav: 8,
            notificationBar: 7,
            head: 6,
            sidebar: 5,
            main: 4,
            toolbar: 5,
            foot: 6,
        },
    },
    fixed: {
        icon: {
            xsmall: '8px',
            small: '12px',
            medium: '16px',
            large: '24px',
            xlarge: '36px',
            xxlarge: '64px',
        },
        editor: {
            elementWrapper: {
                borderRadius: '11px',
                width: '28px',
                backgroundColor: alpha(secondary.main, opacityFactors.low),
            },
            floatingToolbar: {
                height: (stripUnit(spacing[3]) as number) * 2 + 'px',
                separatorHeight: '8px',
            },
            textElement: {
                width: '686px',
            },
            importInterviewDialog: {
                width: '1100px',
            },
            comments: {
                width: '230px',
                zIndex: zIndexes.EDITOR_COMMENTS,
            },
            stickyToolbar: {
                zIndex: zIndexes.EDITOR_STICKY_TOOLBAR,
                hoveredZindex: zIndexes.HOVERED_STICKY_TOOLBAR,
            },
            embed: {
                backgroundColor: primary['100'],
                width: '824px',
                smallWidth: '548px',
            },
            penIcon: {
                leftPosition: '48px',
            },
        },
        fileUpload: {
            dropArea: {
                backgroundColor: 'transparent',
                backgroundHoverColor: primary['200'],
                iconColor: primary['400'],
                iconHoverColor: primary['500'],
            },
        },
        form: {
            xsmall: { width: '320px' },
            small: { width: '480px' },
            medium: { width: '640px' },
            large: { width: '740px' },
            xlarge: { width: '800px' },
            loadingOverlayZindex: zIndexes.FORM_LOADING_OVERLAY,
        },
        navigation: {
            collapsed: { width: '48px' },
            expanded: { width: '200px' },
        },
        pageHeader: {
            height: '64px',
            borderWidth: '2px',
            columnGap: '48px',
        },
        sidebar: {
            small: {
                width: '400px',
            },
            medium: {
                width: '560px',
            },
            large: {
                width: '625px',
            },
            xlarge: {
                width: '720px',
            },
        },
        snackbar: {
            width: '400px',
        },
        toolbar: {
            collapsed: { width: '48px' },
            expanded: { width: '300px' },
        },
        statusSelect: {
            width: '175px',
        },
        stepInput: {
            width: '136px',
        },
        contentItem: {
            popper: {
                width: '370px',
            },
        },
        dialog: {
            minWidth: '450px',
        },
        placement: {
            history: {
                popper: {
                    width: '220px',
                    maxHeight: '300px',
                },
            },
        },
        devices,
        image: {
            boxShadow: `0 0 4px ${alpha(primary['800'], opacityFactors.medium)}`,
        },
        compare: {
            masterColor: warning['600'],
            yourVersionColor: info['900'],
        },
        penaltyCard: {
            yellow: '#FFDE01',
            red: '#FD0100',
        },
        contentStatus: {
            draft: '#FFEA00',
            review1: '#F6C87F',
            seo: '#4527A0',
            review2: '#66FFFF',
            proofread: '#AF23DB',
            requiredRework: '#E9334C',
            readyToPublish: publishedColor,
        },
        publicationStatus: {
            notPublished: baseColors.PRIMARY,
            published: publishedColor,
            unpublished: baseColors.PRIMARY,
        },
    },
    components,
});
