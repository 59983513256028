import { z } from 'zod';

import ContentStatus from '@@constants/ContentStatus';
import PublicationStatus from '@@constants/PublicationStatus';
import { USER_SOURCES } from '@@containers/users/constants';
import { LANG_CODES } from '@@lib/i18n/constants';

import {
    ContentLocale,
    Entity,
    Image,
    RichText,
    UiLocale,
    UnityRichText,
} from '../../utils/schemas/schemas';
import { unityVariantsShape, variantsShape } from '../../utils/schemas/utils';

const UserBase = Entity.extend({
    id: z.string(),
    firstNameFromAD: z.string().nullable(),
    lastNameFromAD: z.string().nullable(),
    username: z.string().nullable(),
    publicUserId: z.number().nullable(),
    serviceUser: z.boolean(),
    userSource: z.nativeEnum(USER_SOURCES),
});

const UserVariantBase = z.object({
    firstName: z.string(),
    lastName: z.string(),
    jobTitle: z.string().nullable(),
    shortName: z.string().nullable(),
    twitterHash: z.string().nullable(),
    prolitterisId: z.string().nullable(),
    userImage: Image.nullable(),
});

export const UnityUser = UserBase.extend({
    variants: unityVariantsShape(
        UserVariantBase.extend({
            longBiography: UnityRichText,
            shortBiography: UnityRichText,
        }),
    ),
});

export type UnityUser = z.infer<typeof UnityUser>;

export const User = UserBase.extend({
    variants: variantsShape(
        UserVariantBase.extend({
            longBiography: RichText,
            shortBiography: RichText,
        }),
    ),
    initials: z.string().optional(),
    fullName: z.string().optional(),
});

export type User = z.infer<typeof User>;

export const NewUser = User.pick({
    variants: true,
});

export type NewUser = z.infer<typeof NewUser>;

export const NewUnityUser = UnityUser.omit({
    id: true,
    publicUserId: true,
    serviceUser: true,
    userSource: true,
});
export type NewUnityUser = z.infer<typeof NewUnityUser>;

export const Agency = z.object({
    id: z.string(),
    name: z.string(),
});

export type Agency = z.infer<typeof Agency>;

const CollapsibleCard = z.enum([
    'additionalSettings',
    'placement',
    'scheduledPlacing',
    'relatedContent',
    'teasers',
    'seoTeasers',
    'tags',
    'infobox',
    'kickword',
    'note',
    'printMetadata',
    'styles',
    'urlSlugs',
]);

export type CollapsibleCard = z.infer<typeof CollapsibleCard>;

const Collapsing = z.object({
    publicationCards: z.record(
        CollapsibleCard,
        z.object({
            collapsed: z.boolean().optional(),
            sections: z
                .record(
                    z.string(),
                    z.object({
                        showMore: z.boolean(),
                    }),
                )
                .optional(),
        }),
    ),
});

export type Collapsing = z.infer<typeof Collapsing>;

const SavedFilters = z.object({
    default: z
        .object({
            tenantIds: z.array(z.number()).nullable(),
        })
        .optional(),
    curatedList: z
        .object({
            q: z.string().nullish(),
            tenantIds: z.array(z.number()).nullish(),
            categoryIds: z.array(z.number()).nullish(),
            publicationStatus: z.array(z.nativeEnum(PublicationStatus)).nullish(),
            tagIds: z.array(z.number()).nullish(),
            userIds: z.string().nullish(),
            contentStatus: z.array(z.nativeEnum(ContentStatus)).nullish(),
        })
        .nullable(),
});

export type SavedFilters = z.infer<typeof SavedFilters>;

const Filters = z.object({
    [LANG_CODES.GERMAN]: SavedFilters.optional(),
    [LANG_CODES.FRENCH]: SavedFilters.optional(),
});

export type Filters = z.infer<typeof Filters>;

export const Preference = z.object({
    contentLocale: ContentLocale.optional(),
    uiLocale: UiLocale,
    id: z.string().optional(),
    betaFeaturesEnabled: z.boolean().optional(),
    collapsing: Collapsing.optional(),
    filters: Filters.optional(),
});

export type Preference = z.infer<typeof Preference>;

/* eslint-disable camelcase */
const LoginPayloadBase = z.object({
    access_token: z.string(),
    token_type: z.string(),
    scope: z.string(),
});

export const LoginPayloadRequest = LoginPayloadBase.extend({
    expires_in: z.string(),
});
export type LoginPayloadRequest = z.infer<typeof LoginPayloadRequest>;
export const LoginPayloadResponse = LoginPayloadBase.extend({
    expires_in: z.union([z.number(), z.string()]),
});
export type LoginPayloadResponse = z.infer<typeof LoginPayloadResponse>;
/* eslint-enable camelcase */
