import { type Image } from '@@api/utils/schemas/schemas';
import makeState from '@@editor/utils/makeState';

import { DEFAULT_CROP_MARKS, DEFAULT_FOCUS_POINT } from './constants';

const createImage = (value: Partial<Image> = {}): Image => ({
    ...value,
    url: value.url || '',
    focusPoint: value.focusPoint || DEFAULT_FOCUS_POINT,
    cropMarks: value.cropMarks || DEFAULT_CROP_MARKS,
    caption: value.caption || makeState(),
    credit: value.credit || '',
    name: value.name || '',
    variants: value.variants || undefined,
    // TODO: Include as soon as backend is ready
    // overlayIcon: value.overlayIcon || {
    //     type: null,
    //     position: 'bottomLeft',
    // },
});

export default createImage;
