import {
    type NewRTElement,
    type NewUnityRTElement,
    type RTElement,
    type UnityRTElement,
} from '@@api/services/rtelements/schemas';
import deserializeState from '@@editor/serialization/deserializeState';
import serializeState from '@@editor/serialization/serializeState';
import transformValues from '@@editor/serialization/transformValues';

export const deserializeRTElement = (entity: UnityRTElement): RTElement =>
    transformValues(entity, [['element', deserializeState]]);

export const serializeRTElement = (
    entity: RTElement | NewRTElement,
): UnityRTElement | NewUnityRTElement => transformValues(entity, [['element', serializeState]]);
