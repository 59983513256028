import { useCallback, useEffect, useState } from 'react';
import { Range } from 'slate';

import { type Editor } from '@@editor/helpers';
import usePrevious from '@@hooks/usePrevious';

import { type EditorSpellAdvice, type EditorSpellAdvices } from '../types';
import { isSpellAdviceSelected } from '../utils/spellAdvices';

type Options = {
    enabled?: boolean;
};

const useSelectedSpellAdvice = (
    editor: Editor,
    spellAdvices: EditorSpellAdvices,
    options: Options = {},
) => {
    const { selection } = editor;
    const { enabled = true } = options;
    const [selectedSpellAdvice, setSelectedSpellAdvice] = useState<EditorSpellAdvice | null>(null);
    const [selectedRange, setSelectedRange] = useState<Range | null>(null);
    const prevSelection = usePrevious(selection);
    const prevSpellAdvicesCommitHash = usePrevious(spellAdvices.lastCommitHash);

    useEffect(() => {
        if (!enabled) {
            return;
        }

        const hasSelectionChanged =
            selection != null && (!prevSelection || !Range.equals(prevSelection, selection));

        const haveSpellAdvicesChanged = prevSpellAdvicesCommitHash !== spellAdvices.lastCommitHash;

        if (selection != null && (hasSelectionChanged || haveSpellAdvicesChanged)) {
            // Use `findLast...` instead of `find...` because: If two spell advices are adjacent to each other,
            // and the cursor is placed exactly at the end of the first spell advice and at the start of the
            // second spell advice, we want to give precedence to the second one.
            const nextSelectedSpellAdvice = spellAdvices.findLastByRange(selection, (spellAdvice) =>
                isSpellAdviceSelected(editor, spellAdvice),
            );

            if (nextSelectedSpellAdvice?.isActive) {
                const nextSelectedRange: Range = {
                    anchor: selection.focus,
                    focus: selection.focus,
                };

                // Make sure to only update if something has been changed. If this state changes, this will
                // trigger updates in other places
                setSelectedSpellAdvice((selectedSpellAdvice) =>
                    !selectedSpellAdvice || selectedSpellAdvice.id !== nextSelectedSpellAdvice.id
                        ? nextSelectedSpellAdvice
                        : selectedSpellAdvice,
                );

                // Make sure to only update if something has been changed. If this state changes, this will
                // trigger updates in other places
                setSelectedRange((selectedRange) =>
                    !selectedRange || !Range.equals(selectedRange, nextSelectedRange)
                        ? nextSelectedRange
                        : selectedRange,
                );

                return;
            }
        }

        setSelectedSpellAdvice(null);
        setSelectedRange(null);
    }, [selection, spellAdvices.lastCommitHash]);

    useEffect(() => {
        if (!enabled) {
            setSelectedSpellAdvice(null);
            setSelectedRange(null);
        }
    }, [enabled]);

    const reset = useCallback(() => {
        setSelectedSpellAdvice(null);
        setSelectedRange(null);
    }, []);

    return {
        selectedSpellAdvice,
        selectedRange,
        reset,
    };
};

export default useSelectedSpellAdvice;
