import { type SeparatorElement } from '@@editor/helpers/Element';
import { type UnitySeparator } from '@@editor/typings/UnityElements';

import { CURRENT_VERSION } from './../../constants';

const separatorRules = {
    deserialize: (element): SeparatorElement => ({
        type: element.type,
        children: [{ text: '' }],
    }),

    serialize: (node): UnitySeparator => ({
        version: CURRENT_VERSION,
        type: node.type,
    }),
};

export default separatorRules;
