import React from 'react';
import { type FieldPath, type FieldValues } from 'react-hook-form';

import { Element } from '@@editor/helpers';
import RichTextEditor from '@@form/components/RichTextEditor/RichTextEditor';

import RichTextField, { type RichTextFieldProps } from './RichTextField';

const hasLoadingElement = (value) => {
    if (value && value.some(Element.containsLoadingElement)) {
        // t('editor.validation.uploading')
        return ['editor.validation.uploading'];
    }
};

export const RichTextEditorField = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
    props: RichTextFieldProps<TFieldValues, TName>,
) => {
    const { inputProps, ...rest } = props;

    return (
        <RichTextField
            {...rest}
            component={RichTextEditor}
            inputProps={{
                ...inputProps,
            }}
            validate={[hasLoadingElement]}
        />
    );
};

export default RichTextEditorField;
