import { BLOCK_META_STATES } from '@@editor/constants';
import { Editor } from '@@editor/helpers';
import { type InsertElementOptions } from '@@editor/helpers/Editor';
import { type EmbedBaseData, type EmbedElement, type EmbedType } from '@@editor/helpers/Element';
import { createElement } from '@@editor/serialization/UnitySerializer/deserializeNodes';
import { type GenerateEmbedBlockConfig } from '@@editor/typings/Embed';

const initialBlockData = {
    [BLOCK_META_STATES.LOADING]: false,
    [BLOCK_META_STATES.UPLOADING_FILE]: false,
    [BLOCK_META_STATES.LOADING_FILE]: false,
    [BLOCK_META_STATES.GENERATING_DESCRIPTION]: false,
    // left, right, full-width
    align: 'full-width',
    // contain / cover
    fit: 'contain',
};

export const createEmbedBlock = <T extends EmbedElement>(
    data: EmbedBaseData,
    nodeType: EmbedType,
    useInlineEditing?: boolean,
): T =>
    createElement<T>(
        nodeType,
        {
            ...initialBlockData,
            ...data,
        },
        { useInlineEditing },
    );

/*
 * framework:
 * - the form used for an embed always delivers either a 'src' or an 'url' or only a string representing the source.
 * - the 'parseEmbedCode' function always returns the parsed source 'src' or an object containing the parsed source.
 */
export const createGenerateEmbedBlock =
    (initialOptions: GenerateEmbedBlockConfig) =>
    (editor: Editor, formData, options?: InsertElementOptions): Editor => {
        const unifiedFormData = unifyFormData(formData);
        const embed = unifiedFormData?.embed;

        // 'src', 'url' and 'iframe' are the embed code carriers and are used throughout the embed plugin
        const embedCode = embed?.src || embed?.url || embed?.iframe;
        const parsedEmbedCode = initialOptions.parseEmbedCode?.(embedCode) || embedCode;

        const unifiedParsedEmbedCode = unifyParsedEmbedCode(parsedEmbedCode);

        // embed, src, originalSrc
        const data = {
            ...unifiedParsedEmbedCode,
            embed: unifiedFormData.embed,
            originalSrc: embedCode,
        };

        const blockData = initialOptions.generateEmbedBlockData(data);

        if (blockData) {
            const block = createEmbedBlock(
                blockData,
                initialOptions.nodeType,
                editor.useInlineEditing,
            );

            Editor.insertElement(editor, block, options);
        }

        return editor;
    };

export const unifyFormData = (formData) => {
    if (typeof formData === 'string') {
        return {
            embed: {
                src: formData,
            },
        };
    } else if (!formData.embed) {
        return {
            embed: formData,
            nodeKey: formData.nodeKey,
        };
    }

    return formData;
};

export const unifyParsedEmbedCode = (code) =>
    typeof code === 'string' || code?.pathname ? { src: code } : code;
