import { InputAdornment, TextField, type TextFieldProps } from '@mui/material';
import React from 'react';
import { type FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useTotalCharacterCount } from '@@containers/CharacterCount/CharacterCountContext';
import { type UseCachedValidationProps } from '@@form/hooks/useCachedValidation';
import { type FormFieldError } from '@@form/hooks/useReactHookFormFieldError';
import { getErrorMessage } from '@@form/utils/getErrorMessage';

type CountProps = {
    totalCharacterCount: number;
    displayUsedCharacters?: boolean;
    displayRemainingCharacters?: number;
};

const getCount = ({
    totalCharacterCount,
    displayRemainingCharacters,
    displayUsedCharacters,
}: CountProps) => {
    if (!displayUsedCharacters && !displayRemainingCharacters) {
        return undefined;
    }

    if (displayRemainingCharacters) {
        return `${totalCharacterCount}/${displayRemainingCharacters}`;
    }

    return totalCharacterCount > 0 ? totalCharacterCount.toString() : undefined;
};

export type TextInputProps = Omit<TextFieldProps, 'error'> & {
    error?: FormFieldError | FieldError;
    validate?: UseCachedValidationProps['validate'];
    displayUsedCharacters?: boolean;
    preserveErrorSpace?: boolean;
} & Omit<CountProps, 'totalCharacterCount'>;

const TextInput = (props: TextInputProps) => {
    const {
        error,
        preserveErrorSpace,
        displayUsedCharacters,
        displayRemainingCharacters,
        InputProps,
        ...rest
    } = props;

    const { t } = useTranslation();
    const totalCharacterCount = useTotalCharacterCount(rest.value);

    const errorMessage = getErrorMessage(error, t);
    const helperText = errorMessage || props.helperText || (preserveErrorSpace && ' ');

    const count = getCount({
        totalCharacterCount,
        displayRemainingCharacters,
        displayUsedCharacters,
    });

    return (
        <TextField
            {...rest}
            error={Boolean(error)}
            helperText={helperText}
            InputProps={{
                ...InputProps,
                ...(count && {
                    endAdornment: <InputAdornment position="end">{count}</InputAdornment>,
                }),
            }}
        />
    );
};

export default TextInput;
