import { differenceWith, intersectionWith } from 'lodash';
import invariant from 'tiny-invariant';
import isUUID from 'validator/lib/isUUID';

import { isNumberish } from '@@utils/string';

const checkIfNaN = (value) => {
    invariant(!isNaN(value), 'No valid numbered Id found');

    return Number(value);
};

// eslint-disable-next-line eqeqeq
export const compare = (v, t) => v == t;
export const eq = (v) => (t) => compare(v, t);

export const intersection = (array1, array2) => intersectionWith(array1, array2, compare);

export const difference = (array1, array2) => differenceWith(array1, array2, compare);

export const convertToNumber = (value) => {
    if (value == null) {
        return value;
    }

    if (value === 'null') {
        return null;
    }

    if (Array.isArray(value)) {
        return value.map(checkIfNaN);
    }

    return checkIfNaN(value);
};

export const parse = (value) => {
    const isNullOrUndefined = value == null;
    const isEmptyString = value === '';
    const isUuid = typeof value === 'string' && isUUID(value);

    if (isNullOrUndefined || isEmptyString || isUuid) {
        return value;
    } else if (isNumberish(value)) {
        return Number(value);
    }

    throw new Error(`Could not parse id "${value}" of type "${typeof value}"!`);
};
