import { Element } from '@@editor/helpers';
import { ELEMENT_TYPES } from '@@editor/helpers/Element';
import { isUnityLayout } from '@@editor/typings/UnityElements';

import dynamicTeaserRules from './dynamicTeaserRules';
import infoboxRules from './infoboxRules';
import interviewRules from './interviewRules';
import quoteRules from './quoteRules';
import separatorRules from './separatorRules';

const layoutRules = {
    deserialize: (element, next, options?) => {
        if (!isUnityLayout(element)) {
            return;
        }

        switch (element.type) {
            case ELEMENT_TYPES.INFOBOX: {
                return infoboxRules.deserialize(element, next, options);
            }
            case ELEMENT_TYPES.QUOTE: {
                return quoteRules.deserialize(element, next, options);
            }
            case ELEMENT_TYPES.INTERVIEW_SEGMENT: {
                return interviewRules.deserialize(element, next, options);
            }
            case ELEMENT_TYPES.DYNAMIC_TEASER: {
                return dynamicTeaserRules.deserialize(element, next, options);
            }
            case ELEMENT_TYPES.SEPARATOR: {
                return separatorRules.deserialize(element);
            }
        }
    },

    serialize: (node, next, options?) => {
        if (!Element.isLayoutElement(node)) {
            return;
        }

        switch (node.type) {
            case ELEMENT_TYPES.INFOBOX: {
                return infoboxRules.serialize(node, next, options);
            }
            case ELEMENT_TYPES.QUOTE: {
                return quoteRules.serialize(node, next, options);
            }
            case ELEMENT_TYPES.INTERVIEW_SEGMENT: {
                return interviewRules.serialize(node, next, options);
            }
            case ELEMENT_TYPES.DYNAMIC_TEASER: {
                return dynamicTeaserRules.serialize(node, next, options);
            }
            case ELEMENT_TYPES.SEPARATOR: {
                return separatorRules.serialize(node);
            }
        }
    },
};

export default layoutRules;
