import React from 'react';

import { type SnippetElement } from '@@editor/helpers/Element';
import { PluginsWrapper } from '@@editor/plugins/serializable/components/PluginsWrapper';
import { PLUGIN_NAMES } from '@@editor/typings/UnityPlugins';
import RichTextEditor from '@@form/components/RichTextEditor/RichTextEditor';

type Props = {
    element: SnippetElement;
    useInlineEditing?: boolean;
    readOnly?: boolean;
};

const Snippet = ({ element }: Props) => {
    const includedPlugins = [PLUGIN_NAMES.INFOBOX, PLUGIN_NAMES.POLL];
    const { content } = element.data;

    return (
        <PluginsWrapper>
            <RichTextEditor
                value={content}
                includedPlugins={includedPlugins}
                readOnly
                allowAttachments={false}
            />
        </PluginsWrapper>
    );
};

export default Snippet;
