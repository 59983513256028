import { MIME_TYPES } from '@@editor/helpers/Element';
import renderElement from '@@editor/plugins/utils/renderElement';
import { type GenerateEmbedBlockData } from '@@editor/typings/Embed';

import { NODE_TYPE, TYPE } from './constants';
import { createGenerateEmbedBlock } from '../utils';
import EmbeddedSnippetNode from './components/EmbeddedSnippetNode';

export const generateEmbedBlockData: GenerateEmbedBlockData = ({ embed }) => ({
    mimetype: MIME_TYPES.EMBEDDED_SNIPPET,
    embed,
});

export const generateEmbedBlock = createGenerateEmbedBlock({
    type: TYPE,
    nodeType: NODE_TYPE,
    generateEmbedBlockData,
});

const internalWithEmbedSnippet = (editor, options) =>
    Object.assign(editor, {
        renderElement: renderElement(editor, [[NODE_TYPE, EmbeddedSnippetNode]], options),
    });

export const withEmbedSnippet = (editor, options) =>
    internalWithEmbedSnippet(editor, {
        ...options,
        nodeType: [NODE_TYPE],
    });

export default withEmbedSnippet;
