import React from 'react';
import { Transforms } from 'slate';

import { type Editor, Element } from '@@editor/helpers';
import renderEditor from '@@editor/plugins/utils/renderEditor';
import { PLUGIN_NAMES } from '@@editor/typings/UnityPlugins';

import EmbeddedComponentForm from './components/EmbeddedComponentForm';
import { COMPONENT_TYPE } from './constants';
import EditorWithEmbedModal from '../embed/components/EditorWithEmbedModal';
import { generateEmbedBlock as generateEmbedIframe } from '../embed/embeddedIframe/plugin';
import { generateEmbedBlock as generateEmbedInfobox } from '../embed/embeddedInfobox/plugin';
import { generateEmbedBlock as generateEmbedPoll } from '../embed/embeddedPoll/plugin';
import { generateEmbedBlock as generateEmbedSnippet } from '../embed/embeddedSnippet/plugin';
import { generateLayoutBlock as generateLayoutInfobox } from '../layout/infobox/plugin';
import { generateLayoutBlock as generateLayoutPoll } from '../poll/plugin';
import { generateLayoutBlocks as generateSnippetContent } from '../snippet/plugin';

const withEmbeddedComponent = (editor: Editor, options) => {
    const { isVoid, useInlineEditing } = editor;

    return Object.assign(editor, {
        isVoid: (element) => {
            if (
                useInlineEditing &&
                (Element.isImageRelatedElement(element) ||
                    Element.isEmbeddedContentRelatedElement(element))
            ) {
                return false;
            }

            return Element.isEmbedElement(element) ? true : isVoid(element);
        },
        renderEditor: renderEditor(
            editor,
            (props) => (
                <EditorWithEmbedModal
                    {...props}
                    formProps={{ editor, formName: 'EmbeddedComponentForm' }}
                    formComponent={EmbeddedComponentForm}
                    generateEmbedBlock={() => {}}
                    type={PLUGIN_NAMES.EMBEDDED_COMPONENT}
                />
            ),
            options,
        ),
        insertEmbedComponent: (formData, type, options) => {
            const generateEmbedBlock = {
                [COMPONENT_TYPE.INFOBOX]: generateEmbedInfobox,
                [COMPONENT_TYPE.POLL]: generateEmbedPoll,
                [COMPONENT_TYPE.IFRAME]: generateEmbedIframe,
                [COMPONENT_TYPE.SNIPPET]: generateEmbedSnippet,
            }[type];

            generateEmbedBlock(editor, formData.embed, options);
        },
        insertLayoutComponent: (formData, type, options) => {
            const generateLayoutBlock = {
                [COMPONENT_TYPE.INFOBOX]: generateLayoutInfobox,
                [COMPONENT_TYPE.POLL]: generateLayoutPoll,
                [COMPONENT_TYPE.SNIPPET]: generateSnippetContent,
            }[type];

            if (options.replace) {
                Transforms.removeNodes(editor, { at: options.path });
                generateLayoutBlock(editor, formData, { at: options.at });
                Transforms.select(editor, options.at);
                Transforms.collapse(editor);
            } else {
                generateLayoutBlock(editor, formData, { at: options.at });
            }
        },
    });
};

export default withEmbeddedComponent;
