import { type RouteObject } from 'react-router-dom';

export const routes: RouteObject[] = [
    {
        path: '*',
        children: [
            {
                path: '*',
                lazy: async () => {
                    const { EditionList } = await import(
                        /* webpackChunkName: "editionList" */ '@@routes/editions/pages/EditionList'
                    );

                    return { Component: EditionList };
                },
            },
            {
                path: ':tenantId/:targetId/edition/:editionId/*',
                lazy: async () => {
                    const { EditionDetailPage } = await import(
                        /* webpackChunkName: "editionDetailPage" */ '@@routes/editions/pages/EditionDetailPage'
                    );

                    return { Component: EditionDetailPage };
                },
            },
        ],
    },
];
