import { initContract } from '@ts-rest/core';
import { initTsrReactQuery } from '@ts-rest/react-query/v5';
import { useStore } from 'react-redux';
import { z } from 'zod';

import { commonResponses } from '@@api/commonResponses';
import { clientDefaults } from '@@api/constants/clientDefaults';
import { DefaultHeaders } from '@@api/headers';
import { Revision } from '@@api/services/revisions/schemas';
import { getQueryKeys } from '@@api/utils/queryKeys';
import config from '@@config';
import { type RootState } from '@@scripts/store/store';

const contract = initContract();

const router = contract.router(
    {
        revisionMetadata: contract.router({
            getDiffs: {
                method: 'GET',
                path: '/revision/metadata/:id/diffs',
                pathParams: z.object({
                    id: z.coerce.number(),
                }),
                responses: {
                    200: z.array(Revision),
                },
            },
        }),
    },
    {
        baseHeaders: DefaultHeaders,
        commonResponses,
    },
);

const revisionsClient = (state: RootState) => {
    const clientArgs = {
        baseUrl: config.apiEnvVars.revisionsUrl,
        ...clientDefaults(state),
    };

    const client = initTsrReactQuery(router, clientArgs);
    const queryKeys = getQueryKeys(router, clientArgs);

    return { client, queryKeys };
};

export const useRevisionsClient = () => {
    const store = useStore<RootState>();
    const state = store.getState();

    return revisionsClient(state);
};
