import { type Metadata } from '@@api/services/metadata/schemas';
import { type Tenant } from '@@api/services/tenant/schemas';
import { openInNewTab } from '@@utils/browser';

const getMetadataIdURL = (metadataId: Metadata['id']) => `/preview/metadata/${metadataId}`;
const getURL = (metadataId: Metadata['id'], tenantId?: Tenant['id'] | null) =>
    tenantId ? `${getMetadataIdURL(metadataId)}/${tenantId}` : getMetadataIdURL(metadataId);

const showPreview = (metadataId: Metadata['id'], tenantId?: Tenant['id'] | null) =>
    openInNewTab(getURL(metadataId, tenantId));

export default showPreview;
