import { Stack, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const BigText = styled('span')(({ theme }) => ({
    ...theme.typography.title3,
    fontSize: '85px',
    lineHeight: '85px',
}));

const WrapperStack = styled(Stack)({
    width: '100%',
    height: '100%',
});

const Title = styled('h1')(({ theme }) => ({
    ...theme.typography.title2,
    marginBottom: theme.spacing(2),
    marginTop: 0,
}));

const Subtitle = styled('h2')(({ theme }) => ({
    ...theme.typography.large,
    margin: '0 0 50px 0',
}));

export const Error403 = () => {
    const { t } = useTranslation();

    return (
        <WrapperStack alignItems="center" justifyContent="center">
            <BigText>403</BigText>
            <Title>{t('403.title')}</Title>
            <Subtitle>{t('403.subtitle')}</Subtitle>
        </WrapperStack>
    );
};
