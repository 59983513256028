import { omit } from 'lodash';

import { type Element } from '@@editor/helpers';
import { type CustomText } from '@@editor/helpers/Element';
import { type UnityElement } from '@@editor/typings/UnityElements';
import isState from '@@editor/utils/isState';
import makeState from '@@editor/utils/makeState';
import { cleanArray } from '@@utils/arrayUtils';

import { default as isSerializedState } from './isSerializedState';
import rules from './rules';
import { type SerializerOptions } from './types';

class UnitySerializer {
    private options: SerializerOptions;

    constructor(options = {}) {
        this.options = options;
    }

    static isSerializedState = isSerializedState;

    // To have `this.options` available in subsequent calls to `deserializeNode`, we need to use the
    // arrow function notation here, in order to bind that function to `this`
    deserializeNode = (node: UnityElement): Element | undefined | void => {
        let res: Element | undefined | void;

        for (let i = 0; i < rules.length; i++) {
            res = rules[i].deserialize(
                omit<UnityElement>(node, 'elementId'),
                this.deserializeNode,
                this.options,
            );

            if (res != null) {
                if ('elementId' in node) {
                    res.id = node.elementId;
                }

                break;
            }
        }

        return res;
    };

    // To have `this.options` available in subsequent calls to `serializeNode`, we need to use the
    // arrow function notation here, in order to bind that function to `this`
    serializeNode = (
        node: Element | CustomText,
        customRules?: any[],
    ): UnityElement | undefined | void => {
        let res: UnityElement | undefined | void;
        const r = Array.isArray(customRules) ? customRules : rules;

        for (let i = 0; i < r.length; i++) {
            // implemented next-pattern
            res = r[i].serialize(
                omit<Element | CustomText>(node, 'id'),
                this.serializeNode,
                this.options,
            );

            if (res != null) {
                if ('id' in node) {
                    res.elementId = node.id;
                }

                break;
            }
        }

        return res;
    };

    deserialize(serializedState: UnityElement[]): Element[] {
        if (!Array.isArray(serializedState)) {
            return makeState();
        }

        // TODO: due to null values in editor state, we just remove them for now
        // TODO: should be removed alongside CD2-3674
        const nonEmptySerializedState: UnityElement[] = cleanArray(serializedState);
        const deserializedNodes: Element[] = nonEmptySerializedState
            .map(this.deserializeNode, this)
            .filter((v) => v != null) as Element[];

        return makeState(deserializedNodes);
    }

    serialize(value: any): UnityElement[] {
        if (!isState(value)) {
            return [];
        }

        return value.map(this.serializeNode, this);
    }
}

// works with predefined rules, no need for custom rules currently
export default UnitySerializer;
