import { flatten, get, omit } from 'lodash';

import { Element, Node } from '@@editor/helpers';
import { ELEMENT_TYPES } from '@@editor/helpers/Element';
import { createTextNode } from '@@editor/serialization/UnitySerializer/deserializeNodes';
import { cleanObject } from '@@utils/object';

import { CURRENT_VERSION } from './../constants';
import markRules from './markRules';

export const getLink = (node) => {
    if (Element.isExternalLinkElement(node)) {
        return {
            text: Node.string(node),
            url: node.data.href,
        };
    } else if (Element.isInternalLinkElement(node)) {
        return {
            text: Node.string(node),
            metadataId: node.data.metadataId,
        };
    }

    return;
};

const textRules = {
    deserialize: (element) => {
        switch (element.type) {
            case ELEMENT_TYPES.TITLE_HEADER:
            case ELEMENT_TYPES.TITLE:
            case ELEMENT_TYPES.LEAD:
            case ELEMENT_TYPES.CROSSHEAD:
            case ELEMENT_TYPES.FOOTER:
            case ELEMENT_TYPES.TEXT:
                return createTextNode(element, markRules.deserialize);
        }
    },

    serialize: (node, next) => {
        const type = node.type;

        if (
            ![
                ELEMENT_TYPES.PARAGRAPH,
                ELEMENT_TYPES.CROSSHEAD,
                ELEMENT_TYPES.TITLE_HEADER,
                ELEMENT_TYPES.TITLE,
                ELEMENT_TYPES.LEAD,
                ELEMENT_TYPES.FOOTER,
            ].includes(type)
        ) {
            return;
        }

        const items = node.children.map((childNode) => {
            const link = getLink(childNode);

            if (link) {
                return link.metadataId
                    ? {
                          type: ELEMENT_TYPES.INTERNAL_LINK,
                          version: CURRENT_VERSION,
                          ...omit(link, ['url']),
                          ...cleanObject({ attributes: omit(childNode.children[0], ['text']) }),
                      }
                    : {
                          type: ELEMENT_TYPES.EXTERNAL_LINK,
                          version: CURRENT_VERSION,
                          ...omit(link, ['metadataId']),
                          ...cleanObject({ attributes: omit(childNode.children[0], ['text']) }),
                      };
            }

            return {
                version: CURRENT_VERSION,
                type: ELEMENT_TYPES.TEXTITEM,
                text: childNode.text,
                ...cleanObject({ attributes: next(omit(childNode, ['text'])) }),
            };
        });

        const flatItems = flatten(items);

        const output = {
            // exception is made for "paragraph" which maps to "text"
            ...(node.data || {}),
            version: CURRENT_VERSION,
            type: type === ELEMENT_TYPES.PARAGRAPH ? ELEMENT_TYPES.TEXT : type,
            items: flatItems.filter((i) => get(i, 'text')),
            // We need this for title, titleHeader and lead elements
            ...(node.variants ? { variants: node.variants } : {}),
        };

        return output;
    },
};

export default textRules;
