import { Text } from 'slate';
import { jsx } from 'slate-hyperscript';

import { type MetadataRouter } from '@@api/services/metadata/client';
import { getQueryParams } from '@@api/utils/getQueryParams';
import {
    Element as SlateElement,
    ELEMENT_TYPES,
    type ImageElement,
    MIME_TYPES,
} from '@@editor/helpers/Element';
import { type LinkPluginOptions } from '@@editor/plugins/serializable/text/link/types';
import extractMetadataIdFromUrl from '@@scripts/utils/extractMetadataIdFromUrl';

const ELEMENT_TAGS = {
    A: (el) => ({
        type: ELEMENT_TYPES.EXTERNAL_LINK,
        data: { href: el.href, templateElement: false },
        children: [{ text: el.textContent }],
    }),
    BLOCKQUOTE: () => ({ type: ELEMENT_TYPES.QUOTE }),
    H1: () => ({ type: ELEMENT_TYPES.CROSSHEAD, data: { templateElement: false } }),
    H2: () => ({ type: ELEMENT_TYPES.CROSSHEAD, data: { templateElement: false } }),
    H3: () => ({ type: ELEMENT_TYPES.CROSSHEAD, data: { templateElement: false } }),
    H4: () => ({ type: ELEMENT_TYPES.CROSSHEAD, data: { templateElement: false } }),
    H5: () => ({ type: ELEMENT_TYPES.CROSSHEAD, data: { templateElement: false } }),
    H6: () => ({ type: ELEMENT_TYPES.CROSSHEAD, data: { templateElement: false } }),
    IMG: () =>
        SlateElement.create<ImageElement>({
            type: ELEMENT_TYPES.IMAGE,
            data: {
                mimetype: MIME_TYPES.PNG,
                templateElement: false,
                embed: {},
            },
            children: [{ text: '' }],
        }),
    LI: () => ({ type: ELEMENT_TYPES.LIST_ITEM }),
    OL: () => ({ type: ELEMENT_TYPES.ORDERED_LIST }),
    P: () => ({ type: ELEMENT_TYPES.PARAGRAPH, data: { templateElement: false } }),
    PRE: () => ({ type: ELEMENT_TYPES.TEXT }),
    UL: () => ({ type: ELEMENT_TYPES.UNORDERED_LIST }),
};

// COMPAT: `B` is omitted here because Google Docs uses `<b>` in weird ways.
const TEXT_TAGS = {
    CODE: () => ({ code: true }),
    DEL: () => ({ strikethrough: true }),
    EM: () => ({ italic: true }),
    I: () => ({ italic: true }),
    S: () => ({ strikethrough: true }),
    STRONG: () => ({ bold: true }),
    U: () => ({ underline: true }),
};

const createTextNodeFromSpan = (el: Element) => {
    const spanStyle = el.getAttribute('style');
    const boldThreshold = 700;
    let decorations = {};

    if (spanStyle) {
        const htmlSPan = document.createElement('span');

        htmlSPan.setAttribute('style', spanStyle);
        if (htmlSPan.style.fontWeight && Number(htmlSPan.style.fontWeight) >= boldThreshold) {
            decorations = { ...decorations, bold: true };
        }
        if (htmlSPan.style.fontStyle.includes('italic')) {
            decorations = { ...decorations, italic: true };
        }
        if (htmlSPan.style.textDecoration.includes('underline')) {
            decorations = { ...decorations, underlined: true };
        }
    }

    return { text: el.textContent, ...decorations };
};

const resolveChildren = (el: Element) => {
    const { nodeName } = el;

    let parent = el;

    if (nodeName === 'PRE' && el.children[0] && el.children[0].nodeName === 'CODE') {
        parent = el.children[0];
    }
    let children: any[] = Array.from(parent.children).map(deserialize).flat();

    if (children.length === 0) {
        children = [{ text: '' }];
    }

    return children;
};

const deserializeElementTag = (el: Element, children: any) => {
    // Google doc sometimes has a P tag inside a List Item, this causes problems
    // in slatejs/unity. So in those cases, we skip the P tag.
    if (el.parentElement?.nodeName === 'LI' && el.nodeName === 'P') {
        return children;
    }
    const isImage = (c) => c && c.type === ELEMENT_TYPES.IMAGE;

    // Special case, when an image appears inside a P tag.
    // We skip the P tag and return the children.
    // So that, slate can render the image using the image plugin.
    if (el.nodeName === 'P') {
        const img = children.find((c) => isImage(c));

        if (img) {
            return children;
        }
    }

    const attrs = ELEMENT_TAGS[el.nodeName](el);

    return jsx('element', attrs, children);
};

const deserializeTextTag = (el: Element, children: any) => {
    const attrs = TEXT_TAGS[el.nodeName](el);

    return children.map((child) => {
        if (Text.isText(child)) {
            return jsx('text', attrs, child);
        }

        return child;
    });
};

export const updateLinkOrigin = async (
    element: SlateElement,
    fetchMetadata: LinkPluginOptions['fetchMetadata'],
) => {
    if (!SlateElement.isExternalLinkElement(element)) {
        return element;
    }
    const metadataId = extractMetadataIdFromUrl(element.data.href);
    const externalLink = {
        type: ELEMENT_TYPES.EXTERNAL_LINK,
        data: { href: element.data.href, templateElement: false },
        children: element.children,
    };
    const internalLink = {
        type: ELEMENT_TYPES.INTERNAL_LINK,
        data: { metadataId: Number(metadataId), templateElement: false },
        children: element.children,
    };

    let isInternalLink = false;

    if (metadataId) {
        try {
            const params = getQueryParams<MetadataRouter['metadata']['getList']>({
                ids: metadataId,
                size: 1,
            });

            await fetchMetadata({ params }).then(({ body }) => {
                const [metadata] = body.content || [];

                isInternalLink = Boolean(metadata);
            });
        } catch {
            return externalLink;
        }
    }

    return isInternalLink ? internalLink : externalLink;
};

const deserialize = (el: Element) => {
    if (el.nodeType === Node.TEXT_NODE) {
        return el.textContent;
    } else if (el.nodeType !== Node.ELEMENT_NODE) {
        return null;
    } else if (
        el.nodeType === Node.ELEMENT_NODE &&
        el.nodeName === 'SPAN' &&
        el.children.length === 0
    ) {
        return createTextNodeFromSpan(el);
    } else if (el.nodeType === Node.ELEMENT_NODE && el.nodeName === 'TABLE') {
        return null;
    }

    const children = resolveChildren(el);

    if (el.nodeName === 'BODY') {
        return jsx('fragment', {}, children);
    }

    if (ELEMENT_TAGS[el.nodeName]) {
        return deserializeElementTag(el, children);
    }

    if (TEXT_TAGS[el.nodeName]) {
        return deserializeTextTag(el, children);
    }

    return children;
};

export default deserialize;
