import { initContract } from '@ts-rest/core';
import { initTsrReactQuery } from '@ts-rest/react-query/v5';
import { useStore } from 'react-redux';
import { z } from 'zod';

import { commonResponses } from '@@api/commonResponses';
import { clientDefaults } from '@@api/constants/clientDefaults';
import { DefaultHeaders, PatchDefaultHeaders } from '@@api/headers';
import { Attachment, ATTACHMENT_STATE, SourceType } from '@@api/services/attachments/schemas';
import { getQueryKeys } from '@@api/utils/queryKeys';
import config from '@@config';
import { type RootState } from '@@scripts/store/store';

const contract = initContract();

const router = contract.router(
    {
        attachments: contract.router({
            delete: {
                method: 'DELETE',
                path: '/attachments/:id',
                pathParams: z.object({
                    id: Attachment.shape.id,
                }),
                body: z.undefined(),
                responses: {
                    204: z.string().optional(),
                },
            },
            patch: {
                method: 'PATCH',
                path: '/attachments/:id',
                pathParams: z.object({
                    id: Attachment.shape.id,
                }),
                body: z.object({
                    state: z.nativeEnum(ATTACHMENT_STATE),
                }),
                responses: {
                    200: Attachment,
                },
                headers: PatchDefaultHeaders,
            },
            post: {
                method: 'POST',
                path: '/attachments/:microservice/:resource/:id',
                pathParams: z.object({
                    microservice: z.string(),
                    resource: z.string(),
                    id: z.string().uuid(),
                }),
                body: z.object({
                    sourceId: z.string(),
                    sourceType: SourceType,
                    state: z.enum([ATTACHMENT_STATE.USED, ATTACHMENT_STATE.UNUSED]),
                }),
                responses: {
                    201: Attachment,
                },
            },
            getAll: {
                method: 'GET',
                path: '/attachments/:microservice/:resource/:id',
                pathParams: z.object({
                    microservice: z.string(),
                    resource: z.string(),
                    id: z.string().uuid(),
                }),
                responses: {
                    200: z.array(Attachment),
                },
            },
        }),
    },
    {
        baseHeaders: DefaultHeaders,
        commonResponses,
    },
);

const attachmentsClient = (state: RootState) => {
    const clientArgs = {
        baseUrl: config.apiEnvVars.attachmentsUrl,
        ...clientDefaults(state),
    };

    const client = initTsrReactQuery(router, clientArgs);
    const queryKeys = getQueryKeys(router, clientArgs);

    return { client, queryKeys };
};

export const useAttachmentsClient = () => {
    const store = useStore<RootState>();
    const state = store.getState();

    return attachmentsClient(state);
};
