import { initContract } from '@ts-rest/core';
import { initTsrReactQuery } from '@ts-rest/react-query/v5';
import { useStore } from 'react-redux';
import { z } from 'zod';

import { commonResponses } from '@@api/commonResponses';
import { clientDefaults } from '@@api/constants/clientDefaults';
import { DefaultHeaders } from '@@api/headers';
import { UrlPreview } from '@@api/services/functions/schemas';
import { getQueryKeys } from '@@api/utils/queryKeys';
import config from '@@config';
import { type RootState } from '@@scripts/store/store';
const contract = initContract();

const router = contract.router(
    {
        checkIframeAllowed: {
            method: 'GET',
            path: '/iframe-allowed',
            query: z.object({
                url: z.string(),
            }),
            responses: {
                200: z.object({
                    allowed: z.boolean().nullable(),
                }),
            },
        },
        checkUrlPreview: {
            method: 'GET',
            path: '/url-preview',
            query: z.object({
                url: z.string(),
            }),
            responses: {
                200: UrlPreview,
            },
        },
    },
    {
        baseHeaders: DefaultHeaders,
        commonResponses,
    },
);

export type FunctionRouter = typeof router;

const functionsClient = (state: RootState) => {
    const clientArgs = {
        baseUrl: config.apiEnvVars.functionsUrl,
        ...clientDefaults(state),
    };

    const client = initTsrReactQuery(router, clientArgs);
    const queryKeys = getQueryKeys(router, clientArgs);

    return { client, queryKeys };
};

export const useFunctionsClient = () => {
    const store = useStore<RootState>();
    const state = store.getState();

    return functionsClient(state);
};
