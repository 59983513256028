import React, { type Dispatch, useContext } from 'react';

type State = {
    formName: string;
    registeredFields: string[];
    registeredFieldArrays: string[];
};

export type ReactHookFormMetaContext = {
    formMetaState: State;
    registerField: (fieldName: string) => void;
    registerFieldArray: (fieldName: string) => void;
    unregisterField: (fieldName: string) => void;
    unregisterFieldArray: (fieldName: string) => void;
};

// @ts-expect-error I do not want to change the type, since during runtime the context will never be undefined
const ReactHookFormMetaContext = React.createContext<ReactHookFormMetaContext>();

type Props = ReactHookFormMetaContext & { children: React.ReactNode };

export const ReactHookFormMetaProvider = ({ children, ...value }: Props) => (
    <ReactHookFormMetaContext.Provider value={value}>{children}</ReactHookFormMetaContext.Provider>
);

export const useReactHookFormMetaContext = () => useContext(ReactHookFormMetaContext);

enum ActionsTypes {
    REGISTER_FIELD = 'REGISTER_FIELD',
    UNREGISTER_FIELD = 'UNREGISTER_FIELD',
    REGISTER_FIELD_ARRAY = 'REGISTER_FIELD_ARRAY',
    UNREGISTER_FIELD_ARRAY = 'UNREGISTER_FIELD_ARRAY',
}

type Action = {
    type: ActionsTypes;
    fieldName: string;
};

export const reducer = (state: State, action: Action) => {
    switch (action.type) {
        case ActionsTypes.REGISTER_FIELD:
            if (!state.registeredFields.includes(action.fieldName)) {
                return {
                    ...state,
                    registeredFields: [...state.registeredFields, action.fieldName],
                };
            }

            break;

        case ActionsTypes.UNREGISTER_FIELD:
            return {
                ...state,
                registeredFields: state.registeredFields.reduce<string[]>((result, value) => {
                    if (value === action.fieldName) {
                        return result;
                    }

                    return result.concat(value);
                }, []),
            };

        case ActionsTypes.REGISTER_FIELD_ARRAY:
            if (!state.registeredFieldArrays.includes(action.fieldName)) {
                return {
                    ...state,
                    registeredFieldArrays: [...state.registeredFieldArrays, action.fieldName],
                };
            }

            break;

        case ActionsTypes.UNREGISTER_FIELD_ARRAY:
            return {
                ...state,
                registeredFieldArrays: state.registeredFieldArrays.reduce<string[]>(
                    (result, value) => {
                        if (value === action.fieldName) {
                            return result;
                        }

                        return result.concat(value);
                    },
                    [],
                ),
            };
    }

    return state;
};

export const createActions = (dispatch: Dispatch<Action>) => ({
    registerField(fieldName: string) {
        dispatch({ type: ActionsTypes.REGISTER_FIELD, fieldName });
    },
    unregisterField(fieldName: string) {
        dispatch({ type: ActionsTypes.UNREGISTER_FIELD, fieldName });
    },
    registerFieldArray(fieldName: string) {
        dispatch({ type: ActionsTypes.REGISTER_FIELD_ARRAY, fieldName });
    },
    unregisterFieldArray(fieldName: string) {
        dispatch({ type: ActionsTypes.UNREGISTER_FIELD_ARRAY, fieldName });
    },
});
