type Dimensions = {
    width: number;
    height: number;
    containerWidth: number;
    containerHeight: number;
};

const getCoveredSize = ({
    width,
    height,
    containerWidth,
    containerHeight,
}: Dimensions): [number, number] => {
    if (width > 0 && height > 0) {
        const naturalRatio = width / height;
        const resizedWidth = containerHeight * naturalRatio;
        const resizedHeight = containerWidth / naturalRatio;

        if (resizedWidth <= containerWidth) {
            // Cover uses up width, so height will be bigger than the container's height
            return [containerWidth, Math.round(containerWidth / naturalRatio)];
        } else if (resizedHeight <= containerHeight) {
            // Cover uses up height, so width will be bigger than the container's width
            return [Math.round(containerHeight * naturalRatio), containerHeight];
        }
    }

    return [containerWidth, containerHeight];
};

export default getCoveredSize;
