import { initContract } from '@ts-rest/core';
import { initTsrReactQuery } from '@ts-rest/react-query/v5';
import { useStore } from 'react-redux';
import { z } from 'zod';

import { commonResponses } from '@@api/commonResponses';
import { clientDefaults } from '@@api/constants/clientDefaults';
import { DefaultHeaders } from '@@api/headers';
import { UnityBreakingNews } from '@@api/services/breakingnews/schemas';
import {
    Article,
    ArticleTemplate,
    DetailedUnityRevision,
    Revision,
    UnityArticle,
    UnityCompare,
} from '@@api/services/content/schemas/article';
import { Comment } from '@@api/services/content/schemas/comment';
import {
    deserializeArticle,
    deserializeDetailedRevision,
    serializeArticle,
} from '@@api/services/content/transformers';
import { Metadata } from '@@api/services/metadata/schemas';
import { getQueryKeys } from '@@api/utils/queryKeys';
import { PaginationParams, SearchParams } from '@@api/utils/schemas/queryParams';
import { paginated } from '@@api/utils/schemas/utils';
import config from '@@config';
import { deserializeCompareType } from '@@containers/Compare/commons/utils';
import { type RootState } from '@@scripts/store/store';

const contract = initContract();

const router = contract.router(
    {
        article: contract.router({
            get: {
                method: 'GET',
                path: '/articles/:id',
                pathParams: z.object({
                    id: UnityArticle.shape.id,
                }),
                responses: {
                    200: UnityArticle.transform(deserializeArticle),
                },
                headers: DefaultHeaders,
            },
            getAll: {
                method: 'GET',
                path: '/articles',
                query: z.object({
                    ...SearchParams.shape,
                    ...PaginationParams.shape,
                }),
                responses: {
                    200: paginated(UnityArticle.transform(deserializeArticle)),
                },
            },
            post: {
                method: 'POST',
                path: '/articles',
                query: z.object({
                    targetLanguage: z.string().optional(),
                    contentLocale: z.string().optional(),
                }),
                body: z
                    .object({
                        ...Article.pick({ content: true, variants: true }).shape,
                        metadata: Metadata.pick({
                            contentName: true,
                            mainCategoryId: true,
                            tenantIds: true,
                        }),
                    })
                    .transform(serializeArticle),
                responses: {
                    201: UnityArticle.transform(deserializeArticle),
                },
            },
            clone: {
                method: 'POST',
                path: '/articles',
                query: z.object({
                    sourceId: UnityArticle.shape.id.optional(),
                    targetLanguage: z.string().optional(),
                    contentLocale: z.string().optional(),
                }),
                body: z
                    .object({
                        metadata: Metadata.pick({
                            contentName: true,
                            mainCategoryId: true,
                            tenantIds: true,
                        }),
                    })
                    .transform(serializeArticle),
                responses: {
                    201: UnityArticle.transform(deserializeArticle),
                },
            },
            put: {
                method: 'PUT',
                path: '/articles/:id',
                pathParams: z.object({
                    id: UnityArticle.shape.id,
                }),
                body: Article.transform(serializeArticle),
                responses: {
                    200: UnityArticle.transform(deserializeArticle),
                    412: UnityCompare,
                },
                headers: DefaultHeaders,
            },
            delete: {
                method: 'DELETE',
                path: '/articles/:id',
                pathParams: z.object({
                    id: UnityArticle.shape.id,
                }),
                body: z.undefined(),
                responses: {
                    204: z.never(),
                },
            },
            replace: {
                method: 'POST',
                path: '/articles/:id/replace',
                pathParams: z.object({
                    id: UnityArticle.shape.id,
                }),
                query: z.object({
                    breakingnewsId: UnityBreakingNews.shape.id,
                }),
                body: z.undefined(),
                responses: {
                    200: z.object({ resourceId: z.string() }),
                },
            },
        }),
        articleDiff: contract.router({
            get: {
                method: 'GET',
                path: '/articles/:id/diff',
                pathParams: z.object({
                    id: UnityArticle.shape.id,
                }),
                query: z.object({
                    origin: z.literal('MASTER'),
                    derivative: Revision.shape.id,
                }),
                responses: {
                    200: UnityCompare.transform(deserializeCompareType),
                },
            },
        }),
        revisions: contract.router({
            getAll: {
                method: 'GET',
                path: '/articles/:id/revisions',
                pathParams: z.object({
                    id: UnityArticle.shape.id,
                }),
                responses: {
                    200: z.array(Revision),
                },
            },

            get: {
                method: 'GET',
                path: '/articles/:articleId/revisions/:revisionId',
                pathParams: z.object({
                    articleId: UnityArticle.shape.id,
                    revisionId: Revision.shape.id,
                }),
                responses: {
                    200: DetailedUnityRevision.transform(deserializeDetailedRevision),
                },
            },
        }),
        templates: contract.router({
            getAll: {
                method: 'GET',
                path: '/templates',
                responses: {
                    200: z.array(ArticleTemplate),
                },
            },
        }),
        comments: contract.router({
            getAll: {
                method: 'GET',
                path: '/articles/:id/comments',
                pathParams: z.object({
                    id: UnityArticle.shape.id,
                }),
                responses: {
                    200: z.array(Comment),
                },
            },
            postCommentResponse: {
                method: 'POST',
                path: '/articles/:articleId/comments/:commentId/comments',
                pathParams: z.object({
                    articleId: UnityArticle.shape.id,
                    commentId: Comment.shape.id,
                }),
                body: Comment.pick({ text: true }),
                responses: {
                    200: Comment,
                },
            },
            post: {
                method: 'POST',
                path: '/articles/:id/comments',
                pathParams: z.object({
                    id: UnityArticle.shape.id,
                }),
                body: Comment.pick({ text: true }),
                responses: {
                    201: Comment,
                },
            },
        }),
    },
    {
        baseHeaders: DefaultHeaders,
        commonResponses,
    },
);

export type ContentRouter = typeof router;

export const contentClient = (state: RootState) => {
    const clientArgs = {
        baseUrl: config.apiEnvVars.contentUrl,
        ...clientDefaults(state),
    };

    const client = initTsrReactQuery(router, clientArgs);
    const queryKeys = getQueryKeys(router, clientArgs);

    return { client, queryKeys };
};

export type ContentClient = ReturnType<typeof contentClient>;

export const useContentClient = () => {
    const store = useStore<RootState>();
    const state = store.getState();

    return contentClient(state);
};
