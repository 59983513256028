import { Range, Text } from 'slate';

import { Editor, Operation } from '@@editor/helpers';
import crypto from '@@utils/crypto';

import {
    type EditorSpellAdvice,
    type EditorSpellAdvices,
    type SpellAdviceWhitelist,
} from '../types';
import TextRangeMap, { type Options } from './TextRangeMap';

const EDITOR_TO_WHITELIST = new WeakMap<Editor, SpellAdviceWhitelist>();

const applyOperationCounter = [];

export const initializeWhitelist = (editor: Editor, options?: Options) => {
    const whitelist = new TextRangeMap(options);

    EDITOR_TO_WHITELIST.set(editor, whitelist);
};

const getWhitelist = (editor: Editor) => {
    const whitelist = EDITOR_TO_WHITELIST.get(editor);

    if (!whitelist) {
        throw new Error('`whitelist` needs to be initialized before it can be used!');
    }

    return whitelist;
};

export const isSpellAdviceWhitelisted = (editor: Editor, spellAdvice: EditorSpellAdvice) => {
    const whitelist = getWhitelist(editor);

    return (
        // The `findByRange` function name could be a bit misleading in the current context. In fact,
        // it will only return whitelist items, which live on the same root indices, that are
        // "touched" by this range. But if the provided range only reaches until the middle of a block,
        // but the spell advice lives at the end of the block, it doesn't matter (it will be still returned),
        // because they share the same root index. This is why we need an additional check for the
        // equality of ranges by using `Range.equals`.
        typeof whitelist.findByRange(
            spellAdvice.range,
            (whitelistItem) =>
                whitelistItem.text === spellAdvice.text &&
                Range.equals(whitelistItem.range, spellAdvice.range),
        ) !== 'undefined'
    );
};

export const addSpellAdviceToWhitelist = (
    editor: Editor,
    spellAdvices: EditorSpellAdvices,
    spellAdviceToAdd: EditorSpellAdvice,
) => {
    Editor.withoutNormalizing(editor, () => {
        spellAdvices.forEach((spellAdvice) => {
            if (spellAdvice.text === spellAdviceToAdd.text) {
                const textNodes = Editor.nodes(editor, {
                    at: spellAdvice.range,
                    match: Text.isText,
                });

                for (const [, textNodePath] of textNodes) {
                    const whitelistId = crypto.randomUUID();

                    applyOperationCounter[whitelistId] = 0;

                    // We kind of "missuse" the `set_node` action here, in order to attach some whitelist related
                    // data to a slate operation. It is not possible to add custom operations, but it is possible
                    // to add additional information to existing ones. That's what we are doing here. This is
                    // necessary to make sure, that add/remove to/from whitelist is part of the editors history.
                    // For example, if you add a spell advice to the whitelist and you press CTRL + Z,
                    // it must be removed from the whitelist again.
                    editor.apply({
                        type: 'set_node',
                        path: textNodePath,
                        properties: {},
                        newProperties: {},
                        whitelistId,
                        whitelistRange: spellAdvice.range,
                    });
                }
            }
        });
    });
};

// In this function we are actually listening to the hijacked `set_node` operation, which will tell us,
// if we need to add/remove a spell advice to/from the whitelist. Becuase there is not other way to find out,
// if this is an add or redo operation or if this is a undo operation, we need to count, how often this operation
// was applied already. The first time, obviously, it will be an add operation. If it will be applied again,
// it must be an undo operation. If it will be applied again, it must be a redo operation, undo, redo, undo etc.
// This because, `slate-history` will re-use the exact same operation (but inversed) to apply undo/redo operations.
export const applySpellAdviceWhitelist = (editor: Editor, operation: Operation) => {
    if (Operation.isSetNodeWhitelistOperation(operation)) {
        const { whitelistId, whitelistRange } = operation;
        const whitelist = getWhitelist(editor);

        applyOperationCounter[whitelistId]++;

        if (applyOperationCounter[whitelistId] % 2 === 1) {
            const text = Editor.string(editor, whitelistRange);

            whitelist.set(whitelistId, { id: whitelistId, range: whitelistRange, text });
        } else {
            whitelist.delete(whitelistId);
        }
    }
};

// This function contains performance critical code! It is called on every keystroke.
// Every whitelist item relates to a certain range within the editor. Whenever the editors
// contents are changed, those ranges need to change (transform) accordingly. For example,
// if we have a spell advice on path `[0, 1]`, and the user inserts an image on top, this path
// needs to be transformed to `[1, 1]`
export const transformSpellAdviceWhitelist = (
    editor: Editor,
    operation: Operation,
    dirtyIndices: number[],
) => {
    const whitelist = getWhitelist(editor);

    whitelist.transformByIndices(dirtyIndices, operation);
};
