import { initContract } from '@ts-rest/core';
import { initTsrReactQuery } from '@ts-rest/react-query/v5';
import { useStore } from 'react-redux';
import { z } from 'zod';

import { commonResponses } from '@@api/commonResponses';
import { clientDefaults } from '@@api/constants/clientDefaults';
import { DefaultHeaders } from '@@api/headers';
import {
    Embed,
    Link,
    NewEmbed,
    NewLink,
    NewVideo,
    UnityEmbed,
    UnityLink,
    UnityVideo,
    Video,
} from '@@api/services/externalContent/schemas';
import {
    deserializeEmbed,
    deserializeLink,
    deserializeVideo,
    serializeEmbed,
    serializeLink,
    serializeNewEmbed,
    serializeNewLink,
    serializeNewVideo,
    serializeVideo,
} from '@@api/services/externalContent/transformers';
import { getQueryKeys } from '@@api/utils/queryKeys';
import { PaginationParams, SearchParams } from '@@api/utils/schemas/queryParams';
import { paginated } from '@@api/utils/schemas/utils';
import config from '@@config';
import { type RootState } from '@@scripts/store/store';

const contract = initContract();

const router = contract.router(
    {
        embed: contract.router({
            get: {
                method: 'GET',
                path: '/embeds/:id',
                pathParams: z.object({
                    id: Embed.shape.id,
                }),
                responses: {
                    200: UnityEmbed.transform(deserializeEmbed),
                },
            },
            getAll: {
                method: 'GET',
                path: '/embeds',
                query: z.object({
                    ...SearchParams.shape,
                    ...PaginationParams.shape,
                }),
                responses: {
                    200: paginated(UnityEmbed.transform(deserializeEmbed)),
                },
            },
            post: {
                method: 'POST',
                path: '/embeds',
                body: NewEmbed.transform(serializeNewEmbed),
                responses: {
                    201: UnityEmbed.transform(deserializeEmbed),
                },
            },
            put: {
                method: 'PUT',
                path: '/embeds/:id',
                pathParams: z.object({
                    id: Embed.shape.id,
                }),
                body: Embed.transform(serializeEmbed),
                responses: {
                    200: UnityEmbed.transform(deserializeEmbed),
                },
            },
            delete: {
                method: 'DELETE',
                path: '/embeds/:id',
                pathParams: z.object({
                    id: Embed.shape.id,
                }),
                body: z.undefined(),
                responses: {
                    204: z.never(),
                },
            },
        }),
        link: contract.router({
            get: {
                method: 'GET',
                path: '/links/:id',
                pathParams: z.object({
                    id: Link.shape.id,
                }),
                responses: {
                    200: UnityLink.transform(deserializeLink),
                },
            },
            getAll: {
                method: 'GET',
                path: '/links',
                query: z.object({
                    ...SearchParams.shape,
                    ...PaginationParams.shape,
                }),
                responses: {
                    200: paginated(UnityLink.transform(deserializeLink)),
                },
            },
            post: {
                method: 'POST',
                path: '/links',
                body: NewLink.transform(serializeNewLink),
                responses: {
                    201: UnityLink.transform(deserializeLink),
                },
            },
            put: {
                method: 'PUT',
                path: '/links/:id',
                pathParams: z.object({
                    id: Link.shape.id,
                }),
                body: Link.transform(serializeLink),
                responses: {
                    200: UnityLink.transform(deserializeLink),
                },
            },
            delete: {
                method: 'DELETE',
                path: '/links/:id',
                pathParams: z.object({
                    id: Link.shape.id,
                }),
                body: z.undefined(),
                responses: {
                    204: z.never(),
                },
            },
        }),
        video: contract.router({
            get: {
                method: 'GET',
                path: '/videos/:id',
                pathParams: z.object({
                    id: Video.shape.id,
                }),
                responses: {
                    200: UnityVideo.transform(deserializeVideo),
                },
            },
            getAll: {
                method: 'GET',
                path: '/videos',
                query: z.object({
                    ...SearchParams.shape,
                    ...PaginationParams.shape,
                }),
                responses: {
                    200: paginated(UnityVideo.transform(deserializeVideo)),
                },
            },
            post: {
                method: 'POST',
                path: '/videos',
                body: NewVideo.transform(serializeNewVideo),
                responses: {
                    201: UnityVideo.transform(deserializeVideo),
                },
            },
            put: {
                method: 'PUT',
                path: '/videos/:id',
                pathParams: z.object({
                    id: Video.shape.id,
                }),
                body: Video.transform(serializeVideo),
                responses: {
                    200: UnityVideo.transform(deserializeVideo),
                },
            },
            delete: {
                method: 'DELETE',
                path: '/videos/:id',
                pathParams: z.object({
                    id: Video.shape.id,
                }),
                body: z.undefined(),
                responses: {
                    204: z.never(),
                },
            },
        }),
    },
    {
        baseHeaders: DefaultHeaders,
        commonResponses,
    },
);

export type ExternalContentRouter = typeof router;

const externalContentClient = (state: RootState) => {
    const clientArgs = {
        baseUrl: config.apiEnvVars.externalContentUrl,
        ...clientDefaults(state),
    };

    const client = initTsrReactQuery(router, clientArgs);
    const queryKeys = getQueryKeys(router, clientArgs);

    return { client, queryKeys };
};

export type ExternalContentClient = ReturnType<typeof externalContentClient>;
export const useExternalContentClient = () => {
    const store = useStore<RootState>();
    const state = store.getState();

    return externalContentClient(state);
};
