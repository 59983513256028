import { identity, isEmpty, mapValues, pickBy } from 'lodash';
import isDataURI from 'validator/lib/isDataURI';

import { type CropMarks, type FocusPoint, type ImageVariants } from '@@api/utils/schemas/schemas';

export type IImageTransformations = {
    cropMarks?: CropMarks | null;
    focusPoint?: FocusPoint | null;
    maxWidth?: number;
    maxHeight?: number;
    mimetype?: string | null;
    variants?: ImageVariants | null;
};

export interface IImageTransformationsOutput {
    // crop
    cropWidth?: string | number;
    cropHeight?: string | number;
    cropOffsetX?: string | number;
    cropOffsetY?: string | number;
    // focuspoint
    focusPointX?: string | number;
    focusPointY?: string | number;
    // limits
    maxWidth?: string | number;
    maxHeight?: string | number;
}

const isFullSizeCrop = (cropMarks: CropMarks) => {
    const { width, height } = cropMarks;

    return Number(width) >= 1.0 && Number(height) >= 1.0;
};

export const SVG_MIME_TYPE = 'image/svg+xml';

const isSVG = (imageTransformations: IImageTransformations) =>
    imageTransformations && imageTransformations.mimetype === SVG_MIME_TYPE;

export const stringifyTransformations = (transformations: IImageTransformationsOutput): string => {
    const params = new URLSearchParams(
        mapValues(pickBy(transformations, identity), (value) =>
            typeof value === 'number' ? value.toString() : value,
        ),
    );

    return params.toString();
};

/**
 * Adds transformation parameters to an image url
 * @param imageUrl - Source url of the image
 * @param imageTransformations - A set of available transformations
 *
 * @return url with/without transformations, or null if the url or the transformations are invalid
 */
const buildImageTransformationUrl = (
    imageUrl?: string | null,
    imageTransformations?: IImageTransformations,
): string => {
    if (!imageUrl) {
        return '';
    }
    if (!imageTransformations || isDataURI(imageUrl) || isSVG(imageTransformations)) {
        return imageUrl;
    }

    let transformations: IImageTransformationsOutput = {};

    // handle allowed transformations
    const { cropMarks, focusPoint, maxWidth, maxHeight } = imageTransformations;

    if (cropMarks && !isFullSizeCrop(cropMarks)) {
        transformations = {
            ...transformations,
            // support pixel precision on any 1e5 pixel side-length sized images
            cropWidth: cropMarks.width.toFixed(4),
            cropHeight: cropMarks.height.toFixed(4),
            cropOffsetX: cropMarks.x.toFixed(4),
            cropOffsetY: cropMarks.y.toFixed(4),
        };
    }

    // Even though backend is not (yet) doing anything with the focus point value, we add it to the
    // images url, in order to trigger another `onLoad` event on the image after the
    // focus point has been changed (in order to recalculate its position).
    if (focusPoint) {
        transformations = {
            ...transformations,
            focusPointX: focusPoint.x.toFixed(4),
            focusPointY: focusPoint.y.toFixed(4),
        };
    }

    if (maxWidth && maxWidth > 0) {
        transformations = {
            ...transformations,
            // We want retina quality!
            maxWidth: 2 * maxWidth,
        };
    }

    if (maxHeight && maxHeight > 0) {
        transformations = {
            ...transformations,
            // We want retina quality!
            maxHeight: 2 * maxHeight,
        };
    }

    return isEmpty(transformations)
        ? imageUrl
        : `${imageUrl}?${stringifyTransformations(transformations)}`;
};

export default buildImageTransformationUrl;
