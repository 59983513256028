import isDataURI from 'validator/lib/isDataURI';

import { type ImageMimeType } from '@@constants/ImageMimeTypes';

import { splitDataUri, toURL } from './URL';

export const isSupportedUrl = (value: string, allowedMimeTypes: ImageMimeType[]): boolean => {
    const url = toURL(value);

    if (url instanceof URL) {
        if (isDataURI(url.href)) {
            const { mimeType } = splitDataUri(url.href);

            return allowedMimeTypes
                .map((type) => type.toLowerCase())
                .includes(mimeType.toLowerCase());
        }

        const supportedTypes = allowedMimeTypes.map((type) => type.split('/')[1]).join('|');
        const regex = new RegExp(`[.](${supportedTypes})$`, 'g');

        return regex.test(url.pathname);
    }

    return false;
};

export const isSupportedFile = (file: File, allowedMimeTypes: ImageMimeType[]): boolean =>
    allowedMimeTypes.includes(file.type.toLowerCase() as ImageMimeType);

export const stripUnsupportedFiles = (
    files: FileList | File[],
    allowedMimeTypes: ImageMimeType[],
): File[] => Array.from(files).filter((file) => isSupportedFile(file, allowedMimeTypes));
