import { Stack, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Icon from '@@components/Icon';
import { IFRAME_DEFAULT_HEIGHT } from '@@constants/Embeds';

const Wrapper = styled(Stack)(({ theme }) => ({
    ...theme.typography.subtitle3,
    background: theme.palette.primary['100'],
    color: theme.palette.primary.dark,
    height: `${IFRAME_DEFAULT_HEIGHT}px`,
}));

const StyledIcon = styled(Icon)({
    width: '50px',
    height: '50px',
});

const BrokenPreview = () => {
    const { t } = useTranslation();

    return (
        <Wrapper justifyContent="center" alignItems="center">
            <StyledIcon name="face-frown-slight-light" />
            <p>{t('embed.iframe.preview.broken')}</p>
        </Wrapper>
    );
};

export default BrokenPreview;
