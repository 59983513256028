import React from 'react';
import { type FieldPath, type FieldValues } from 'react-hook-form';

import SimpleRichTextEditor from '@@form/components/RichTextEditor/SimpleRichTextEditor';

import RichTextField, { type RichTextFieldProps } from './RichTextField';

export const SimpleRichTextEditorField = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
    props: RichTextFieldProps<TFieldValues, TName>,
) => <RichTextField {...props} component={SimpleRichTextEditor} />;

export default SimpleRichTextEditorField;
