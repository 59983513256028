import { Path } from 'slate';

import { type Editor, type Operation } from '@@editor/helpers';

const EDITOR_TO_DIRTY_INDICES = new WeakMap<Editor, number[]>();

export const getDirtyIndices = (editor: Editor) => {
    let dirtyIndices = EDITOR_TO_DIRTY_INDICES.get(editor);

    if (!dirtyIndices) {
        dirtyIndices = [];

        EDITOR_TO_DIRTY_INDICES.set(editor, dirtyIndices);
    }

    return dirtyIndices;
};

export const addDirtyIndex = (editor: Editor, index: number) => {
    const dirtyIndices = getDirtyIndices(editor);

    if (!dirtyIndices.includes(index)) {
        dirtyIndices.push(index);
    }
};

export const clearDirtyIndices = (editor: Editor) => {
    const dirtyIndices = getDirtyIndices(editor);

    dirtyIndices.length = 0;
};

export const transformDirtyIndices = (editor: Editor, operation: Operation) => {
    const dirtyIndices = getDirtyIndices(editor);

    // Remove all items from `dirtyIndices` and immediately re-add them transformed
    dirtyIndices.splice(0).reduce((result, index) => {
        const transformedPath = Path.transform([index], operation);

        if (transformedPath) {
            // Since we want to manipulate the original `dirtyIndices`-array, we need to push, instead of concat, here
            result.push(transformedPath[0]);
        }

        return result;
    }, dirtyIndices);
};
