import { type RouteObject } from 'react-router-dom';

export const routes: RouteObject[] = [
    {
        path: '',
        lazy: async () => {
            const { Layout } = await import(/* webpackChunkName: "tags" */ '@@routes/tags');

            return { Component: Layout };
        },
        children: [
            {
                path: ':id',
                lazy: async () => {
                    const { default: TagForm } = await import(
                        /* webpackChunkName: "tagForm" */ '@@routes/tags/TagForm'
                    );

                    return { Component: TagForm };
                },
            },
        ],
    },
];
