import { MIME_TYPES } from '@@editor/helpers/Element';
import { type GenerateEmbedBlockData } from '@@editor/typings/Embed';
import { isZattooUrl } from '@@utils/socialMedia/zattoo';

export const generateEmbedBlockData: GenerateEmbedBlockData = ({ src }) => {
    if (!isZattooUrl(src)) {
        return;
    }

    const { href } = src as URL;

    return {
        mimetype: MIME_TYPES.ZATTOO,
        src: href,
        originalSrc: href,
    };
};
