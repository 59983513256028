import {
    type NewSlideshow,
    type NewSlideshowSlide,
    type NewUnitySlideshow,
    type NewUnitySlideshowSlide,
    type Slideshow,
    type SlideshowSlide,
    type UnitySlideshow,
    type UnitySlideshowSlide,
} from '@@api/services/slideshow/schemas';
import deserializeState from '@@editor/serialization/deserializeState';
import serializeState from '@@editor/serialization/serializeState';
import transformValues from '@@editor/serialization/transformValues';
import createImage from '@@form/components/ImageEditor/createImage';

import {
    deserializeImage,
    deserializeVariants,
    serializeImage,
    serializeVariants,
} from '../../utils/transformers';
import { deserializeMetadata, serializeMetadata } from '../metadata/transformers';

export const deserializeSlideshow = (entity: UnitySlideshow): Slideshow => {
    const variants = deserializeVariants(entity.variants, (variant) => ({
        ...variant,
        lead: deserializeState(variant.lead),
        allowAutomaticTeaserUpdate: true,
    }));
    const metadata = entity.metadata ? deserializeMetadata(entity.metadata) : undefined;

    return { ...entity, metadata, variants };
};

export const serializeSlideshow = (entity: Slideshow): UnitySlideshow => {
    const variants = serializeVariants(entity.variants, (variant) => ({
        ...variant,
        lead: serializeState(variant.lead),
    }));
    const metadata = entity.metadata ? serializeMetadata(entity.metadata) : undefined;

    return { ...entity, metadata, variants };
};

export const serializeNewSlideshow = (entity: NewSlideshow): NewUnitySlideshow => {
    const variants = serializeVariants(entity.variants, (variant) => ({
        ...variant,
        lead: serializeState(variant.lead),
    }));

    return {
        ...entity,
        variants,
    };
};

export const deserializeSlideshowSlide = (entity: UnitySlideshowSlide): SlideshowSlide => {
    const deserializedVariants = deserializeVariants(entity.variants, (variant) =>
        deserializeImage(
            transformValues<typeof variant, SlideshowSlide['variants'][number]>(variant, [
                [['image.caption'], deserializeState],
            ]),
        ),
    );

    return {
        ...entity,
        variants: deserializedVariants.map((variant) => ({
            ...variant,
            image: createImage(variant.image),
            allowAutomaticTeaserUpdate: false,
        })),
    };
};

export const serializeSlideshowSlide = (
    entity: SlideshowSlide | NewSlideshowSlide,
): UnitySlideshowSlide | NewUnitySlideshowSlide => {
    const serializedVariants = serializeVariants(entity.variants, (variant) => {
        const serializedImage = serializeImage(variant);

        return transformValues<typeof serializedImage, UnitySlideshowSlide['variants'][string]>(
            serializedImage,
            [[['image.caption'], serializeState]],
        );
    });

    return {
        ...entity,
        variants: serializedVariants,
    };
};
