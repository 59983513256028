import { useEffect } from 'react';
import { type FieldValues, type UseFormReturn } from 'react-hook-form';

import useModalLeavePromptContext from '@@containers/LeavePrompt/ModalLeavePrompt/useModalLeavePromptContext';

type ModalLeavePromptFn = () => boolean;

export type ModalLeavePromptProps<
    TFieldValues extends FieldValues = FieldValues,
    TContext = any,
    TTransformedValues extends FieldValues | undefined = undefined,
> = {
    modalLeavePrompt?: ModalLeavePromptFn;
    formContext: UseFormReturn<TFieldValues, TContext, TTransformedValues>;
};
const useModalLeavePrompt = <TFieldValues extends FieldValues = FieldValues>({
    modalLeavePrompt,
    formContext,
}: ModalLeavePromptProps<TFieldValues>) => {
    const { formState } = formContext;
    const hasUnsavedChanges = formState.isDirty || formState.isSubmitting;

    const context = useModalLeavePromptContext();

    // Some forms do not live inside a LeavePromptProvider, so we will not
    // always have a context here
    useEffect(() => {
        const unregister = context?.actions.register({
            when: modalLeavePrompt ?? (() => hasUnsavedChanges),
        });

        return unregister;
    }, [hasUnsavedChanges]);
};

export default useModalLeavePrompt;
