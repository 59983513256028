import { styled } from '@mui/material';
import React, { useLayoutEffect, useRef } from 'react';

import { type ImageMimeType } from '@@constants/ImageMimeTypes';

import DropArea from './DropArea';
import Progress from './Progress';
import useDropArea from './useDropArea';

const OuterWrapper = styled('div')({
    display: 'flex',
    minWidth: '100%',
    minHeight: '100%',
});

type Props = {
    allowedMimeTypes?: ImageMimeType[];
    children: React.ReactNode;
    className?: string;
    multiple?: boolean;
    compact?: boolean;
    hideValidationCriteria?: boolean;
    isLoading?: boolean;
    progress?: number;
    done?: number;
    total?: number;
    onDrop: (files) => void;
    onUpload: (files) => void;
    acceptTypes?: string[];
    useChildrenHeight?: boolean;
};

const FileDropArea = (props: Props) => {
    const {
        children,
        className,
        isLoading,
        progress = 0,
        done = 0,
        total = 0,
        useChildrenHeight = true,
    } = props;

    const childrenRef = useRef<HTMLDivElement>(null);
    const minHeight = useRef(0);

    const [dropAreaProps, drop] = useDropArea(props);

    const shouldRenderLoader = isLoading;
    const shouldRenderChildren = Boolean(!shouldRenderLoader && !dropAreaProps.isOver && children);

    useLayoutEffect(() => {
        if (!useChildrenHeight && shouldRenderChildren && childrenRef.current?.firstElementChild) {
            minHeight.current = childrenRef.current.firstElementChild.clientHeight;
        }
    });

    const style = minHeight.current ? { minHeight: minHeight.current } : {};

    return (
        <OuterWrapper ref={drop}>
            <div ref={childrenRef} style={{ display: shouldRenderChildren ? 'contents' : 'none' }}>
                {children}
            </div>

            {(shouldRenderLoader && (
                <Progress {...{ className, progress, done, total }} style={style} />
            )) ||
                (!shouldRenderChildren && <DropArea {...dropAreaProps} style={style} />)}
        </OuterWrapper>
    );
};

export default FileDropArea;
