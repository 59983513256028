import { type ClientArgs, initContract } from '@ts-rest/core';
import { initTsrReactQuery } from '@ts-rest/react-query/v5';
import { useStore } from 'react-redux';
import { z } from 'zod';

import { commonResponses } from '@@api/commonResponses';
import { clientDefaults } from '@@api/constants/clientDefaults';
import { DefaultHeaders, FileUploadHeaders } from '@@api/headers';
import { FilerepoFile } from '@@api/services/metadata/schemas';
import { getQueryKeys } from '@@api/utils/queryKeys';
import config from '@@config';
import { type RootState } from '@@scripts/store/store';

const contract = initContract();

const router = contract.router(
    {
        files: contract.router({
            postFile: {
                method: 'POST',
                contentType: 'multipart/form-data',
                path: '/files',
                body: contract.type<{ file: File }>(),
                responses: {
                    200: FilerepoFile,
                },
                headers: FileUploadHeaders,
            },
            postUrl: {
                method: 'POST',
                path: '/files',
                body: z.object({
                    url: z.string(),
                }),
                responses: {
                    200: FilerepoFile,
                },
                headers: DefaultHeaders,
            },
        }),
    },
    {
        commonResponses,
    },
);

export type FilerepoRouter = typeof router;

const filerepoClient = (state: RootState) => {
    const clientArgs = {
        baseUrl: config.apiEnvVars.filerepoUrl,
        ...clientDefaults(state),
    } satisfies ClientArgs;

    const client = initTsrReactQuery(router, clientArgs);
    const queryKeys = getQueryKeys(router, clientArgs);

    return { client, queryKeys };
};

export const useFilerepoClient = () => {
    const store = useStore<RootState>();
    const state = store.getState();

    return filerepoClient(state);
};
