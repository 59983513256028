import {
    type BreakingNews,
    type NewBreakingNews,
    type NewUnityBreakingNews,
    type UnityBreakingNews,
} from '@@api/services/breakingnews/schemas';
import deserializeState from '@@editor/serialization/deserializeState';
import serializeState from '@@editor/serialization/serializeState';

import { deserializeVariants, serializeVariants } from '../../utils/transformers';
import { deserializeMetadata, serializeMetadata } from '../metadata/transformers';

export const deserializeBreakingnews = (entity: UnityBreakingNews): BreakingNews => {
    const variants = deserializeVariants(entity.variants, (variant) => ({
        ...variant,
        lead: deserializeState(variant.lead),
        body: deserializeState(variant.body),
        allowAutomaticTeaserUpdate: true,
    }));

    const metadata = entity.metadata ? deserializeMetadata(entity.metadata) : undefined;

    return {
        ...entity,
        metadata,
        variants,
    };
};

export const serializeBreakingnews = (entity: BreakingNews): UnityBreakingNews => {
    const variants = serializeVariants(entity.variants, (variant) => ({
        ...variant,
        lead: serializeState(variant.lead),
        body: serializeState(variant.body),
    }));

    const metadata = entity.metadata ? serializeMetadata(entity.metadata) : undefined;

    return {
        ...entity,
        metadata,
        variants,
    };
};
export const serializeNewBreakingnews = (entity: NewBreakingNews): NewUnityBreakingNews => {
    const variants = serializeVariants(entity.variants, (variant) => ({
        ...variant,
        lead: serializeState(variant.lead),
        body: serializeState(variant.body),
    }));

    return {
        ...entity,
        variants,
    };
};
