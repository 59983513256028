export default (token: string) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

    try {
        return JSON.parse(window.atob(base64));
    } catch (e) {
        return false;
    }
};
