import { styled } from '@mui/material';
import React from 'react';
import ReactDOM from 'react-dom';
import { useSlateStatic } from 'slate-react';

import HorizontalLine from '@@editor/components/HorizontalLine';

import { useDropLine } from './DropLineContext';
import { type DropDirection } from './types';

const StyledDropLine = styled(HorizontalLine)<{ $direction: DropDirection; $hidden: boolean }>(
    ({ $direction, $hidden, theme }) => ({
        position: 'absolute',
        left: '50%',
        [`${$direction}`]: 0,
        ...($hidden && { display: 'none' }),
        transform: `translate(-50%, ${
            $direction === 'top'
                ? `calc(-50% - ${theme.fixed.editor.floatingToolbar.height} / 2)`
                : `calc(50% + ${theme.fixed.editor.floatingToolbar.height} / 2)`
        })`,
    }),
);

StyledDropLine.displayName = 'StyledDropLine';

type Props = {
    for: {
        element: HTMLElement | null;
        direction: DropDirection;
    };
};

const DropLine = ({ for: { element: forElement, direction: forDirection } }: Props) => {
    const { dropLine } = useDropLine();
    const editor = useSlateStatic();

    if (!forElement) {
        return null;
    }

    const isVisible = dropLine?.element === forElement && dropLine?.direction === forDirection;

    // In order to avoid jumping around of the drop line, it always needs to be ready in the dom,
    // already positioned, and we just show or hide it. This was the only way we found, to display
    // the drop lines without flickering. It looks like `react-popper` is too slow in positioning
    // the drop line if we want to re-use the same component for different positions. Just to inform
    // you, that if you want to change this to use `react-popper`, it might be a hard road to take.
    return ReactDOM.createPortal(
        <StyledDropLine
            $direction={forDirection}
            $hidden={!isVisible}
            contentEditable={false}
            isArticleEditor={editor.isArticleEditor}
            type={dropLine?.type}
        />,
        forElement,
    );
};

export default DropLine;
