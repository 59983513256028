import { MIME_TYPES } from '@@editor/helpers/Element';
import { type GenerateEmbedBlockData } from '@@editor/typings/Embed';
import { isTwitterUrl } from '@@utils/socialMedia/twitter';

export const generateEmbedBlockData: GenerateEmbedBlockData = ({ originalSrc, url, dataset }) => {
    if (!isTwitterUrl(url)) {
        return;
    }

    // https://twitter.com/Interior/status/507185938620219395
    if (url && /^\/[^/]+\/status\//.test(url.pathname)) {
        return {
            mimetype: MIME_TYPES.TWITTER,
            src: url.href,
            originalSrc,
            options: dataset,
        };
    }
};
