import { initContract } from '@ts-rest/core';
import { initTsrReactQuery } from '@ts-rest/react-query/v5';
import { useStore } from 'react-redux';
import { z } from 'zod';

import { commonResponses } from '@@api/commonResponses';
import { clientDefaults } from '@@api/constants/clientDefaults';
import { DefaultHeaders } from '@@api/headers';
import {
    ProposeToDictionaryRequestBody,
    SpellCheckRequestBody,
    SpellCheckResponseBody,
} from '@@api/services/spellChecker/schemas';
import { getQueryKeys } from '@@api/utils/queryKeys';
import { SearchParams } from '@@api/utils/schemas/queryParams';
import config from '@@config';
import { type RootState } from '@@scripts/store/store';

const contract = initContract();

const router = contract.router(
    {
        spellCheck: contract.router({
            post: {
                method: 'POST',
                path: '/spell-check',
                query: z.object({
                    ...SearchParams.pick({ tenantIds: true }).shape,
                }),
                body: SpellCheckRequestBody,
                responses: {
                    200: SpellCheckResponseBody,
                },
            },
        }),
        proposal: contract.router({
            post: {
                method: 'POST',
                path: '/dictionary-proposals',
                query: z.object({
                    tenantIds: z.string(),
                }),
                body: ProposeToDictionaryRequestBody,
                responses: {
                    201: z.never(),
                },
            },
        }),
    },
    {
        baseHeaders: DefaultHeaders,
        commonResponses,
    },
);

export type SpellCheckerRouter = typeof router;

export const spellCheckerClient = (state: RootState) => {
    const clientArgs = {
        baseUrl: config.apiEnvVars.spellCheckerUrl,
        ...clientDefaults(state),
    };

    const client = initTsrReactQuery(router, clientArgs);
    const queryKeys = getQueryKeys(router, clientArgs);

    return { client, queryKeys };
};

export const useSpellCheckerClient = () => {
    const store = useStore<RootState>();
    const state = store.getState();

    return spellCheckerClient(state);
};
