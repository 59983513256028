import { noop, omit } from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useReducer } from 'react';

import { useAiToolsClient } from '@@api/services/aiTools/client';

import ImageAltTextMessenger, { MessageEventType } from './ImageAltTextMessenger';
import {
    type Action,
    ActionType,
    type ContextValue,
    type DispatchContextValue,
    type ReducerState,
    type UseImageAltTextGeneratorProps,
} from './types';

const INITIAL_REDUCER_STATE: ReducerState = { listeners: [], images: {} };

const ImageAltTextContext = React.createContext<ContextValue>(INITIAL_REDUCER_STATE.images);

const ImageAltTextDispatchContext = React.createContext<DispatchContextValue>({
    addListener: noop,
    removeListener: noop,
});

const applyAction = (state: ReducerState, action: Action): ReducerState => {
    switch (action.type) {
        case ActionType.ADD_LISTENER:
            return {
                ...state,
                listeners: [...state.listeners, action.payload],
            };

        case ActionType.REMOVE_LISTENER:
            return {
                ...state,
                listeners: state.listeners.filter((listener) => listener.fn !== action.payload.fn),
            };

        case ActionType.FETCH:
            state.listeners.forEach((listener) => {
                if (listener.eventName === action.type && action.payload.key === listener.key) {
                    listener.fn(action.payload);
                }
            });

            return {
                ...state,
                images: {
                    ...state.images,
                    [action.payload.key]: { imageUrl: action.payload.imageUrl },
                },
            };

        case ActionType.SUCCESS:
            state.listeners.forEach((listener) => {
                if (listener.eventName === action.type && action.payload.key === listener.key) {
                    listener.fn(action.payload);
                }
            });

            return {
                ...state,
                images: omit(state.images, action.payload.key),
            };

        case ActionType.ERROR:
            state.listeners.forEach((listener) => {
                if (listener.eventName === action.type && action.payload.key === listener.key) {
                    listener.fn(action.payload);
                }
            });

            return {
                ...state,
                images: omit(state.images, action.payload.key),
            };

        default:
            return state;
    }
};

export const ImageAltTextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(applyAction, INITIAL_REDUCER_STATE);

    const actions: DispatchContextValue = useMemo(
        () => ({
            addListener: (eventName, fn, key) => {
                dispatch({ type: ActionType.ADD_LISTENER, payload: { eventName, fn, key } });
            },
            removeListener: (eventName, fn) => {
                dispatch({ type: ActionType.REMOVE_LISTENER, payload: { eventName, fn } });
            },
        }),
        [],
    );

    const { client } = useAiToolsClient();
    const { mutateAsync: generateImageDescription } =
        client.imageDescriptionGenerator.post.useMutation();

    const handleMessageEvent = useCallback(
        ({ type, payload }) => {
            switch (type) {
                case MessageEventType.FETCH:
                    return new Promise((resolve, reject) => {
                        const { imageUrl } = payload;

                        dispatch({ type: ActionType.FETCH, payload });

                        generateImageDescription({ body: { imageUrl } })
                            .then(({ body }) => {
                                resolve({
                                    ...payload,
                                    altText: body.altText,
                                });
                            })
                            .catch((error) => {
                                reject({
                                    ...payload,
                                    error,
                                });
                            });
                    });

                case MessageEventType.SUCCESS:
                    dispatch({ type: ActionType.SUCCESS, payload });

                    break;

                case MessageEventType.ERROR:
                    dispatch({ type: ActionType.ERROR, payload });

                    break;
            }
        },
        [generateImageDescription],
    );

    useEffect(() => ImageAltTextMessenger.listen(handleMessageEvent), [handleMessageEvent]);

    return (
        <ImageAltTextContext.Provider value={state.images}>
            <ImageAltTextDispatchContext.Provider value={actions}>
                {children}
            </ImageAltTextDispatchContext.Provider>
        </ImageAltTextContext.Provider>
    );
};

export const generateImageAltText = ImageAltTextMessenger.send;

export const useImageAltTextGenerator = (props: UseImageAltTextGeneratorProps) => {
    const { key, onFetch = noop, onSuccess = noop, onError = noop } = props;
    const state = useContext(ImageAltTextContext);
    const { addListener, removeListener } = useContext(ImageAltTextDispatchContext);

    useEffect(() => {
        addListener(ActionType.FETCH, onFetch, key);

        return () => {
            removeListener(ActionType.FETCH, onFetch);
        };
    }, [key, addListener, removeListener, onFetch]);

    useEffect(() => {
        addListener(ActionType.SUCCESS, onSuccess, key);

        return () => {
            removeListener(ActionType.SUCCESS, onSuccess);
        };
    }, [key, addListener, removeListener, onSuccess]);

    useEffect(() => {
        addListener(ActionType.ERROR, onError, key);

        return () => {
            removeListener(ActionType.ERROR, onError);
        };
    }, [key, addListener, removeListener, onError]);

    return {
        isPending: key in state,
    };
};
