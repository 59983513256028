import { styled } from '@mui/material';
import React, { type CSSProperties, type PropsWithChildren } from 'react';

import { type Editor, ReactEditor } from '@@editor/helpers';
import { type ElementAttributes, type FooterElement } from '@@editor/helpers/Element';
import { type BadgeProps, getTextReadOnlyBadgeStyle } from '@@editor/plugins/serializable/styles';

type FooterWrapperProps = {
    $isArticleEditor?: boolean;
} & BadgeProps;

export const FooterWrapper = styled('div')<FooterWrapperProps>(
    ({ $isArticleEditor, readOnly, theme, ...props }) => ({
        ...(!$isArticleEditor && readOnly && getTextReadOnlyBadgeStyle({ theme, ...props })),
        ...($isArticleEditor && { ...theme.typography.editorSubtitleSmall }),
    }),
);
type Props = PropsWithChildren<{
    attributes?: ElementAttributes;
    editor: Editor;
    element?: FooterElement;
    style?: CSSProperties;
}>;

const Footer = React.forwardRef<HTMLElement, Props>(
    ({ attributes, children, editor, style }, ref) => {
        const readOnly = ReactEditor.isReadOnly(editor);

        return (
            <FooterWrapper
                {...{ ref, style, ...attributes, readOnly }}
                // We use this prop for the badge text because the type is
                // called "footer" but we and the websites call it "footnote"
                badgeText={editor.t('embed.paragraph.footer')}
                $isArticleEditor={editor.isArticleEditor}
            >
                {children}
            </FooterWrapper>
        );
    },
);

export default Footer;
