import { Button, FormControl, FormLabel, IconButton, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Icon from '@@components/Icon';
import Spacer from '@@components/Spacer';
import useFieldArray from '@@containers/ReactHookForm/useFieldArray';
import TextInputField from '@@form/fields/TextInput';

const Property = styled('div')({
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
});

const NAME = 'embed.properties';

const PropertiesArrayField = () => {
    const { t } = useTranslation();
    const { fields, append, remove } = useFieldArray({ name: NAME });
    const handleAddProperty = () => append({ key: '', value: '' });
    const handleRemoveProperty = (index) => remove(index);

    return (
        <>
            <FormControl fullWidth>
                <FormLabel>{t('embed.frontendComponent.properties')}</FormLabel>
                {fields.map((field, index) => {
                    const { id } = field;

                    return (
                        <React.Fragment key={id}>
                            <Property>
                                <TextInputField
                                    name={`${NAME}.${index}.key`}
                                    label={`${t('editor.plugin.frontendComponent.key')} ${
                                        index + 1
                                    }`}
                                    required
                                />
                                <TextInputField
                                    name={`${NAME}.${index}.value`}
                                    label={`${t('editor.plugin.frontendComponent.value')} ${
                                        index + 1
                                    }`}
                                    required
                                />

                                <IconButton
                                    title={t('editor.plugin.frontendComponent.form.removeButton')}
                                    onClick={() => handleRemoveProperty(index)}
                                >
                                    <Icon name="trash-can" />
                                </IconButton>
                            </Property>

                            <Spacer v md />
                        </React.Fragment>
                    );
                })}
            </FormControl>
            <Spacer v sm />

            <Button
                startIcon={<Icon name="plus-sharp" />}
                variant="outlined"
                onClick={handleAddProperty}
            >
                {t('editor.plugin.frontendComponent.form.addButton')}
            </Button>
        </>
    );
};

export default PropertiesArrayField;
