import { flow, isEmpty, map, partialRight } from 'lodash';

import { DEFAULT_BLOCK, DEFAULT_EMBED_DATA } from '@@editor/constants';
import { Element } from '@@editor/helpers';
import {
    ELEMENT_TYPES,
    type EmbedData,
    type EmbedElement,
    type EmbedType,
    type ImageElement,
    MIME_TYPES,
    type MimeType,
} from '@@editor/helpers/Element';
import Plain from '@@editor/serialization/Plain';
import transformValues from '@@editor/serialization/transformValues';
import {
    type EmbedContentAttributes,
    isUnityEmbed,
    type UnityElement,
    type UnityEmbed,
    type UnityImage,
} from '@@editor/typings/UnityElements';
import addDefaultElementIfEmptyState from '@@editor/utils/addDefaultElementIfEmptyState';

import { type SerializerOptions } from '../types';
import unwrapSerializedState from '../unwrapSerializedState';
import wrapSerializedState from '../wrapSerializedState';
import { CURRENT_VERSION } from './../constants';
import { createElement } from './../deserializeNodes';

const serializeInlineEmbedData = (children: Element[], data: EmbedElement['data']) => {
    const newData = { ...data };
    const caption = children.find(Element.isEmbedCaptionElement);
    const credit = children.find(Element.isEmbedCreditElement);

    newData.embed = newData.embed ?? {};

    if (caption) {
        newData.embed.caption = [{ ...DEFAULT_BLOCK, children: caption.children }];
    }

    if (credit) {
        newData.embed.credit = Plain.serialize(credit.children);
    }

    return newData;
};

/* Our frontend UI is related to the "type", since we use the same UI for video and livestream,
 * we unify the "type" in the frontend, even though it is a different type in the backend
 */
const adjustType = (type: EmbedType, mimetype: MimeType | undefined): EmbedType => {
    if (type === ELEMENT_TYPES.VIDEOCMS && mimetype === MIME_TYPES.VIDEOCMS_LIVESTREAM) {
        return ELEMENT_TYPES.VIDEOCMS_LIVESTREAM;
    }

    if (type === ELEMENT_TYPES.VIDEOCMS_LIVESTREAM && mimetype === MIME_TYPES.VIDEOCMS_LIVESTREAM) {
        return ELEMENT_TYPES.VIDEOCMS;
    }

    return type;
};

export const createSerializedStateIfEmpty = (value) => {
    if (isEmpty(value)) {
        return wrapSerializedState(addDefaultElementIfEmptyState([]));
    }

    return value;
};

const deserializeContentAttributes = (
    contentAttributes: EmbedContentAttributes | undefined,
    next,
): EmbedData => {
    const transformedValues = transformValues(contentAttributes, [
        [
            ['caption', 'previewImage.caption'],
            flow(
                createSerializedStateIfEmpty,
                unwrapSerializedState,
                addDefaultElementIfEmptyState,
                partialRight(map, next),
            ),
        ],
    ]);

    return transformedValues;
};

export const serializeContentAttributes = (
    embedData: EmbedData | undefined,
    next,
): EmbedContentAttributes =>
    transformValues(embedData, [
        [['caption', 'previewImage.caption'], flow(partialRight(map, next), wrapSerializedState)],
    ]);

const deserialize = (type: EmbedType, partialElement, next): EmbedElement['data'] => {
    switch (type) {
        case ELEMENT_TYPES.CS_SLIDESHOW:
            return transformValues(partialElement, [
                ['slides', (slides: UnityImage[]): ImageElement[] => slides.map(next)],
            ]);

        default: {
            const { contentAttributes, ...rest } = partialElement;

            return {
                ...DEFAULT_EMBED_DATA,
                ...rest,
                embed: deserializeContentAttributes(contentAttributes, next),
            };
        }
    }
};

const serialize = (type: EmbedType, data, next): Partial<UnityEmbed> => {
    switch (type) {
        case ELEMENT_TYPES.CS_SLIDESHOW:
            return transformValues(data, [
                ['slides', (slides: ImageElement[]): UnityImage[] => slides.map(next)],
            ]);

        default: {
            const { mimetype, src, originalSrc, ...restData } = data;

            return {
                mimetype,
                src,
                originalSrc,
                // we don't need the rest of the data, only the containers
                contentAttributes: serializeContentAttributes(restData.embed, next),
                options: restData.options,
                templateElement: restData.templateElement,
            };
        }
    }
};

const embedRules = {
    deserialize: (element: UnityElement, next, options?): EmbedElement | undefined => {
        if (!isUnityEmbed(element)) {
            return;
        }

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { type, version, ...rest } = element;
        const adjustedType = adjustType(type, rest.mimetype);
        const data = deserialize(adjustedType, rest, next);

        return createElement<EmbedElement>(adjustedType, data, options);
    },

    serialize: (
        node: EmbedElement,
        next,
        options: SerializerOptions = {},
    ): UnityEmbed | undefined => {
        if (!Element.isEmbedElement(node)) {
            return;
        }

        const { type, data, children } = node;
        const { useInlineEditing } = options;
        const adjustedType = adjustType(type, data.mimetype);
        const newData = useInlineEditing ? serializeInlineEmbedData(children, data) : data;

        return {
            version: CURRENT_VERSION,
            type: adjustedType,
            ...serialize(adjustedType, newData, next),
        } as UnityEmbed;
    },
};

export default embedRules;
