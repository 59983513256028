import { z } from 'zod';

import { getAuthToken } from '@@auth/authSlice';
import config from '@@config';
import { getFullLocale } from '@@lib/i18n/i18nSlice';
import { getContentLocaleSetting } from '@@scripts/settings/settingsSlice';
import { type RootState } from '@@scripts/store/store';

import { CONTENT_LANGUAGE_HEADER } from './constants/headers';

const authHeaders = (state: RootState) => ({
    authorization: getAuthToken(state) || '',
});

const AuthHeaders = z.object({
    authorization: z.string(),
});

const languageHeaders = (state: RootState) => ({
    'accept-language': getFullLocale(state),
    [CONTENT_LANGUAGE_HEADER]: getContentLocaleSetting(state),
});

const LanguageHeaders = z.object({
    'accept-language': z.string(),
    [CONTENT_LANGUAGE_HEADER]: z.string().optional(),
});

const jsonHeaders = {
    accept: 'application/json',
    'content-type': 'application/json',
};

const JsonHeaders = z.object({
    accept: z.string(),
    'content-type': z.string(),
});

const patchJsonHeaders = {
    accept: 'application/json',
    'content-type': 'application/json-patch+json',
};

const PatchJsonHeaders = z.object({
    accept: z.string(),
    'content-type': z.string(),
});

const EtagHeaders = z.object({
    'if-match': z.string().optional(),
    etag: z.string().optional(),
});

export const fileUploadHeaders = (state: RootState) => ({
    ...authHeaders(state),
    ...languageHeaders(state),
    'content-type': 'multipart/form-data',
    accept: 'application/json',
});

export const FileUploadHeaders = z.object({
    ...AuthHeaders.shape,
    ...LanguageHeaders.shape,
    accept: z.literal('application/json'),
    'content-type': z.literal('multipart/form-data'),
});

export const loginHeaders = (state: RootState) => ({
    ...languageHeaders(state),
    authorization: config.loginAuthHeader,
    accept: 'application/json',
    'content-type': 'application/x-www-form-urlencoded',
});

export const LoginHeaders = z.object({
    ...AuthHeaders.shape,
    ...LanguageHeaders.shape,
    accept: z.literal('application/json'),
    'content-type': z.literal('application/x-www-form-urlencoded'),
});

export const defaultHeaders = (state: RootState) => ({
    ...authHeaders(state),
    ...languageHeaders(state),
    ...jsonHeaders,
});

export const DefaultHeaders = z.object({
    ...AuthHeaders.shape,
    ...LanguageHeaders.shape,
    ...JsonHeaders.shape,
    ...EtagHeaders.shape,
});

export const patchDefaultHeaders = (state: RootState) => ({
    ...authHeaders(state),
    ...languageHeaders(state),
    ...patchJsonHeaders,
});

export const PatchDefaultHeaders = z.object({
    ...AuthHeaders.shape,
    ...LanguageHeaders.shape,
    ...PatchJsonHeaders.shape,
    ...EtagHeaders.shape,
});
