import { Element } from '@@editor/helpers';
import Plain from '@@editor/serialization/Plain';
import isState from '@@editor/utils/isState';

const toText = (state) => {
    if (typeof state === 'string') {
        return state;
    } else if (typeof state === 'number') {
        return String(state);
    } else if (Element.isElement(state)) {
        return Plain.serialize([state]);
    } else if (isState(state)) {
        return Plain.serialize(state);
    }

    console.warn(`toText: Could not convert state to text ${state}`);

    return '';
};

export default toText;
