import React, { type CSSProperties } from 'react';

const divStyle: CSSProperties = {
    position: 'relative',
    height: 0,
    overflow: 'hidden',
    maxWidth: '100%',
    width: '100%',
};

const iframeStyle: CSSProperties = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
};

const ratioToPercent = (ratio: string) => {
    const [w, h] = ratio.split(':').map((num) => Number(num));

    return `${(h / w) * 100}%`;
};

const isValidRatio = (ratio: string) => /^\d+:\d+$/.test(ratio);

const validateRatio = (ratio: string) => {
    if (!isValidRatio(ratio)) {
        throw new Error(
            'Invalid ratio supplied to ResponsiveIFrame. Expected a string pattern X:Y',
        );
    }
};

type Props = React.IframeHTMLAttributes<HTMLIFrameElement> & {
    ratio?: string;
    omitSandbox?: boolean;
};

// Usage: <ResponsiveIFrame src='video_url' ratio='4:3' />
const ResponsiveIFrame: React.FC<Props> = ({ ratio = '16:9', ...props }) => {
    validateRatio(ratio);

    const paddingBottom = ratioToPercent(ratio);
    const style = { ...divStyle, paddingBottom };
    const iframeProps = Object.assign({ frameBorder: 0 }, props, { style: iframeStyle });

    // We use "allow-scripts" for the sandbox to make the basic JS-based websites work.
    // Do not add "allow-same-origin" as it would make the whole sandboxing useless
    // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/iframe#attr-sandbox
    // For some websites like youtube, preview will not work if we have "allow-scripts".
    // omitSandbox will omit the sandbox property all together. This means all restrictions are applied.
    return (
        <div style={style}>
            {props.omitSandbox ? (
                <iframe {...iframeProps} />
            ) : (
                <iframe {...iframeProps} sandbox="allow-scripts" />
            )}
        </div>
    );
};

export default ResponsiveIFrame;
