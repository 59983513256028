import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { getAccessToken, selectIsAuthenticated } from '@@auth/authSlice';
import useAuthorization from '@@auth/hooks/useAuthorization';
import { type NavigationItem, resolveAuthenticationApiUrl } from '@@config';
import { Error403 } from '@@containers/App/Error403';
import { useSelector } from '@@store/hooks';

const login = () => window.location.assign(resolveAuthenticationApiUrl());

export type Props = Partial<NavigationItem> & {
    children?:
        | React.ReactNode
        | ((props: Omit<Props, 'children' | 'forceErrorCode' | 'authorize'>) => React.ReactNode);
    forceErrorCode?: '403' | '404' | '406' | '500' | null;
    path?: string;
};

const PrivateRoute = ({ forceErrorCode, children, authorize, ...props }: Props) => {
    const { isAuthorized } = useAuthorization();
    const isAuthenticated = useSelector(selectIsAuthenticated);
    const accessToken = useSelector(getAccessToken);

    if (!isAuthorized(authorize)) {
        return <Error403 />;
    }

    if (forceErrorCode) {
        return <Navigate to={`/${forceErrorCode}`} />;
    }

    // Allow the user to access the route if authentication is valid
    if (isAuthenticated) {
        if (children) {
            return typeof children === 'function' ? children(props) : children;
        }

        return <Outlet />;
    }

    // In order to avoid using acceptance environments when not necessary
    // We revert to default ones when token expires
    if (accessToken && !isAuthenticated) {
        localStorage.removeItem('CDII_API');
    }

    // Trigger a login process if authentication fails
    login();

    return null;
};

export default PrivateRoute;
