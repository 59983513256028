import { type InputProps, styled } from '@mui/material';
import { noop } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Spacer from '@@components/Spacer';
import NumberField from '@@form/fields/Number';
import UrlIframeField from '@@form/fields/UrlIframe';

const FallBackHeightField = styled(NumberField)({
    width: '140px',
});

type Props = {
    names?: {
        url: string;
        fallBackHeight: string;
    };
    validateIframeField?: Validator[];
    onIframeBlur?: React.FocusEventHandler<HTMLTextAreaElement>;
    onIframeChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
    InputProps: InputProps;
};

const DEFAULT_NAMES = {
    url: 'url',
    fallBackHeight: 'fallBackHeight',
};

const IframeFields = ({
    names = DEFAULT_NAMES,
    validateIframeField,
    onIframeBlur = noop,
    onIframeChange,
    InputProps,
}: Props) => {
    const { t } = useTranslation();

    return (
        <>
            <UrlIframeField
                name={names.url}
                label={t('embed.iframe')}
                required
                InputProps={InputProps}
                validate={validateIframeField}
                onBlur={onIframeBlur}
                onChange={(e, onFieldChange) => {
                    onFieldChange(e);
                    onIframeChange?.(e);
                }}
            />

            <Spacer v md />

            <FallBackHeightField name={names.fallBackHeight} label={t('embed.fallBackHeight')} />
        </>
    );
};

export default IframeFields;
