import React, { useCallback } from 'react';
import { useDragDropManager } from 'react-dnd';
import { useReadOnly, useSlateStatic } from 'slate-react';

import { type Editor } from '@@editor/helpers';
import { PLUGIN_NAMES } from '@@editor/typings/UnityPlugins';
import useDocumentDragHandlers from '@@hooks/useDocumentDragHandlers';

import { DropLineProvider } from './DropLineContext';
import renderEditor from '../utils/renderEditor';

export const setCoverIframes = (editor: Editor, coverIframes: boolean) => {
    editor.setData({
        [PLUGIN_NAMES.DRAG_DROP]: { coverIframes },
    });
};

type Props = { children: React.ReactNode };

const EditorWrapper = (props: Props) => {
    const { children } = props;
    const editor = useSlateStatic();
    const readOnly = useReadOnly();
    const dndManager = useDragDropManager();

    const handleDocumentDragEnter = useCallback(() => {
        if (readOnly) {
            return;
        }

        setCoverIframes(editor, true);
    }, [editor, readOnly]);

    const handleDocumentDragLeave = useCallback(() => {
        if (readOnly) {
            return;
        }

        setCoverIframes(editor, false);
    }, [editor, readOnly]);

    const handleDocumentDrop = useCallback(() => {
        if (readOnly) {
            return;
        }

        // The `drop`-event on the document is fired prior the drop event of `react-dnd` hooks. In order to
        // avoid race conditions, we need to wait, until those `react-dnd` drop handlers are executed (by
        // checking if any `react-dnd` drag operation is pending), before uncovering the iframes. This is, because
        // the overlay itself, sometimes is a `react-dnd` drop area. So if we would hide that overlay, being
        // a `react-dnd` drop area, too early, it would never receive that drop event.
        const unsubscribe = dndManager.getMonitor().subscribeToStateChange(() => {
            if (!dndManager.getMonitor().isDragging()) {
                setCoverIframes(editor, false);
                unsubscribe();
            }
        });
    }, [editor, readOnly]);

    useDocumentDragHandlers({
        onDocumentDragEnter: handleDocumentDragEnter,
        onDocumentDragLeave: handleDocumentDragLeave,
        onDocumentDrop: handleDocumentDrop,
    });

    return <DropLineProvider>{children}</DropLineProvider>;
};

export const withDnd = (editor: Editor) => {
    const { insertTextData } = editor;

    return Object.assign(editor, {
        renderEditor: renderEditor(editor, ({ children }) => (
            <EditorWrapper>{children}</EditorWrapper>
        )),
        insertTextData: (data) => {
            // We do not support dropping of text into the editor for now
            if (document.body.classList.contains('dragging')) {
                return;
            }

            insertTextData(data);
        },
    });
};

export default withDnd;
