import { Button, Divider, IconButton, Paper, Popper, styled } from '@mui/material';
import { noop } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Icon from '@@components/Icon';
import Spacer from '@@components/Spacer';

import { type VirtualElement } from '../hooks/useSpellAdvicePopoverPopper';
import { type EditorSpellAdvice } from '../types';

const BUTTON_SIZE = 'medium';
const POPOVER_WIDTH = '300px';

type Props = {
    spellAdvice: EditorSpellAdvice;
    onPropose?: (spellAdvice: EditorSpellAdvice, proposal: string) => void;
    onIgnore?: (spellAdvice: EditorSpellAdvice) => void;
    onClose?: (e: KeyboardEvent) => void;
    onSuggest?: (spellAdvice: EditorSpellAdvice) => void;
    enableSuggestions?: boolean;
    virtualReference: VirtualElement;
};

const Title = styled('div')(({ theme }) => ({
    ...theme.typography.subtitle4,
}));

const Header = styled('div')<{ $separator?: boolean }>(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    margin: `0 -${theme.spacing(2)}`,
    padding: theme.spacing(2, 1, 2, 2),
    [`${Title}`]: {
        alignSelf: 'center',
    },
}));

const ProposalList = styled('ul')(({ theme }) => ({
    listStyleType: 'none',
    display: 'flex',
    flexWrap: 'wrap',
    gap: `${theme.spacing(2)}`,
    margin: 0,
    padding: 0,
}));

const ProposalButton = styled(Button)(({ theme }) => ({
    textTransform: 'none',
    whiteSpace: 'nowrap',
    paddingLeft: `${theme.spacing(2)}`,
    paddingRight: `${theme.spacing(2)}`,
}));

const ActionButtons = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    paddingLeft: `${theme.spacing(1)}`,
}));

const ActionButton = styled(IconButton)(({ theme }) => ({
    paddingLeft: `${theme.spacing(2)}`,
    paddingRight: `${theme.spacing(2)}`,
}));

const Content = styled('div')(({ theme }) => ({
    padding: `${theme.spacing(2)} 0`,
}));

const Text = styled('span')(({ theme }) => ({
    ...theme.typography.small,
}));

export const SpellAdvicePopover = (props: Props) => {
    const {
        virtualReference,
        spellAdvice,
        onPropose = noop,
        onIgnore = noop,
        onClose = noop,
        onSuggest = noop,
        enableSuggestions,
    } = props;
    const { t } = useTranslation();
    const [isExpanded, setIsExpanded] = useState(false);
    const hasProposals = spellAdvice.proposals.length > 0;

    const handleKeyDown = useCallback(
        (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                onClose();
            }
        },
        [onClose],
    );

    const handleMouseDownOnProposal = (e, proposal) => {
        onPropose(spellAdvice, proposal);
    };

    const handleMouseDownOnIgnore = () => {
        onIgnore(spellAdvice);
    };

    const handleMouseDownOnToggle = () => {
        setIsExpanded(!isExpanded);
    };

    const handleMouseDownOnSuggest = () => {
        onSuggest(spellAdvice);
    };

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    // Since we use only 1 popover for the whole editor and all its spell advices, we need to re-initialize certain
    // values, whenever the spell advice (id) changes
    useEffect(() => {
        setIsExpanded(!hasProposals);
    }, [spellAdvice.id]);

    return (
        <Popper
            open
            anchorEl={virtualReference}
            placement="bottom-start"
            onMouseDown={(e) => {
                // Prevent loosing the focus on the editor. User must, at any point in time, to be able to write
                // text into the editor.
                e.preventDefault();
            }}
            sx={(theme) => ({
                zIndex: theme.zIndex.layer3,
                width: POPOVER_WIDTH,
            })}
            disablePortal
        >
            <Paper
                sx={(theme) => ({
                    padding: `${theme.spacing(0)} ${theme.spacing(2)}`,
                })}
            >
                <Header $separator={isExpanded} role="menu">
                    {hasProposals ? (
                        <ProposalList role="group">
                            {spellAdvice.proposals.map((proposal, index) => (
                                <li key={index}>
                                    <ProposalButton
                                        role="menuitem"
                                        size={BUTTON_SIZE}
                                        title={t('editor.plugin.spellChecker.useProposal')}
                                        variant="outlined"
                                        onMouseDown={(e) => {
                                            handleMouseDownOnProposal(e, proposal);
                                        }}
                                    >
                                        {proposal}
                                    </ProposalButton>
                                </li>
                            ))}
                        </ProposalList>
                    ) : (
                        <Title>{t('editor.plugin.spellChecker.noProposals')}</Title>
                    )}

                    <ActionButtons role="group">
                        <ActionButton
                            role="menuitem"
                            size={BUTTON_SIZE}
                            title={t('editor.plugin.spellChecker.ignore')}
                            onMouseDown={handleMouseDownOnIgnore}
                        >
                            <Icon name="ban" />
                        </ActionButton>

                        {enableSuggestions && (
                            <ActionButton
                                role="menuitem"
                                size={BUTTON_SIZE}
                                title={t('editor.plugin.spellChecker.suggestForDictionary')}
                                onMouseDown={handleMouseDownOnSuggest}
                            >
                                <Icon name="spellcheck-suggest" />
                            </ActionButton>
                        )}

                        <Divider orientation="vertical" flexItem variant="middle" sx={{ mx: 1 }} />

                        <ActionButton
                            role="menuitem"
                            size={BUTTON_SIZE}
                            title={
                                isExpanded
                                    ? t('editor.plugin.spellChecker.showLess')
                                    : t('editor.plugin.spellChecker.showMore')
                            }
                            onMouseDown={handleMouseDownOnToggle}
                        >
                            <Icon
                                name={isExpanded ? 'chevron-down-sharp' : 'chevron-right-sharp'}
                            />
                        </ActionButton>
                    </ActionButtons>
                </Header>

                {isExpanded && (
                    <>
                        <Divider variant="fullWidth" indent={-2} />

                        <Content>
                            <Title>{t('editor.plugin.spellChecker.advice')}</Title>

                            <Spacer v xs />

                            <Text>
                                {spellAdvice.errorMessage ||
                                    t('editor.plugin.spellChecker.noAdvice')}
                            </Text>
                        </Content>
                    </>
                )}
            </Paper>
        </Popper>
    );
};

export default SpellAdvicePopover;
