import { useTheme } from '@mui/material';
import React from 'react';
import { useFocused, useSlateStatic } from 'slate-react';

import { type PluginList } from '@@editor/typings/UnityPlugins';

import { DEFAULT_STICKYTOOLBAR_BUTTONS } from '../constants';
import LargeToolbar from './LargeToolbar';
import Toolbar from './Toolbar';
import { getToolbarConfig } from '../utils/getToolbarConfig';

type Props = {
    activePlugins?: PluginList[];
    isArticleEditor?: boolean;
    readOnly?: boolean;
    targetTextLength?: number;
};

export const StickyToolbar = ({
    activePlugins = DEFAULT_STICKYTOOLBAR_BUTTONS,
    isArticleEditor = false,
    readOnly,
    targetTextLength,
}: Props) => {
    const theme = useTheme();
    const editor = useSlateStatic();
    const isFocused = useFocused();
    const buttonsConfig = getToolbarConfig(editor, activePlugins, theme);

    if (isArticleEditor) {
        return (
            <LargeToolbar
                buttonsConfig={buttonsConfig}
                disabled={editor.disabled || !isFocused}
                readOnly={readOnly}
                targetTextLength={targetTextLength}
            />
        );
    }

    return <Toolbar buttonsConfig={buttonsConfig} disabled={editor.disabled} />;
};
