import update from 'immutability-helper';
import { omit } from 'lodash';

import {
    type NewTickerEvent,
    type NewUnityTickerEvent,
    type TickerEvent,
    type UnityTickerEvent,
} from '@@api/services/liveticker/schemas/event';
import {
    type League,
    type NewTeam,
    type NewUnityTeam,
    type Team,
    type UnityTeam,
} from '@@api/services/liveticker/schemas/team';
import {
    type NewTicker,
    type NewUnityTicker,
    type Ticker,
    type UnityTicker,
} from '@@api/services/liveticker/schemas/ticker';
import deserializeState from '@@editor/serialization/deserializeState';
import serializeState from '@@editor/serialization/serializeState';
import { TICKER_TYPES } from '@@routes/ticker/constants';
import { soccerOrIceHockey, tennis } from '@@routes/ticker/utils';

import { deserializeVariants, serializeVariants } from '../../utils/transformers';
import { deserializeMetadata, serializeMetadata } from '../metadata/transformers';

/*
 * This function is here as a compatibility tool after the tennis score points
 * have been switched from number to string.
 * TODO: It can be removed once all the data are migrated.
 */
export const parseValueAndConvertPoints = (value) => {
    const convertPoints = (points) =>
        points.map((point) => {
            if (typeof point === 'number') {
                return point.toString();
            }

            return point;
        });

    if (value.data && value.data.points) {
        return update(value, { data: { points: { $set: convertPoints(value.data.points) } } });
    } else if (value.data && value.data.score && value.data.score.points) {
        return update(value, {
            data: { score: { points: { $set: convertPoints(value.data.score.points) } } },
        });
    }

    return value;
};

export const deserializeTickerEvent = (entity: UnityTickerEvent): TickerEvent => {
    const variants = deserializeVariants(entity.variants, (variant) => ({
        ...variant,
        content: deserializeState(variant.content),
    }));

    return { ...entity, variants };
};

export const serializeTickerEvent = (
    entity: TickerEvent | NewTickerEvent,
): UnityTickerEvent | NewUnityTickerEvent => {
    const variants = serializeVariants(entity.variants, (variant) => ({
        ...variant,
        content: serializeState(variant.content),
    }));

    return { ...entity, variants };
};

export const deserializeTicker = (entity: UnityTicker): Ticker => {
    const variants = deserializeVariants(entity.variants, (variant) => ({
        ...variant,
        lead: deserializeState(variant.lead),
        intro: deserializeState(variant.intro),
        allowAutomaticTeaserUpdate: true,
    }));

    const metadata = entity.metadata ? deserializeMetadata(entity.metadata) : undefined;

    const base = { ...entity, variants, metadata };

    if (base.type === TICKER_TYPES.TENNIS) {
        return { ...base, data: { ...base.data, score: { ...tennis, ...base.data.score } } };
    }

    if (base.type === TICKER_TYPES.SOCCER || base.type === TICKER_TYPES.ICE_HOCKEY) {
        return {
            ...base,
            data: { ...base.data, score: { ...soccerOrIceHockey, ...base.data.score } },
        };
    }

    return base;
};

export const serializeTicker = (entity: Ticker): UnityTicker => {
    const variants = serializeVariants(entity.variants, (variant) => ({
        ...variant,
        lead: serializeState(variant.lead),
        intro: serializeState(variant.intro),
    }));

    const metadata = entity.metadata ? serializeMetadata(entity.metadata) : undefined;

    return { ...entity, variants, metadata };
};

export const serializeNewTicker = (entity: NewTicker): NewUnityTicker => {
    const base = {
        ...entity,
        variants: serializeVariants(entity.variants, (variant) => ({
            ...variant,
            lead: serializeState(variant.lead),
            intro: serializeState(variant.intro),
        })),
    };

    if (base.type === TICKER_TYPES.TENNIS) {
        return { ...base, data: { ...base.data, score: tennis } };
    }

    if (base.type === TICKER_TYPES.SOCCER || base.type === TICKER_TYPES.ICE_HOCKEY) {
        return { ...base, data: { ...base.data, score: soccerOrIceHockey } };
    }

    return base;
};

export const deserializeTeam = (entity: UnityTeam): Team => ({
    ...omit(entity, ['logoId', 'logoURL']),
    image: {
        elvisId: entity.logoId,
        url: entity.logoURL,
    },
});

export const serializeTeam = (entity: Team | NewTeam): UnityTeam | NewUnityTeam => ({
    ...omit(entity, ['image']),
    logoId: entity.image?.elvisId,
    logoURL: entity.image?.url,
});

export const deserializeLeague = (entity: League): League => ({
    ...entity,
    names: entity.names.map((name) => ({
        ...name,
        // TODO: Remove replace when https://jira.tamedia.ch/browse/CD2-3682 is solved!
        locale: name.locale?.replace('_', '-'),
    })),
});
