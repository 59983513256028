import {
    // This is the only place in this stack where we use `useFormContext` directly!
    // Use `useReactHookFormContext` in all other places.
    // eslint-disable-next-line no-restricted-imports
    useFormContext,
} from 'react-hook-form';

import { type EnhancedUseFormReturn } from './types';

type UseFormContext = () => EnhancedUseFormReturn;

const useReactHookFormContext = (() => useFormContext()) as UseFormContext;

export default useReactHookFormContext;
