import {
    FormControl,
    FormHelperText,
    FormLabel,
    type FormLabelProps,
    Stack,
    styled,
} from '@mui/material';
import { type ClientInferResponses } from '@ts-rest/core';
import { get, isEmpty, mapValues, noop, omit } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { type FieldError, type FieldPath, type FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import useUploadFile from '@@api/hooks/useUploadFile';
import { type FilerepoRouter } from '@@api/services/filerepo/client';
import { type Image as ImageType, type ImageVariants } from '@@api/utils/schemas/schemas';
import FileUploadDropArea, {
    type Props as FileUploadDropAreaProps,
} from '@@components/FileUploadDropArea/FileUploadDropArea';
import Image from '@@components/Image';
import config from '@@config';
import { HTTP_STATUS_CODES } from '@@constants/http';
import useReactHookFormContext from '@@containers/ReactHookForm/useReactHookFormContext';
import {
    DEFAULT_CROP_MARKS,
    DEFAULT_FOCUS_POINT,
    DEFAULT_IMAGE_SIZES,
} from '@@form/components/ImageEditor/constants';
import ImageEditor from '@@form/components/ImageEditor/ImageEditor';
import { type ButtonsType } from '@@form/components/ImageEditor/ImageUpload/ButtonsContext';
import ImageUpload, {
    type ImageUploadProps,
    type RenderImageProps,
} from '@@form/components/ImageEditor/ImageUpload/ImageUpload';
import useFormField, { type UseFormFieldProps } from '@@form/hooks/useFormField';
import { type FormFieldError } from '@@form/hooks/useReactHookFormFieldError';
import { getErrorMessage } from '@@form/utils/getErrorMessage';
import required from '@@form/utils/validators/required';
import i18n from '@@lib/i18n/i18n';
import { SVG_MIME_TYPE } from '@@utils/buildImageTransformationUrl';

const allowedMimeTypes = Object.values(config.allowedImageMimeTypes);

const StyledFileUploadDropArea = styled(FileUploadDropArea)<{ $error: boolean }>(
    ({ $error, theme }) => ({
        borderColor: $error ? theme.palette.error.main : undefined,
        minHeight: `calc(${DEFAULT_IMAGE_SIZES.loaderHeight} - ${theme.spacing(3)})`,
    }),
);

const StyledImage = styled(Image)({
    maxWidth: DEFAULT_IMAGE_SIZES.maxWidth,
    maxHeight: DEFAULT_IMAGE_SIZES.maxHeight,
});

const VariantsWrapper = styled('div')(({ theme }) => ({
    marginRight: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
}));

const AspectRatio = styled('div')(({ theme }) => ({
    ...theme.typography.small,
    marginBottom: theme.spacing(1),
    width: 0,
    minWidth: '100%',
}));

const Previews = styled('div')(({ theme }) => ({
    ...theme.typography.subtitle4,
    marginBottom: theme.spacing(1),
}));

type Props = {
    noDropArea?: boolean;
    onMetadataChange: (value: UnknownObject) => void;
    allowImageEdit?: boolean;
    className?: string;
    error?: FormFieldError | FieldError;
    label?: FormLabelProps['children'];
    // only provide the basic image tools: upload, crop, focuspoint, reset
    // but hide all additional fields: caption, credit, ...
    imageOnly?: boolean;
    renderButtons?: (buttons: ButtonsType, ownProps: ImageUploadProps) => React.ReactNode;
    renderImage?: (args: RenderImageProps) => React.ReactNode;
    renderLoader?: () => React.ReactNode;
    showPlaceholderImage?: boolean;
    style?: React.StyleHTMLAttributes<HTMLDivElement>;
    hasButtonGroup?: boolean;
    value?: ImageType;
    onChange: (
        value:
            | (ImageType & { description?: string | null; uploadSuccess?: boolean })
            | { errors: unknown },
        fieldOnChange?: any,
    ) => void;
    name: string;
    placeholder?: string;
    required?: boolean;
    tabIndex?: number;
    aspectRatio?: string;
};

// t('metaform.teaser.modal.preview.1:1')
// t('metaform.teaser.modal.preview.3:2')
// t('metaform.teaser.modal.preview.16:9')
// t('metaform.teaser.modal.preview.2:1')
export const ImageUploadField = (props: Props) => {
    const {
        onChange,
        value,
        name,
        onMetadataChange = noop,
        className,
        error,
        noDropArea = false,
        renderButtons,
        hasButtonGroup,
        renderLoader,
        renderImage,
        showPlaceholderImage = true,
        placeholder,
        allowImageEdit = true,
        label,
        required,
    } = props;
    const uploadFile = useUploadFile();
    const [imageVariants, setImageVariants] = useState(value?.variants);

    const { t } = useTranslation();

    const errorMessage = getErrorMessage(error, t);

    const helperText = errorMessage;

    const { trigger, watch } = useReactHookFormContext();
    const aspectRatio = watch('aspect') || props.aspectRatio;
    const handleOnUploadSuccess = (
        payload: ClientInferResponses<
            FilerepoRouter['files']['postFile'] | FilerepoRouter['files']['postUrl']
        >,
    ) => {
        if (payload.status === HTTP_STATUS_CODES.OK) {
            onChange({
                // We want the previous value, but not potential old error data
                ...omit(value, 'errors'),
                url: payload.body._links.data.href,
                elvisId: payload.body.id,
                focusPoint: DEFAULT_FOCUS_POINT,
                cropMarks: DEFAULT_CROP_MARKS,
                // Only file repo is calling it mediaType, we use mimetype
                mimetype: payload.body.mediaType,
                description: payload.body.metadata.name,
                uploadSuccess: true,
                naturalWidth: payload.body.width,
                naturalHeight: payload.body.height,
                variants:
                    value?.variants &&
                    (mapValues(value.variants, () => ({
                        cropMarks: DEFAULT_CROP_MARKS,
                    })) as ImageVariants),
            });

            trigger(name);

            onMetadataChange(payload.body.metadata);
        }
    };

    const handleOnUploadFailure = (errors) => {
        onChange({ errors });

        trigger(name, { force: true });
    };

    const handleOnChange = (image) =>
        onChange({
            ...value,
            ...omit(image, ['src']),
        });

    const handleOnVariantChange = useCallback(
        (aspectRatio) => (image) => {
            setImageVariants((imageVariants) => ({
                ...imageVariants!,
                [aspectRatio]: {
                    cropMarks: image.cropMarks,
                },
            }));
        },
        [value, onChange],
    );

    useEffect(() => {
        if (imageVariants) {
            onChange({
                ...value,
                variants: {
                    ...imageVariants!,
                },
            });
        }
    }, [imageVariants]);

    const handleOnDeleteImage = () => {
        onChange({
            ...value,
            url: null,
            elvisId: null,
            focusPoint: DEFAULT_FOCUS_POINT,
            cropMarks: DEFAULT_CROP_MARKS,
            variants:
                value?.variants &&
                (mapValues(value.variants, () => ({
                    cropMarks: DEFAULT_CROP_MARKS,
                })) as ImageVariants),
        });
    };

    const upload: FileUploadDropAreaProps['uploadFile'] = (file) =>
        uploadFile({
            file,
        });

    const renderImageUpload = () => (
        <ImageUpload
            // TODO: build cropped url
            src={(value && value.url) || ''}
            allowedMimeTypes={allowedMimeTypes}
            uploadImage={uploadFile}
            onChange={onChange}
            onUploadSuccess={handleOnUploadSuccess}
            onUploadFailure={handleOnUploadFailure}
            onDeleteImage={handleOnDeleteImage}
            // This needs to be a inline function to properly update the focus point
            renderImage={(renderProps: RenderImageProps) => {
                if (renderImage) {
                    return renderImage({ ...renderProps, value });
                }

                if (!value || !value.url) {
                    if (showPlaceholderImage) {
                        return <Image placeholder={placeholder} alt="" />;
                    }

                    return null;
                }

                const { handleOnLoad } = renderProps;

                if (value.mimetype === SVG_MIME_TYPE || !allowImageEdit) {
                    return <StyledImage src={value.url} onLoad={handleOnLoad} alt="" />;
                }

                if (!isEmpty(value.variants)) {
                    const variants = value.variants;
                    const url = value.url;

                    return (
                        <div>
                            <Previews>{t('metaform.teaser.modal.preview.title')}</Previews>
                            <Stack direction="row">
                                {Object.keys(value.variants).map((aspectRatio) => (
                                    <VariantsWrapper key={aspectRatio}>
                                        <AspectRatio>
                                            {t(`metaform.teaser.modal.preview.${aspectRatio}`)}
                                        </AspectRatio>
                                        <ImageEditor
                                            {...props}
                                            value={{
                                                ...value,
                                                src: url,
                                                cropMarks: variants[aspectRatio].cropMarks,
                                            }}
                                            onChange={handleOnVariantChange(aspectRatio)}
                                            onLoad={handleOnLoad}
                                            aspectRatio={aspectRatio}
                                            disableFocusPoint
                                            small
                                        />
                                    </VariantsWrapper>
                                ))}
                            </Stack>
                        </div>
                    );
                }

                return (
                    <ImageEditor
                        {...props}
                        value={{
                            ...value,
                            src: value.url,
                        }}
                        onChange={handleOnChange}
                        onLoad={handleOnLoad}
                        aspectRatio={aspectRatio}
                    />
                );
            }}
            renderButtons={renderButtons}
            hasButtonGroup={hasButtonGroup}
            renderLoader={renderLoader}
            className={className}
            aspectRatio={aspectRatio}
        />
    );

    return (
        <FormControl fullWidth error={Boolean(error)} required={required}>
            {label && isEmpty(value?.variants) && <FormLabel>{label}</FormLabel>}

            {noDropArea ? (
                renderImageUpload()
            ) : (
                <StyledFileUploadDropArea
                    $error={Boolean(error)}
                    className={className}
                    allowedMimeTypes={allowedMimeTypes}
                    uploadFile={upload}
                    onUploadSuccess={handleOnUploadSuccess}
                    onUploadFailure={handleOnUploadFailure}
                >
                    {value && value.url && renderImageUpload()}
                </StyledFileUploadDropArea>
            )}

            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    );
};

const validateRequired = (path) => (value) => {
    const specificValue = get(value, path);

    if (required(specificValue)) {
        return [i18n.t('validator.message.required')];
    }
};

type ImageUploadFormFieldProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Props & Omit<UseFormFieldProps<TFieldValues, TName>, 'validate'>;

const ImageUploadFormField = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
    props: ImageUploadFormFieldProps<TFieldValues, TName>,
) => {
    const validate = props.required ? [validateRequired('url')] : [];

    const {
        name,
        control,
        novalidate,
        required,
        requiredCustom,
        validateCacheKey,
        onChange,
        onBlur,
        transform,
        defaultValue,
        ...rest
    } = props;

    const { field: formFieldProps } = useFormField<TFieldValues, TName>({
        name,
        control,
        novalidate,
        validate,
        required,
        requiredCustom,
        validateCacheKey,
        onChange,
        onBlur,
        transform,
        defaultValue,
    });

    return <ImageUploadField {...omit(formFieldProps, ['ref'])} {...rest} required={required} />;
};

export default ImageUploadFormField;
