import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import ModalLeavePromptProvider from '@@containers/LeavePrompt/ModalLeavePrompt/ModalLeavePromptProvider';
import useModalLeavePromptContext from '@@containers/LeavePrompt/ModalLeavePrompt/useModalLeavePromptContext';

import Modal from './Modal';
import { type ModalProps } from './types';
import useModal from './useModal/useModal';

const ModalWithLeavePrompt = ({ onClose, ...restModalProps }: ModalProps) => {
    const context = useModalLeavePromptContext();
    const { t } = useTranslation();

    const { confirm } = useModal({
        type: 'confirm',
        title: t('form.modal.cancelTitle'),
        children: <p>{t('form.modal.cancelMessage')}</p>,
    });

    const when = context?.state.when;

    const handleClose = useCallback(
        (...args) => {
            if (when?.()) {
                confirm().then(() => onClose(...args));
            } else {
                onClose(...args);
            }
        },
        [confirm, onClose, when],
    );

    return <Modal {...restModalProps} onClose={handleClose} />;
};

export default (props: ModalProps) => (
    <ModalLeavePromptProvider>
        <ModalWithLeavePrompt {...props} />
    </ModalLeavePromptProvider>
);
