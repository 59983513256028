import { get } from 'lodash';

import { MIME_TYPES } from '@@editor/helpers/Element';
import { type GenerateEmbedBlockData } from '@@editor/typings/Embed';

export const generateEmbedBlockData: GenerateEmbedBlockData = ({ embed, originalSrc }) => ({
    mimetype: MIME_TYPES.SLIDESHOW,
    embed,
    originalSrc,
});

export const getSlideshowId = (data) =>
    get(data, 'embed.id') || get(data, 'contentAttributes.id') || data.originalSrc;
