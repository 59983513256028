import { Path, Transforms } from 'slate';

import { type TickerSummary } from '@@api/services/aiTools/schemas';
import { type Ticker } from '@@api/services/liveticker/schemas/ticker';
import { BLOCK_META_STATES, DEFAULT_BLOCK } from '@@editor/constants';
import { Editor } from '@@editor/helpers';
import { type PluginOptions } from '@@editor/typings/UnityPlugins';
import type usePostTickerSummary from '@@form/hooks/usePostTickerSummary';
import crypto from '@@scripts/utils/crypto';

const insertSummary = (editor: Editor, id: string, summary?: TickerSummary['summary']) => {
    const [firstMatch] = Editor.nodes(editor, {
        at: [],
        match: (node) => node.data?.[BLOCK_META_STATES.LOADING] === id,
    });

    if (firstMatch) {
        const [, path] = firstMatch;

        Editor.withoutNormalizing(editor, () => {
            Transforms.removeNodes(editor, { at: path });

            if (summary && summary.length > 0) {
                Editor.insertElement(
                    editor,
                    summary.map((stuff) => ({
                        ...DEFAULT_BLOCK,
                        children: [{ text: stuff }],
                    })),
                    { at: path },
                );

                const endPath = summary.slice(0, -1).reduce((acc) => Path.next(acc), path);

                const target = {
                    anchor: Editor.start(editor, path),
                    focus: Editor.end(editor, endPath),
                };

                Transforms.select(editor, target);
            }
        });
    }
};

const withTickerSummary = (
    editor: Editor,
    {
        contentId,
        generateTickerSummary,
    }: PluginOptions & {
        contentId: Ticker['id'];
        generateTickerSummary: ReturnType<typeof usePostTickerSummary>;
    },
) =>
    Object.assign(editor, {
        insertTickerSummary: (at) => {
            const id = crypto.randomUUID();
            const params = { id: contentId };

            Editor.insertElement(
                editor,
                {
                    ...DEFAULT_BLOCK,
                    data: {
                        ...DEFAULT_BLOCK.data,
                        [BLOCK_META_STATES.LOADING]: id,
                    },
                },
                { at },
            );

            generateTickerSummary({ params })
                .then(({ body: { summary } }) => {
                    insertSummary(editor, id, summary);
                })
                .catch(() => {
                    insertSummary(editor, id);
                });
        },
    });

export default withTickerSummary;
