import React from 'react';
import { Transforms } from 'slate';

import { type Editor } from '@@editor/helpers';
import { Element, ELEMENT_TYPES } from '@@editor/helpers/Element';
import deleteBackward from '@@editor/plugins/utils/deleteBackward';
import deleteForward from '@@editor/plugins/utils/deleteForward';
import deleteFragment from '@@editor/plugins/utils/deleteFragment';
import {
    normalizeInlineEditableElement,
    preventDeleteBackward,
    preventDeleteForward,
    preventDeleteFragment,
    preventInsertBreak,
} from '@@editor/plugins/utils/inlineEditing';
import insertBreak from '@@editor/plugins/utils/insertBreak';
import normalizeNode from '@@editor/plugins/utils/normalizeNode';
import renderEditor from '@@editor/plugins/utils/renderEditor';
import renderElement from '@@editor/plugins/utils/renderElement';
import { PLUGIN_NAMES } from '@@editor/typings/UnityPlugins';
import makeState from '@@editor/utils/makeState';

import EditorWithEmbedModal from '../embed/components/EditorWithEmbedModal';
import { createGenerateLayoutBlock } from '../layout/utils';
import QuestionAnswersForm from './../layout/components/QuestionAnswersForm';
import PollAnswerElement from './components/PollAnswerElement';
import PollNode, { INLINE_EDITABLE_CHILDREN_TYPES, NODE_TYPE, TYPE } from './components/PollNode';
import PollQuestionElement from './components/PollQuestionElement';

const emptyPollData = {
    question: makeState(),
    answers: [makeState()],
};

export const generateLayoutBlock = createGenerateLayoutBlock({
    type: TYPE,
    nodeType: NODE_TYPE,
});

const internalWithPoll = (editor: Editor, options) => {
    const { isVoid, useInlineEditing } = editor;

    return Object.assign(editor, {
        isVoid: (element: Element) => {
            if (useInlineEditing && Element.isPollRelatedElement(element)) {
                return false;
            }

            return element.type === NODE_TYPE ? true : isVoid(element);
        },
        deleteForward: deleteForward(editor, [
            [preventDeleteForward, { types: INLINE_EDITABLE_CHILDREN_TYPES }],
        ]),
        deleteBackward: deleteBackward(editor, [
            [preventDeleteBackward, { types: INLINE_EDITABLE_CHILDREN_TYPES }],
        ]),
        deleteFragment: deleteFragment(editor, [
            [preventDeleteFragment, { types: INLINE_EDITABLE_CHILDREN_TYPES }],
        ]),
        insertBreak: insertBreak(editor, [
            [preventInsertBreak, { types: [ELEMENT_TYPES.INTERVIEW_SEGMENT_QUESTION] }],
        ]),
        normalizeNode: normalizeNode(editor, [
            [
                normalizeInlineEditableElement,
                {
                    type: ELEMENT_TYPES.POLL,
                    allowedChildrenTypes: INLINE_EDITABLE_CHILDREN_TYPES,
                },
            ],
        ]),
        renderEditor: renderEditor(
            editor,
            (props) => (
                <EditorWithEmbedModal
                    {...props}
                    formProps={{ editor, formName: 'LayoutPoll' }}
                    formComponent={QuestionAnswersForm}
                    generateEmbedBlock={generateLayoutBlock}
                    type={TYPE}
                />
            ),
            options,
        ),
        renderElement: renderElement(
            editor,
            [
                [NODE_TYPE, PollNode],
                [ELEMENT_TYPES.POLL_QUESTION, PollQuestionElement],
                [ELEMENT_TYPES.POLL_ANSWER, PollAnswerElement],
            ],
            options,
        ),
        insertPoll: (at = editor.selection?.focus) => {
            if (useInlineEditing) {
                generateLayoutBlock(editor, emptyPollData, {
                    at,
                    replace: false,
                });
                if (at) {
                    Transforms.select(editor, at);
                    Transforms.collapse(editor);
                }
            } else {
                editor.showEmbedModal(PLUGIN_NAMES.POLL, undefined, at);
            }
        },
    });
};

export const withPoll = (editor, options) =>
    internalWithPoll(editor, {
        ...options,
        nodeType: NODE_TYPE,
    });

export default withPoll;
