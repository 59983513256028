import isPropValid from '@emotion/is-prop-valid';
import { Stack, styled } from '@mui/material';

import Icon from '@@components/Icon';
import PreviewImage from '@@containers/PreviewImage';
import { DropMode } from '@@hooks/useDropItem';

export const FIXED_ITEM_HEIGHT = 113;
export const PREVIEW_IMAGE_WIDTH = 100;

export const isReplaceDropMode = (dropMode) => dropMode === DropMode.REPLACE;

export const Wrapper = styled('div', { shouldForwardProp: (prop: string) => isPropValid(prop) })<{
    $canDrag?: boolean;
    $fixedHeight?: boolean;
}>(({ theme, $canDrag, $fixedHeight }) => ({
    position: 'relative',
    display: 'flex',
    flexFlow: 'row',
    width: '100%',
    height: $fixedHeight ? `${FIXED_ITEM_HEIGHT}px` : 'auto',
    margin: 0,
    padding: theme.spacing(3, 4),
    cursor: $canDrag ? 'move' : 'auto',
}));

export const Position = styled(Stack)(({ theme }) => ({
    flexDirection: 'row',
    position: 'absolute',
    left: `-${theme.spacing(4)}`,
    width: theme.spacing(4),
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    ...theme.typography.subtitle4,
    color: theme.palette.primary.light,
}));

export const ContentWrapper = styled('div')(({ theme }) => ({
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingLeft: theme.spacing(2),
}));

export const InfoBar = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    color: theme.palette.text.secondary,
    ...theme.typography.small,
}));

export const Title = styled('span', { shouldForwardProp: (prop: string) => isPropValid(prop) })<{
    $small?: boolean;
    $reducedOpacity?: boolean;
}>(({ theme, $small, $reducedOpacity }) => ({
    ...($small ? theme.typography.subtitle4 : theme.typography.subtitle3),
    color: theme.palette.text.primary,
    whiteSpace: 'pre-wrap',
    overflowWrap: 'anywhere',
    position: 'relative',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    ...($reducedOpacity && { opacity: 0.5 }),
}));

export const TitleHeader = styled('span')(({ theme }) => ({
    ...theme.typography.small,
    color: theme.palette.text.secondary,
    whiteSpace: 'pre-wrap',
    position: 'relative',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
}));

export const StyledIcon = styled(Icon)(({ theme }) => ({
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary,
}));

export const StyledPreviewImage = styled(PreviewImage)({
    width: `${PREVIEW_IMAGE_WIDTH}px`,
    height: '56px',
    objectFit: 'cover',
});

export const ContentItemActions = styled(Stack, {
    shouldForwardProp: (prop: string) => !prop.startsWith('$'),
})<{ $position?: 'top' | 'bottom' }>(({ theme, $position = 'bottom' }) => ({
    position: 'absolute',
    [$position]: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: theme.zIndex.layer1,
    flexDirection: 'row',
    gap: `calc(${theme.spacing(1)} / 2)`,
}));
