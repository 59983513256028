import { Fab, styled, useTheme } from '@mui/material';
import React, { useRef, useState } from 'react';
import { type Path } from 'slate';
import { useSlateStatic } from 'slate-react';

import ElementsDropdown from '@@components/ElementsDropdown';
import Icon from '@@components/Icon';
import HorizontalLine from '@@editor/components/HorizontalLine';
import { useDropBlock } from '@@editor/plugins/dnd/useDropBlock';
import { type PluginName } from '@@editor/typings/UnityPlugins';

import { DEFAULT_FLOATINGTOOLBAR_BUTTONS } from '../constants';
import { getFloatingButtonConfig } from '../utils/getFloatingElementsConfig';

// This toolbar needs a height of 0, because the editor elements need to touch in order to make drag and drop
// work correctly. If this toolbar would have a height > 0, the editor elements wouldn't touch (since this toolbar
// is rendered in between editor elements).
const Wrapper = styled('ul')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
    height: theme.fixed.editor.floatingToolbar.height,
    margin: '0 auto',
    padding: 0,
    zIndex: 1,
    userSelect: 'none',
    listStyleType: 'none',
}));

export const InnerWrapper = styled('li')<{
    $isArticleEditor?: boolean;
    $isToolbarVisible?: boolean;
}>(({ theme, $isArticleEditor, $isToolbarVisible }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    maxWidth: $isArticleEditor ? theme.fixed.editor.textElement.width : '100%',
    height: '100%',
    margin: 0,
    padding: 0,
    transition: theme.transitions.create('opacity', {
        easing: theme.transitions.easing.easeIn,
        duration: theme.transitions.duration.shortest,
    }),
    opacity: $isToolbarVisible ? '1' : '0',
}));

const StyledElementsDropdown = styled(ElementsDropdown)(({ theme }) => ({
    margin: theme.spacing(1),
}));

type Props = {
    activePlugins?: PluginName[];
    path?: Path;
};

const FloatingToolbar = ({ path, activePlugins = DEFAULT_FLOATINGTOOLBAR_BUTTONS }: Props) => {
    const [isToolbarVisible, setIsToolbarVisible] = useState(false);
    const theme = useTheme();
    const blockRef = useRef<HTMLUListElement>(null);
    const editor = useSlateStatic();
    const [, drop] = useDropBlock(editor, { blockRef });

    drop(blockRef);

    const showFloatingToolbar = () => {
        setIsToolbarVisible(true);
    };

    const hideFloatingToolbar = () => {
        // Because we use a transition for the toolbar, we want to wait until the transition is finished
        setTimeout(() => {
            setIsToolbarVisible(false);
        }, theme.transitions.duration.shortest);
    };

    const handleMouseDown = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
    };

    const renderElement = () => {
        const tabElements = getFloatingButtonConfig(editor, activePlugins, theme, path);

        return (
            <StyledElementsDropdown
                container={blockRef.current?.parentElement}
                autoFocus
                withTabs
                tabElements={tabElements}
                columnWidth={200}
                onClose={hideFloatingToolbar}
                renderToggleButton={(props) => (
                    <HorizontalLine isArticleEditor={editor.isArticleEditor}>
                        <Fab
                            {...props}
                            color="secondary"
                            size="small"
                            // Button shall not receive focus (not even by tab key) since it
                            // would interrupt the flow within a form or within the page
                            tabIndex={-1}
                            onMouseDown={handleMouseDown}
                        >
                            <Icon name="plus-sharp" />
                        </Fab>
                    </HorizontalLine>
                )}
            />
        );
    };

    return (
        <Wrapper
            ref={blockRef}
            aria-hidden={!isToolbarVisible}
            data-slate-floating-toolbar
            contentEditable={false}
            onMouseOver={showFloatingToolbar}
            onMouseLeave={hideFloatingToolbar}
        >
            <InnerWrapper
                $isArticleEditor={editor.isArticleEditor}
                $isToolbarVisible={isToolbarVisible}
            >
                {isToolbarVisible && renderElement()}
            </InnerWrapper>
        </Wrapper>
    );
};

export default FloatingToolbar;
