import { find, omit } from 'lodash';

import { type Article, type UnityArticle } from '@@api/services/content/schemas/article';
import { DEFAULT_TENANT_ID } from '@@containers/metadata/constants';
import { type Element } from '@@editor/helpers';
import { ELEMENT_TYPES, type ElementType } from '@@editor/helpers/Element';
import deserializeState from '@@editor/serialization/deserializeState';
import transformValues from '@@editor/serialization/transformValues';
import unwrapSerializedState from '@@editor/serialization/UnitySerializer/unwrapSerializedState';
import wrapSerializedState from '@@editor/serialization/UnitySerializer/wrapSerializedState';
import {
    type UnityElement,
    type UnityLead,
    type UnityParagraph,
    type UnityState,
    type UnityText,
    type UnityTitle,
    type UnityTitleHeader,
} from '@@editor/typings/UnityElements';

import { deserializeVariants } from '../../../utils/transformers';
import { type Headings } from '../types';

const toParagraph = (element: UnityText): UnityParagraph => ({
    ...element,
    type: ELEMENT_TYPES.TEXT,
});

export const makeVariantField = (field: UnityTitle | UnityTitleHeader | UnityLead): UnityState =>
    wrapSerializedState([omit(toParagraph(field), 'variants')]);

export const findInContent = <T extends UnityElement | Element>(
    content: UnityElement[] | Element[],
    type: ElementType,
): T | undefined => find<UnityElement | Element>(content, (o) => o.type === type) as T | undefined;

export const getVariantsFieldsFromContent = (entity: UnityArticle): Headings => {
    const content = unwrapSerializedState(entity.content);

    return content.slice(0, 3) as [UnityTitleHeader, UnityTitle, UnityLead];
};

export const transformToArticleVariants = (headings: Headings) => {
    let variants = {
        [DEFAULT_TENANT_ID]: {
            headings: wrapSerializedState(headings),
        },
    };

    headings.forEach((heading) => {
        if (heading.variants) {
            Object.entries(heading.variants).forEach(([variantId, variant]) => {
                variants = {
                    ...variants,
                    [variantId]: {
                        headings: wrapSerializedState([
                            ...(unwrapSerializedState(variants[variantId]?.headings) || []),
                            { ...variant, elementId: heading.elementId },
                        ]),
                    },
                };
            });
        }
    });

    return deserializeVariants(variants, (variant) =>
        transformValues<typeof variant, Article['variants'][number]>(variant, [
            [['headings'], deserializeState],
        ]),
    );
};

export const removeUnwantedElements = (content: UnityElement[]) =>
    content.filter(
        (item) =>
            ![ELEMENT_TYPES.TITLE, ELEMENT_TYPES.TITLE_HEADER, ELEMENT_TYPES.LEAD].includes(
                // @ts-expect-error Typing of includes function when used to filter out is very tricky https://fettblog.eu/typescript-array-includes/
                item.type,
            ),
    );
