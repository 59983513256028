import React, { useMemo, useReducer } from 'react';

import ModalActionsContext from './ModalActionsContext';
import ModalContext from './ModalContext';
import { applyAction, createActionFunctions } from './reducer';
import { type Action, type State } from './types';

const initialState: State = {
    modals: [],
};

type Reducer = (prevState: State, action: Action) => State;

type Props = { children: React.ReactElement };
const ModalProvider = ({ children }: Props) => {
    const [state, dispatch] = useReducer<Reducer>(applyAction, initialState);
    const actions = useMemo(() => createActionFunctions(dispatch), []);

    return (
        <ModalContext.Provider value={state}>
            <ModalActionsContext.Provider value={actions}>
                {state.modals.map((modal) => {
                    const ModalComponent = modal.component;

                    return (
                        <ModalComponent
                            key={modal.id}
                            {...modal.props}
                            onClose={() => {
                                actions.hide(modal.id);

                                modal.props.onClose?.();
                            }}
                        />
                    );
                })}

                {children}
            </ModalActionsContext.Provider>
        </ModalContext.Provider>
    );
};

export default ModalProvider;
