import { Link, Stack, styled, Typography } from '@mui/material';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Icon from '@@components/Icon';
import { type EmbedElement } from '@@editor/helpers/Element';

type Props = {
    element: EmbedElement;
    iconName: string;
    socialNetworkName: string;
};

const Wrapper = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.primary['50'],
    position: 'relative',
    width: '100%',
    minHeight: '300px',
    overflow: 'hidden',
}));

const SocialEmbedIcon = styled(Icon)({
    position: 'absolute',
    top: 0,
    left: 0,
    transform: 'rotate(-20deg) translate(-55px, -45px)',
    zIndex: 0,
});

const PreviewIcon = styled(Icon)({ verticalAlign: 'middle' });

const SocialEmbed = ({ element, iconName, socialNetworkName }: Props) => {
    const { src } = element.data;
    const href = src?.toString();
    const { t } = useTranslation();

    return (
        <Wrapper>
            <SocialEmbedIcon name={iconName} color="primary.light" size="300px" />

            <Stack
                justifyContent="center"
                alignItems="center"
                textAlign="center"
                position="relative"
                p={4}
                height="100%"
            >
                <Typography variant="h3">
                    {t('editor.plugin.socialEmbed.securityMessage1', { socialNetworkName })}
                </Typography>

                <Typography>
                    <p>{t('editor.plugin.socialEmbed.securityMessage2')}</p>

                    <Link href={href} target="_blank" sx={{ wordBreak: 'break-all' }}>
                        {href}
                    </Link>

                    <p style={{ marginBottom: 0 }}>
                        {/* t('editor.plugin.socialEmbed.securityMessage3') */}
                        <Trans
                            i18nKey="editor.plugin.socialEmbed.securityMessage3"
                            components={{
                                PreviewIcon: (
                                    <PreviewIcon name="eye-regular" color="action.active" />
                                ),
                            }}
                        />
                    </p>
                </Typography>
            </Stack>
        </Wrapper>
    );
};

export default SocialEmbed;
