import { castArray, first, flow, map, partialRight, pick } from 'lodash';

import { DEFAULT_BLOCK } from '@@editor/constants';
import { type DynamicTeaserElement, Element, ELEMENT_TYPES } from '@@editor/helpers/Element';
import transformValues from '@@editor/serialization/transformValues';
import { type UnityDynamicTeaser } from '@@editor/typings/UnityElements';
import addDefaultElementIfEmptyState from '@@editor/utils/addDefaultElementIfEmptyState';

import { type SerializerOptions } from '../../types';
import unwrapSerializedState from '../../unwrapSerializedState';
import wrapSerializedState from '../../wrapSerializedState';
import { CURRENT_VERSION } from './../../constants';
import { createElement } from './../../deserializeNodes';

const serializeInlineDynamicTeaserData = (
    children: Element[],
    data: DynamicTeaserElement['data'],
) => {
    const newData = { ...data };
    const title = children.find(Element.isDynamicTeaserTitleElement);

    if (title) {
        newData.title = [{ ...DEFAULT_BLOCK, children: title.children }];
    }

    return newData;
};

const dynamicTeaserRules = {
    deserialize: (element: UnityDynamicTeaser, next, options?): DynamicTeaserElement => {
        const values = pick(element, ['title', 'image', 'metadataIds', 'templateElement']);

        return createElement<DynamicTeaserElement>(
            ELEMENT_TYPES.DYNAMIC_TEASER,
            transformValues<typeof values, DynamicTeaserElement['data']>(values, [
                ['title', flow(addDefaultElementIfEmptyState, next, castArray)],
                ['image.caption', flow(unwrapSerializedState, partialRight(map, next))],
            ]),
            options,
        );
    },

    serialize: (
        node: DynamicTeaserElement,
        next,
        options: SerializerOptions = {},
    ): UnityDynamicTeaser => {
        const { type, data, children } = node;
        const { useInlineEditing } = options;
        const newData = useInlineEditing ? serializeInlineDynamicTeaserData(children, data) : data;

        return {
            version: CURRENT_VERSION,
            type,
            ...transformValues<typeof data, Omit<UnityDynamicTeaser, 'version' | 'type'>>(newData, [
                ['title', flow(first, next)],
                ['image.caption', flow(partialRight(map, next), wrapSerializedState)],
            ]),
        };
    },
};

export default dynamicTeaserRules;
