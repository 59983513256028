import { useMemo } from 'react';

import { type MetadataRouter, useMetadataClient } from '@@api/services/metadata/client';
import { type RequestQuery } from '@@api/types';

const useFetchMetadata = () => {
    const { client: metadataClient, queryKeys: metadataKeys } = useMetadataClient();
    const queryClient = metadataClient.useQueryClient();

    const fetchMetadata = ({
        params,
    }: {
        params: RequestQuery<MetadataRouter['metadata']['getList']>;
    }) =>
        queryClient.metadata.getList.fetchQuery({
            queryKey: metadataKeys.metadata.getList({ query: params }),
            queryData: {
                query: params,
            },
        });

    const memoizedFetchMetadata = useMemo(() => fetchMetadata, []);

    return memoizedFetchMetadata;
};

export default useFetchMetadata;
