import React from 'react';

import { PLUGIN_CONFIG_TEMPLATES } from '@@editor/constants';
import { PLUGIN_NAMES } from '@@editor/typings/UnityPlugins';

import RichTextEditor from './RichTextEditor';

const InfoboxEditor = (props) => {
    const pluginConfig = [
        PLUGIN_NAMES.PARAGRAPH,
        PLUGIN_NAMES.BOLD,
        PLUGIN_NAMES.ITALIC,
        PLUGIN_NAMES.UNDERLINED,
        PLUGIN_NAMES.SUBSCRIPT,
        PLUGIN_NAMES.SUPERSCRIPT,
        PLUGIN_CONFIG_TEMPLATES.specialCharacters,
        PLUGIN_NAMES.LINK,
        PLUGIN_CONFIG_TEMPLATES.softHyphen,
        PLUGIN_NAMES.LIST,
        PLUGIN_NAMES.DRAG_DROP,
        {
            name: PLUGIN_NAMES.IMAGE,
            options: {
                plugins: [PLUGIN_NAMES.FILE_UPLOAD],
            },
        },
    ];

    return <RichTextEditor {...{ ...props, pluginConfig }} />;
};

export default InfoboxEditor;
