import {
    FormControlLabel,
    type FormControlLabelProps,
    Radio,
    type RadioProps,
} from '@mui/material';
import React from 'react';

export type RadioInputProps = RadioProps & {
    label?: FormControlLabelProps['label'];
    labelPlacement?: FormControlLabelProps['labelPlacement'];
};

const RadioInput = (props: RadioInputProps) => {
    const { label, labelPlacement, ...rest } = props;

    if (label) {
        return (
            <FormControlLabel
                control={<Radio />}
                value={rest.value}
                label={label}
                labelPlacement={labelPlacement}
            />
        );
    }

    return <Radio {...rest} />;
};

export default RadioInput;
