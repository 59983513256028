import { Stack, styled } from '@mui/material';
import React from 'react';

import Image from '@@components/Image';

const Wrapper = styled(Stack)(({ theme }) => ({
    padding: theme.spacing(4),
    width: '100%',
    height: '100%',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: `${theme.shape.borderRadius}px`,
}));

const StyledImage = styled(Image)({
    width: '80%',
    maxHeight: '90px',
});

const Message = styled('p')(({ theme }) => ({
    ...theme.typography.medium,
    textAlign: 'center',
}));

const Intro = styled('p')(({ theme }) => ({
    ...theme.typography.subtitle3,
    marginTop: theme.spacing(3),
    textAlign: 'center',
}));

export type Props = {
    children?: React.ReactNode;
    intro: string;
    message: string;
    image?: {
        src: string;
        alt: string;
    };
};

const EmbedPlaceholder = ({ image, intro, message, children }: Props) => (
    <Wrapper alignItems="center" justifyContent="center">
        {image && <StyledImage src={image.src} alt={image.alt} />}
        <Intro>{intro}</Intro>
        <Message>{message}</Message>
        {children}
    </Wrapper>
);

export default EmbedPlaceholder;
