import {
    type MaintenanceNote,
    type ReleaseNote,
    type UnityMaintenanceNote,
    type UnityReleaseNote,
} from '@@api/services/releasenotes/schemas';
import deserializeState from '@@editor/serialization/deserializeState';
import serializeState from '@@editor/serialization/serializeState';
import transformValues from '@@editor/serialization/transformValues';

export const deserializeReleasenote = (entity: UnityReleaseNote): ReleaseNote =>
    transformValues(entity, [['body', deserializeState]]);

export const serializeReleasenote = (entity: ReleaseNote): UnityReleaseNote =>
    transformValues(entity, [['body', serializeState]]);

export const deserializeMaintenancenote = (entity: UnityMaintenanceNote): MaintenanceNote =>
    transformValues(entity, [['note', deserializeState]]);

export const serializeMaintenancenote = (entity: MaintenanceNote): UnityMaintenanceNote =>
    transformValues(entity, [['note', serializeState]]);
