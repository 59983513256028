import { noop, omit } from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';

import { type PluginName } from '@@editor/typings/UnityPlugins';

import { mergeData } from '../utils';

type EditorId = string;

type SetDataActionOptions = { editorId?: EditorId };

export type Data = Record<EditorId, AnyObject>;
type DataActions = {
    registerEditorId: (editorId: EditorId) => void;
    setData: (newData: AnyObject, options?: SetDataActionOptions) => void;
    setPluginData: (
        pluginName: PluginName,
        newData: AnyObject,
        options?: SetDataActionOptions,
    ) => void;
    unregisterEditorId: (editorId: EditorId) => void;
};

export const EditorDataContext = React.createContext<Data>({});
export const EditorDataActionsContext = React.createContext<DataActions>({
    registerEditorId: noop,
    setData: noop,
    setPluginData: noop,
    unregisterEditorId: noop,
});

type MergeContextDataOptions = {
    pluginName?: PluginName;
};

export const mergeContextData = (
    data: Data,
    newData: AnyObject,
    editorIds: EditorId[],
    options: MergeContextDataOptions = {},
) => {
    const { pluginName } = options;

    const nextData = editorIds.reduce<Data>(
        (result, editorId) => {
            const editorData = data[editorId] ?? {};

            if (pluginName) {
                if (pluginName in editorData) {
                    return Object.assign(result, {
                        [editorId]: mergeData(data[editorId] ?? {}, { [pluginName]: newData }),
                    });
                }

                return result;
            }

            return Object.assign(result, {
                [editorId]: mergeData(editorData, newData),
            });
        },
        { ...data },
    );

    return nextData;
};

export type State = {
    data: Data;
    registeredEditorIds: EditorId[];
};

export type RegisterEditorIdAction = {
    type: 'REGISTER_EDITOR_ID';
    editorId: EditorId;
};

export type SetDataAction = {
    type: 'SET_DATA';
    newData: AnyObject;
    editorId?: EditorId;
    pluginName?: PluginName;
};

export type UnregisterEditorIdAction = {
    type: 'UNREGISTER_EDITOR_ID';
    editorId: EditorId;
};

type Action = RegisterEditorIdAction | SetDataAction | UnregisterEditorIdAction;

export const applyAction = (state: State, action: Action): State => {
    switch (action.type) {
        case 'REGISTER_EDITOR_ID': {
            const { editorId } = action;

            const registeredEditorIds = [...state.registeredEditorIds];

            if (!registeredEditorIds.includes(editorId)) {
                registeredEditorIds.push(editorId);
            }

            return {
                ...state,
                data: mergeContextData(state.data, {}, [editorId]),
                registeredEditorIds,
            };
        }

        case 'SET_DATA': {
            const { editorId, pluginName, newData } = action;

            if (editorId && !state.registeredEditorIds.includes(editorId)) {
                return state;
            }

            const editorIds = editorId ? [editorId] : state.registeredEditorIds;

            return {
                ...state,
                data: mergeContextData(state.data, newData, editorIds, { pluginName }),
            };
        }

        case 'UNREGISTER_EDITOR_ID': {
            const { editorId } = action;

            return {
                ...state,
                data: omit(state.data, editorId),
                registeredEditorIds: state.registeredEditorIds.filter(
                    (registeredEditorId) => registeredEditorId !== editorId,
                ),
            };
        }
    }
};

const initialState: State = { data: {}, registeredEditorIds: [] };

type Props = { children: React.ReactNode };

export const EditorDataContextProvider = ({ children }: Props) => {
    const [state, dispatch] = useReducer(applyAction, initialState);

    const dataActions = useMemo<DataActions>(
        () => ({
            registerEditorId(editorId) {
                dispatch({ type: 'REGISTER_EDITOR_ID', editorId });
            },
            setData(newData, options) {
                dispatch({ ...options, type: 'SET_DATA', newData });
            },
            setPluginData(pluginName, newData, options) {
                dispatch({ ...options, type: 'SET_DATA', pluginName, newData });
            },
            unregisterEditorId(editorId) {
                dispatch({ type: 'UNREGISTER_EDITOR_ID', editorId });
            },
        }),
        [dispatch],
    );

    return (
        <EditorDataContext.Provider value={state.data}>
            <EditorDataActionsContext.Provider value={dataActions}>
                {children}
            </EditorDataActionsContext.Provider>
        </EditorDataContext.Provider>
    );
};

export const useEditorDataContext = () => useContext(EditorDataContext);
export const useEditorDataActionsContext = () => useContext(EditorDataActionsContext);

// This is doing 3 things:
// 1. Return the context data (and its related setter function) which belongs to the current editor
// 2. Adding a namespace for the current editor within the context data on mount and removing it on unmount
export const useInitEditorDataContext = (externalEditorId: string) => {
    // Use the editorId provided on first render. Make sure it will never change (by using `useState`).
    // This is important! The codebase is not ready to deal with changes of `editorId`s.
    const [editorId] = useState(externalEditorId);
    const { registerEditorId, setData, unregisterEditorId } = useEditorDataActionsContext();
    const data = useEditorDataContext();
    const editorContextData = data[editorId];

    // This equals to `componentWillMount`. We need to register the editor id earlier than `componentDidMount`,
    // because plugins will try to set data for the current editor on `componentDidMount` (`componentDidMount`
    // of child components is called before `componentDidMount` of parent components, but `componentWillMount` of
    // parent components is called before `componentWillMount` of children)
    useState(() => {
        registerEditorId(editorId);
    });

    useEffect(
        () => () => {
            unregisterEditorId(editorId);
        },
        [],
    );

    const setEditorContextData = useCallback(
        (data) => setData(data, { editorId }),
        [editorId, setData],
    );

    return { editorContextData, setEditorContextData };
};
