import { type CSSObject, type Theme } from '@mui/material';

// Add the transparent 1px border to the bottom in order to the simulate the correct dimensions of the
// element that uses this border. For example: if we position another element absolute with a `bottomn` value
// inside the element that uses this border, without the `border-bottom` it would be missaligned about 1px.
// Why do we not just use the `border-bottom` instead of doing this `::after` thingy? There was a reason for it,
// but unfortunately i forgot about it.... it was important though! :)

const listItemBorderStyle = ({ theme }: { theme: Theme }): CSSObject => ({
    position: 'relative',
    borderBottom: '1px solid transparent',
    '&::after': {
        display: 'block',
        content: '""',
        position: 'absolute',
        bottom: '-1px',
        left: '0',
        right: '0',
        height: '1px',
        background: theme.palette.primary['100'],
    },
});

export default listItemBorderStyle;
