import { debounce } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { type AuthRouter, useAuthClient } from '@@api/services/auth/client';
import { type Agency, type User } from '@@api/services/auth/schemas';
import { getQueryParams } from '@@api/utils/getQueryParams';
import config from '@@config';
import Autocomplete, { type AutocompleteProps } from '@@form/components/Autocomplete';
import useDeepCompareMemo from '@@hooks/useDeepCompareMemo';

import { RESOURCE_TYPES } from './constants';

const SIZE = 20;

type FormattedOption<T extends User | Agency> = T & {
    label: string;
    value: string;
    type: 'user' | 'agency';
    id: `${'user' | 'agency'}.${string}`;
};

const formatOption = <T extends User | Agency>(
    option: T,
    listName: 'user' | 'agency',
    propertyToShow: 'fullName' | 'name',
): FormattedOption<T> => ({
    ...option,
    label: option[propertyToShow],
    value: option.id,
    type: RESOURCE_TYPES[listName],
    id: `${listName}.${option.id}`,
});

const formatOptions = <T extends User | Agency>(
    list: T[],
    listName: 'user' | 'agency',
    propertyToShow: 'fullName' | 'name',
    selectedItem: FormattedOption<T> | null,
) => {
    if (list.length) {
        return (
            list
                .map((option) => formatOption(option, listName, propertyToShow))
                // In some cases, a user is not properly defined in the database and cannot generate a label
                // which in turns causes a crash if the label is undefined. So we filter out these users
                // (This happens in development environments)
                .filter((item) => item.label)
        );
    }

    if (selectedItem) {
        return [selectedItem];
    }

    return [];
};

type Props = Pick<
    AutocompleteProps<string | null>,
    'label' | 'placeholder' | 'title' | 'className'
> & {
    value: string | null;
    onChange: (value: string | null) => void;
};

export const AuthorSearchableAutocomplete = (props: Props) => {
    const { value, onChange, ...rest } = props;
    const { t } = useTranslation();
    const { client: authClient, queryKeys: authKeys } = useAuthClient();

    const [filter, setFilter] = useState('');
    const setFilterDebounced = debounce(setFilter, config.searchDebounce);

    const hasMinCharacters = filter.length >= 2;

    const memoizedUserParams = useDeepCompareMemo(
        () =>
            getQueryParams<AuthRouter['users']['getAll']>({
                q: filter,
                size: SIZE,
            }),
        [filter],
    );
    const memoizedAgencyParams = useDeepCompareMemo(
        () =>
            getQueryParams<AuthRouter['agency']['getAll']>({
                size: SIZE,
            }),
        [filter],
    );

    const { data: usersData, isLoading: isUsersListLoading } = authClient.users.getAll.useQuery({
        queryKey: authKeys.users.getAll({ query: memoizedUserParams }),
        queryData: { query: memoizedUserParams },
        enabled: hasMinCharacters,
    });
    const usersList = usersData?.body.content || [];

    const { data: agenciesData, isLoading: isAgenciesListLoading } =
        authClient.agency.getAll.useQuery({
            queryKey: authKeys.agency.getAll({ query: memoizedAgencyParams }),
            queryData: { query: memoizedAgencyParams },
            enabled: hasMinCharacters,
        });
    const agenciesList = agenciesData?.body.content || [];

    /*
    when the user enters the page with query params, to know which resource to fetch,
    the prefix with type (agency/user) is added for userIds query param
    userIds=agency.{userId}
    */
    const [type, id = 0] = value?.split('.') || [];

    const params = {
        id: String(id),
    };
    const { data: selectedUserData, isLoading: isUserLoading } =
        authClient.users.profile.get.useQuery({
            queryKey: authKeys.users.profile.get({ params }),
            queryData: { params },
            enabled: Boolean(id && type === RESOURCE_TYPES.user),
        });
    const selectedUser = selectedUserData?.body;

    const { data: selectedAgencyData, isLoading: isAgencyLoading } = authClient.agency.get.useQuery(
        {
            queryKey: authKeys.agency.get({ params }),
            queryData: { params },
            enabled: Boolean(id && type === RESOURCE_TYPES.agency),
        },
    );
    const selectedAgency = selectedAgencyData?.body;

    const enhancedSelectedUser = React.useMemo(() => {
        if (selectedUser) {
            return formatOption(selectedUser, 'user', 'fullName');
        }

        return null;
    }, [selectedUser]);
    const enhancedSelectedAgency = React.useMemo(() => {
        if (selectedAgency) {
            return formatOption(selectedAgency, 'agency', 'name');
        }

        return null;
    }, [selectedAgency]);

    const userOptions = formatOptions(usersList, 'user', 'fullName', enhancedSelectedUser);
    const agencyOptions = formatOptions(agenciesList, 'agency', 'name', enhancedSelectedAgency);

    const allOptions: (FormattedOption<User> | FormattedOption<Agency>)[] = [
        ...userOptions,
        ...agencyOptions,
    ];

    const currentValue = React.useMemo(() => {
        if (value === enhancedSelectedUser?.id) {
            return enhancedSelectedUser;
        }

        if (value === enhancedSelectedAgency?.id) {
            return enhancedSelectedAgency;
        }

        return null;
    }, [value, enhancedSelectedUser, enhancedSelectedAgency]);

    const handleChange = (
        event: React.SyntheticEvent,
        value: FormattedOption<User> | FormattedOption<Agency> | null,
    ) => {
        const adjustedValue = value?.id ?? null;

        if (onChange) {
            onChange(adjustedValue);
        }
    };

    const handleInputChange = (event: React.SyntheticEvent, value: string) => {
        setFilterDebounced(value);
    };

    return (
        <Autocomplete
            noOptionsText={
                hasMinCharacters ? t('search.authors.nonefound') : t('search.minCharacters')
            }
            loading={
                isUsersListLoading || isAgenciesListLoading || isUserLoading || isAgencyLoading
            }
            {...rest}
            options={allOptions}
            value={currentValue}
            onChange={handleChange}
            onInputChange={handleInputChange}
            groupBy={(option) => option.type}
            getOptionKey={(option) => option.value}
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            filterOptions={(x) => x}
        />
    );
};

export default AuthorSearchableAutocomplete;
