import invariant from 'tiny-invariant';

import {
    type Score,
    type SoccerScore,
    type SportTicker,
    type TennisScore,
    type Ticker,
} from '@@api/services/liveticker/schemas/ticker';

import { DEFAULT_TENNIS_SCORE, TICKER_EVENTS, TICKER_TYPES } from './constants';
import { type TickerEventWithScore } from './events/EventForm/utils';

export const soccerOrIceHockey = {
    goals: [0, 0],
    penaltyShots: [[], []],
    // TODO: this needs not to be saved on ticker, only for event
    penaltyShot: null,
} satisfies SoccerScore;

export const tennis = {
    points: ['0', '0'],
    tiebreak: [0, 0],
    sets: [[0, 0]],
    isFinished: false,
} satisfies TennisScore;

export const getDefaultTickerScoreByType = (type: Ticker['type']) => {
    const typeAvailable = Object.values(TICKER_TYPES).find((c) => c === type);

    invariant(typeAvailable, `getDefaultTickerScoreByType: type "${type}" is not available!`);

    switch (type) {
        case TICKER_TYPES.ICE_HOCKEY: {
            return soccerOrIceHockey;
        }
        case TICKER_TYPES.SOCCER: {
            return soccerOrIceHockey;
        }
        case TICKER_TYPES.TENNIS: {
            return tennis;
        }
    }
};

export const getCurrentTickerScore = (type: Ticker['type'], score?: Score | null) => ({
    ...getDefaultTickerScoreByType(type),
    ...score,
});

export const calculateNewScore = (tickerEvent: TickerEventWithScore, ticker: SportTicker) => {
    if (ticker.type === TICKER_TYPES.SOCCER || ticker.type === TICKER_TYPES.ICE_HOCKEY) {
        const currentScore = ticker.data.score;

        if (
            tickerEvent.type === TICKER_EVENTS.SOCCER_GOAL ||
            tickerEvent.type === TICKER_EVENTS.ICE_HOCKEY_GOAL
        ) {
            const currentGoals = tickerEvent.data.goals;

            return {
                ...currentScore,
                goals: currentGoals,
            };
        }

        if (
            tickerEvent.type === TICKER_EVENTS.SOCCER_SHOOT_OUT ||
            tickerEvent.type === TICKER_EVENTS.ICE_HOCKEY_SHOOT_OUT
        ) {
            const currentPenaltyShots = tickerEvent.data.penaltyShot;

            return {
                ...currentScore,
                penaltyShots: [
                    [...currentScore.penaltyShots[0], currentPenaltyShots?.[0]].filter(
                        (s) => s !== null,
                    ),
                    [...currentScore.penaltyShots[1], currentPenaltyShots?.[1]].filter(
                        (s) => s !== null,
                    ),
                ],
            };
        }
    }

    if (ticker.type === TICKER_TYPES.TENNIS) {
        const currentScore = ticker.data.score;

        if (tickerEvent.type === TICKER_EVENTS.TENNIS_POINT) {
            const currentPoints = tickerEvent.data.points;

            return {
                ...DEFAULT_TENNIS_SCORE,
                sets: currentScore.sets,
                points: currentPoints,
            };
        }

        if (tickerEvent.type === TICKER_EVENTS.TENNIS_TIEBREAK) {
            const currentTieBreak = tickerEvent.data.tiebreak;

            return {
                ...DEFAULT_TENNIS_SCORE,
                sets: currentScore.sets,
                tiebreak: currentTieBreak,
            };
        }

        if (tickerEvent.type === TICKER_EVENTS.TENNIS_GAME) {
            const currentSets = tickerEvent.data.sets;

            return {
                ...DEFAULT_TENNIS_SCORE,

                sets: [...currentScore.sets.slice(0, -1), currentSets[currentSets.length - 1]],
            };
        }

        if (tickerEvent.type === TICKER_EVENTS.TENNIS_SET) {
            const currentSets = tickerEvent.data.sets;

            // Save the current set and then start with a fresh set
            return {
                ...DEFAULT_TENNIS_SCORE,
                sets: [
                    ...currentScore.sets.slice(0, -1),
                    currentSets[currentSets.length - 1],
                    DEFAULT_TENNIS_SCORE.sets[DEFAULT_TENNIS_SCORE.sets.length - 1],
                ],
            };
        }

        if (tickerEvent.type === TICKER_EVENTS.TENNIS_MATCH) {
            const currentSets = tickerEvent.data.sets;

            return {
                ...DEFAULT_TENNIS_SCORE,
                sets: [...currentScore.sets.slice(0, -1), currentSets[currentSets.length - 1]],
                isFinished: true,
            };
        }
    }
};
