import { type AppRouteResponse } from '@ts-rest/core';

import { ApiError } from './utils/schemas/errors';

export const commonResponses = {
    400: ApiError,
    401: ApiError,
    403: ApiError,
    404: ApiError,
    412: ApiError,
    429: ApiError,
    500: ApiError,
} satisfies Record<number, AppRouteResponse>;
