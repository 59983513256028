export const DEFAULT_TENANT_COLOR = 'primary.400';

export const DEFAULT_CATEGORY_ID = 0;
export const DEFAULT_TAG_ID = 0;

export const COLUMN_TYPE = {
    CATEGORY: 'c',
    TAG: 't',
} as const;

export type ColumnType = (typeof COLUMN_TYPE)[keyof typeof COLUMN_TYPE];
