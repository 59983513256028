import React from 'react';

import { type ModalContextType } from './types';

const initialContextValue: ModalContextType = {
    modals: [],
};

const ModalContext = React.createContext<ModalContextType>(initialContextValue);

export default ModalContext;
