import { styled } from '@mui/material';
import React, { type CSSProperties, type PropsWithChildren } from 'react';

import { type Editor } from '@@editor/helpers';
import { Element, type ElementAttributes, type TitleElement } from '@@editor/helpers/Element';
import { getTextBoxStyle } from '@@editor/plugins/serializable/styles';

import HeadingWrapper from './HeadingWrapper';

type TitleWrapperProps = {
    placeholderText: string;
};

const TitleWrapper = styled(HeadingWrapper)<TitleWrapperProps>(({ theme, placeholderText }) => ({
    ...getTextBoxStyle({ theme, placeholderText }),
    ...theme.typography.editorSecondaryXlarge,
    fontWeight: 'bold',
}));

type Props = PropsWithChildren<{
    attributes?: ElementAttributes;
    editor: Editor;
    element: TitleElement;
    style?: CSSProperties;
}>;

const Title = React.forwardRef<HTMLElement, Props>(
    ({ attributes, children, editor, element, style }, ref) => {
        const isEmpty = Element.isEmptyTextElement(element);

        const placeholderText = isEmpty
            ? editor.t('editor.plugin.paragraph.title.placeholderText')
            : '';

        return (
            <TitleWrapper {...{ ref, style, ...attributes, editor, element, placeholderText }}>
                {children}
            </TitleWrapper>
        );
    },
);

export default Title;
