import embedRules from './embedRules';
import errorRules from './errorRules';
import layoutRules from './layoutRules';
import legacyImportRules from './legacyImportRules';
import listRules from './listRules';
import markRules from './markRules';
import pollRules from './pollRules';
import snippetRules from './snippetRules';
import summaryListRules from './summaryListRules';
import textRules from './textRules';

export const agnosticRules = [
    textRules,
    listRules,
    embedRules,
    markRules,
    layoutRules,
    legacyImportRules,
    pollRules,
    snippetRules,
    summaryListRules,
];

export default [...agnosticRules, errorRules];
