import { Stack, styled } from '@mui/material';
import React from 'react';

import isEmptyState from '@@editor/utils/isEmptyState';
import QuoteEditor from '@@form/components/RichTextEditor/QuoteEditor';

import { P } from '../../text/paragraph/components/Paragraph';

const QuoteText = styled(QuoteEditor)(({ theme }) => ({
    ...theme.typography.editorSecondaryXlarge,
    padding: `0 0 ${theme.spacing(2)}`,
    [`& ${P}`]: {
        textAlign: 'center',
    },
}));

const QuoteCaption = styled(QuoteEditor)(({ theme }) => ({
    ...theme.typography.editorSecondaryLarge,
}));

const Wrapper = styled(Stack)(({ theme }) => ({
    alignItems: 'flex-end',
    padding: `${theme.spacing(3)}`,
}));

const Quote = ({ element }) => {
    const { quote, caption } = element.data;

    return (
        <Wrapper>
            <QuoteText value={quote} readOnly />

            {!isEmptyState(caption) && (
                <Stack direction="row" alignItems="center">
                    {'— '} <QuoteCaption value={caption} readOnly />
                </Stack>
            )}
        </Wrapper>
    );
};

export default Quote;
