import { type Editor } from '@@editor/helpers';
import { type ElementType } from '@@editor/helpers/Element';

export const NEW_COMMENT_SELECTION_SYMBOL = 'newCommentSelection';
export const NEW_COMMENT_SELECTION_IS_VISIBLE_SYMBOL = 'newCommentSelectionIsVisible';
export const MARK = 'commentId';
export const NEW_COMMENT_ID = new WeakMap<Editor, string>();
export const MAX_TEXT_LENGTH = 1024;
export const EDITOR_TO_UNSUPPORTED_ELEMENT_TYPES = new WeakMap<Editor, ElementType[]>();
export const COMMENT_THREAD_ID_ATTRIBUTE_NAME = 'data-slate-comment-plugin-comment-thread-id';
