import { debounce, isEqual, noop } from 'lodash';
import React, { useCallback, useContext, useMemo, useReducer } from 'react';

import { type HorizontalLineType } from '@@editor/components/HorizontalLine';

import { type DropDirection } from './types';

const HIDE_DROP_LINE_DELAY = 25;

type DropLine = {
    element: HTMLElement | null;
    direction: DropDirection;
    type?: HorizontalLineType;
};

type ContextType = {
    dropLine: DropLine | null;
};

type ActionsContextType = {
    clearDropLine: VoidFunction;
    setDropLine: (dropLine: DropLine) => void;
    setIsOverTarget: (id: string, isOver: boolean) => void;
};

const DEFAULT_VALUE: ContextType = {
    dropLine: null,
};

const DEFAULT_ACTIONS_VALUE: ActionsContextType = {
    clearDropLine: noop,
    setDropLine: noop,
    setIsOverTarget: noop,
};

const DropLineContext = React.createContext<ContextType>(DEFAULT_VALUE);
const DropLineActionsContext = React.createContext<ActionsContextType>(DEFAULT_ACTIONS_VALUE);

const reduce = (state, action) => {
    switch (action.type) {
        case 'SET_DROP_LINE': {
            // This `isEqual` check is crucial for performance - especially for people on less powerful
            // computers!
            if (!isEqual(action.dropLine, state.dropLine)) {
                return { ...state, dropLine: action.dropLine };
            }

            break;
        }

        case 'CLEAR_DROP_LINE':
            return { ...state, dropLine: null };
    }

    return state;
};

type Props = { children: React.ReactNode };

export const DropLineProvider = ({ children }: Props) => {
    const [state, dispatch] = useReducer(reduce, { dropLine: null });
    const hoveredDropLineIds = useMemo(() => new Set(), []);

    // We need to debounce here, in order to avoid blinking drop lines
    const hideDropLine = useMemo(
        () =>
            debounce(() => {
                dispatch({ type: 'CLEAR_DROP_LINE' });
            }, HIDE_DROP_LINE_DELAY),
        [],
    );

    const setIsOverTarget = useCallback(
        (id, isOver) => {
            if (isOver) {
                hoveredDropLineIds.add(id);
            } else {
                hoveredDropLineIds.delete(id);
            }

            if (hoveredDropLineIds.size === 0) {
                hideDropLine();
            }
            // If the user is changing drop targets quickly, by dragging the drag source over editor elements,
            // we get a blinky drop line. For example element 1 will report a dragleave, element 2 will
            // report a dragenter. This would hide, and immediately show the drop line again (blinky).
            // So instead of hiding and showing again immediately, we just wait for `HIDE_DROP_LINE_DELAY`,
            // to see if another drop target reported a hover in the meantime.
            else {
                hideDropLine.cancel();
            }
        },
        [hoveredDropLineIds, hideDropLine],
    );

    const actions = useMemo(
        () => ({
            clearDropLine: () => {
                dispatch({ type: 'CLEAR_DROP_LINE' });
            },
            setDropLine: (dropLine) => {
                dispatch({ type: 'SET_DROP_LINE', dropLine });
            },
            setIsOverTarget,
        }),
        [dispatch, setIsOverTarget],
    );

    return (
        // Separate the state context and the actions context because the tutorial says so:
        // https://react.dev/learn/scaling-up-with-reducer-and-context
        <DropLineContext.Provider value={state}>
            <DropLineActionsContext.Provider value={actions}>
                {children}
            </DropLineActionsContext.Provider>
        </DropLineContext.Provider>
    );
};

export const useDropLine = () => useContext(DropLineContext);
export const useDropLineActions = () => useContext(DropLineActionsContext);
