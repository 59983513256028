import { styled } from '@mui/material';
import React, { type CSSProperties, type PropsWithChildren } from 'react';

import { type Editor } from '@@editor/helpers';
import { type ElementAttributes } from '@@editor/helpers/Element';

const Wrapper = styled('ul')<{ $isArticleEditor?: boolean }>(({ theme, $isArticleEditor }) => ({
    position: 'relative',
    marginTop: 0,
    marginBottom: 0,
    ...($isArticleEditor ? { ...theme.typography.editorPrimaryLarge } : {}),
}));

type Props = PropsWithChildren<{
    attributes?: ElementAttributes;
    editor: Editor;
    style?: CSSProperties;
}>;

const Ul = React.forwardRef<HTMLElement, Props>(({ attributes, children, editor, style }, ref) => (
    <Wrapper {...{ ref, style, ...attributes }} $isArticleEditor={editor.isArticleEditor}>
        {children}
    </Wrapper>
));

export default Ul;
