// This is the only place in this stack where we use `Operation` directly! Use this helper
// in all other places.
/* eslint-disable no-restricted-imports */
import {
    type BaseOperation,
    type BaseSetNodeOperation,
    Operation as SlateOperation,
    type Range,
} from 'slate';

type SetNodeWhitelistOperation = BaseSetNodeOperation & {
    whitelistId: string;
    whitelistRange: Range;
};

export type Operation = (BaseOperation | SetNodeWhitelistOperation) & {
    preventIdOverwrite?: boolean;
};

interface IOperation {
    isSetNodeWhitelistOperation: (operation: Operation) => operation is SetNodeWhitelistOperation;
}

export const Operation: typeof SlateOperation & IOperation = {
    ...SlateOperation,

    isSetNodeWhitelistOperation: (operation: Operation): operation is SetNodeWhitelistOperation =>
        operation.type === 'set_node' &&
        'whitelistId' in operation &&
        'whitelistRange' in operation,
};
