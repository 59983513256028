import { type Editor } from '@@editor/helpers';

const insertBreak = (
    editor: Editor,
    handlers: [handler: (editor: Editor, ...args: any[]) => boolean | undefined, ...args: any[]][],
) => {
    const { insertBreak } = editor;

    return () => {
        for (const [handler, ...args] of handlers) {
            if (handler(editor, ...args)) {
                return;
            }
        }

        insertBreak();
    };
};

export default insertBreak;
