import { Editor, Element, type Node, ReactEditor } from '@@editor/helpers';
import { PLUGIN_NAMES, type PluginName } from '@@editor/typings/UnityPlugins';

type ToollbarSetting = {
    match: (editor: Editor, node: Node) => boolean;
    embedWrapperToolbar: {
        enabled: boolean;
    };
    floatingToolbar: {
        enabled: boolean;
        allowedPlugins?: PluginName[];
    };
};

const TOOLBAR_SETTINGS: ToollbarSetting[] = [
    {
        match: (editor, node) => {
            const path = ReactEditor.findPath(editor, node);
            const [parentNode] = Editor.parent(editor, path);

            return Editor.isEditor(parentNode);
        },
        embedWrapperToolbar: {
            enabled: true,
        },
        floatingToolbar: {
            enabled: true,
        },
    },
    {
        match: (editor, node) => {
            const path = ReactEditor.findPath(editor, node);
            const [parentNode] = Editor.parent(editor, path);

            return Element.isInfoboxContentElement(parentNode);
        },
        embedWrapperToolbar: {
            enabled: true,
        },
        floatingToolbar: {
            enabled: true,
            allowedPlugins: [PLUGIN_NAMES.IMAGE, PLUGIN_NAMES.PARAGRAPH],
        },
    },
    {
        match: (editor, node) => {
            const path = ReactEditor.findPath(editor, node);
            const [parentNode] = Editor.parent(editor, path);

            return Element.isSummaryListSummaryElement(parentNode);
        },
        embedWrapperToolbar: {
            enabled: false,
        },
        floatingToolbar: {
            enabled: false,
        },
    },
];

export const getToolbarSettings = (editor: Editor, element: Element) =>
    TOOLBAR_SETTINGS.find(({ match }) => match(editor, element));
