import React from 'react';
import { type RouteObject } from 'react-router-dom';

import { TenantsParamsProvider } from '@@containers/TenantSpecific/TenantsParamsContext';

export const routes: RouteObject[] = [
    {
        path: '',
        lazy: async () => {
            const { Layout } = await import(
                /* webpackChunkName: "placement" */ '@@routes/placement'
            );

            return {
                element: (
                    <TenantsParamsProvider>
                        <Layout />
                    </TenantsParamsProvider>
                ),
            };
        },
        children: [
            {
                path: ':tenantId/category/:categoryId/sections/:sectionId',
                lazy: async () => {
                    const { CategorySectionModal } = await import(
                        /* webpackChunkName: "categorySectionModal" */ '@@routes/placement/components/SectionModal/CategorySectionModal'
                    );

                    return { Component: CategorySectionModal };
                },
            },
            {
                path: ':tenantId/tag/:tagId/sections/:sectionId',
                lazy: async () => {
                    const { TagSectionModal } = await import(
                        /* webpackChunkName: "tagSectionModal" */ '@@routes/placement/components/SectionModal/TagSectionModal'
                    );

                    return { Component: TagSectionModal };
                },
            },
        ],
    },
];
