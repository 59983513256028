import { groupBy, mapValues, noop } from 'lodash';
import React, { useContext, useMemo, useReducer } from 'react';

import { type Comment } from '@@api/services/content/schemas/comment';
import { type Editor, ReactEditor } from '@@editor/helpers';
import { not } from '@@utils/function';

import { NEW_COMMENT_ID } from './constants';
import { type CommentNodeInfo } from './types';

type State = {
    commentNodesInfo: CommentNodeInfo[];
    commentIds: Comment['id'][];
    commentNodesInfoByEditorId: Record<Editor['id'], CommentNodeInfo[]>;
    commentIdsByEditorId: Record<Editor['id'], Comment['id'][]>;
};

type Actions = {
    registerEditor: (editor: Editor) => () => void;
    updateCommentNodesInfo: (editor: Editor, commentNodesInfo: CommentNodeInfo[]) => void;
};

export type RegisterEditorAction = {
    type: 'REGISTER_EDITOR';
    editor: Editor;
};

export type UnregisterEditorAction = {
    type: 'UNREGISTER_EDITOR';
    editor: Editor;
};

export type UpdateCommentNodesInfoAction = {
    type: 'UPDATE_COMMENT_NODES_INFO';
    editor: Editor;
    commentNodesInfo: CommentNodeInfo[];
};

type Action = UpdateCommentNodesInfoAction | RegisterEditorAction | UnregisterEditorAction;

const initialState: State = {
    commentNodesInfo: [],
    commentIds: [],
    commentNodesInfoByEditorId: {},
    commentIdsByEditorId: {},
};

const CommentContext = React.createContext<State>(initialState);

const CommentActionsContext = React.createContext<Actions>({
    registerEditor: () => noop,
    updateCommentNodesInfo: noop,
});

const comparePositionInDocument = (prev, next) => {
    try {
        const prevEditorDomNode = ReactEditor.toDOMNode(prev.editor, prev.editor);
        const nextEditorDomNode = ReactEditor.toDOMNode(next.editor, next.editor);
        const comparisonResult = prevEditorDomNode.compareDocumentPosition(nextEditorDomNode);

        // eslint-disable-next-line no-bitwise
        if (comparisonResult & Node.DOCUMENT_POSITION_PRECEDING) {
            return 1;
            // eslint-disable-next-line no-bitwise
        } else if (comparisonResult & Node.DOCUMENT_POSITION_FOLLOWING) {
            return -1;
        }

        return 0;
    } catch (e) {
        return 0;
    }
};

const generateState = (nextCommentNodesInfo: CommentNodeInfo[]) => {
    const nextCommentNodesInfoWithoutNew = nextCommentNodesInfo.filter(
        ({ editor, id }) => id !== NEW_COMMENT_ID.get(editor),
    );

    const commentIds = nextCommentNodesInfoWithoutNew.map(({ id }) => id);

    const commentNodesInfoByEditorId = groupBy(
        nextCommentNodesInfoWithoutNew,
        ({ editor }) => editor.id,
    );

    const commentIdsByEditorId = mapValues(commentNodesInfoByEditorId, (commentNodesInfo) =>
        commentNodesInfo.map(({ id }) => id),
    );

    return {
        commentNodesInfo: nextCommentNodesInfo,
        commentIds,
        commentNodesInfoByEditorId,
        commentIdsByEditorId,
    };
};

const createIsSameEditor = (editor) => (commentNodeInfo) => commentNodeInfo.editor.id === editor.id;
const isNewComment = ({ editor, id }: { editor: Editor; id: Comment['id'] }) =>
    id === NEW_COMMENT_ID.get(editor);
const isNotNewComment = not(isNewComment);
const isAnything = () => true;

export const applyAction = (state: State, action: Action): State => {
    switch (action.type) {
        case 'REGISTER_EDITOR': {
            return state;
        }

        case 'UPDATE_COMMENT_NODES_INFO': {
            const { editor, commentNodesInfo } = action;
            const containsNewComment = commentNodesInfo.findIndex(isNewComment) >= 0;

            const isNotSameEditor = not(createIsSameEditor(editor));

            const nextCommentNodesInfo = state.commentNodesInfo
                .filter(containsNewComment ? isNotNewComment : isAnything)
                .filter(isNotSameEditor)
                .concat(commentNodesInfo)
                .sort(comparePositionInDocument);

            return {
                ...state,
                ...generateState(nextCommentNodesInfo),
            };
        }

        case 'UNREGISTER_EDITOR': {
            const { editor } = action;

            const nextCommentNodesInfo = state.commentNodesInfo.filter(
                ({ editor: { id } }) => editor.id !== id,
            );

            return {
                ...state,
                ...generateState(nextCommentNodesInfo),
            };
        }
    }
};

type Props = { children: React.ReactNode };

export const CommentContextProvider = ({ children }: Props) => {
    const [state, dispatch] = useReducer(applyAction, initialState);

    const actions = useMemo<Actions>(
        () => ({
            registerEditor(editor) {
                dispatch({ type: 'REGISTER_EDITOR', editor });

                return () => {
                    dispatch({ type: 'UNREGISTER_EDITOR', editor });
                };
            },
            updateCommentNodesInfo(editor, commentNodesInfo) {
                dispatch({ type: 'UPDATE_COMMENT_NODES_INFO', editor, commentNodesInfo });
            },
        }),
        [dispatch],
    );

    return (
        <CommentContext.Provider value={state}>
            <CommentActionsContext.Provider value={actions}>
                {children}
            </CommentActionsContext.Provider>
        </CommentContext.Provider>
    );
};

export const useComments = (editorId?: Editor['id']) => {
    const context = useContext(CommentContext);

    const commentNodesInfo = editorId
        ? context.commentNodesInfoByEditorId[editorId] ?? initialState.commentNodesInfo
        : context.commentNodesInfo;

    const commentIds = editorId
        ? context.commentIdsByEditorId[editorId] ?? initialState.commentIds
        : context.commentIds;

    return { commentNodesInfo, commentIds, commentIdsList: commentIds.join(',') };
};

export const useCommentActions = () => useContext(CommentActionsContext);
