import {
    createTheme,
    Drawer,
    Link,
    ListItemIcon,
    ListItemText,
    styled,
    type Theme,
    ThemeProvider,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import useAuthorization from '@@auth/hooks/useAuthorization';
import Icon from '@@components/Icon';
import Image from '@@components/Image';
import List from '@@components/List/List';
import ListItem, { DEFAULT_HEIGHT } from '@@components/List/ListItem';
import { type ListProps } from '@@components/List/types';
import config, { type NavigationItem } from '@@config';
import { SEARCH_NAMESPACES } from '@@containers/Search/types';
import useSavedFiltersSearchParams from '@@containers/Search/useSavedFiltersSearchParams';
import unityLogoBig from '@@images/unity-logo-big.png';
import { getReducedUISmartSetting } from '@@settings/settingsSlice';
import { useSelector } from '@@store/hooks';

const SHADOW_HEIGHT = 24;

const ITEMS_WITH_SPACER = ['placement', 'categories'];

const ICON_NAMES = {
    articles: config.contentTypeToIconName.articles,
    tickers: config.contentTypeToIconName.tickers,
    slideshows: config.contentTypeToIconName.slideshows,
    videos: config.contentTypeToIconName.videos,
    links: config.contentTypeToIconName.links,
    embeds: config.contentTypeToIconName.embeds,
    breakingnews: config.contentTypeToIconName.breakingnews,
    placement: 'grid-2-regular',
    editions: 'newspaper',
    curatedList: 'document-star',
    categories: 'inbox',
    sitemaps: 'sitemap',
    newsletters: 'envelope',
    collections: 'clipboard-list',
    tags: 'tags',
    kickwords: 'flag',
    teams: 'users',
    rtelements: 'puzzle-piece',
    users: 'users-gear',
    userSettings: 'gear',
};

const Logo = styled(Image)(({ theme }) => ({
    display: 'block',
    height: '28px',
    margin: theme.spacing(3, 2, 1),
    objectFit: 'contain',
    objectPosition: '0 0',
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
    paddingLeft: `calc(${theme.spacing(4)} / 2)`,
    paddingRight: `calc(${theme.spacing(4)} / 2)`,
})) as typeof ListItem;

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
    minWidth: theme.spacing(5),
})) as typeof ListItemIcon;

const StyledDrawer = styled(Drawer)(({ theme }) => ({
    width: theme.fixed.navigation.collapsed.width,

    '& .MuiDrawer-paper': {
        width: theme.fixed.navigation.collapsed.width,
        transition: theme.transitions.create('width'),
        overflow: 'hidden',

        '&:hover': {
            width: theme.fixed.navigation.expanded.width,
        },

        '&:not(:hover)': {
            boxShadow: 'none',
        },
    },
})) as typeof Drawer;

const DrawerHeader = styled('div')(({ theme }) => ({
    boxShadow: `0px 0px ${SHADOW_HEIGHT}px ${SHADOW_HEIGHT}px ${theme.palette.background.paper}`,
    zIndex: 1,
}));

const DrawerFooter = styled(DrawerHeader)({
    marginTop: 'auto',
});

const generateTheme = (theme: Theme) =>
    createTheme(theme, {
        palette: {
            text: {
                primary: theme.palette.common.white,
            },
            background: {
                paper: theme.palette.primary['600'],
            },
            action: {
                active: theme.palette.common.white,
                focus: theme.palette.primary['700'],
                focusOpacity: 0.25,
                hover: theme.palette.primary['700'],
                hoverOpacity: 0.25,
                selectedOpacity: 0.75,
            },
            primary: {
                main: theme.palette.primary['800'],
            },
        },
    });

type ListData = NavigationItem | null;

type Props = {
    navigation?: NavigationItem[];
};

/*
 * This comment is for translation auto extract
 * (all possible navigation items)
 t('navigation.breakingnews')
 t('navigation.categories')
 t('navigation.embeds')
 t('navigation.kickwords')
 t('navigation.links')
 t('navigation.placement')
 t('navigation.sitemaps')
 t('navigation.collections')
 t('navigation.slideshows')
 t('navigation.teams')
 t('navigation.tags')
 t('navigation.tickers')
 t('navigation.videos')
 t('navigation.users')
 t('navigation.editions')
 t('navigation.rtelements')
 t('navigation.userSettings')
 */
const Navigation = ({ navigation = Object.values(config.navigation) }: Props) => {
    const { isAuthorized } = useAuthorization();
    const { t } = useTranslation();

    const reducedUI = useSelector(getReducedUISmartSetting);

    const searchFilters = useSavedFiltersSearchParams(SEARCH_NAMESPACES.DEFAULT);
    const searchCuratedList = useSavedFiltersSearchParams(SEARCH_NAMESPACES.CURATED_LIST);

    const items = [
        null,
        ...navigation.reduce<Array<ListData>>((result, item) => {
            if (
                item.excludeFromNavigationMenu ||
                (reducedUI && item.excludeFromReducedUI) ||
                !isAuthorized(item.authorize)
            ) {
                return result;
            }

            if (ITEMS_WITH_SPACER.indexOf(item.key) !== -1) {
                result.push(null);
            }

            return result.concat(item);
        }, []),
        null,
    ];

    const renderHeader = () => (
        // `display: flex` is needed to avoid collapsing `margin-top` when scrolling
        <Link href="/" title={t('page.title.home')} sx={{ display: 'flex' }}>
            <Logo src={unityLogoBig} alt="Unity Logo" />
        </Link>
    );

    const renderItem: ListProps<ListData>['renderItem'] = (props) => {
        const { item } = props;

        if (!item) {
            return <StyledListItem {...props} divider={false} />;
        }

        const title = t(`navigation.${item.key}`);
        const search = item.uri.includes('curatedList') ? searchCuratedList : searchFilters;

        return (
            <StyledListItem
                {...props}
                divider={false}
                href={`${item.uri}?${search}`}
                title={title}
            />
        );
    };

    const renderItemContent = (index: number, item: ListData) =>
        item && (
            <>
                <StyledListItemIcon>
                    <Icon name={ICON_NAMES[item.key]} size="large" />
                </StyledListItemIcon>

                <ListItemText
                    primary={t(`navigation.${item.key}`)}
                    primaryTypographyProps={{ noWrap: true, variant: 'subtitle3' }}
                />
            </>
        );

    const renderFooter = () => {
        const index = -1;
        const item: ListData = { key: 'userSettings', uri: 'userSettings' };

        return (
            <StyledListItem
                divider={false}
                href={item.uri}
                index={index}
                item={item}
                style={{ height: DEFAULT_HEIGHT }}
                title={t(`navigation.${item.key}`)}
            >
                {renderItemContent(index, item)}
            </StyledListItem>
        );
    };

    return (
        <ThemeProvider theme={generateTheme}>
            <StyledDrawer variant="permanent">
                <DrawerHeader>{renderHeader()}</DrawerHeader>

                <List
                    data={items}
                    noBorder
                    noOverscroll
                    noScrollbar
                    renderItem={renderItem}
                    renderItemContent={renderItemContent}
                />

                <DrawerFooter>{renderFooter()}</DrawerFooter>
            </StyledDrawer>
        </ThemeProvider>
    );
};

export default Navigation;
