import { initContract } from '@ts-rest/core';
import { initTsrReactQuery } from '@ts-rest/react-query/v5';
import { useStore } from 'react-redux';
import { z } from 'zod';

import { commonResponses } from '@@api/commonResponses';
import { clientDefaults } from '@@api/constants/clientDefaults';
import { DefaultHeaders, PatchDefaultHeaders } from '@@api/headers';
import { UserPresence, UserPresenceUser } from '@@api/services/userPresence/schemas';
import { getQueryKeys } from '@@api/utils/queryKeys';
import config from '@@config';
import { type RootState } from '@@scripts/store/store';

const contract = initContract();

const router = contract.router(
    {
        userPresence: contract.router({
            getAll: {
                method: 'GET',
                path: '/user-presences/:id',
                pathParams: z.object({
                    id: z.string(),
                }),
                responses: {
                    200: UserPresence,
                },
            },
            patchAll: {
                method: 'PATCH',
                path: '/user-presences/:id',
                pathParams: z.object({
                    id: z.string(),
                }),
                body: z.object({
                    users: z.record(UserPresenceUser.pick({ attributes: true }).nullable()),
                }),
                responses: {
                    200: UserPresence,
                },
                headers: PatchDefaultHeaders,
            },
        }),
    },
    {
        baseHeaders: DefaultHeaders,
        commonResponses,
    },
);

const userPresenceClient = (state: RootState) => {
    const clientArgs = {
        baseUrl: config.apiEnvVars.userPresencesUrl,
        ...clientDefaults(state),
    };

    const client = initTsrReactQuery(router, clientArgs);
    const queryKeys = getQueryKeys(router, clientArgs);

    return { client, queryKeys };
};

export const useUserPresenceClient = () => {
    const store = useStore<RootState>();
    const state = store.getState();

    return userPresenceClient(state);
};
