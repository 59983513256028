import React from 'react';
import { type FieldPath, type FieldValues } from 'react-hook-form';

import TextInput, { type TextInputProps } from '@@form/components/TextInput';
import useFormField, { type UseFormFieldProps } from '@@form/hooks/useFormField';

export type TextInputFieldProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<TextInputProps, 'onChange'> & UseFormFieldProps<TFieldValues, TName>;

const TextInputField = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
    props: TextInputFieldProps<TFieldValues, TName>,
) => {
    const {
        name,
        control,
        validate,
        novalidate,
        required,
        requiredCustom,
        validateCacheKey,
        onChange,
        onBlur,
        transform,
        defaultValue,
        ...rest
    } = props;

    const {
        field: { ref, value, ...formFieldProps },
    } = useFormField<TFieldValues, TName>({
        name,
        control,
        novalidate,
        validate,
        required,
        requiredCustom,
        validateCacheKey,
        onChange,
        onBlur,
        transform,
        defaultValue,
    });

    const controlledValue = value || '';

    return (
        <TextInput
            inputRef={ref}
            {...formFieldProps}
            value={controlledValue}
            required={required}
            fullWidth
            margin={control ? 'dense' : 'none'}
            {...rest}
        />
    );
};

export default TextInputField;
