import { styled } from '@mui/material';
import React from 'react';
import { useReadOnly } from 'slate-react';

import { Editor } from '@@editor/helpers';
import { Element, ELEMENT_TYPES } from '@@editor/helpers/Element';
import { PLUGIN_ICON_NAMES, PLUGIN_NAMES } from '@@editor/typings/UnityPlugins';

import { PluginsWrapper } from '../../components/PluginsWrapper';
import Quote from '../quote/Quote';
import EmbedWrapper from './../../embed/components/EmbedWrapper';

export const TYPE = PLUGIN_NAMES.QUOTE;
export const NODE_TYPE = ELEMENT_TYPES.QUOTE;
export const INLINE_EDITABLE_CHILDREN_TYPES = [
    ELEMENT_TYPES.QUOTE_TEXT,
    ELEMENT_TYPES.QUOTE_CAPTION,
];

export interface TemplateProps {
    editor: Editor;
    element: Element;
}

const QuoteWrapper = styled(PluginsWrapper)<TemplateProps>(({ theme, editor, element }) => ({
    backgroundColor: Element.isEmptyQuoteElement(element, {
        isInlineEdited: !Editor.isVoid(editor, element),
    })
        ? theme.palette.gold.light
        : 'unset',
}));

const EmptyComponent = (props) => {
    const isReadOnly = useReadOnly();

    return (
        <QuoteWrapper
            {...{
                ...props,
                contentEditable: props.contentEditable && !isReadOnly,
            }}
        >
            {props.children}
        </QuoteWrapper>
    );
};
const QuoteNode = (props) => {
    const { editor, element } = props;
    const isInlineEdited = !Editor.isVoid(editor, element);

    const component = isInlineEdited ? EmptyComponent : Quote;

    return (
        <EmbedWrapper
            {...props}
            type={TYPE}
            component={component}
            placeholderComponent={component}
            contentEditable={isInlineEdited}
            toolbarConfig={{
                infos: {
                    iconName: PLUGIN_ICON_NAMES[TYPE],
                    title: 'Quote',
                },
                actions: ['delete'],
                // t('editor.plugin.quote.tooltip')
                tooltips: [{ title: 'editor.plugin.quote.tooltip' }],
            }}
        />
    );
};

export default QuoteNode;
