import { type Tenant } from '@@api/services/tenant/schemas';
import type ContentStatus from '@@constants/ContentStatus';
import type PublicationStatus from '@@constants/PublicationStatus';
import { type ContentLanguage } from '@@lib/i18n/constants';

import { type UnitySearchProps } from './UnitySearch';

export type SearchValues = {
    q?: string;
    leagueIds?: string[];
    tenantIds?: string[] | number[] | null;
    categoryIds?: string[];
    tagIds?: string[];
    userIds?: string | null;
    publicationStatus?: PublicationStatus[];
    contentStatus?: ContentStatus[];
    contentType?: string | null;
    type?: string | null;
    contentLocale: ContentLanguage;
    publishedBefore?: string;
    publishedAfter?: string;
};

export type SearchFieldOption<T extends ValueOf<SearchValues>> = {
    value: T;
    mapToUrl: boolean;
    persist?: boolean;
    debounceSearch?: boolean;
};

export type SearchFields = {
    q: SearchFieldOption<SearchValues['q']>;
    leagueIds?: SearchFieldOption<SearchValues['leagueIds']>;
    tenantIds?: SearchFieldOption<SearchValues['tenantIds']>;
    categoryIds?: SearchFieldOption<SearchValues['categoryIds']>;
    tagIds?: SearchFieldOption<SearchValues['tagIds']>;
    userIds?: SearchFieldOption<SearchValues['userIds']>;
    publicationStatus?: SearchFieldOption<SearchValues['publicationStatus']>;
    contentStatus?: SearchFieldOption<SearchValues['contentStatus']>;
    contentType?: SearchFieldOption<SearchValues['contentType']>;
    type?: SearchFieldOption<SearchValues['type']>;
    contentLocale?: SearchFieldOption<SearchValues['contentLocale']>;
    publishedBefore?: SearchFieldOption<SearchValues['publishedBefore']>;
    publishedAfter?: SearchFieldOption<SearchValues['publishedAfter']>;
};

export type SearchFieldName = keyof SearchFields;

export type Search = {
    values: SearchValues;
    debouncedValues: SearchValues;
    fields: {
        q: {
            value: SearchValues['q'];
            onChange: VoidFunction;
        };
        leagueIds?: {
            value: Required<SearchValues['leagueIds']>;
            onChange: VoidFunction;
        };
        tenantIds?: {
            value: Required<SearchValues['tenantIds']>;
            onChange: VoidFunction;
        };
        categoryIds?: {
            value: Required<SearchValues['categoryIds']>;
            onChange: VoidFunction;
        };
        tagIds?: {
            value: Required<SearchValues['tagIds']>;
            onChange: VoidFunction;
        };
        userIds?: {
            value: Required<SearchValues['userIds']>;
            onChange: VoidFunction;
        };
        publicationStatus?: {
            value: Required<SearchValues['publicationStatus']>;
            onChange: VoidFunction;
        };
        contentStatus?: {
            value: Required<SearchValues['contentStatus']>;
            onChange: VoidFunction;
        };
        contentType?: {
            value: Required<SearchValues['contentType']>;
            onChange: VoidFunction;
        };
        type?: {
            value: Required<SearchValues['type']>;
            onChange: VoidFunction;
        };
        contentLocale?: {
            value: Required<SearchValues['contentLocale']>;
            onChange: VoidFunction;
        };
        publishedBefore: {
            value: Required<SearchValues['publishedBefore']>;
            onChange: VoidFunction;
        };
        publishedAfter: {
            value: Required<SearchValues['publishedAfter']>;
            onChange: VoidFunction;
        };
    };
};

export type UnitySearch = Search & {
    pageNumbers: number[];
    getPageHref?: (page: number) => string;
    handlePageChange?: (e: any, page: number) => void;
    unitySearchProps: UnitySearchProps;
};

export type getSearchFieldsProps = UseUnitySearchOptions & {
    urlParams: URLSearchParams;
    contentLocale: ContentLanguage;
};

export type UseUnitySearchOptions = {
    fields: { [K in keyof SearchFields]: boolean };
    focusOnMount?: boolean;
    leagueIdsSportType?: string;
    values?: {
        tenantIds?: Tenant['id'][];
    };
    minWidth?: string;
    moreFilters?: boolean;
    disableUrlSync?: boolean;
    fieldsProps?: {
        [K in keyof SearchFields]: {
            multiple?: boolean;
            disabled?: boolean;
        };
    };
    getSearchFields?: (props: getSearchFieldsProps) => SearchFields;
    searchNamespace?: SearchNamespace;
};

export const SEARCH_NAMESPACES = {
    DEFAULT: 'default',
    CURATED_LIST: 'curatedList',
} as const;

export type SearchNamespace = ValueOf<typeof SEARCH_NAMESPACES>;
