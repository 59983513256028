import { z } from 'zod';

import { CompareOperationEnum } from '@@containers/Compare/commons/types';
import {
    type LeadElement,
    type TitleElement,
    type TitleHeaderElement,
} from '@@editor/helpers/Element';

import {
    ContentOwner,
    ContentStatus,
    Creator,
    Entity,
    PublicationStatus,
    RichText,
    RichTextElement,
    UnityRichText,
    UnityRichTextElement,
} from '../../../utils/schemas/schemas';
import { variantsShape } from '../../../utils/schemas/utils';
import { Metadata, UnityEmbeddedMetadata } from '../../metadata/schemas';

const ExternalSystem = z.enum(['cs', 'nn', 'tw', 'bw', 'bl', 'ls', 'dn', 'fw', 'ti', 'twa']);

const ArticleBase = Entity.extend({
    id: z.string().uuid(),
    metadataId: z.number(),
    externalId: z.string().nullish(),
    externalSystem: ExternalSystem.nullish(),
    contentOwner: ContentOwner.nullish(),
    contentName: z.string().nullish(),
    contentStationDeletedDate: z.string().optional(),
    auroraId: z.string().optional(),
    actualTextLength: z.number().nullish(),
});

export const UnityArticle = ArticleBase.extend({
    content: UnityRichText,
    metadata: UnityEmbeddedMetadata.optional(),
});

export type UnityArticle = z.infer<typeof UnityArticle>;

const ArticleVariants = variantsShape({
    headings: z.tuple([
        z.custom<TitleHeaderElement>(),
        z.custom<TitleElement>(),
        z.custom<LeadElement>(),
    ]),
});

export const Article = ArticleBase.extend({
    content: RichText,
    variants: ArticleVariants,
    metadata: Metadata.optional(),
});

export type Article = z.infer<typeof Article>;

const RevisionDataBase = Entity.extend({
    id: z.string(),
    contentName: z.string(),
    contentOwner: ContentOwner.nullish(),
    externalId: z.string().nullable(),
    externalSystem: ExternalSystem.nullable(),
    metadataId: z.number(),
    sourceArticleId: z.string().nullable(),
});

const UnityRevisionData = RevisionDataBase.extend({
    content: UnityRichText,
});

const RevisionData = RevisionDataBase.extend({
    content: RichText,
    variants: ArticleVariants,
});

export type RevisionData = z.infer<typeof RevisionData>;

export const Revision = Entity.extend({
    creator: Creator,
    date: z.string(),
    id: z.string(),
    resourceId: z.string(),
    resourceName: z.string(),
    revisionNumber: z.number(),
    contentStatus: ContentStatus,
    publicationStatus: PublicationStatus,
});

export type Revision = z.infer<typeof Revision>;

export const DetailedUnityRevision = Revision.extend({
    revisionData: UnityRevisionData,
});

export type DetailedUnityRevision = z.infer<typeof DetailedUnityRevision>;

const DetailedRevision = Revision.extend({
    revisionData: RevisionData,
});

export type DetailedRevision = z.infer<typeof DetailedRevision>;

const TemplateElement = z.object({
    type: z.string(),
    attributes: z.record(z.string()).optional(),
});

export type TemplateElement = z.infer<typeof TemplateElement>;

export const ArticleTemplate = z.object({
    name: z.string(),
    order: z.number(),
    elementTypes: z.array(TemplateElement),
});

export type ArticleTemplate = z.infer<typeof ArticleTemplate>;

export const isArticle = (entity): entity is Article => 'content' in entity;

const AddOperationBase = z.object({
    operation: z.literal(CompareOperationEnum.ADD),
});
const UnityAddOperation = AddOperationBase.extend({
    element: UnityRichTextElement,
});

const AddOperation = AddOperationBase.extend({
    element: RichTextElement,
});

const IdentityOperation = z.object({
    operation: z.literal(CompareOperationEnum.IDENTITY),
});

export type IdentityOperation = z.infer<typeof IdentityOperation>;

const UnityUpdateOperation = z.object({
    operation: z.literal(CompareOperationEnum.UPDATE),
    element: UnityRichTextElement,
});
const UpdateOperation = z.object({
    operation: z.literal(CompareOperationEnum.UPDATE),
    element: RichTextElement,
});

const RemoveOperation = z.object({
    operation: z.literal(CompareOperationEnum.REMOVE),
});

export type RemoveOperation = z.infer<typeof RemoveOperation>;

const MoveStart = z.object({
    operation: z.literal(CompareOperationEnum.MOVE_START),
    to: z.number(),
});

export type MoveStart = z.infer<typeof MoveStart>;

const BaseMoveEnd = z.object({
    operation: z.literal(CompareOperationEnum.MOVE_END),
    from: z.number(),
});
const UnityUpdatedMoveEnd = BaseMoveEnd.extend({
    updated: z.literal(true),
    element: UnityRichTextElement,
});
const UpdatedMoveEnd = BaseMoveEnd.extend({
    updated: z.literal(true),
    element: RichTextElement,
});
const UnityMoveEnd = z.union([BaseMoveEnd, UnityUpdatedMoveEnd]);
const MoveEnd = z.union([BaseMoveEnd, UpdatedMoveEnd]);

export type MoveEnd = z.infer<typeof MoveEnd>;
const UnityMoveOperation = z.union([MoveStart, UnityMoveEnd]);
const MoveOperation = z.union([MoveStart, MoveEnd]);
const UnityActionOperation = z.union([UnityAddOperation, UnityUpdateOperation]);
const ActionOperation = z.union([AddOperation, UpdateOperation]);

export type ActionOperation = z.infer<typeof ActionOperation>;

export const UnityCompareOperation = z.union([
    IdentityOperation,
    RemoveOperation,
    UnityActionOperation,
    UnityMoveOperation,
]);

export const CompareOperation = z.union([
    IdentityOperation,
    RemoveOperation,
    ActionOperation,
    MoveOperation,
]);

const DerivativeArticleBase = z.object({
    revisionSource: z.string(),
    revision: z.string().nullable(),
});

const OriginArticleBase = z.object({
    revisionSource: z.string(),
    revision: z.string().nullable(),
});

export const UnityCompare = z.object({
    derivativeArticle: DerivativeArticleBase.extend({
        contentModifications: z.array(UnityCompareOperation),
    }),
    originArticle: OriginArticleBase.extend({
        content: z.object({
            richTextFormat: z.array(z.union([UnityRichTextElement, z.null()])),
        }),
    }),
});
export type UnityCompare = z.infer<typeof UnityCompare>;

export const Compare = z.object({
    derivativeArticle: DerivativeArticleBase.extend({
        contentModifications: z.array(CompareOperation),
    }),
    originArticle: OriginArticleBase.extend({
        content: z.array(z.union([RichTextElement, z.null()])),
    }),
});
export type Compare = z.infer<typeof Compare>;
