import { useEffect } from 'react';
import {
    type FieldArrayPath,
    type FieldValues,
    // This is the only place in this stack where we use `useFieldArray` directly! Use this abstraction
    // in all other places!
    // eslint-disable-next-line no-restricted-imports
    useFieldArray as originalUseFieldArray,
    type UseFieldArrayProps,
    type UseFieldArrayReturn,
} from 'react-hook-form';

import { useReactHookFormMetaContext } from './ReactHookFormMetaContext';

const useFieldArray = <
    TFieldValues extends FieldValues = FieldValues,
    TFieldArrayName extends FieldArrayPath<TFieldValues> = FieldArrayPath<TFieldValues>,
    TKeyName extends string = 'id',
>(
    props: UseFieldArrayProps<TFieldValues, TFieldArrayName, TKeyName>,
): UseFieldArrayReturn<TFieldValues, TFieldArrayName, TKeyName> => {
    const { name } = props;
    const { registerFieldArray, unregisterFieldArray } = useReactHookFormMetaContext();

    useEffect(() => {
        registerFieldArray(name);

        return () => {
            unregisterFieldArray(name);
        };
    }, []);

    return originalUseFieldArray<TFieldValues, TFieldArrayName, TKeyName>(props);
};

export default useFieldArray;
