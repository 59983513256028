import { get } from 'lodash';

import { type Node } from '@@editor/helpers';

import { CROSSHEAD_STYLES, type CrossheadStyle } from './constants';

export const getCrossheadStyle = (node: Node): CrossheadStyle =>
    get(node, 'data.style.crossheadType', '_crosshead_default');

export const generateCrossheadData = (crossheadStyle: CrossheadStyle) => ({
    style: crossheadStyle === CROSSHEAD_STYLES.SECTION ? {} : { crossheadType: crossheadStyle },
});
