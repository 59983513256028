import { max, min } from 'lodash';

/**
 * Ensures that a value lies between two numbers.
 *
 * @param value - A number that should been guaranteed to not exceed the minimum and maximum
 * @param minimum - The minimum the value must not exceed and will fall back to otherwise
 * @param maximum - The maximum the value must not exceed and will fall back to otherwise
 */
export const between = (value: number, minimum: number, maximum: number): number =>
    min([max([value, minimum]), maximum]) as number;

export const isBetween = (value: number, minimum: number, maximum: number): boolean =>
    value >= minimum && value <= maximum;

type unitValue = number | undefined | null;
type unitReturnValue = string | undefined | null;

export const toUnit = (n: unitValue, unit: string): unitReturnValue =>
    // eslint-disable-next-line no-nested-ternary
    n === null || n === undefined ? n : n === 0 ? '0' : `${n}${unit}`;

export const toPx = (n: unitValue): unitReturnValue => toUnit(n, 'px');
