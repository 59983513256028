import { type RouteObject } from 'react-router-dom';

export const routes: RouteObject[] = [
    {
        index: true,
        lazy: async () => {
            const { ArticleList } = await import(
                /* webpackChunkName: "articleList" */ '@@routes/articles/ArticleList'
            );

            return { Component: ArticleList };
        },
    },
    {
        path: 'new',
        lazy: async () => {
            const { ArticleSetupPage } = await import(
                /* webpackChunkName: "articleSetupPage" */ '@@routes/articles'
            );

            return { Component: ArticleSetupPage };
        },
    },
    {
        path: ':id/*',
        lazy: async () => {
            const { ArticleDetailPage } = await import(
                /* webpackChunkName: "articleDetailPage" */ '@@routes/articles'
            );

            return { Component: ArticleDetailPage };
        },
    },
];
