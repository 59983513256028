import { styled } from '@mui/material';
import { omit, pick } from 'lodash';
import React, { type ForwardedRef, useEffect, useState } from 'react';

import placeholderImage from '@@images/placeholder-image.jpeg';

export type Props = {
    src?: string | null;
    alt?: string;
    className?: string;
    forwardedRef?: ForwardedRef<HTMLImageElement>;
    placeholder?: string;
    title?: string;
    onClick?: VoidFunction;
    onError?: VoidFunction;
    onLoad?: React.ReactEventHandler<HTMLImageElement>;
};

const Image = ({
    forwardedRef,
    src,
    placeholder = placeholderImage,
    onError = () => {},
    ...restProps
}: Props) => {
    const [error, setError] = useState<boolean>(false);
    const imgProps = pick(restProps, ['alt', 'src', 'onClick', 'onLoad', 'title', 'className']);

    useEffect(() => {
        setError(false);
    }, [src]);

    const handleError = () => {
        onError();
        setError(true);
    };

    // Handle missing src or failed loading of a valid/invalid src
    if (error || !src) {
        // `onLoad` must not be fired on the placeholder image (since it is a placeholder).
        // In addition, `PreviewImage` needs this, in order to not overwrite the objectPosition value which is set here.
        const placeholderImgProps = omit(imgProps, ['onLoad']);

        return (
            <img
                {...placeholderImgProps}
                ref={forwardedRef}
                src={placeholder}
                style={{
                    objectFit: 'cover',
                    objectPosition: 'initial',
                }}
                // This is needed for letting `PreviewImage` know that `Image` currently renders a placeholder image
                data-placeholder
            />
        );
    }

    return <img {...imgProps} ref={forwardedRef} src={src} onError={handleError} />;
};

const ForwardRef = React.forwardRef(
    (
        props: JSX.LibraryManagedAttributes<typeof Image, Props>,
        ref: React.Ref<HTMLImageElement>,
    ) => <Image {...props} forwardedRef={ref} />,
);

export default styled(ForwardRef)({});
