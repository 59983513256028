import { MIME_TYPES } from '@@editor/helpers/Element';
import { type GenerateEmbedBlockData } from '@@editor/typings/Embed';
import { isFacebookUrl } from '@@utils/socialMedia/facebook';
import { getUrlParameterByName } from '@@utils/URL';

const getHrefParam = (url) => {
    const hrefParam = getUrlParameterByName('href', url) || '';

    if (!isFacebookUrl(hrefParam)) {
        return '';
    }

    const hrefParamAsURL = new URL(hrefParam);

    return hrefParamAsURL.href;
};

export const generateEmbedBlockData: GenerateEmbedBlockData = ({ src, originalSrc }) => {
    const url = src as URL;

    if (!isFacebookUrl(url)) {
        return;
    }

    const hrefParam = getHrefParam(url);

    // Pasting embed code for video
    // https://www.facebook.com/plugins/video.php...
    // ...?href=https%3A%2F%2Fwww.facebook.com%2F345714815478728%2Fvideos%2F1477920875591444%2F
    if (url.pathname === '/plugins/video.php') {
        const showText = url.searchParams ? url.searchParams.get('show_text') : true;
        // allowFullScreen is not in the url, but a property on the iframe?
        const allowFullScreen = url.searchParams ? url.searchParams.get('allowFullScreen') : true;

        return {
            mimetype: MIME_TYPES.FACEBOOK_VIDEO,
            originalSrc,
            src: hrefParam,
            options: {
                showText,
                allowFullScreen,
            },
        };
    }

    // https://www.facebook.com/plugins/....
    // ....post.php?href=https://www.facebook.com/colheitafresca/posts/1755718067788656:0&width=500
    if (url.pathname === '/plugins/post.php') {
        return {
            mimetype: MIME_TYPES.FACEBOOK_POST,
            originalSrc,
            src: hrefParam,
        };
    }

    // https://www.facebook.com/plugins/comment_embed.php?href=https%3A%2F%2Fwww.facebook.com%2F
    // ...colheitafresca%2Fphotos%2Fa.1753719117988551.1073741828.1688435437850253%2F17557180677
    // ...88656%2F%3Ftype%3D3%26comment_id%3D1755732884453841&include_parent=false
    if (url.pathname === '/plugins/comment_embed.php') {
        return {
            mimetype: MIME_TYPES.FACEBOOK_COMMENT,
            originalSrc,
            src: hrefParam,
        };
    }

    // Dragging/Pasting a Subject/Product/Organisation page EMBED CODE from Facebook
    if (url.pathname === '/plugins/page.php') {
        return {
            mimetype: MIME_TYPES.FACEBOOK_PAGE,
            originalSrc,
            src: hrefParam,
            options: {
                smallHeader: true,
                showFacepile: false,
            },
        };
    }

    if (!hrefParam) {
        return {
            mimetype: MIME_TYPES.FACEBOOK_POST,
            src: url.href,
        };
    }

    /*
     * Uncomment the following lines to support drop/paste of browser urls to be embedded.
     */

    // // Dragging/Pasting an Image URL from facebook
    // if (url.pathname.indexOf('/photos/') === 0) {
    //     return {
    //         mimetype: 'x-facebook/post',
    //         src: url.href,
    //     };
    // }

    // // Dragging/Pasting a Video URL from Facebook
    // if (url.pathname.indexOf('/facebook/videos/') === 0) {
    //     return {
    //         mimetype: 'x-facebook/video',
    //         src: url.href,
    //     };
    // }

    // // Dragging/Pasting a Comment URL from facebook
    // if (/^\/[^/]+\/posts\/[0-9]+\?comment_id=/.test(url.pathname)) {
    //     return {
    //         mimetype: 'x-facebook/comment',
    //         src: url.href,
    //     };
    // }

    // // Dragging/Pasting a Post URL from Facebook
    // if (/^\/[^/]+\/posts\//.test(url.pathname)) {
    //     return {
    //         mimetype: 'x-facebook/post',
    //         src: url.href,
    //     };
    // }

    // // Dragging/Pasting a Subject/Product/Organisation page URL from Facebook
    // if (/^\/[^/]+\/$/.test(url.pathname)) {
    //     return {
    //         mimetype: 'x-facebook/page',
    //         src: url.href,
    //         payload: {
    //             smallHeader: true,
    //             showFacepile: false,
    //         },
    //     };
    // }
};
