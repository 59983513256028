import { Button, type ButtonProps } from '@mui/material';
import React, { createContext, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import UploadButtonComponent from '@@components/buttonImplementations/UploadButton';
import { type ImageMimeType } from '@@constants/ImageMimeTypes';

type Props = {
    children: React.ReactNode;
    handleImageUpload: (e, files: File[]) => Promise<void>;
    allowedMimeTypes: ImageMimeType[];
    handleImageDelete: VoidFunction;
};

const ButtonsContext = createContext<Props | null>(null);

export const ButtonsProvider = (props: Props) => (
    <ButtonsContext.Provider value={props}>{props.children}</ButtonsContext.Provider>
);

export const useButtonsContext = () => useContext(ButtonsContext);

const UploadButton = (props: ButtonProps = {}) => {
    const { t } = useTranslation();
    const context = useButtonsContext();

    if (!context) {
        throw Error('UploadButton needs to be in the ButtonsContext');
    }

    const { handleImageUpload, allowedMimeTypes } = context;

    return (
        <UploadButtonComponent
            accept={allowedMimeTypes.join(',')}
            onUpload={handleImageUpload}
            {...props}
        >
            {t('metaform.teaser.modal.uploadimage')}
        </UploadButtonComponent>
    );
};

const DeleteButton = (props: ButtonProps = {}) => {
    const { t } = useTranslation();
    const context = useButtonsContext();

    if (!context) {
        throw Error('DeleteButton needs to be in the ButtonsContext');
    }

    const { handleImageDelete } = context;

    return (
        <Button variant="outlined" onClick={handleImageDelete} {...props}>
            {t('metaform.teaser.modal.deleteimage')}
        </Button>
    );
};

export type ButtonsType = {
    DeleteButton: typeof DeleteButton;
    UploadButton: typeof DeleteButton;
};

const Buttons = {
    UploadButton,
    DeleteButton,
};

export default Buttons;
