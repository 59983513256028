import { type Editor } from '@@editor/helpers';

const deleteBackward = (
    editor: Editor,
    handlers: [
        handler: (editor: Editor, unit: string, ...args: any[]) => boolean | undefined,
        ...args: any[],
    ][],
) => {
    const { deleteBackward } = editor;

    return (unit) => {
        for (const [handler, ...args] of handlers) {
            if (handler(editor, unit, ...args)) {
                return;
            }
        }

        deleteBackward(unit);
    };
};

export default deleteBackward;
