import { initContract } from '@ts-rest/core';
import { initTsrReactQuery } from '@ts-rest/react-query/v5';
import { useStore } from 'react-redux';
import { z } from 'zod';

import { commonResponses } from '@@api/commonResponses';
import { clientDefaults } from '@@api/constants/clientDefaults';
import { DefaultHeaders, PatchDefaultHeaders } from '@@api/headers';
import {
    ArticleSummary,
    ImageAltText,
    KeywordSuggestions,
    ResultChoice,
    TeaserGenerator,
    TickerSummary,
} from '@@api/services/aiTools/schemas';
import { UnityArticle } from '@@api/services/content/schemas/article';
import { TickerCommon } from '@@api/services/liveticker/schemas/ticker';
import { getQueryKeys } from '@@api/utils/queryKeys';
import config from '@@config';
import { type RootState } from '@@scripts/store/store';

const contract = initContract();

const router = contract.router(
    {
        resultChoice: contract.router({
            patch: {
                method: 'PATCH',
                path: '/result-choice',
                query: z.object({
                    id: ResultChoice.shape.id,
                }),
                body: ResultChoice.pick({
                    feedbackEvaluation: true,
                    used: true,
                }).partial(),
                responses: {
                    200: ResultChoice,
                },
                headers: PatchDefaultHeaders,
            },
        }),
        keywordSuggestions: contract.router({
            post: {
                method: 'POST',
                path: '/keyword-suggestions/articles/:id',
                pathParams: z.object({
                    id: UnityArticle.shape.id,
                }),
                body: z.object({
                    articleBody: z.string(),
                    numOfVariations: z.number(),
                }),
                responses: {
                    201: KeywordSuggestions,
                },
            },
            get: {
                method: 'GET',
                path: '/keyword-suggestions/articles/:id',
                pathParams: z.object({
                    id: UnityArticle.shape.id,
                }),
                responses: {
                    200: KeywordSuggestions,
                },
            },
        }),
        teaserGenerator: contract.router({
            post: {
                method: 'POST',
                path: '/teaser-generator',
                body: z.object({
                    articleBody: z.string(),
                    numOfVariations: z.number(),
                }),
                responses: {
                    201: z.array(TeaserGenerator),
                },
            },
        }),
        imageDescriptionGenerator: contract.router({
            post: {
                method: 'POST',
                path: '/image-alt-text-generator',
                body: z.object({
                    imageUrl: z.string(),
                }),
                responses: {
                    200: ImageAltText,
                },
            },
        }),
        articleSummaryGeneration: contract.router({
            post: {
                method: 'POST',
                path: '/summary-generation/articles/:id',
                pathParams: z.object({
                    id: UnityArticle.shape.id,
                }),
                body: z.object({
                    articleBody: z.string(),
                }),
                responses: {
                    201: ArticleSummary,
                },
            },
            get: {
                method: 'GET',
                path: '/summary-generation/articles/:id',
                pathParams: z.object({
                    id: UnityArticle.shape.id,
                }),
                responses: {
                    200: ArticleSummary,
                },
            },
        }),
        tickerSummaryGeneration: contract.router({
            post: {
                method: 'POST',
                path: '/summary-generation/tickers/:id',
                pathParams: z.object({
                    id: TickerCommon.shape.id,
                }),
                body: z.undefined(),
                responses: {
                    201: TickerSummary,
                },
            },
        }),
    },
    {
        baseHeaders: DefaultHeaders,
        commonResponses,
    },
);

export type AiToolsRouter = typeof router;

const aiToolsClient = (state: RootState) => {
    const clientArgs = {
        baseUrl: config.apiEnvVars.aiToolsUrl,
        ...clientDefaults(state),
    };

    const client = initTsrReactQuery(router, clientArgs);
    const queryKeys = getQueryKeys(router, clientArgs);

    return { client, queryKeys };
};

export const useAiToolsClient = () => {
    const store = useStore<RootState>();
    const state = store.getState();

    return aiToolsClient(state);
};
