import { castArray, first, flow, map, partialRight, pick } from 'lodash';

import { DEFAULT_BLOCK } from '@@editor/constants';
import { Element, ELEMENT_TYPES, type InfoboxElement } from '@@editor/helpers/Element';
import transformValues from '@@editor/serialization/transformValues';
import { type UnityInfobox } from '@@editor/typings/UnityElements';
import addDefaultElementIfEmptyState from '@@editor/utils/addDefaultElementIfEmptyState';
import makeDefaultTextIfNull from '@@editor/utils/makeDefaultUnityTextIfNull';

import { CURRENT_VERSION } from '../../constants';
import { type SerializerOptions } from '../../types';
import { createElement } from './../../deserializeNodes';

const serializeInlineInfoboxData = (children: Element[], data: InfoboxElement['data']) => {
    const newData = { ...data };
    const title = children.find(Element.isInfoboxTitleElement);
    const content = children.find(Element.isInfoboxContentElement);

    if (title) {
        newData.title = [{ ...DEFAULT_BLOCK, children: title.children }];
    }

    if (content) {
        newData.content = content.children;
    }

    return newData;
};

const infoboxRules = {
    deserialize: (element: UnityInfobox, next, options?): InfoboxElement => {
        const values = pick(element, ['title', 'content', 'style', 'collapsed', 'templateElement']);

        const embedNode = createElement<InfoboxElement>(
            ELEMENT_TYPES.INFOBOX,
            transformValues<typeof values, InfoboxElement['data']>(values, [
                ['title', flow(makeDefaultTextIfNull, next, castArray)],
                ['content', flow(addDefaultElementIfEmptyState, partialRight(map, next))],
            ]),
            options,
        );

        return embedNode;
    },

    serialize: (node: InfoboxElement, next, options: SerializerOptions = {}): UnityInfobox => {
        const { type, data, children } = node;
        const { useInlineEditing } = options;
        const newData = useInlineEditing ? serializeInlineInfoboxData(children, data) : data;

        return {
            version: CURRENT_VERSION,
            type,
            ...transformValues<typeof newData, Omit<UnityInfobox, 'version' | 'type'>>(newData, [
                ['title', flow(first, next)],
                ['content', partialRight(map, next)],
            ]),
        };
    },
};

export default infoboxRules;
