export type Listener = {
    eventName: typeof ActionType.FETCH | typeof ActionType.SUCCESS | typeof ActionType.ERROR;
    fn: (
        payload: FetchAction['payload'] | SuccessAction['payload'] | ErrorAction['payload'],
    ) => void;
    key: Key;
};

type Key = string;

export type ReducerState = {
    listeners: Listener[];
    images: Record<Key, { imageUrl: string }>;
};

export type ContextValue = ReducerState['images'];

export type DispatchContextValue = {
    addListener: (
        eventName: AddListenerAction['payload']['eventName'],
        fn: AddListenerAction['payload']['fn'],
        key: AddListenerAction['payload']['key'],
    ) => void;
    removeListener: (
        eventName: RemoveListenerAction['payload']['eventName'],
        fn: RemoveListenerAction['payload']['fn'],
    ) => void;
};

export const ActionType = {
    ADD_LISTENER: 'ADD_LISTENER',
    REMOVE_LISTENER: 'REMOVE_LISTENER',
    FETCH: 'FETCH',
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
} as const;

export type ActionType = ValueOf<typeof ActionType>;

export type AddListenerAction = {
    type: typeof ActionType.ADD_LISTENER;
    payload: Listener;
};

export type RemoveListenerAction = {
    type: typeof ActionType.REMOVE_LISTENER;
    payload: Omit<Listener, 'key'>;
};

export type FetchAction = {
    type: typeof ActionType.FETCH;
    payload: {
        key: Key;
        imageUrl: string;
    };
};

export type SuccessAction = {
    type: typeof ActionType.SUCCESS;
    payload: {
        key: Key;
        imageUrl: string;
        altText: string;
    };
};

export type ErrorAction = {
    type: typeof ActionType.ERROR;
    payload: {
        key: Key;
        imageUrl: string;
        error: Error;
    };
};

export type Action =
    | AddListenerAction
    | RemoveListenerAction
    | FetchAction
    | SuccessAction
    | ErrorAction;

export type UseImageAltTextGeneratorProps = {
    key: Key;
    onFetch?: (payload: FetchAction['payload']) => void;
    onSuccess?: (payload: SuccessAction['payload']) => void;
    onError?: (payload: ErrorAction['payload']) => void;
};
