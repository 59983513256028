import React, { type ReactNode } from 'react';

import Loader from '@@components/Loader';
import { BLOCK_META_STATES } from '@@editor/constants';
import { type Editor } from '@@editor/helpers';
import { type EmbedElement } from '@@editor/helpers/Element';
import { updateFileNodeData } from '@@editor/plugins/fileUpload/utils';
import { useLoader, type UseLoaderHandlers } from '@@hooks/useLoader';

const resetLoadingState = (editor: Editor, element: EmbedElement) => {
    if (element.data[BLOCK_META_STATES.LOADING_FILE]) {
        updateFileNodeData(editor, element, { [BLOCK_META_STATES.LOADING_FILE]: false });
    }
};

type Props = {
    editor: Editor;
    element: EmbedElement;
    children: (props: UseLoaderHandlers) => ReactNode;
};

const EmbedComponentLoader = ({ children, editor, element }: Props) => {
    const handleOnLoad = () => {
        resetLoadingState(editor, element);
    };

    const handleOnError = () => {
        resetLoadingState(editor, element);
    };

    const { isLoading, onLoad, onError } = useLoader({
        onLoad: handleOnLoad,
        onError: handleOnError,
    });

    return (
        <Loader isLoading={isLoading} keepChildrenInDom>
            {children({ onLoad, onError })}
        </Loader>
    );
};

export default EmbedComponentLoader;
