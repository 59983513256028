import { styled } from '@mui/material';
import { createSelector } from '@reduxjs/toolkit';
import { omit } from 'lodash';
import React, { useMemo } from 'react';
import { type FieldPath, type FieldValues } from 'react-hook-form';

import Table from '@@components/Table';
import useFormField, { type UseFormFieldProps } from '@@form/hooks/useFormField';
import { addItem } from '@@utils/arrayUtils';

const StyledTable = styled(Table)({
    '& th[type="image"]': {
        paddingLeft: 0,
    },
});

type Value = string | number | null | string[] | number[];
type Data = Record<string, unknown>;

type Props = {
    columns: TableColumn[];
    tableData: Data[];
    className?: string;
    multiple?: boolean;
    onClickFieldAction?: (action: IContextMenuAction, column: TableColumn, record: Data) => void;
    value: Value;
    onChange: (value: Value) => void;
    isRowDisabled?: ({ id }: { id: Id }) => boolean;
};

export const SelectionTable = (props: Props) => {
    const {
        tableData,
        value,
        multiple,
        columns,
        onClickFieldAction,
        onChange,
        className,
        isRowDisabled,
    } = props;

    const getData = createSelector(
        (state) => state.tableData,
        (state) => state.value,
        (state) => state.multiple,
        (tableData: Data[], value, multiple) =>
            tableData.map((item: Data) => ({
                ...item,
                isSelected: Boolean(
                    multiple
                        ? // as unknown[] is used because value can be string[] | number[] which makes array methods unhappy
                          // generics should help but with useFormField is not achivable right now
                          (value as unknown[]).find((id) => id === item?.id)
                        : value === item?.id,
                ),
            })),
    );

    const computedData = useMemo(
        () => getData({ tableData, value, multiple }),
        [getData, multiple, tableData, value],
    );

    const handleClickOnFieldAction = (
        e,
        action: IContextMenuAction,
        column: TableColumn,
        record: Data,
    ) => {
        e.stopPropagation();
        onClickFieldAction?.(action, column, record);
    };

    const handleClickOnRow = (e, column: TableColumn, record: Data, index: number) => {
        e.preventDefault();
        changeValue(!record.isSelected, record, index);
    };

    const handleChangeOnField = (e, column: TableColumn, record: Data) => {
        if (column && (column.type === 'checkbox' || column.type === 'radio')) {
            changeValue(e.target.checked, record);
        }
    };

    const changeValue = (newValue: boolean, record: Data, index = 0) => {
        if (multiple) {
            if (!newValue) {
                const idsWithoutSelection = (value as string[] | number[]).filter(
                    (id) => record.id !== id,
                ) as Value;

                onChange(idsWithoutSelection);
            } else {
                // TODO: This is wrong, makes no sense to add the item to the current row index
                // We should try to preserve the original order of the full data array.
                const idsWithSelection = addItem(value as string[] | number[], index, record.id);

                onChange(idsWithSelection as Value);
            }
        } else {
            if (!newValue) {
                onChange(null);
            } else {
                onChange(record.id as Value);
            }
        }
    };

    const renderRow = (columns, { data: record, index }) => {
        if (record.id) {
            return (
                <Table.Row
                    key={index}
                    columns={columns}
                    record={record}
                    index={index}
                    selected={record.isSelected}
                    onClick={handleClickOnRow}
                    onClickFieldAction={handleClickOnFieldAction}
                    onChangeField={handleChangeOnField}
                    disabled={isRowDisabled && isRowDisabled(record)}
                />
            );
        }

        return <Table.RowLoading key={index} columns={columns} />;
    };

    const tableColumns = [
        {
            type: multiple ? 'checkbox' : 'radio',
            fieldName: 'isSelected',
        },
        ...columns,
    ];

    return (
        <StyledTable
            className={className}
            data={computedData}
            renderRow={renderRow}
            columns={tableColumns}
        />
    );
};

type SelectionTableFieldProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<Props, 'onChange' | 'value'> & UseFormFieldProps<TFieldValues, TName>;

const SelectionTableField = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
    props: SelectionTableFieldProps<TFieldValues, TName>,
) => {
    const {
        name,
        control,
        validate,
        novalidate,
        required,
        requiredCustom,
        validateCacheKey,
        onChange,
        onBlur,
        transform,
        defaultValue,
        ...rest
    } = props;

    const { field: formFieldProps } = useFormField<TFieldValues, TName>({
        name,
        control,
        novalidate,
        validate,
        required,
        requiredCustom,
        validateCacheKey,
        onChange,
        onBlur,
        transform,
        defaultValue,
    });

    return <SelectionTable {...omit(formFieldProps, ['ref'])} {...rest} />;
};

export default SelectionTableField;
