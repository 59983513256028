import { styled } from '@mui/material';
import React from 'react';
import { Text } from 'slate';

import { SOFT_HYPHEN } from '@@constants/Unicode';
import renderLeaf from '@@editor/plugins/utils/renderLeaf';

const SYMBOL_NAME = 'softHyphen';

const StyledSoftHyphen = styled('span')(({ theme }) => ({
    '&:before': {
        display: 'inline-block',
        content: '"-"',
        color: theme.palette.primary.light,
    },
}));

type Props = {
    attributes: UnknownObject;
    children: React.ReactNode;
    leaf: {
        text: string;
    };
};

const SoftHyphen = (props: Props) => (
    <StyledSoftHyphen {...props.attributes}>{props.children}</StyledSoftHyphen>
);

export const withSoftHyphen = (editor) => {
    const { decorate } = editor;

    return Object.assign(editor, {
        // This inspired by the following slate example:
        // https://github.com/ianstormtaylor/slate/blob/master/site/examples/search-highlighting.js
        // If performance is not good we need to change to an inline elmeent. Something like:
        // https://github.com/DND-IT/cms-frontend/pull/2202
        decorate: (nodeEntry) => {
            const [node, path] = nodeEntry;
            const ranges: any[] = [];

            if (Text.isText(node)) {
                const { text } = node;
                const parts = text.split(SOFT_HYPHEN);
                let offset = 0;

                parts.forEach((part, i) => {
                    if (i !== 0) {
                        ranges.push({
                            anchor: { path, offset: offset - 1 },
                            focus: { path, offset },
                            [SYMBOL_NAME]: true,
                        });
                    }

                    offset = offset + part.length + 1;
                });
            }

            return [...ranges, ...decorate(nodeEntry)];
        },
        renderLeaf: renderLeaf(editor, [[SYMBOL_NAME, SoftHyphen]]),
    });
};

export default withSoftHyphen;
