import { z } from 'zod';

import {
    type COMPACT_EVENTS,
    type ICE_HOCKEY_EVENTS,
    type SOCCER_EVENTS,
    type STANDARD_EVENTS,
    type TENNIS_EVENTS,
    TICKER_TYPES,
} from '@@routes/ticker/constants';

import { TeamType } from './team';
import { Entity, RichText, UnityRichText } from '../../../utils/schemas/schemas';
import { unityVariantsShape, variantsShape } from '../../../utils/schemas/utils';
import { CreationMetadata, EmbeddedMetadata, UnityEmbeddedMetadata } from '../../metadata/schemas';

const TickerVariantBase = z.object({
    title: z.string(),
    titleHeader: z.string().nullable(),
    allowAutomaticTeaserUpdate: z.boolean().optional(),
});

const TickerVariant = TickerVariantBase.extend({
    lead: RichText,
    intro: RichText,
});

const UnityTickerVariant = TickerVariantBase.extend({
    lead: UnityRichText,
    intro: UnityRichText,
});

export const TickerCommon = Entity.extend({
    id: z.string().uuid(),
    metadataId: z.number(),
});

const UnityTickerBase = TickerCommon.extend({
    variants: unityVariantsShape(UnityTickerVariant),
    metadata: UnityEmbeddedMetadata.optional(),
});

const LocalTickerBase = TickerCommon.extend({
    variants: variantsShape(TickerVariant),
    metadata: EmbeddedMetadata.optional(),
});

const PenaltyShot = z.array(z.union([z.literal(0), z.literal(1), z.null()]));

export type PenaltyShot = z.infer<typeof PenaltyShot>;

const SoccerScore = z.object({
    penaltyShots: z.array(PenaltyShot),
    penaltyShot: PenaltyShot.nullable(),
    goals: z.array(z.number()),
    resultAdditive: z.enum(['SO', 'OT']).nullish(),
});

export type SoccerScore = z.infer<typeof SoccerScore>;

const IceHockeyScore = SoccerScore;

export type IceHockeyScore = z.infer<typeof IceHockeyScore>;

const SportTickerDataBase = z.object({
    scoreboardEnabled: z.boolean(),
    leagueId: z.number().nullable(),
    teams: z.array(TeamType),
});

const SoccerTickerBase = z.object({
    type: z.literal(TICKER_TYPES.SOCCER),
    data: SportTickerDataBase.extend({
        score: SoccerScore,
    }),
});

const UnitySoccerTickerBase = z.object({
    type: z.literal(TICKER_TYPES.SOCCER),
    data: SportTickerDataBase.extend({
        score: SoccerScore.nullable(),
    }),
});

const UnitySoccerTicker = UnityTickerBase.merge(UnitySoccerTickerBase);

export type UnitySoccerTicker = z.infer<typeof UnitySoccerTicker>;

export const SoccerTicker = LocalTickerBase.merge(SoccerTickerBase);

export type SoccerTicker = z.infer<typeof SoccerTicker>;

const IceHockeyTickerBase = z.object({
    type: z.literal(TICKER_TYPES.ICE_HOCKEY),
    data: SportTickerDataBase.extend({
        score: IceHockeyScore,
    }),
});

const UnityIceHockeyTickerBase = z.object({
    type: z.literal(TICKER_TYPES.ICE_HOCKEY),
    data: SportTickerDataBase.extend({
        score: IceHockeyScore.nullable(),
    }),
});

const UnityIceHockeyTicker = UnityTickerBase.merge(UnityIceHockeyTickerBase);

export type UnityIceHockeyTicker = z.infer<typeof UnityIceHockeyTicker>;

export const IceHockeyTicker = LocalTickerBase.merge(IceHockeyTickerBase);

export type IceHockeyTicker = z.infer<typeof IceHockeyTicker>;

const TennisSet = z.tuple([z.number(), z.number()]);

export type TennisSet = z.infer<typeof TennisSet>;

const TennisScore = z.object({
    sets: z.array(TennisSet),
    points: z.array(z.string()),
    tiebreak: z.array(z.number()),
    isFinished: z.boolean(),
});

export type TennisScore = z.infer<typeof TennisScore>;

const TennisTickerBase = z.object({
    type: z.literal(TICKER_TYPES.TENNIS),
    data: SportTickerDataBase.extend({
        startingTeam: z.string().optional(),
        score: TennisScore,
    }),
});

const UnityTennisTickerBase = z.object({
    type: z.literal(TICKER_TYPES.TENNIS),
    data: SportTickerDataBase.extend({
        startingTeam: z.string().optional(),
        score: TennisScore.nullable(),
    }),
});

const UnityTennisTicker = UnityTickerBase.merge(UnityTennisTickerBase);

export type UnityTennisTicker = z.infer<typeof UnityTennisTicker>;

export const TennisTicker = LocalTickerBase.merge(TennisTickerBase);

export type TennisTicker = z.infer<typeof TennisTicker>;

const StandardTickerBase = z.object({
    type: z.literal(TICKER_TYPES.STANDARD),
});

const UnityStandardTicker = UnityTickerBase.merge(StandardTickerBase);

export type UnityStandardTicker = z.infer<typeof UnityStandardTicker>;

export const StandardTicker = LocalTickerBase.merge(StandardTickerBase);

export type StandardTicker = z.infer<typeof StandardTicker>;

const CompactTickerBase = z.object({
    type: z.literal(TICKER_TYPES.COMPACT),
});

const UnityCompactTicker = UnityTickerBase.merge(CompactTickerBase);

export type UnityCompactTicker = z.infer<typeof UnityCompactTicker>;

export const CompactTicker = LocalTickerBase.merge(CompactTickerBase);

export type CompactTicker = z.infer<typeof CompactTicker>;

export const Ticker = z.discriminatedUnion('type', [
    SoccerTicker,
    IceHockeyTicker,
    TennisTicker,
    StandardTicker,
    CompactTicker,
]);

export type Ticker = z.infer<typeof Ticker>;

const NewSoccerTicker = SoccerTicker.pick({ type: true, variants: true }).extend({
    metadata: CreationMetadata,
    data: SoccerTicker.shape.data.omit({ score: true }),
});
const NewIceHockeyTicker = IceHockeyTicker.pick({ type: true, variants: true }).extend({
    metadata: CreationMetadata,
    data: IceHockeyTicker.shape.data.omit({ score: true }),
});
const NewTennisTicker = TennisTicker.pick({ type: true, variants: true }).extend({
    metadata: CreationMetadata,
    data: TennisTicker.shape.data.omit({ score: true }),
});
const NewStandardTicker = StandardTicker.pick({ type: true, variants: true }).extend({
    metadata: CreationMetadata,
});
const NewCompactTicker = CompactTicker.pick({ type: true, variants: true }).extend({
    metadata: CreationMetadata,
});

export const NewTicker = z.discriminatedUnion('type', [
    NewSoccerTicker,
    NewIceHockeyTicker,
    NewTennisTicker,
    NewStandardTicker,
    NewCompactTicker,
]);

export type NewTicker = z.infer<typeof NewTicker>;

const NewUnitySoccerTicker = UnitySoccerTicker.pick({
    type: true,
    variants: true,
    data: true,
}).extend({
    metadata: CreationMetadata,
});
const NewUnityIceHockeyTicker = UnityIceHockeyTicker.pick({
    type: true,
    variants: true,
    data: true,
}).extend({
    metadata: CreationMetadata,
});
const NewUnityTennisTicker = UnityTennisTicker.pick({
    type: true,
    variants: true,
    data: true,
}).extend({
    metadata: CreationMetadata,
});
const NewUnityStandardTicker = UnityStandardTicker.pick({ type: true, variants: true }).extend({
    metadata: CreationMetadata,
});
const NewUnityCompactTicker = UnityCompactTicker.pick({ type: true, variants: true }).extend({
    metadata: CreationMetadata,
});

export const NewUnityTicker = z.discriminatedUnion('type', [
    NewUnitySoccerTicker,
    NewUnityIceHockeyTicker,
    NewUnityTennisTicker,
    NewUnityStandardTicker,
    NewUnityCompactTicker,
]);

export type NewUnityTicker = z.infer<typeof NewUnityTicker>;

export const SportTicker = z.union([SoccerTicker, IceHockeyTicker, TennisTicker]);

export type SportTicker = z.infer<typeof SportTicker>;

export const UnityTicker = z.discriminatedUnion('type', [
    UnitySoccerTicker,
    UnityIceHockeyTicker,
    UnityTennisTicker,
    UnityStandardTicker,
    UnityCompactTicker,
]);

export type UnityTicker = z.infer<typeof UnityTicker>;

const Score = z.union([SoccerScore, IceHockeyScore, TennisScore]);

export type Score = z.infer<typeof Score>;

export const TickerTypesTypes = z.object({
    [TICKER_TYPES.STANDARD]: z.custom<typeof STANDARD_EVENTS>(),
    [TICKER_TYPES.COMPACT]: z.custom<typeof COMPACT_EVENTS>(),
    [TICKER_TYPES.SOCCER]: z.custom<typeof SOCCER_EVENTS>(),
    [TICKER_TYPES.ICE_HOCKEY]: z.custom<typeof ICE_HOCKEY_EVENTS>(),
    [TICKER_TYPES.TENNIS]: z.custom<typeof TENNIS_EVENTS>(),
});

export type TickerTypesTypes = z.infer<typeof TickerTypesTypes>;
