import { useEffect, useRef, useState } from 'react';

import MultiFileUploadHelper, {
    type MultiFileUploadHelperOptions,
} from '@@utils/MultiFileUploadHelper';

type UseMultiFileUploadHelperOptions = Partial<MultiFileUploadHelperOptions> & {
    waitAfterComplete?: number;
};

type UploadState = {
    isLoading: boolean;
    totalFiles: number;
    filesPending: number;
    progress: number;
};

type UseMultiFileUploadHelperReturn = [UploadState, (files: File[]) => void];

const useMultiFileUploadHelper = (
    options: UseMultiFileUploadHelperOptions = {},
): UseMultiFileUploadHelperReturn => {
    const { waitAfterComplete = 0, onProgress = () => {}, onComplete = () => {} } = options;

    const [isLoading, setLoading] = useState(false);
    const [totalFiles, setTotalFiles] = useState(0);
    const [filesPending, setFilesPending] = useState(0);
    const [progress, setProgress] = useState(0);
    const helperRef = useRef<MultiFileUploadHelper | null>(null);

    useEffect(() => {
        helperRef.current = new MultiFileUploadHelper({
            ...options,
            onProgress: (e) => {
                const { filesPending, progress } = e;

                setFilesPending(filesPending);
                setProgress(progress);

                onProgress(e);
            },
            onComplete: (results) => {
                setFilesPending(0);
                setProgress(100);

                setTimeout(() => {
                    onComplete(results);

                    setLoading(false);
                    setTotalFiles(0);
                    setFilesPending(0);
                    setProgress(0);
                }, waitAfterComplete);
            },
        });

        return () => {
            helperRef.current = null;
        };
    }, [options, onProgress, onComplete, waitAfterComplete]);

    const upload = (files: File[]) => {
        if (files.length <= 0) {
            return;
        }

        setLoading(true);
        setTotalFiles(files.length);
        setFilesPending(files.length);

        helperRef.current?.upload(files);
    };

    return [{ isLoading, totalFiles, filesPending, progress }, upload];
};

export default useMultiFileUploadHelper;
