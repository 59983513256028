import { styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { type Editor, Element } from '@@editor/helpers';
import { type ElementAttributes, type EmbedElement } from '@@editor/helpers/Element';
import { getInputPlaceholderStyle } from '@@editor/plugins/serializable/styles';

type Props = {
    attributes: ElementAttributes;
    children: React.ReactNode;
    editor: Editor;
    element: EmbedElement;
};

type StyledInfoboxTitleProps = {
    $templateElement: boolean;
    $placeholderText: string;
};

export const StyledInfoboxTitle = styled('div')<StyledInfoboxTitleProps>(
    ({ $templateElement, $placeholderText, theme }) => ({
        ...theme.typography.title3,
        ...($templateElement &&
            getInputPlaceholderStyle({ theme, placeholderText: $placeholderText })),
    }),
);

const InfoboxTitleElement = (props: Props) => {
    const { children, attributes, element } = props;

    const { t } = useTranslation();

    return (
        <StyledInfoboxTitle
            {...attributes}
            $templateElement={Element.isEmptyInfoboxTitleElement(element)}
            $placeholderText={t('editor.plugin.infobox.form.title')}
        >
            {children}
        </StyledInfoboxTitle>
    );
};

export default InfoboxTitleElement;
