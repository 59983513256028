import React from 'react';

import { HOTKEYS } from '@@editor/constants';
import { Editor } from '@@editor/helpers';
import handleHotkey from '@@editor/plugins/utils/handleHotkey';
import renderLeaf from '@@editor/plugins/utils/renderLeaf';

const MARK = 'subscript';

type Props = {
    attributes: UnknownObject;
    children: React.ReactNode;
};
const SubscriptMark = (props: Props) => <sub {...props.attributes}>{props.children}</sub>;

export const withSubscript = (editor: Editor) => {
    const { toggleMark } = editor;

    return Object.assign(editor, {
        renderLeaf: renderLeaf(editor, [[MARK, SubscriptMark]]),
        handleHotkey: handleHotkey(editor, [
            [HOTKEYS.SUBSCRIPT, (editor: Editor) => editor.toggleMark(MARK)],
        ]),
        toggleMark: (mark, single) => {
            // If user applies any other mark, remove this one (subscript) first
            if (Editor.isMarkActive(editor, MARK) && mark !== MARK) {
                editor.removeMark(MARK);
            }

            // Toggle mark. If it is subscript, remove any other first
            toggleMark(mark, mark === MARK ? true : single);
        },
    });
};

export default withSubscript;
