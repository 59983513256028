import { type TFunction } from 'i18next';
import { type FieldError } from 'react-hook-form';

import { type FormFieldError } from '@@form/hooks/useReactHookFormFieldError';

export const getErrorMessage = (error: FormFieldError | FieldError | undefined, t: TFunction) => {
    if (!error) {
        return undefined;
    }

    if (Array.isArray(error)) {
        const errorMessage = error?.[0];
        const message = errorMessage?.[0] ?? '';
        const replacements = errorMessage?.[1] ?? {};

        return t(message, replacements);
    }

    return error.message;
};
