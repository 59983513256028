import { type ElementType } from 'react';

export type ModalContextType = State;

export type ModalActionsContextType = ActionFunctions;

export type Modal = {
    id: Id;
    component: ElementType;
    props: AnyObject;
};

export type State = {
    modals: Modal[];
};

export type ActionFunctions = {
    register: (id: Id, props: AnyObject, component: ElementType) => Id;
    unregister: (id: Id) => void;
    show: (id: Id, props?: AnyObject) => void;
    hide: (id: Id) => void;
    updateProps: (id: Id, props: AnyObject) => void;
};

export enum ActionType {
    REGISTER = 'REGISTER',
    UNREGISTER = 'UNREGISTER',
    SHOW = 'SHOW',
    HIDE = 'HIDE',
    UPDATE_PROPS = 'UPDATE_PROPS',
}

type BaseAction = {
    id: Id;
    type: ActionType;
};

export type RegisterAction = BaseAction & {
    type: ActionType.REGISTER;
    component: ElementType;
    props: AnyObject;
};

export type UnregisterAction = BaseAction & {
    type: ActionType.UNREGISTER;
};

export type ShowAction = BaseAction & {
    type: ActionType.SHOW;
};

export type HideAction = BaseAction & {
    type: ActionType.HIDE;
};

export type UpdatePropsAction = BaseAction & {
    type: ActionType.UPDATE_PROPS;
    props: AnyObject;
};

export type Action =
    | RegisterAction
    | UnregisterAction
    | ShowAction
    | HideAction
    | UpdatePropsAction;

export const isRegisterAction = (action: Action): action is RegisterAction =>
    action.type === ActionType.REGISTER;

export const isUnregisterAction = (action: Action): action is UnregisterAction =>
    action.type === ActionType.UNREGISTER;

export const isShowAction = (action: Action): action is ShowAction =>
    action.type === ActionType.SHOW;

export const isHideAction = (action: Action): action is HideAction =>
    action.type === ActionType.HIDE;

export const isUpdatePropsAction = (action: Action): action is UpdatePropsAction =>
    action.type === ActionType.UPDATE_PROPS;
