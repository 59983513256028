import moment from 'moment';
import React, { createContext, useCallback, useEffect } from 'react';
import { type FieldValues, type UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import config from '@@config';
import snackbar from '@@containers/Snackbar';
import { isExpired } from '@@lib/dateTime/utils';
import { getNoFormRestorationSmartSetting } from '@@settings/settingsSlice';
import { useSelector } from '@@store/hooks';

import { getReadableNameFromFormKey } from './utils';

const FORM_PERSIST_PREFIX = 'UnityFormPersist';

type Options = {
    persist: boolean;
};

type PersistedForm<TFieldValues extends FieldValues = FieldValues> = {
    values: TFieldValues;
    lastChanged: moment.Moment;
};

const defaultPersistOptions = {
    expireAfter: config.persistedFormDataExpireAfter,
};

const getPersistedForm = (storageKey: string) => {
    const value = localStorage.getItem(storageKey);

    return value ? JSON.parse(value) : null;
};

const updatePersistedForm = <TFieldValues extends FieldValues = FieldValues>(
    storageKey: string,
    formToPesist: PersistedForm<TFieldValues>,
) => {
    localStorage.setItem(storageKey, JSON.stringify(formToPesist));
};

const clearPersistedForm = (storageKey: string) => {
    localStorage.removeItem(storageKey);
};

const useReactHookFormPersist = <TFieldValues extends FieldValues = FieldValues>(
    formName: string,
    form: UseFormReturn<TFieldValues>,
    options: Options,
): { clearPersistedForm: () => void } => {
    const { persist = false } = options;
    const {
        formState: { isDirty, isSubmitting },
        getValues,
        reset,
    } = form;
    const storageKey = `${FORM_PERSIST_PREFIX}:${formName}`;

    const { t } = useTranslation();
    const noFormRestoration = useSelector(getNoFormRestorationSmartSetting);
    const shouldPersist = persist && !noFormRestoration;

    const updateFormValues = useCallback(
        (values: TFieldValues) => {
            reset(values, {
                keepErrors: false,
                keepDefaultValues: true,
                keepValues: false,
                keepDirty: false,
                keepDirtyValues: false,
                keepIsSubmitted: false,
                keepTouched: false,
                keepIsValid: false,
                keepSubmitCount: false,
            });

            snackbar.warning(
                `${t('form.changes.restored')}: ${getReadableNameFromFormKey(formName)}`,
            );
        },
        [formName, reset, t],
    );

    useEffect(() => {
        if (!shouldPersist) {
            return;
        }

        if (noFormRestoration) {
            console.warn('Did not restore because of setting "noFormRestoration"');
        }

        const persistedForm = getPersistedForm(storageKey);

        if (persistedForm) {
            const { values, lastChanged } = persistedForm;

            if (isExpired(lastChanged, defaultPersistOptions.expireAfter)) {
                clearPersistedForm(storageKey);
            } else {
                updateFormValues(values);
            }
        }
    }, [noFormRestoration, shouldPersist, storageKey, updateFormValues]);

    useEffect(
        () => () => {
            clearPersistedForm(storageKey);
        },
        [storageKey],
    );

    const values = getValues();

    useEffect(() => {
        if (shouldPersist && isDirty && !isSubmitting) {
            updatePersistedForm<TFieldValues>(storageKey, {
                values,
                lastChanged: moment(),
            });
        }
    }, [isDirty, shouldPersist, storageKey, values, isSubmitting]);

    return {
        clearPersistedForm: () => clearPersistedForm(storageKey),
    };
};

export default useReactHookFormPersist;

const FormPersistContext = createContext<ReturnType<typeof useReactHookFormPersist> | null>(null);

type FormPersistProviderProps<TFieldValues extends FieldValues = FieldValues> = {
    clearPersistedForm: ReturnType<
        typeof useReactHookFormPersist<TFieldValues>
    >['clearPersistedForm'];
    children: React.ReactNode;
};
export const FormPersistProvider = <TFieldValues extends FieldValues = FieldValues>(
    props: FormPersistProviderProps<TFieldValues>,
) => {
    const { children, clearPersistedForm } = props;

    return (
        <FormPersistContext.Provider value={{ clearPersistedForm }}>
            {children}
        </FormPersistContext.Provider>
    );
};

export const useFormPersistContext = () => {
    const context = React.useContext(FormPersistContext);

    if (!context) {
        throw new Error('useFormPersist must be used within a FormPersistProvider');
    }

    return context;
};
