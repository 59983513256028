import { Box, CircularProgress as MuiCircularProgress, Typography } from '@mui/material';
import React from 'react';

type Props = {
    percent: number;
    mainColor: string;
    secondaryColor: string;
    textColor: string;
};

const CircularProgress = ({ percent, mainColor, secondaryColor, textColor }: Props) => {
    const mainPercentage = Math.min(percent, 100);
    const warningPercentage = Math.min(Math.max(percent - 100, 0), 100);

    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <MuiCircularProgress
                variant="determinate"
                value={mainPercentage}
                sx={{ color: mainColor }}
            />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <MuiCircularProgress
                    variant="determinate"
                    value={warningPercentage}
                    sx={{ color: secondaryColor }}
                />
            </Box>
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography
                    variant="caption"
                    component="div"
                    color={textColor}
                >{`${percent}%`}</Typography>
            </Box>
        </Box>
    );
};

export default CircularProgress;
