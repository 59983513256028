import React, { useMemo, useState } from 'react';
import { Transforms } from 'slate';

import { type Tenant } from '@@api/services/tenant/schemas';
import Spacer from '@@components/Spacer';
import { type Editor, Element, Node, ReactEditor } from '@@editor/helpers';
import { type InterviewSegmentElement } from '@@editor/helpers/Element';
import {
    EmbedInterviewContentEditor,
    EmbedInterviewHeadingEditor,
} from '@@form/components/RichTextEditor/InterviewRichTextEditor';

import { PluginsWrapper } from '../../components/PluginsWrapper';

type Props = {
    editor: Editor;
    element: InterviewSegmentElement;
    readOnly?: boolean;
    tenantIds: Tenant['id'][];
};

const onHeaderChange = (editor, node, path) => (value) => {
    Transforms.setNodes(
        editor,
        {
            ...node,
            data: {
                ...node.data,
                question: value,
            },
        },
        { at: path },
    );
};

const onContentsChange = (editor: Editor, node: any, path) => (value) => {
    const answers = value.map((answer) => [answer]);

    Transforms.setNodes(
        editor,
        {
            ...node,
            data: {
                ...node.data,
                answers,
            },
        },
        { at: path },
    );
};

const InterviewSegment = ({ element, editor, tenantIds, readOnly }: Props) => {
    const { question, answers } = element.data;

    const path = ReactEditor.findPath(editor, element);
    const node = Node.get(editor, path);
    const [headerFocused, setHeaderFocused] = useState(false);
    const [contentFocused, setContentFocused] = useState(false);

    const isHeaderEmpty = useMemo(
        () => Element.isEmptyParagraphElement(element.data.question[0]) && !headerFocused,
        [headerFocused, element.data.question],
    );

    const isContentEmpty = useMemo(
        () =>
            element.data.answers.every((answer) => Element.isEmptyParagraphElement(answer[0])) &&
            !contentFocused,
        [contentFocused, element.data.answers],
    );

    const commonProps = {
        hideStickyToolbar: true,
        readOnly: readOnly || !editor.isArticleEditor,
        isArticleEditor: editor.isArticleEditor,
        tenantIds,
    };

    const headerProps = {
        ...commonProps,
        $templateElement: isHeaderEmpty,
        placeholderText: editor.t('editor.plugin.layout.form.question'),
        onChange: onHeaderChange(editor, node, path),
        onFocus: () => {
            setHeaderFocused(true);
        },
        onBlur: () => {
            setHeaderFocused(false);
        },
    };
    const contentProps = {
        ...commonProps,
        $templateElement: isContentEmpty,
        placeholderText: editor.t('editor.plugin.layout.form.answer'),
        multiline: editor.isArticleEditor,
        onChange: onContentsChange(editor, node, path),
        onFocus: () => {
            setContentFocused(true);
        },
        onBlur: () => {
            setContentFocused(false);
        },
    };

    return (
        <PluginsWrapper>
            <EmbedInterviewHeadingEditor value={question} {...headerProps} />
            {editor.isArticleEditor ? (
                <EmbedInterviewContentEditor value={answers.flat()} {...contentProps} />
            ) : (
                answers.map((answer, index) => (
                    <React.Fragment key={index}>
                        <EmbedInterviewContentEditor
                            value={answer}
                            readOnly
                            tenantIds={tenantIds}
                        />
                        {index < answers.length - 1 && <Spacer v xs />}
                    </React.Fragment>
                ))
            )}
        </PluginsWrapper>
    );
};

export default InterviewSegment;
