import React from 'react';

import { type InsertElementOptions } from '@@editor/helpers/Editor';
import { ELEMENT_TYPES } from '@@editor/helpers/Element';
import renderEditor from '@@editor/plugins/utils/renderEditor';
import renderElement from '@@editor/plugins/utils/renderElement';
import { PLUGIN_ICON_NAMES, PLUGIN_NAMES } from '@@editor/typings/UnityPlugins';
import zattooEmbedCode from '@@form/utils/validators/zattooEmbedCode';
import { parseZattooUrl } from '@@utils/socialMedia/zattoo';

import EditorWithEmbedModal from './../components/EditorWithEmbedModal';
import EmbeddedIframe from './../components/EmbeddedIframe';
import EmbedWrapper from './../components/EmbedWrapper';
import { createGenerateEmbedBlock } from './../utils';
import MimeTypes from './mimetypes';
import { generateEmbedBlockData } from './utils';
import ZattooForm from './ZattooForm';

const TYPE = PLUGIN_NAMES.ZATTOO;
const NODE_TYPE = ELEMENT_TYPES.ZATTOO;

const mimetypeConfigs = {
    [MimeTypes.VIDEO]: {
        Component: EmbeddedIframe,
    },
};

const extendedStyles = {};

const ZattooNode = (props) => (
    <EmbedWrapper
        {...props}
        type={TYPE}
        mimetypeConfigs={mimetypeConfigs}
        extendedStyles={extendedStyles}
        toolbarConfig={{
            infos: {
                iconName: PLUGIN_ICON_NAMES[TYPE],
                title: 'Zattoo',
            },
            actions: ['edit', 'delete'],
        }}
    />
);

const generateEmbedBlock = createGenerateEmbedBlock({
    contentType: 'html',
    type: TYPE,
    nodeType: NODE_TYPE,
    generateEmbedBlockData,
    parseEmbedCode: parseZattooUrl,
});

const validate = [zattooEmbedCode];

const internalWithZattoo = (editor, options) => {
    const { insertData } = editor;

    return Object.assign(editor, {
        renderEditor: renderEditor(
            editor,
            (props) => (
                <EditorWithEmbedModal
                    {...props}
                    formComponent={ZattooForm}
                    validate={validate}
                    generateEmbedBlock={generateEmbedBlock}
                    type={TYPE}
                />
            ),
            options,
        ),
        renderElement: renderElement(editor, [[NODE_TYPE, ZattooNode]], options),
        insertData: (data: DataTransfer, options: InsertElementOptions) => {
            const text = data.getData('text/plain');

            if (text && !zattooEmbedCode(text)) {
                generateEmbedBlock(editor, text);
            } else {
                insertData(data, options);
            }
        },
    });
};

export const withZattoo = (editor, options) =>
    internalWithZattoo(editor, {
        ...options,
        nodeType: NODE_TYPE,
    });

export default withZattoo;
