import { type ChipTypeMap } from '@mui/material';
import React from 'react';
import { type FieldPath, type FieldValues } from 'react-hook-form';

import Autocomplete, { type AutocompleteProps } from '@@form/components/Autocomplete';
import useFormField, { type UseFormFieldProps } from '@@form/hooks/useFormField';

export type AutocompleteFieldProps<
    Value,
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
    Multiple extends boolean | undefined = false,
    DisableClearable extends boolean | undefined = false,
    FreeSolo extends boolean | undefined = false,
    ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
> = UseFormFieldProps<TFieldValues, TName> &
    Omit<AutocompleteProps<Value, Multiple, DisableClearable, FreeSolo, ChipComponent>, 'onChange'>;

const AutocompleteField = <
    Value,
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
    Multiple extends boolean | undefined = false,
    DisableClearable extends boolean | undefined = false,
    FreeSolo extends boolean | undefined = false,
    ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
>(
    props: AutocompleteFieldProps<
        Value,
        TFieldValues,
        TName,
        Multiple,
        DisableClearable,
        FreeSolo,
        ChipComponent
    >,
) => {
    const {
        name,
        control,
        novalidate,
        required,
        requiredCustom,
        validate,
        validateCacheKey,
        onChange,
        onBlur,
        transform,
        defaultValue,
        ...rest
    } = props;

    const {
        field: { ref, ...formFieldProps },
    } = useFormField<TFieldValues, TName>({
        name,
        control,
        novalidate,
        required,
        requiredCustom,
        validate,
        validateCacheKey,
        onChange,
        onBlur,
        transform,
        defaultValue,
    });

    return <Autocomplete inputRef={ref} {...formFieldProps} required={required} {...rest} />;
};

export default AutocompleteField;
