import { createTheme, type Theme } from '@mui/material';

const inlineEditedImageTheme = (theme: Theme) =>
    createTheme(theme, {
        fixed: {
            fileUpload: {
                dropArea: {
                    backgroundColor: theme.fixed.editor.embed.backgroundColor,
                },
            },
        },
    });

export default inlineEditedImageTheme;
