import { type CSSObject, styled, type Theme } from '@mui/material';
import { noop } from 'lodash';
import { stripUnit } from 'polished';
import React, { type CSSProperties, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { type Descendant } from 'slate';

import ErrorBoundary from '@@components/ErrorBoundary';
import { FINGERPRINTS } from '@@constants/ErrorTracking';
import snackbar from '@@containers/Snackbar';
import { SLATE_ATTRIBUTE_SELECTORS } from '@@editor/constants';
import Editor from '@@editor/editor';
import { checkForDnd } from '@@editor/plugins/dnd/utils';
import { type Plugin, type PluginList } from '@@editor/typings/UnityPlugins';
import makeState from '@@editor/utils/makeState';
import { type FormFieldError } from '@@form/hooks/useReactHookFormFieldError';

import ValidationIcon, {
    HORIZONTAL_PADDING,
    VERTICAL_PADDING,
    Wrapper as StyledValidationIcon,
} from '../ValidationIcon';

type OuterWrapperProps = {
    display?: 'block' | 'inline';
    direction: CSSProperties['flexDirection'];
    readOnly: boolean | undefined;
    disabled?: boolean;
    error?: FormFieldError;
};

const getColor = (props: OuterWrapperProps & { theme: Theme }): CSSObject => {
    if (props.error) {
        return {
            color: props.theme.palette.error.main,
            '&:hover': {
                color: props.theme.palette.error.dark,
            },
            '&:focus, &:active': {
                color: props.theme.palette.error['700'],
            },
        };
    } else if (props.disabled) {
        return {
            color: props.theme.palette.primary.main,
        };
    }

    return {
        color: props.theme.palette.text.primary,
    };
};

const OuterWrapper = styled('div')<OuterWrapperProps>((props) => ({
    display: props.display === 'block' ? 'flex' : 'inline-flex',
    position: 'relative',
    flexDirection: props.direction,
    ...getColor(props),
    backgroundColor: props.readOnly ? 'transparent' : props.theme.palette.common.white,
    ...(props.readOnly &&
        props.disabled && { backgroundColor: props.theme.palette.primary['200'] }),
    flexGrow: 1,
    maxWidth: '100%',
    ...props.theme.typography.medium,
    ...(props.display === 'inline' && {
        minWidth: 'unset',
        maxWidth: 'unset',
        minHeight: 'unset',
    }),
}));

const ErrorMessage = styled('div')(({ theme }) => ({
    color: theme.palette.error.main,
    marginBottom: theme.spacing(2),
}));

const Wrapper = styled('div')<{ display?: 'block' | 'inline' }>((props) => ({
    display: props.display === 'block' ? 'flex' : 'inline-flex',
    minWidth: '100%',
    maxWidth: '100%',
    position: 'relative',
    textAlign: 'left',
    verticalAlign: 'middle',
    ...(props.display === 'inline' && {
        minWidth: 'unset',
        maxWidth: 'unset',
        minHeight: 'unset',
    }),
    [`${StyledValidationIcon}`]: {
        padding: `${VERTICAL_PADDING} ${HORIZONTAL_PADDING}`,
        top: `calc(50% - calc(calc(${props.theme.fixed.icon.medium} + (${VERTICAL_PADDING}*2))/2))`,
    },
}));

const ROW_HEIGHT = 20;

type EditorProps = Omit<Props, 'className' | 'display' | 'error' | 'plugins'> & {
    withDnd?: boolean;
};

// theme is not properly typed yet
type EditorPropsWithTheme = EditorProps & { theme: Theme };

const getPadding = ({ isArticleEditor, readOnly, theme }: EditorPropsWithTheme) => {
    if (readOnly) {
        return ['0', '0'];
    }
    if (isArticleEditor) {
        return [theme.spacing(2), theme.spacing(1)];
    }

    return ['7px', '12px'];
};

const getBorderWidth = (props: EditorPropsWithTheme) => {
    if (props.readOnly || props.isArticleEditor) {
        return 0;
    }

    return props.theme.borders[1];
};

// This works only accurate for empty/paragraph lines, but that's fine. No need
// to be perfect here.
const calcMinHeight = (props: EditorPropsWithTheme) => {
    const { hideFloatingToolbar, minRows = 0, readOnly } = props;

    if (readOnly || (hideFloatingToolbar && minRows === 1)) {
        return 'unset';
    }

    const [verticalPadding] = getPadding(props).map(stripUnit) as string[];
    const borderWidth = stripUnit(getBorderWidth(props)) as number;

    const rowMargin = (
        hideFloatingToolbar
            ? stripUnit(props.theme.spacing(2))
            : stripUnit(props.theme.fixed.editor.floatingToolbar.height)
    ) as number;

    // - rowMargin: because last block has a margin-bottom of 0
    // + rowMargin: because we have a floating toolbar in between each element and on top and on bottom
    const additionalRowMargin = hideFloatingToolbar ? -rowMargin : rowMargin;

    // verticalPadding: because the editable has a padding-top and a padding-bottom
    return (
        verticalPadding +
        minRows * (ROW_HEIGHT + rowMargin) +
        additionalRowMargin +
        verticalPadding +
        borderWidth * 2 +
        'px'
    );
};

export const StyledEditor = styled(Editor)<EditorProps>((props) => ({
    minWidth: '100%',
    // Since slate is internally overwriting the min-height of the editor, when using a
    // placeholder, we need to overrule it here with '!important'
    minHeight: `${calcMinHeight(props)} !important`,
    overflow: 'visible',
    outline: 'none',
    border: 'none',
    padding: props.isArticleEditor ? getPadding(props).join(' ') : '0',
    // If wrapped in a label component, the cursor will switch from 'text' to 'default'. Therefore rich text will not
    // look like editable text to the user. We have to set it here again.
    cursor: props.disabled || (props.isArticleEditor && props.readOnly) ? 'default' : 'text',
    '& *::selection': {
        backgroundColor: props.theme.fixed.editor.elementWrapper.backgroundColor,
    },
    // This padding defines the white space between elements in the editor. We need to use padding, not margin
    // in order to make the blue line drag and drop indicator working. Why? If we drag something over the margin
    // area of a drop target, it does not recognise it as a drop target. Only if the draged something is draged
    // completely over the drop target (inside of its margins), it will receive a hover event.
    [SLATE_ATTRIBUTE_SELECTORS.BLOCKS]: {
        // We need all blocks to be positioned relative to make sure drop lines are positioned correctly
        position: 'relative',
        '&:not([data-slate-void=true])': {
            paddingBottom: `${props.theme.spacing(2)}`,
            // If we have a void element (for example an image) after a non void element (for example a paragraph),
            // that void element must not have a top padding. Otherwise the padding would be too big (since the
            // bottom padding of the non void element and the top padding of the void element would sum up).
            '& + [data-slate-void=true]': {
                paddingTop: '0',
            },
            '&:last-child': {
                paddingBottom: '0',
            },
        },
        '&[data-slate-void=true]': {
            paddingTop: '5px',
            paddingBottom: '5px',
        },
    },
    // Reset the padding if there is a floating toolbar rendered before a block, because the floating toolbar will
    // take care about the spacing between blocks
    [`[data-slate-floating-toolbar=true] + ${SLATE_ATTRIBUTE_SELECTORS.BLOCKS}`]: {
        '&:not([data-slate-void=true])': {
            paddingBottom: '0',
        },
        '&[data-slate-void=true]': {
            paddingTop: '0',
            paddingBottom: '0',
        },
    },
}));

type UseRecentValuesProps = Pick<Props, 'value' | 'onChange'>;

type UseRecentValuesReturn = {
    caughtError: boolean;
    restoreRecentValue: VoidFunction;
    onChange: UseRecentValuesProps['onChange'];
};

const useRecentValues = (props: UseRecentValuesProps): UseRecentValuesReturn => {
    const MAX_RECENT_VALUES = 5;
    const { value = makeState(), onChange = noop } = props;
    const { t } = useTranslation();

    const version = useRef(0);
    const createVersion = () => ++version.current;

    const [caughtError, setCaughtError] = useState(false);
    const [recentValues, setRecentValues] = useState([
        {
            value,
            version: version.current,
        },
    ]);

    const handleChangeProxy = useCallback(
        (...args) => {
            const [value] = args;

            setRecentValues((recentValues) =>
                [{ value, version: createVersion() }, ...recentValues].slice(0, MAX_RECENT_VALUES),
            );

            onChange(...args);
        },
        [onChange],
    );

    const restoreRecentValue = useCallback(() => {
        if (recentValues.length > 0) {
            setRecentValues((recentValues) => {
                onChange([...recentValues[0].value]);

                snackbar.warning(t('richtext.warning.restore'));

                return recentValues.slice(1);
            });
        } else {
            onChange(makeState());
            setCaughtError(true);
        }
    }, [onChange, recentValues.length, t]);

    return {
        restoreRecentValue,
        caughtError,
        onChange: handleChangeProxy,
    };
};

type Value = Descendant[];

export type Props = {
    onBlur?: (e: React.FocusEvent) => void;
    onChange?: (value: Value) => void;
    onFocus?: (e: React.FocusEvent) => void;
    className?: string;
    display?: 'block' | 'inline';
    error?: FormFieldError;
    hideFloatingToolbar?: boolean;
    isArticleEditor?: boolean;
    readOnly?: boolean;
    minRows?: number;
    plugins: Plugin[];
    value?: Value;
    stickyToolbarButtons?: PluginList[];
    isArticleHeadingsEditor?: boolean;
    useInlineEditing?: boolean;
    spellCheck?: boolean;
    disabled?: boolean;
    hideStickyToolbar?: boolean;
    preserveFloatingToolbarHeight?: boolean;
    targetTextLength?: number | null;
    showReadOnlyStickyToolbar?: boolean;
};

// Usually input elements (text, date, checkbox etc.) are rendered by default inline, but since
// we use the rich text editor to display form contents in read only mode, the default use case
// is actually to render the editor as block.
const defaultProps = {
    display: 'block' as const,
    onFocus: () => {},
    onBlur: () => {},
};

const RichTextEditorBase = (props: Props) => {
    const { t } = useTranslation();

    const { restoreRecentValue, caughtError, onChange } = useRecentValues(props);

    const { className, display, error, readOnly, isArticleEditor, ...rest } = {
        ...defaultProps,
        ...props,
    };

    if (!('minRows' in rest)) {
        rest.minRows = props.hideFloatingToolbar ? 2 : 1;
    }

    const withDnd = checkForDnd(rest.plugins);

    const isEmptyArticleInViewMode = isArticleEditor && readOnly && !props.value?.length;

    // smooth workaround to fix the focus issue when flex-grow is used with flex-direction "column"
    const flexDirection = caughtError || isEmptyArticleInViewMode ? 'column' : 'row';

    return (
        <ErrorBoundary<Value | undefined>
            fingerprint={[FINGERPRINTS.RICHTEXTEDITOR]}
            resetOnChangedProp={{
                fieldName: 'value',
            }}
            onError={restoreRecentValue}
            value={props.value}
        >
            <OuterWrapper
                className={className}
                direction={flexDirection}
                display={display}
                readOnly={readOnly}
            >
                {caughtError ? <ErrorMessage>{t('richtext.error.critical')}</ErrorMessage> : null}

                <Wrapper display={display}>
                    <StyledEditor
                        {...rest}
                        isArticleEditor={isArticleEditor}
                        readOnly={readOnly}
                        withDnd={withDnd}
                        onChange={onChange}
                    />

                    {error ? <ValidationIcon error={error} /> : null}
                </Wrapper>
            </OuterWrapper>
        </ErrorBoundary>
    );
};

export default RichTextEditorBase;
