import { styled } from '@mui/material';
import { noop } from 'lodash';
import React, { useRef } from 'react';
import { useDrag } from 'react-dnd';

import { TYPES } from '@@constants/DragAndDrop';
import useDropItem, { type DropMode } from '@@hooks/useDropItem';
import dragAndDropIndicatorStyle from '@@styles/dragAndDropIndicatorStyle';
import { updateRef } from '@@utils/index';

import Row, { type Props as RowProps } from './Row';

export const StyledDraggableRow = styled(Row, {
    shouldForwardProp: (prop) => prop !== 'dropMode',
})<{ draggable?: boolean; dropMode: DropMode | null }>(({ draggable, dropMode, theme }) => ({
    ...(draggable && { cursor: 'move' }),
    td: {
        ...dragAndDropIndicatorStyle({ theme, dropMode }),
    },
}));

export type Props<T = any> = RowProps<T> & {
    accept?: string[];
    dragContextId?: string;
    draggable?: boolean;
    droppable?: boolean;
    getRecord?: (props: Props) => any;
    index: number;
    supportedDropModes?: DropMode[];
    onDrop?: (dragItem: any, dropItem: any) => void;
    type?: string;
    dragItemProps?: ((params: { record: T; index: number }) => any) | AnyObject;
    record?: T;
    onDragStart?: (e) => void;
    columns: TableColumn[];
};

const defaultProps = {
    accept: [],
    dragItemProps: {},
    draggable: true,
    droppable: true,
    getRecord: (props) => props.record,
};

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const DraggableRow = <T extends any>(
    props: Props<T> & { forwardedRef: React.MutableRefObject<HTMLTableRowElement> },
) => {
    const propsWithDefaults = { ...defaultProps, ...props };
    const {
        accept,
        dragContextId,
        draggable,
        droppable,
        forwardedRef,
        getRecord,
        index,
        supportedDropModes,
        onDrop = noop,
        type = TYPES.TABLE_ROW,
    } = propsWithDefaults;

    const record = getRecord(props);

    const dragItemProps =
        typeof props.dragItemProps === 'function'
            ? props.dragItemProps({ record, index })
            : props.dragItemProps;

    const dragItem = {
        ...(dragItemProps || {}),
        dragContextId,
        record,
        index,
        type: TYPES.TABLE_ROW,
    };

    const [{ dropMode }, drop] = useDropItem<{
        dragContextId: Props['dragContextId'];
        record?: T;
        index: number;
        type: typeof TYPES.TABLE_ROW;
    }>({
        ref: forwardedRef,
        accept: [TYPES.TABLE_ROW, ...(accept || [])],
        item: dragItem,
        supportedDropModes,
        onDrop,
    });

    const [, drag] = useDrag({
        type,
        item: dragItem,
    });

    return (
        <StyledDraggableRow
            {...propsWithDefaults}
            ref={(domNode) => {
                updateRef(forwardedRef, domNode);

                if (draggable) {
                    drag(domNode);
                }

                if (droppable) {
                    drop(domNode);
                }
            }}
            dropMode={dropMode}
        />
    );
};

const DraggableRowWithForwardedRef = React.forwardRef<HTMLTableRowElement, Props>((props, ref) => {
    const fallbackRef = useRef<HTMLTableRowElement>(null);
    const forwardedRef = ref ?? fallbackRef;

    return (
        <DraggableRow
            {...props}
            forwardedRef={forwardedRef as React.MutableRefObject<HTMLTableRowElement>}
        />
    );
});

DraggableRowWithForwardedRef.displayName = 'DraggableRow';

export default DraggableRowWithForwardedRef;
