import { z } from 'zod';

import { Entity, RichText, UnityRichText } from '../../utils/schemas/schemas';
import { unityVariantsShape, variantsShape } from '../../utils/schemas/utils';
import {
    EmbeddedMetadata,
    Metadata,
    UnityEmbeddedMetadata,
    UnityMetadata,
} from '../metadata/schemas';

const BreakingNewsVariantBase = z.object({
    titleHeader: z.string().nullable(),
    title: z.string(),
    allowAutomaticTeaserUpdate: z.boolean().optional(),
});

const UnityBreakingNewsVariants = unityVariantsShape(
    BreakingNewsVariantBase.extend({
        lead: UnityRichText,
        body: UnityRichText,
    }),
);

const BreakingNewsVariants = variantsShape(
    BreakingNewsVariantBase.extend({
        lead: RichText,
        body: RichText,
    }),
);

const BreakingNewsBase = Entity.extend({
    id: z.string().uuid(),
    metadataId: z.number(),
    notificationCategories: z.unknown().optional(),
    notificationMessage: z.string().optional(),
});

export const UnityBreakingNews = BreakingNewsBase.extend({
    variants: UnityBreakingNewsVariants,
    metadata: UnityEmbeddedMetadata.optional(),
});

export type UnityBreakingNews = z.infer<typeof UnityBreakingNews>;

export const BreakingNews = BreakingNewsBase.extend({
    variants: BreakingNewsVariants,
    metadata: EmbeddedMetadata.optional(),
});

export type BreakingNews = z.infer<typeof BreakingNews>;

export const NewBreakingNews = BreakingNews.pick({
    variants: true,
    notificationCategories: true,
    notificationMessage: true,
}).extend({
    metadata: Metadata.pick({ tenantIds: true, mainCategoryId: true }).extend({
        categoryIds: z.array(z.number()),
    }),
});

export type NewBreakingNews = z.infer<typeof NewBreakingNews>;

export const NewUnityBreakingNews = UnityBreakingNews.pick({
    variants: true,
    notificationCategories: true,
    notificationMessage: true,
}).extend({
    metadata: UnityMetadata.pick({ tenantIds: true, mainCategoryId: true }).extend({
        categoryIds: z.array(z.number()),
    }),
});
export type NewUnityBreakingNews = z.infer<typeof NewUnityBreakingNews>;
