import isPropValid from '@emotion/is-prop-valid';
import { pickBy } from 'lodash';
import React from 'react';

import { LinkBehavior } from '@@themes/mui/components';

const createElementWithoutInvalidProps = <T extends HTMLElement>(Component: React.ElementType) =>
    React.forwardRef<T>((props, ref) => {
        const validProps = pickBy(props, (value, key) => isPropValid(key));

        return <Component ref={ref} {...validProps} />;
    });

export const DivWithoutInvalidProps = createElementWithoutInvalidProps<HTMLDivElement>('div');

export const LinkBehaviorWithoutInvalidProps =
    createElementWithoutInvalidProps<HTMLAnchorElement>(LinkBehavior);

export const HrWithoutInvalidProps = createElementWithoutInvalidProps<HTMLHRElement>('hr');
