import { useCallback } from 'react';

import { getUserRoleSmartSetting } from '@@settings/settingsSlice';
import { useSelector } from '@@store/hooks';

import { ROLES, type UserRole } from '../constants';

const useAuthorization = () => {
    const currentUserRole = useSelector(getUserRoleSmartSetting);

    const isAuthorized = useCallback(
        (userRoles: UserRole[] | undefined) =>
            !userRoles || userRoles.includes(currentUserRole) || userRoles.includes(ROLES.EDITOR),
        [currentUserRole],
    );

    // check role authorization, authorize everyone if no authorize is supplied
    return { isAuthorized };
};

export default useAuthorization;
