import { type ApiFetcherArgs } from '@ts-rest/core';
import { type z } from 'zod';

const DATADOG_CHAR_LIMIT = 220000;

export class FetcherValidationError extends Error {
    constructor(
        public type: 'Body' | 'Query' | 'Headers' | 'Response',
        public args: ApiFetcherArgs,
        public cause: z.ZodError,
        public initialData?: unknown,
    ) {
        super('API client error');
        this.name = this.constructor.name;
        this.logError();
    }

    private logError() {
        const { method, path, route } = this.args;

        // Keep the alignment of the template string as-is
        const message = `[fetcher] ${this.type} validation failed:
Method: ${method}
Route: ${route.path}
URL: ${path}
Error: ${this.cause.message}

Data provided:
${this.initialData ? JSON.stringify(this.initialData, null, 2) : 'None'}
        `;

        if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'prod') {
            console.error(message.substring(0, DATADOG_CHAR_LIMIT));
        } else {
            console.error(message);
        }
    }
}
