import { TableRow } from '@mui/material';
import React from 'react';
import ReactIs from 'react-is';

import DataField from './DataField';

type RenderFieldProps = {
    key: number;
    column: TableColumn;
    value: 'loading';
};

type Props = {
    columns: TableColumn[];
    as?: React.ElementType;
    className?: string;
    renderField?: (props: RenderFieldProps) => React.ReactNode;
    style?: React.CSSProperties;
};

const RowLoading = (props: Props) => {
    const { as, className, columns, style } = props;

    const renderField = (column: TableColumn, i: number) => {
        const loadingColumn = column.type !== 'actions' ? { ...column, type: 'loading' } : column;

        const fieldProps: RenderFieldProps = {
            key: i,
            column: loadingColumn,
            value: 'loading',
        };

        if (props.renderField) {
            if (ReactIs.isValidElementType(props.renderField)) {
                const Component = props.renderField as React.ComponentType<RenderFieldProps>;

                return <Component {...fieldProps} />;
            }

            return props.renderField(fieldProps);
        }

        return <DataField {...fieldProps} />;
    };

    return <TableRow {...{ as, className, style }}>{columns.map(renderField)}</TableRow>;
};

export default RowLoading;
