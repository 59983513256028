import { styled } from '@mui/material';
import React from 'react';

import ResponsiveIFrame from '@@components/ResponsiveIFrame';
import { type Editor } from '@@editor/helpers';
import { type EmbeddedIframeElement } from '@@editor/helpers/Element';

import EmbedComponentLoader from './EmbedComponentLoader';

const Wrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(2),
    backgroundColor: theme.fixed.editor.embed.backgroundColor,
}));

type Props = {
    editor: Editor;
    element: EmbeddedIframeElement;
    omitSandbox?: boolean;
};

const EmbeddedIframe = ({ element, omitSandbox, editor }: Props) => {
    const { src } = element.data;

    return (
        <EmbedComponentLoader editor={editor} element={element}>
            {({ onLoad }) => (
                <Wrapper>
                    <ResponsiveIFrame
                        src={typeof src === 'string' ? src : src?.toString()}
                        allowFullScreen
                        omitSandbox={omitSandbox}
                        onLoad={onLoad}
                    />
                </Wrapper>
            )}
        </EmbedComponentLoader>
    );
};

export default EmbeddedIframe;
