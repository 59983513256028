import { type ClientInferResponses } from '@ts-rest/core';

import { type OnUploadProgress } from '@@api/fetcher';
import { type UploadFilePromiseResponse } from '@@api/hooks/useUploadFile';
import { type FilerepoRouter } from '@@api/services/filerepo/client';
import { validateImageFile, type ValidationError } from '@@form/utils/validators/image';
import Deferred from '@@utils/Deferred';

type UploadProgressEvent = {
    progress: number;
    totalSize: number;
    totalLoaded: number;
    filesPending: number;
};

type UploadFileOptions = {
    onUploadProgress: OnUploadProgress;
};

type UploadFileFunction = (file: File, options: UploadFileOptions) => UploadFilePromiseResponse;

export type MultiFileUploadHelperOptions = {
    uploadFile: UploadFileFunction;
    onProgress?: (event: UploadProgressEvent) => void;
    onSuccess?: (
        payload: ClientInferResponses<
            FilerepoRouter['files']['postFile'] | FilerepoRouter['files']['postUrl']
        >,
    ) => void;
    onFailure?: (errors: ValidationError[]) => void;
    onComplete?: (results: any[]) => void;
};

type FileStatus = 'pending' | 'succeeded' | 'failed';

type FileWrapper = {
    file: File;
    deferred: Deferred<any>;
    loaded: number | undefined;
    size: number | undefined;
    status: FileStatus;
};

const noop = () => {};

class MultiFileUploadHelper {
    isUploading = false;
    files: FileWrapper[] = [];
    options: Required<MultiFileUploadHelperOptions>;

    constructor(options: Partial<MultiFileUploadHelperOptions> = {}) {
        this.options = {
            uploadFile: () => {
                throw new Error('uploadFile function is required');
            },
            onProgress: noop,
            onSuccess: noop,
            onFailure: noop,
            onComplete: noop,
            ...options,
        };
    }

    clearFiles() {
        if (this.isUploading) {
            return;
        }

        this.files.length = 0;
    }

    initializeFiles(files: File[]) {
        if (this.isUploading) {
            return;
        }

        files.forEach((file) => {
            const size = file instanceof File ? file.size : 0;

            this.files.push({
                file,
                deferred: new Deferred<any>(),
                loaded: 0,
                size,
                status: 'pending',
            });
        });
    }

    get promises() {
        return this.files.map((file) => file.deferred.promise);
    }

    get totalSize() {
        return this.files.reduce((previousValue, file) => previousValue + (file.size || 0), 0);
    }

    get totalLoaded() {
        return this.files.reduce((previousValue, file) => previousValue + (file.loaded || 0), 0);
    }

    get filesPending() {
        return this.files.filter((file) => file.status === 'pending').length;
    }

    get progress() {
        return this.totalSize === 0 ? 0 : (100 * this.totalLoaded) / this.totalSize;
    }

    upload(files: File[]) {
        return new Promise((resolve, reject) => {
            if (this.isUploading) {
                reject(new Error('You cannot start an upload while already uploading'));

                return;
            }

            this.initializeFiles(files);
            this.isUploading = true;

            Promise.allSettled(this.promises).then((results) => {
                const flattenedResults = results.map((result) =>
                    result.status === 'fulfilled' ? result.value : result.reason,
                );

                this.isUploading = false;
                this.clearFiles();
                this.options.onComplete(flattenedResults);
                resolve(flattenedResults);
            });

            this.files.forEach(async ({ file, deferred }, index) => {
                const errors = await validateImageFile(file);

                if (errors.length > 0) {
                    this.options.onFailure(errors);
                    deferred.reject(errors);
                } else {
                    this.options
                        .uploadFile(file, {
                            onUploadProgress: (event) => {
                                this.files[index].loaded = event.loaded;
                                this.files[index].size = event.total;

                                this.options.onProgress({
                                    progress: this.progress,
                                    totalSize: this.totalSize,
                                    totalLoaded: this.totalLoaded,
                                    filesPending: this.filesPending,
                                });
                            },
                        })
                        .then((payload) => {
                            this.files[index].loaded = this.files[index].size;
                            this.files[index].status = 'succeeded';

                            this.options.onProgress({
                                progress: this.progress,
                                totalSize: this.totalSize,
                                totalLoaded: this.totalLoaded,
                                filesPending: this.filesPending,
                            });

                            this.options.onSuccess(payload);
                            deferred.resolve(payload);
                        })
                        .catch((error) => {
                            this.files[index].status = 'failed';

                            this.options.onProgress({
                                progress: this.progress,
                                totalSize: this.totalSize,
                                totalLoaded: this.totalLoaded,
                                filesPending: this.filesPending,
                            });

                            this.options.onFailure(error);
                            deferred.reject(error);
                        });
                }
            });
        });
    }
}

export default MultiFileUploadHelper;
