import { styled } from '@mui/material';
import { type TFunction } from 'i18next';
import React, { useLayoutEffect, useRef, useState } from 'react';

// import { LinkData } from '@@editor/helpers/Editor';
import { type Metadata } from '@@api/services/metadata/schemas';
import ReactHookForm from '@@containers/ReactHookForm/ReactHookForm';
import { type UseReactHookFormProps } from '@@containers/ReactHookForm/types';
import RadioGroup from '@@form/components/RadioGroup';
import RelatedContentField from '@@form/fields/RelatedContent';
import TextInputField from '@@form/fields/TextInput';
import UrlField from '@@form/fields/Url';

const StyledForm = styled(ReactHookForm)({
    width: '400px',
});

type Props = {
    selectedLink: string;
    selectedText: string;
    t: TFunction;
    onCancel: VoidFunction;
    onSubmit: UseReactHookFormProps['onSubmit']; // (data: LinkData) => void;
    selectedMetadataId: Metadata['id'] | null;
};

const LinkForm = ({
    selectedLink,
    selectedText,
    onSubmit,
    onCancel,
    selectedMetadataId,
    t,
}: Props) => {
    const [isInternalLink, setIsInternalLink] = useState(
        Boolean(selectedMetadataId) || selectedLink.length === 0,
    );

    const linkFieldRef = useRef<HTMLInputElement | null>(null);

    useLayoutEffect(() => {
        // Needs a timeout because the ref is not defined directly after the component mounted
        setTimeout(() => {
            if (linkFieldRef.current) {
                const input = linkFieldRef.current.querySelector('input');

                input?.select();
            }
        });
    }, [linkFieldRef]);

    const handleOnChange = () => {
        setIsInternalLink((isInternalLink) => !isInternalLink);
    };

    const handleSubmit: UseReactHookFormProps['onSubmit'] = (values, form, options) => {
        const newValues = {
            ...values,
            link: isInternalLink ? '' : values.link,
            metadataId: isInternalLink ? values.metadataId : null,
        };

        return onSubmit(newValues, form, options);
    };

    return (
        <StyledForm
            formName="LinkForm"
            onSubmit={handleSubmit}
            onCancel={onCancel}
            values={{
                text: selectedText,
                link: selectedLink,
                metadataId: selectedMetadataId,
            }}
            hasActiveCancelButton
            alwaysShowCancelButton
        >
            <RadioGroup
                row
                required
                onChange={handleOnChange}
                value={isInternalLink ? 'true' : 'false'}
                fields={[
                    {
                        value: 'true',
                        label: t('editor.insert.link.modal.internallink'),
                    },
                    {
                        value: 'false',
                        label: t('editor.insert.link.modal.externallink'),
                    },
                ]}
            />

            <TextInputField name="text" autoComplete="off" label="Link Text" required />

            {isInternalLink ? (
                <RelatedContentField
                    allowBundle={false}
                    name="metadataId"
                    useModal
                    modalProps={{
                        modalTitle: t('relatedcontent.buttonLabel'),
                    }}
                    label={t('sitemap.form.contentReference')}
                    required
                />
            ) : (
                <UrlField
                    inputRef={linkFieldRef}
                    name="link"
                    placeholder={t('editor.insert.link.modal.link.placeholder')}
                    autoComplete="off"
                    label="Link URL"
                    allowInsecure
                    allowMailto
                    required
                />
            )}
        </StyledForm>
    );
};

export default LinkForm;
