import { styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Element, Node, ReactEditor } from '@@editor/helpers';
import { getInputPlaceholderStyle } from '@@editor/plugins/serializable/styles';

type StyledCreditElementProps = {
    $templateElement?: boolean;
    $placeholderText: string;
};

export const StyledCreditElement = styled('div')<StyledCreditElementProps>(
    ({ $templateElement, $placeholderText, theme }) => ({
        padding: '0 !important',
        backgroundColor: 'transparent',
        color: theme.palette.primary.dark,
        ...theme.typography.editorSubtitleXsmall,
        ...($templateElement &&
            getInputPlaceholderStyle({ theme, placeholderText: $placeholderText })),
    }),
);

const CreditElement = (props) => {
    const { attributes, children, editor, element } = props;
    const { t } = useTranslation();
    const path = ReactEditor.findPath(editor, element);
    const parentNode = Node.parent(editor, path);

    return (
        <StyledCreditElement
            {...attributes}
            $templateElement={
                Element.isTemplateElement(parentNode) || Element.isEmptyEmbedCreditElement(element)
            }
            $placeholderText={t('image.credit')}
        >
            {children}
        </StyledCreditElement>
    );
};

export default CreditElement;
