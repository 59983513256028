/* eslint-disable no-console */
import { type ApiFetcherArgs, type AppRoute, checkZodSchema, isZodType } from '@ts-rest/core';
import axios, {
    type AxiosError,
    type AxiosProgressEvent,
    type AxiosResponse,
    isAxiosError,
} from 'axios';
import { omit } from 'lodash';
import invariant from 'tiny-invariant';
import { ZodError } from 'zod';

import { HTTP_STATUS_CODES, type HttpMethod } from '@@constants/http';
import { type RootState } from '@@scripts/store/store';
import { addDatadogError } from '@@scripts/utils/dataDog';
import { extractETag } from '@@scripts/utils/eTagUtils';

import { FetcherValidationError } from './FetcherValidationError';
import { defaultHeaders, fileUploadHeaders, loginHeaders, patchDefaultHeaders } from './headers';
import { etagCache } from './queryClient';

export type OnUploadProgress = (event: AxiosProgressEvent) => void;

type ResultData = {
    resultBody: ReturnType<typeof checkZodSchema>;
    resultQuery: ReturnType<typeof checkZodSchema>;
    resultHeaders: ReturnType<typeof checkZodSchema>;
    resultResponse: Awaited<ReturnType<typeof axios.request>>;
};
type LogType = 'Body' | 'Query' | 'Headers' | 'Response' | 'Error';

type Method = Uppercase<HttpMethod>;

const isDebugEnabled = {
    Body: true,
    Query: true,
    Headers: true,
    Response: true,
    Error: true,
    All: false,
};

const log = (type: LogType, ...logArgs: unknown[]) => {
    if (isDebugEnabled.All === false || isDebugEnabled[type] === false) {
        return;
    }

    console.groupCollapsed(type);

    if (type === 'Error') {
        console.error(...logArgs);
    } else if (type === 'Response') {
        console.log(...logArgs);
    } else {
        console.log('Raw value:', logArgs[0]);
        console.log('Validated value:', logArgs[1]);
    }

    console.groupEnd();

    return;
};

const logRequest = (args: ApiFetcherArgs & ResultData) => {
    if (isDebugEnabled.All === false || !Object.values(isDebugEnabled).some(Boolean)) {
        return;
    }

    const {
        method,
        path,
        rawBody,
        rawQuery,
        headers,
        resultBody,
        resultQuery,
        resultHeaders,
        resultResponse,
        route,
    } = args;

    console.groupCollapsed(`[${method} ${route.path}]`);
    console.log(`Url: ${path}`);

    log('Body', rawBody, resultBody);
    log('Query', rawQuery, resultQuery);
    log('Headers', headers, resultHeaders);
    log('Response', resultResponse);

    console.groupEnd();
};

const getRouteMethod = (route: AppRoute): Method | undefined => {
    if (route.metadata && typeof route.metadata === 'object' && 'method' in route.metadata) {
        return route.metadata.method as Method;
    }
};

const getHeaders = (route: AppRoute, state: RootState, args: ApiFetcherArgs) => {
    if (route.method === 'PATCH') {
        return {
            ...patchDefaultHeaders(state),
            ...args.headers,
        };
    }

    if (route.method === 'POST' && route.contentType === 'multipart/form-data') {
        return {
            ...fileUploadHeaders(state),
        };
    }

    if (route.method === 'POST' && route.contentType === 'application/x-www-form-urlencoded') {
        return {
            ...loginHeaders(state),
        };
    }

    return {
        ...defaultHeaders(state),
        ...args.headers,
    };
};

const processHeaders = (method: Uppercase<HttpMethod>, headers: unknown, args: ApiFetcherArgs) => {
    if (typeof headers !== 'object' || headers === null) {
        return {} as Record<string, string>;
    }

    let processedHeaders = headers;

    // When using the Fetch API, if the body is a FormData object, the browser will
    // automatically set the correct content-type header for us. If we try to set it
    // ourselves, the browser will throw an error.
    if ('content-type' in headers && headers['content-type'] === 'multipart/form-data') {
        processedHeaders = omit(headers, ['content-type']);
    }

    if (etagCache.has(args.path)) {
        processedHeaders = {
            ...processedHeaders,
            'if-match': etagCache.get(args.path),
        };
    }

    // We also want to remove the etag header for all requests except GET requests
    // Since headers are part of the cache, we need to remove it before reusing the data
    if (method !== 'GET') {
        processedHeaders = omit(processedHeaders, ['etag']);
    }

    return processedHeaders as Record<string, string>;
};

// We don't want to JSON.stringify FormData or URLSearchParams objects
// as they need to be processed correctly by the fetch API
const processBody = (body: unknown) => {
    if (body instanceof FormData || body instanceof URLSearchParams || typeof body === 'string') {
        return body;
    }

    return JSON.stringify(body);
};

type Validations = {
    resultBody: ReturnType<typeof checkZodSchema>;
    resultQuery: ReturnType<typeof checkZodSchema>;
    resultHeaders: ReturnType<typeof checkZodSchema>;
};

const handleResponse = async (
    requestValidations: Validations,
    result: AxiosResponse | undefined,
    args: AugmentedApiFetcherArgs,
) => {
    if (!result) {
        return {
            headers: new Headers(),
            status: HTTP_STATUS_CODES.NO_RESPONSE,
            body: { message: 'Fetcher Error: No response', path: args.path },
        };
    }
    const { resultBody, resultQuery, resultHeaders } = requestValidations;

    const headers = new Headers(result.headers as Record<string, string>);

    const etagHeader = headers.get('etag');

    if (etagHeader) {
        etagCache.set(args.path, extractETag(etagHeader));
    }

    if (
        result.config.method?.toUpperCase() === 'HEAD' &&
        result.status === HTTP_STATUS_CODES.NOT_FOUND
    ) {
        return { ...result, body: { message: 'tag.form.asyncvalidate.reject' }, headers };
    }

    const contentType = headers.get('content-type') as string;

    if (contentType?.includes('application/') && contentType?.includes('json')) {
        const response = {
            status: result.status,
            body: result.data,
            headers,
        };

        const responseSchema = args.route.responses[response.status];

        if (
            (args.validateResponse ?? args.route.validateResponseOnClient) &&
            isZodType(responseSchema)
        ) {
            try {
                const resultResponse = responseSchema.parse(response.body);

                logRequest({ ...args, resultBody, resultQuery, resultHeaders, resultResponse });

                return {
                    ...response,
                    headers,
                    body: resultResponse,
                };
            } catch (error: unknown) {
                if (error instanceof ZodError) {
                    const errorInstance = new FetcherValidationError(
                        'Response',
                        args,
                        error,
                        response.body,
                    );

                    addDatadogError(errorInstance);

                    throw errorInstance;
                }
            }
        }

        return response;
    }

    if (contentType?.includes('text/')) {
        logRequest({
            ...args,
            resultBody,
            resultQuery,
            resultHeaders,
            resultResponse: result.data,
        });

        return {
            status: result.status,
            body: await result.data,
            headers,
        };
    }

    const data = await result.data;

    logRequest({ ...args, resultBody, resultQuery, resultHeaders, resultResponse: data });

    return {
        status: result.status,
        body: data,
        headers,
    };
};

type AugmentedApiFetcherArgs = ApiFetcherArgs & {
    onUploadProgress?: OnUploadProgress;
};

type ApiFetcher = (state: RootState) => (args: AugmentedApiFetcherArgs) => Promise<{
    status: number;
    body: unknown;
    headers: Headers;
}>;

export const apiFetcher: ApiFetcher = (state) => async (args) => {
    const { route, rawBody, rawQuery, validateResponse, onUploadProgress } = args;

    invariant(
        validateResponse === true,
        'validateResponse must be true as it enables serialization and deserialization',
    );

    // Headers cannot be put in the client config, because
    // they are dynamic, and the client configs are defined once and cached.
    const headers = getHeaders(route, state, args);

    const resultBody = checkZodSchema(rawBody, 'body' in route ? route.body : null);

    if (!resultBody.success) {
        const errorInstance = new FetcherValidationError('Body', args, resultBody.error, rawBody);

        addDatadogError(errorInstance);

        throw errorInstance;
    }

    const resultQuery = checkZodSchema(rawQuery || {}, route.query ?? null);

    if (!resultQuery.success) {
        const errorInstance = new FetcherValidationError(
            'Query',
            args,
            resultQuery.error,
            rawQuery || {},
        );

        addDatadogError(errorInstance);

        throw errorInstance;
    }

    const resultHeaders = checkZodSchema(headers, route.headers ?? null);

    if (!resultHeaders.success) {
        const errorInstance = new FetcherValidationError(
            'Headers',
            args,
            resultHeaders.error,
            headers,
        );

        addDatadogError(errorInstance);

        throw errorInstance;
    }

    const method = getRouteMethod(route) || route.method;

    const processedHeaders = processHeaders(method, resultHeaders.data, args);
    const processedBody = processBody(resultBody.data);

    try {
        const result = await axios.request({
            url: args.path,
            method,
            headers: processedHeaders,
            data: processedBody,
            onUploadProgress,
        });

        return handleResponse({ resultBody, resultQuery, resultHeaders }, result, args);
    } catch (error: Error | AxiosError | unknown) {
        log('Error', error);

        if (isAxiosError(error)) {
            const axiosError = error as AxiosError;
            const response = axiosError.response;

            return handleResponse({ resultBody, resultQuery, resultHeaders }, response, args);
        }

        return {
            headers: new Headers(),
            status: HTTP_STATUS_CODES.BAD_REQUEST,
            body: { message: `Fetcher Error: ${error?.toString?.() || error}` },
        };
    }
};
