import { styled } from '@mui/material';
import { omit } from 'lodash';
import React from 'react';

import ReactHookForm from '@@containers/ReactHookForm/ReactHookForm';
import { type UseReactHookFormProps } from '@@containers/ReactHookForm/types';
import { type Editor, type Element } from '@@editor/helpers';
import { type UnityState } from '@@editor/typings/UnityElements';
import makeState from '@@editor/utils/makeState';

import PollFields from './PollFields';

type FormValues = {
    question: UnityState;
    answers: UnityState[];
};

type Props = {
    element: Element;
    editor: Editor;
    className?: string;
    renderButtons: (...args: any) => JSX.Element;
    onCancel: VoidFunction;
    onSubmit: UseReactHookFormProps['onSubmit'];
};

export const QuestionAnswersForm = ({ element, ...rest }: Props) => {
    // @ts-expect-error
    const initialValues: FormValues = element?.data || {
        question: makeState(),
        answers: [makeState()],
    };

    return (
        <ReactHookForm
            formName="QuestionAnswersForm"
            values={initialValues}
            hasCancelButton
            hasActiveCancelButton
            alwaysShowCancelButton
            {...omit(rest, ['editor'])}
        >
            <PollFields />
        </ReactHookForm>
    );
};

export default styled(QuestionAnswersForm)({
    width: '640px',
});
