import { flow, omit } from 'lodash';

import {
    type Article,
    type DetailedRevision,
    type DetailedUnityRevision,
    isArticle,
    type Revision,
    type RevisionData,
    type UnityArticle,
} from '@@api/services/content/schemas/article';
import deserializeState from '@@editor/serialization/deserializeState';
import serializeState from '@@editor/serialization/serializeState';
import transformValues from '@@editor/serialization/transformValues';
import unwrapSerializedState from '@@editor/serialization/UnitySerializer/unwrapSerializedState';
import wrapSerializedState from '@@editor/serialization/UnitySerializer/wrapSerializedState';
import { logDuplicateEvent, removeDuplicates } from '@@editor/utils/removeDuplicates';

import {
    getVariantsFieldsFromContent,
    removeUnwantedElements,
    transformToArticleVariants,
} from './utils/deserialization';
import { transformToArticleContent } from './utils/serialization';

const deserializeContentState = (state) => deserializeState(state, { useInlineEditing: true });
const serializeContentState = (state) => serializeState(state, { useInlineEditing: true });

const transformVariants = flow(getVariantsFieldsFromContent, transformToArticleVariants);
const contentTransformations = [
    [
        'content',
        flow(
            unwrapSerializedState,
            removeUnwantedElements,
            wrapSerializedState,
            deserializeContentState,
        ),
    ],
];

export const deserializeArticle = (entity: UnityArticle): Article =>
    // If the article is a Unity article, we need to get the variants out of the content
    entity.contentOwner === 'UNITY'
        ? transformValues(
              { ...entity, variants: transformVariants(entity) },
              contentTransformations,
          )
        : // Else we just transform the content
          transformValues<typeof entity, Article>(entity, [['content', deserializeContentState]]);

export const serializeArticle = <T = Article>(entity: T): UnityArticle | T => {
    // When a article is cloned, we will have no data in the body except metadata. So only convert
    // the content if it already exists on the entity. We need create a futureproof solution here...
    if (isArticle(entity)) {
        const newContent = removeDuplicates(transformToArticleContent(entity));
        const newEntity = { ...omit(entity, ['variants']), content: newContent.filteredValue };

        logDuplicateEvent(newContent.isModified, {
            values: newContent.originalValue,
            articleId: entity.id,
        });

        return transformValues<typeof newEntity, UnityArticle>(newEntity, [
            ['content', serializeContentState],
        ]);
    }

    return entity;
};

export const deserializeRevision = (entity: Revision): Revision => entity;

export const deserializeDetailedRevision = (entity: DetailedUnityRevision): DetailedRevision => {
    const revisionData = {
        ...entity.revisionData,
        variants: transformVariants(entity.revisionData),
    };

    const deserializedRevisionData = transformValues<typeof revisionData, RevisionData>(
        revisionData,
        contentTransformations,
    );

    return {
        ...entity,
        revisionData: deserializedRevisionData,
    };
};
