import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Table, { type Props as TableProps } from '@@components/Table';
import { DropMode } from '@@hooks/useDropItem';
import crypto from '@@utils/crypto';

import { type Props as DraggableRowProps } from './Table/DraggableRow';

type Props<T> = Pick<
    DraggableRowProps<T>,
    'accept' | 'dragItemProps' | 'onDrop' | 'onClickFieldAction'
> &
    Pick<TableProps<T>, 'data' | 'withHeader' | 'onEndReached' | 'className' | 'columns'>;

const DndTable = <T,>(props: Props<T>) => {
    const {
        accept,
        className,
        columns,
        data,
        dragItemProps,
        withHeader,
        onEndReached,
        onClickFieldAction,
        onDrop,
    } = props;
    const { t } = useTranslation();
    const [dragContextId] = useState(() => crypto.randomUUID());

    const renderEmptyRow = useCallback(
        (columns) => (
            <Table.DraggableRow
                {...{ columns, accept, onDrop }}
                as={Table.EmptyRow}
                index={0}
                draggable={false}
                supportedDropModes={[DropMode.REPLACE]}
            >
                {t('list.noresult')}
            </Table.DraggableRow>
        ),
        [accept, t, onDrop],
    );

    const renderRow = useCallback(
        (columns, { data: record, index }) => {
            if (
                record == null ||
                (typeof record.id === 'string' && record.id.startsWith('loading'))
            ) {
                return <Table.RowLoading key={index} columns={columns} />;
            }

            // key = should be unique, otherwise there are problems with slate
            return (
                <Table.DraggableRow
                    {...{
                        columns,
                        record,
                        index,
                        accept,
                        dragItemProps,
                        onDrop,
                        onClickFieldAction,
                    }}
                    key={index}
                    dragContextId={dragContextId}
                />
            );
        },
        [accept, dragContextId, dragItemProps, onDrop, onClickFieldAction],
    );

    return (
        <Table
            {...{
                className,
                data,
                renderEmptyRow,
                renderRow,
                columns,
                withHeader,
                onEndReached,
            }}
        />
    );
};

export default DndTable;
