import { styled } from '@mui/material';
import React from 'react';

import ReactHookForm from '@@containers/ReactHookForm/ReactHookForm';
import { type UseReactHookFormProps } from '@@containers/ReactHookForm/types';
import { type Element } from '@@editor/helpers';
import { type UnityState } from '@@editor/typings/UnityElements';
import makeState from '@@editor/utils/makeState';

import { DEFAULT_CONTAINER_TYPE, DEFAULT_INFOBOX_COLLAPSED_VALUE } from '../constants';
import InfoboxFields from './InfoboxFields';

interface IFormValues {
    style: string | null;
    title: UnityState | null;
    content: UnityState | null;
}

interface IProps {
    type: string;
    element: Element;
    inlineEdited?: boolean;
    className?: string;
    renderButtons: () => React.ReactNode;
    onCancel: VoidFunction;
    onSubmit: UseReactHookFormProps['onSubmit'];
}

const InfoboxForm = (props: IProps) => {
    const { element, inlineEdited, ...rest } = props;

    // @ts-expect-error
    const initialValues: IFormValues = element?.data || {
        title: makeState(),
        content: makeState(),
        collapsed: DEFAULT_INFOBOX_COLLAPSED_VALUE,
        style: { containerType: DEFAULT_CONTAINER_TYPE },
    };

    return (
        <ReactHookForm
            formName="LayoutInfobox"
            values={initialValues}
            hasCancelButton
            hasActiveCancelButton
            alwaysShowCancelButton
            persist={false}
            {...rest}
        >
            <InfoboxFields inlineEdited={inlineEdited} />
        </ReactHookForm>
    );
};

const ComposedInfoboxForm = styled(InfoboxForm)({
    width: '640px',
});

export default ComposedInfoboxForm;
