import { type Editor } from '@@editor/helpers';
import { PLUGIN_NAMES } from '@@editor/typings/UnityPlugins';

type EditorContextPluginData = {
    isLoading?: boolean;
    isPartiallyLoading?: boolean;
    isError?: boolean;
    errorCode?: string;
    isReadOnly?: boolean;
    isEnabled?: boolean;
    isDistractionFree?: boolean;
    spellAdvicesCount?: number;
};

type PluginData = EditorContextPluginData;
type SetPluginData = Partial<PluginData>;
type SetEditorContextPluginData = Partial<EditorContextPluginData>;

export const getPluginData = <K extends keyof PluginData>(editor: Editor, key: K): PluginData[K] =>
    editor.getDataIn(([PLUGIN_NAMES.SPELL_CHECKER] as string[]).concat(key));

export const setPluginData = (editor: Editor, data: SetPluginData, shouldForceUpdate = false) => {
    editor.setData({ [PLUGIN_NAMES.SPELL_CHECKER]: data }, shouldForceUpdate);
};

export const setEditorContextPluginData = (editor: Editor, data: SetEditorContextPluginData) => {
    editor.setEditorContextData({ [PLUGIN_NAMES.SPELL_CHECKER]: data });
};

export const isEditorDistractionFree = (editor: Editor): boolean =>
    Boolean(getPluginData(editor, 'isDistractionFree'));
