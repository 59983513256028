import { type Editor } from '@@editor/helpers';
import { PLUGIN_NAMES } from '@@editor/typings/UnityPlugins';

import { type HeadingTeasersPluginData, type HeadingTeasersState } from './typings';

type SetEditorContextPluginData = Partial<HeadingTeasersPluginData>;

export const setPluginData = (
    editor: Editor,
    data: SetEditorContextPluginData,
    shouldForceUpdate = false,
) => {
    editor.setData({ [PLUGIN_NAMES.TEASER_GENERATOR]: data }, shouldForceUpdate);
};

export const getEditorContextPluginData = <K extends keyof SetEditorContextPluginData>(
    editor: Editor,
    key: K,
): SetEditorContextPluginData[K] =>
    editor.getDataIn(([PLUGIN_NAMES.TEASER_GENERATOR] as string[]).concat(key));

export const setEditorContextPluginData = (editor: Editor, data: SetEditorContextPluginData) => {
    editor.setEditorContextData({ [PLUGIN_NAMES.TEASER_GENERATOR]: data });
};

export const getPluginState = (editor: Editor): string =>
    getEditorContextPluginData(editor, 'state') || 'disabled';
export const setPluginState = (editor: Editor, state: HeadingTeasersState, data?) =>
    setEditorContextPluginData(editor, { state, data });

export const getPluginData = (editor: Editor) =>
    getEditorContextPluginData(editor, 'data') || undefined;
