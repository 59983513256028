import { Transforms } from 'slate';

import { DEFAULT_BLOCK } from '@@editor/constants';
import { Editor } from '@@editor/helpers';
import {
    type CrossheadElement,
    type CrossheadStyle,
    ELEMENT_TYPES,
} from '@@editor/helpers/Element';
import renderElement from '@@editor/plugins/utils/renderElement';
import { type PluginOptions } from '@@editor/typings/UnityPlugins';

import { CROSSHEAD_STYLES, EDITOR_TO_ORDERED_LISTICLES } from './constants';
import { generateCrossheadData, getCrossheadStyle } from './utils';
import TextBlock from '../components/TextBlock';

const isParagraphActive = (editor: Editor) =>
    Editor.isElementActive(editor, ELEMENT_TYPES.PARAGRAPH);

const isFooterActive = (editor: Editor) => Editor.isElementActive(editor, ELEMENT_TYPES.FOOTER);

const isCrossheadActive = (editor: Editor, style?: CrossheadStyle) =>
    Editor.isElementActive(editor, ELEMENT_TYPES.CROSSHEAD, {
        match: (node) => typeof style === 'undefined' || style === getCrossheadStyle(node),
    });

const toParagraph = (editor: Editor) => {
    Transforms.setNodes(editor, DEFAULT_BLOCK, { mode: 'highest' });
};

const toFooter = (editor: Editor) => {
    Transforms.setNodes(editor, { type: ELEMENT_TYPES.FOOTER, data: {} }, { mode: 'highest' });
};

const toCrosshead = (editor: Editor, crossheadStyle: CrossheadStyle) => {
    const data = generateCrossheadData(crossheadStyle);

    Transforms.setNodes(editor, { type: ELEMENT_TYPES.CROSSHEAD, data }, { mode: 'highest' });
};

const toggleCrosshead = (editor: Editor, crossheadStyle: CrossheadStyle) => {
    // if the same style is used twice, toggle between crosshead and paragraph
    // crosshead:style -> paragraph
    if (isCrossheadActive(editor, crossheadStyle)) {
        toParagraph(editor);
    }
    // element can be undefined as it is filtering
    // paragraph -> crosshead:style
    // crosshead:styleA -> crosshead:styleB
    else if (isParagraphActive(editor) || isFooterActive(editor) || isCrossheadActive(editor)) {
        toCrosshead(editor, crossheadStyle);
    }
};

export const withParagraph = (editor: Editor, options: PluginOptions) => {
    const { normalizeNode } = editor;

    return Object.assign(editor, {
        renderElement: renderElement(
            editor,
            [
                [ELEMENT_TYPES.PARAGRAPH, TextBlock],
                [ELEMENT_TYPES.TITLE_HEADER, TextBlock],
                [ELEMENT_TYPES.TITLE, TextBlock],
                [ELEMENT_TYPES.LEAD, TextBlock],
                [ELEMENT_TYPES.CROSSHEAD, TextBlock],
                [ELEMENT_TYPES.FOOTER, TextBlock],
            ],
            options,
        ),
        isParagraphActive: () => isParagraphActive(editor),
        isSectionActive: () => isCrossheadActive(editor, CROSSHEAD_STYLES.SECTION),
        isSubsectionActive: () => isCrossheadActive(editor, CROSSHEAD_STYLES.SUBSECTION),
        isUnorderedListicleActive: () =>
            isCrossheadActive(editor, CROSSHEAD_STYLES.UNORDERED_LISTICLE),
        isOrderedListicleActive: () => isCrossheadActive(editor, CROSSHEAD_STYLES.ORDERED_LISTICLE),
        isFooterActive: () => isFooterActive(editor),
        toggleCrosshead: (crossheadStyle: CrossheadStyle) =>
            toggleCrosshead(editor, crossheadStyle),
        toParagraph: () => toParagraph(editor),
        toFooter: () => toFooter(editor),
        normalizeNode: (nodeEntry) => {
            const [, path] = nodeEntry;

            if (path.length === 0) {
                const matches = Editor.elements<CrossheadElement>(editor, {
                    mode: 'highest',
                    at: [],
                    match: (node) =>
                        node.type === ELEMENT_TYPES.CROSSHEAD &&
                        node.data.style?.crossheadType === CROSSHEAD_STYLES.ORDERED_LISTICLE,
                });

                const orderedListicles = new Set<CrossheadElement>();

                for (const [orderedListicle] of matches) {
                    orderedListicles.add(orderedListicle);
                }

                EDITOR_TO_ORDERED_LISTICLES.set(editor, orderedListicles);
            }

            normalizeNode(nodeEntry);
        },
    });
};

export default withParagraph;
