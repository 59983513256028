import { type UploadFile } from '@@api/hooks/useUploadFile';
import { type Editor, type Node, ReactEditor } from '@@editor/helpers';
import { ELEMENT_TYPES } from '@@editor/helpers/Element';
import getElvisIdByImage from '@@editor/selectors/getElvisIdByImage';
import getVideocmsPreviewImage from '@@editor/selectors/getVideocmsPreviewImage';
import { type PluginOptions } from '@@editor/typings/UnityPlugins';
import makeState from '@@editor/utils/makeState';

import { uploadFileHelper } from './uploadFileHelper';

/*
 * caption gets serialized as it is not serialized on previewImage and maybe on other places as well.
 */

export const withFileUpload = (
    editor: Editor,
    options: PluginOptions & { uploadFile: UploadFile },
): Editor => {
    const { onAfterUpdateElement, onAfterInsertElement } = editor;
    const uploadsInProgress = new Set<string>();

    const uploadVideoCms = uploadFileHelper({
        uploadFile: options.uploadFile,
        updateEmbedData: (payload) => ({
            previewImage: {
                url: payload.src,
                elvisId: payload.id,
                caption: makeState(payload.title),
            },
        }),
        updateSource: false,
    });

    const uploadImage = uploadFileHelper({
        uploadFile: options.uploadFile,
        updateEmbedData: ({
            src,
            id,
            caption,
            name,
            nameSource,
            credit,
            naturalWidth,
            naturalHeight,
        }) => ({
            url: src,
            elvisId: id,
            caption: makeState(caption),
            name,
            nameSource,
            credit,
            naturalWidth,
            naturalHeight,
        }),
    });

    const uploadFileByNode = (editor: Editor, node: Node, previousNode?: Node) => {
        const key = ReactEditor.findKey(editor, node);

        switch (node.type) {
            case ELEMENT_TYPES.VIDEOCMS: {
                const previewImage = getVideocmsPreviewImage(node);

                if (!previewImage?.elvisId && previewImage?.url) {
                    if (!uploadsInProgress.has(key.id)) {
                        uploadsInProgress.add(key.id);

                        uploadVideoCms(editor, node, previewImage.url)
                            .then(() => {
                                uploadsInProgress.delete(key.id);
                            })
                            .catch((e) => console.log(e));
                    }
                }

                break;
            }

            case ELEMENT_TYPES.IMAGE:
                if (!getElvisIdByImage(node) && node.data.originalSrc) {
                    if (!uploadsInProgress.has(key.id)) {
                        uploadsInProgress.add(key.id);

                        uploadImage(editor, node, node.data.originalSrc, previousNode)
                            .then(() => {
                                uploadsInProgress.delete(key.id);
                            })
                            .catch((e) => console.log(e));
                    }
                }

                break;
        }
    };

    return Object.assign(editor, {
        onAfterUpdateElement: (node: Node, previousNode?: Node) => {
            uploadFileByNode(editor, node, previousNode);

            onAfterUpdateElement(node, previousNode);
        },
        onAfterInsertElement: (node: Node) => {
            uploadFileByNode(editor, node);

            onAfterInsertElement(node);
        },
    });
};

export default withFileUpload;
