import { type Editor } from '@@editor/helpers';

const normalizeNode = (
    editor: Editor,
    handlers: [handler: (editor: Editor, ...args: any[]) => boolean | undefined, ...args: any[]][],
) => {
    const { normalizeNode } = editor;

    return (nodeEntry) => {
        for (const [handler, ...args] of handlers) {
            if (handler(editor, nodeEntry, ...args)) {
                return;
            }
        }

        normalizeNode(nodeEntry);
    };
};

export default normalizeNode;
