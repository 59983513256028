import { Badge, badgeClasses, styled } from '@mui/material';

const RelativeBadge = styled(Badge)({
    [`& .${badgeClasses.badge}`]: {
        position: 'relative',
        transform: 'none',
    },
});

export default RelativeBadge;
