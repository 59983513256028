import React from 'react';

import Slideshow from '@@containers/Slideshow/Slideshow';
import { renderSlideshowImage } from '@@containers/Slideshow/SlideshowImage';
import { type Editor } from '@@editor/helpers';
import { type CSSlideshowElement } from '@@editor/helpers/Element';

type Props = {
    element: CSSlideshowElement;
    editor: Editor;
    readOnly: boolean;
};

type CSSlide = CSSlideshowElement['data']['slides'][number];

const getImageFromSlide = (slide: CSSlide) => slide.data.embed;

const ContentstationSlideshow = ({ element, readOnly }: Props) => {
    const slides = element.data.slides;

    const renderImage = (imageProps) =>
        renderSlideshowImage({
            ...imageProps,
            loading: false,
            image: getImageFromSlide(slides[imageProps.slideIndex]),
        });

    return <Slideshow slidesLength={slides.length} renderImage={renderImage} readOnly={readOnly} />;
};

export default ContentstationSlideshow;
