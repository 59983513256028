import React from 'react';

import { Editor } from '@@editor/helpers';
import { Element, ELEMENT_TYPES } from '@@editor/helpers/Element';
import renderElement from '@@editor/plugins/utils/renderElement';
import { createElement } from '@@editor/serialization/UnitySerializer/deserializeNodes';
import { PLUGIN_ICON_NAMES, PLUGIN_NAMES } from '@@editor/typings/UnityPlugins';

import Snippet from './Snippet';
import EmbedWrapper from '../embed/components/EmbedWrapper';
import { removePollIds } from '../poll/utils';

const TYPE = PLUGIN_NAMES.SNIPPET;
const NODE_TYPE = ELEMENT_TYPES.SNIPPET;

const SnippetNode = (props) => (
    <EmbedWrapper
        {...props}
        type={TYPE}
        component={Snippet}
        toolbarConfig={{
            infos: {
                iconName: PLUGIN_ICON_NAMES[TYPE],
                title: props.editor.t('editor.plugin.snippet.frame.title'),
            },
        }}
    />
);

export const generateLayoutBlocks = (editor, formData, options) => {
    Editor.insertElement(
        editor,
        formData.element[0].data.content.map((node) => {
            // In case we extract an editable copy a poll from an existing snippet,
            // we do not want the ids to be copied along, as it would affect the results
            // of all the polls that were created from that snippet
            if (Element.isPollElement(node)) {
                const dataStrippedFromIds = removePollIds(node.data);

                return createElement(node.type, dataStrippedFromIds, {
                    useInlineEditing: editor.useInlineEditing,
                    children: node.children,
                });
            }

            return createElement(node.type, node.data, {
                useInlineEditing: editor.useInlineEditing,
                children: node.children,
            });
        }),
        options,
    );
};

const internalWithSnippet = (editor, options) => {
    const { isVoid } = editor;

    return Object.assign(editor, {
        isVoid: (element) => (element.type === NODE_TYPE ? true : isVoid(element)),
        renderElement: renderElement(editor, [[NODE_TYPE, SnippetNode]], {
            ...options,
            useInlineEditing: editor.useInlineEditing,
        }),
    });
};

export const withSnippet = (editor, options) =>
    internalWithSnippet(editor, {
        ...options,
        nodeType: NODE_TYPE,
    });

export default withSnippet;
