import { type ValidationMode } from 'react-hook-form';

export const VALIDATION_MODE: ValidationMode = {
    onBlur: 'onBlur',
    onChange: 'onChange',
    onSubmit: 'onSubmit',
    onTouched: 'onTouched',
    all: 'all',
};

export const REVALIDATION_MODE: Omit<ValidationMode, 'all' | 'onTouched'> = {
    onBlur: 'onBlur',
    onChange: 'onChange',
    onSubmit: 'onSubmit',
};
