import { type RouteObject } from 'react-router-dom';

export const routes: RouteObject[] = [
    {
        path: '*',
        children: [
            {
                path: '*',
                lazy: async () => {
                    const { Layout } = await import(
                        /* webpackChunkName: "collections" */ '@@routes/collections'
                    );

                    return { Component: Layout };
                },
            },
        ],
    },
];
