import { noop } from 'lodash';
import { type ReactEventHandler, useEffect, useRef } from 'react';

type Props = {
    onDocumentDragStart?: ReactEventHandler;
    onDocumentDragEnter?: ReactEventHandler;
    onDocumentDragLeave?: ReactEventHandler;
    onDocumentDrop?: ReactEventHandler;
};

const useDocumentDragHandlers = ({
    onDocumentDragStart = noop,
    onDocumentDragEnter = noop,
    onDocumentDragLeave = noop,
    onDocumentDrop = noop,
}: Props) => {
    const enteredRef = useRef(false);

    const handleDocumentDragStart = (e) => {
        onDocumentDragStart(e);
    };

    const handleDocumentDragEnter = (e) => {
        if (enteredRef.current === true) {
            return;
        }

        onDocumentDragEnter(e);
        enteredRef.current = true;
    };

    const handleDocumentDragLeave = (e) => {
        if (!e.clientX && !e.clientY) {
            onDocumentDragLeave(e);
            enteredRef.current = false;
        }
    };

    const handleDrop = (e) => {
        enteredRef.current = false;
        onDocumentDrop(e);
    };

    useEffect(() => {
        document.addEventListener('dragstart', handleDocumentDragStart, true);
        document.addEventListener('dragenter', handleDocumentDragEnter, true);
        document.addEventListener('dragleave', handleDocumentDragLeave, true);
        document.addEventListener('drop', handleDrop, true);

        return () => {
            document.removeEventListener('dragstart', handleDocumentDragStart, true);
            document.removeEventListener('dragenter', handleDocumentDragEnter, true);
            document.removeEventListener('dragleave', handleDocumentDragLeave, true);
            document.removeEventListener('drop', handleDrop, true);
        };
    }, [onDocumentDragStart, onDocumentDragEnter, onDocumentDragLeave]);
};

export default useDocumentDragHandlers;
