import { styled } from '@mui/material';
import { type TFunction } from 'i18next';
import React from 'react';

import ReactHookForm from '@@containers/ReactHookForm/ReactHookForm';
import { type UseReactHookFormProps } from '@@containers/ReactHookForm/types';
import { type ZattooElement } from '@@editor/helpers/Element';
import AutocompleteField from '@@form/fields/Autocomplete';

import ZATTOO_CHANNELS from './channels';

const INITIAL_VALUES = { src: '' };

type Props = {
    t: TFunction;
    type: string;
    className: string;
    element: ZattooElement;
    renderButtons: (buttons: any) => JSX.Element;
    validate: any[];
    onCancel: VoidFunction;
    onSubmit: UseReactHookFormProps['onSubmit'];
};
export const ZattooForm = ({
    onCancel,
    onSubmit,
    element,
    renderButtons,
    validate,
    className,
    t,
}: Props) => {
    const handleClickOnCancel = () => {
        onCancel();
    };

    const initialValues = (element && element.data) || INITIAL_VALUES;

    const options = Object.entries(ZATTOO_CHANNELS).map(([key, value]) => ({
        label: key,
        value,
    }));

    return (
        <ReactHookForm
            formName="EmbedZattooForm"
            onSubmit={onSubmit}
            onCancel={handleClickOnCancel}
            renderButtons={renderButtons}
            hasCancelButton
            hasActiveCancelButton
            alwaysShowCancelButton
            className={className}
            values={initialValues}
        >
            <AutocompleteField
                name="src"
                label={t('editor.insert.embed.zattoo.label')}
                validate={validate}
                options={options}
                getOptionKey={(option) => option.value}
                getOptionLabel={(option) => option.label}
                transform={{
                    input: (value) =>
                        value ? options.find((option) => option.value === value) : null,
                    output: (event, value) => (value ? value.value : null),
                }}
            />
        </ReactHookForm>
    );
};

export default styled(ZattooForm)({
    width: '450px',
});
