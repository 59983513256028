import React from 'react';
import { useTranslation } from 'react-i18next';

import { useTenantClient } from '@@api/services/tenant/client';
import ToggleButtonRadioGroup, {
    type ToggleButtonRadioGroupProps,
} from '@@form/components/ToggleButtonRadioGroup';
import { type ContentLanguage } from '@@lib/i18n/constants';

export type ContentLocaleRadioGroupProps = Omit<ToggleButtonRadioGroupProps, 'fields' | 'name'> & {
    value?: ContentLanguage;
    helperText?: boolean;
};

const ContentLocaleRadioGroup = (props: ContentLocaleRadioGroupProps) => {
    const { helperText, ...rest } = props;
    const { client: tenantClient, queryKeys: tenantKeys } = useTenantClient();
    const { t } = useTranslation();

    const { data } = tenantClient.locales.getAll.useQuery({
        queryKey: tenantKeys.locales.getAll(),
        queryData: {},

        staleTime: Infinity,
    });

    const locales = data?.body ?? [];

    return (
        <ToggleButtonRadioGroup
            name="contentLocale"
            label={t('contentLocaleRadioGroup')}
            {...(helperText && { helperText: t('contentLocaleRadioGroup.description') })}
            {...rest}
            fields={locales.map(({ locale: value }) => ({
                // t('contentLocaleRadioGroup.de-CH')
                // t('contentLocaleRadioGroup.fr-CH')
                value,
                label: t(`contentLocaleRadioGroup.${value}`),
            }))}
        />
    );
};

export default ContentLocaleRadioGroup;
