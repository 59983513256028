import crypto from '@@scripts/utils/crypto';

import { type ErrorAction, type FetchAction, type SuccessAction } from './types';

export const MessageEventType = {
    FETCH: 'UNITY_GENERATE_IMAGE_ALT_TEXT',
    SUCCESS: 'UNITY_GENERATE_IMAGE_ALT_TEXT_SUCCESS',
    ERROR: 'UNITY_GENERATE_IMAGE_ALT_TEXT_ERROR',
} as const;

export type MessageEventType = ValueOf<typeof MessageEventType>;

type FetchMessageEvent = {
    type: typeof MessageEventType.FETCH;
    channelId: string;
    payload: FetchAction['payload'];
};

type SuccessMessageEvent = {
    type: typeof MessageEventType.SUCCESS;
    channelId: string;
    payload: SuccessAction['payload'];
};

type ErrorMessageEvent = {
    type: typeof MessageEventType.ERROR;
    channelId: string;
    payload: ErrorAction['payload'];
};

type MessageEventUnion = FetchMessageEvent | SuccessMessageEvent | ErrorMessageEvent;

class ImageAltTextMessenger {
    static listen(callback) {
        const handleMessageEvent = (event) => {
            if (event.origin !== window.location.origin && process.env.NODE_ENV !== 'test') {
                return;
            }

            if (event.data.type === MessageEventType.FETCH) {
                callback({ type: event.data.type, payload: event.data.payload })
                    .then((payload) => {
                        window.postMessage(
                            {
                                channelId: event.data.channelId,
                                type: MessageEventType.SUCCESS,
                                payload,
                            },
                            window.location.origin,
                        );
                    })
                    .catch((payload) => {
                        window.postMessage(
                            {
                                channelId: event.data.channelId,
                                type: MessageEventType.ERROR,
                                payload,
                            },
                            window.location.origin,
                        );
                    });
            } else if (
                event.data.type === MessageEventType.SUCCESS ||
                event.data.type === MessageEventType.ERROR
            ) {
                callback({ type: event.data.type, payload: event.data.payload });
            }
        };

        window.addEventListener('message', handleMessageEvent);

        return () => {
            window.removeEventListener('message', handleMessageEvent);
        };
    }

    static send(payload: FetchMessageEvent['payload']) {
        return new Promise<SuccessMessageEvent['payload']>((resolve, reject) => {
            const channelId = crypto.randomUUID();

            const listener = (event: MessageEvent<MessageEventUnion>) => {
                if (
                    event.data.type === MessageEventType.SUCCESS &&
                    event.data.channelId === channelId
                ) {
                    window.removeEventListener('message', listener);

                    resolve(event.data.payload);
                } else if (
                    event.data.type === MessageEventType.ERROR &&
                    event.data.channelId === channelId
                ) {
                    window.removeEventListener('message', listener);

                    reject(event.data.payload);
                }
            };

            window.addEventListener('message', listener);

            window.postMessage(
                {
                    type: MessageEventType.FETCH,
                    channelId,
                    payload,
                },
                window.location.origin,
            );
        });
    }
}

export default ImageAltTextMessenger;
