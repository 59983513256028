import React from 'react';

import { type InsertElementOptions } from '@@editor/helpers/Editor';
import { ELEMENT_TYPES, type InstagramElement, MIME_TYPES } from '@@editor/helpers/Element';
import renderEditor from '@@editor/plugins/utils/renderEditor';
import renderElement from '@@editor/plugins/utils/renderElement';
import { PLUGIN_ICON_NAMES, PLUGIN_NAMES } from '@@editor/typings/UnityPlugins';
import instagramUrlOrEmbedCode from '@@form/utils/validators/instagramUrlOrEmbedCode';
import { parseInstagramUrlOrEmbedCode } from '@@utils/socialMedia/instagram';

import EditorWithEmbedModal from './../components/EditorWithEmbedModal';
import EmbedWrapper from './../components/EmbedWrapper';
import { createGenerateEmbedBlock } from './../utils';
import { generateEmbedBlockData } from './utils';
import SocialEmbed from '../components/SocialEmbed';

const TYPE = PLUGIN_NAMES.INSTAGRAM;
const NODE_TYPE = ELEMENT_TYPES.INSTAGRAM;
const ICON_NAME = PLUGIN_ICON_NAMES[TYPE];
const TITLE = 'Instagram';

type InstagramSocialEmbedProps = { element: InstagramElement };

const InstagramSocialEmbed = ({ element }: InstagramSocialEmbedProps) => (
    <SocialEmbed element={element} iconName={ICON_NAME} socialNetworkName={TITLE} />
);

const mimetypeConfigs = {
    [MIME_TYPES.INSTAGRAM]: {
        Component: InstagramSocialEmbed,
    },
};

const InstagramNode = (props) => (
    <EmbedWrapper
        {...props}
        type={TYPE}
        mimetypeConfigs={mimetypeConfigs}
        toolbarConfig={{
            infos: {
                iconName: ICON_NAME,
                title: TITLE,
            },
            actions: ['edit', 'delete'],
        }}
        widthType="small"
    />
);

const generateEmbedBlock = createGenerateEmbedBlock({
    type: TYPE,
    nodeType: NODE_TYPE,
    generateEmbedBlockData,
    parseEmbedCode: parseInstagramUrlOrEmbedCode,
});

const validate = [instagramUrlOrEmbedCode];

const internalWithInstagram = (editor, options) => {
    const { insertData } = editor;

    return Object.assign(editor, {
        renderEditor: renderEditor(
            editor,
            (props) => (
                <EditorWithEmbedModal
                    {...props}
                    validate={validate}
                    generateEmbedBlock={generateEmbedBlock}
                    type={TYPE}
                    formProps={{
                        withIncludeCaption: true,
                        withUrlAsDefault: true,
                    }}
                />
            ),
            options,
        ),
        renderElement: renderElement(editor, [[NODE_TYPE, InstagramNode]], options),
        insertData: (data: DataTransfer, options: InsertElementOptions) => {
            const text = data.getData('text/plain');

            if (text && !instagramUrlOrEmbedCode(text)) {
                generateEmbedBlock(editor, text);
            } else {
                insertData(data, options);
            }
        },
    });
};

export const withInstagram = (editor, options) =>
    internalWithInstagram(editor, {
        ...options,
        nodeType: NODE_TYPE,
    });

export default withInstagram;
