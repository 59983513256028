import { cloneDeep, find, findIndex, map } from 'lodash';

import { type Tenant } from '@@api/services/tenant/schemas';
import { type LocalVariants } from '@@api/utils/schemas/utils';
import { DEFAULT_TENANT_ID } from '@@containers/metadata/constants';
import { not } from '@@utils/function';
import { includedIn } from '@@utils/includedIn';

export const collectVariantIds = (variants): Tenant['id'][] => map(variants, 'variantId');
export const getVariantById = (variants, id) => find(variants, { variantId: id });
export const getVariantIndexById = (variants, id) => findIndex(variants, { variantId: id });
export const getDefaultVariant = (variants) => find(variants, { variantId: DEFAULT_TENANT_ID });

export const findNextEnabledVariantId = (
    variantIds: Tenant['id'][],
    disabledVariantIds: Tenant['id'][] = [],
    selectedVariantId: Tenant['id'],
) => {
    const selectedVariantIndex = variantIds.indexOf(selectedVariantId);

    // The selected variant is not in variantIds,
    // no way to tell which variant should be the next.
    if (selectedVariantIndex === -1) {
        return;
    }

    return variantIds
        .slice(selectedVariantIndex)
        .filter(not(includedIn(disabledVariantIds, Number)))
        .shift();
};

const makeNewVariants = (
    tenantIds: Tenant['id'][],
    tenantVariants: LocalVariants,
    sourceTenantId: Tenant['id'],
) => {
    const sourceTenantVariant = getVariantById(tenantVariants, sourceTenantId);

    return tenantIds.map((tenantId) => ({ ...sourceTenantVariant, variantId: tenantId }));
};

export const addVariants = (
    tenantIds: Tenant['id'][],
    tenantVariants: LocalVariants,
    sourceTenantId: Tenant['id'],
) => {
    const newTenantVariantsWithDefaults = makeNewVariants(
        tenantIds,
        tenantVariants,
        sourceTenantId,
    );

    return newTenantVariantsWithDefaults.reduce((result, newTenantVariant) => {
        const index = getVariantIndexById(result, newTenantVariant.variantId);

        if (index === -1) {
            // Only append the new variant if it does not yet exist in order to avoid duplicate variants
            result.push(newTenantVariant);
        }

        return result;
    }, cloneDeep(tenantVariants));
};

export const removeVariants = (tenantIds: Tenant['id'][], tenantVariants: any[]) =>
    tenantVariants.filter(({ variantId }) => !tenantIds.includes(variantId));

export const copyVariants = (
    tenantIds: Tenant['id'][],
    tenantVariants: LocalVariants,
    sourceTenantId: Tenant['id'],
) => {
    const newTenantVariantsWithDefaults = makeNewVariants(
        tenantIds,
        tenantVariants,
        sourceTenantId,
    );

    return newTenantVariantsWithDefaults.reduce((result, newTenantVariant) => {
        const index = getVariantIndexById(result, newTenantVariant.variantId);

        if (index >= 0) {
            // If the variant already exist, overwrite it in order to avoid duplicate variants
            result.splice(index, 1, newTenantVariant);
        } else {
            // Otherwise just append it
            result.push(newTenantVariant);
        }

        return result;
    }, cloneDeep(tenantVariants));
};
