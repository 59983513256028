import { styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import useCheckIframeAllowed from '@@api/hooks/useCheckIframeAllowed';
import InfoTooltip from '@@components/InfoTooltip';
import ReactHookForm from '@@containers/ReactHookForm/ReactHookForm';
import { type UseReactHookFormProps } from '@@containers/ReactHookForm/types';
import { type Editor, type Element } from '@@editor/helpers';
import makeState from '@@editor/utils/makeState';
import { parseValueForUrl } from '@@form/fields/UrlIframe';
import EmbedPreview from '@@routes/externalContent/embeds/components/EmbedPreview';

import EmbeddedContentFields from './EmbeddedContentFields';

const StyledForm = styled(ReactHookForm)({
    width: '450px',
});

const PreviewWrapper = styled('div')({
    minHeight: '380px',
});

const Header = styled('div')(({ theme }) => ({
    ...theme.typography.subtitle4,
    padding: theme.spacing(1, 0),
}));

const HeaderWrapper = styled('div')({
    display: 'flex',
    alignItems: 'end',
});

export const DEFAULT_EMBEDDED_CONTENT_FORM_VALUES = {
    embed: {
        iframe: '',
        caption: makeState(),
        captionTitle: '',
        credit: '',
        fallBackHeight: null,
    },
};

// element data needs to be transformed back into form data
export const transformElementToFormValues = (element) => {
    // default
    if (!element) {
        return DEFAULT_EMBEDDED_CONTENT_FORM_VALUES;
    }

    const elementData = Array.isArray(element) ? element[0].data : element.data;
    const embed = elementData?.embed;

    return {
        ...elementData,
        embed: {
            ...embed,
            iframe: embed?.iframe || elementData?.originalSrc,
        },
    };
};

const IFRAME_FIELDNAME = 'embed.iframe';

type Props = {
    type: string;
    element?: Element;
    onCancel: VoidFunction;
    onSubmit: UseReactHookFormProps['onSubmit'];
    className: string;
    renderButtons: (buttons: any) => JSX.Element;
    editor?: Editor;
};

export const EmbeddedContentForm = ({
    element,
    renderButtons,
    className,
    onSubmit,
    onCancel,
    editor,
}: Props) => {
    const { t } = useTranslation();
    const initialValues = transformElementToFormValues(element);
    const { isLoading, handleUrlFieldBlur, isValid, UrlInputProps } = useCheckIframeAllowed();

    return (
        <StyledForm
            values={initialValues}
            onSubmit={onSubmit}
            onCancel={onCancel}
            renderButtons={renderButtons}
            hasCancelButton
            hasActiveCancelButton
            alwaysShowCancelButton
            className={className}
            formName="EmbedEmbeddedContent"
        >
            {({ watch }) => {
                const src = watch(IFRAME_FIELDNAME);

                return (
                    <>
                        <EmbeddedContentFields
                            InputProps={UrlInputProps}
                            onIframeBlur={handleUrlFieldBlur}
                            editor={editor}
                        />

                        <PreviewWrapper>
                            <HeaderWrapper>
                                {/* t('editor.embeddedcontent.tooltip') */}
                                <InfoTooltip title="editor.embeddedcontent.tooltip">
                                    <Header>{t('externalcontent.embeds.form.preview')}</Header>
                                </InfoTooltip>
                            </HeaderWrapper>

                            <EmbedPreview
                                src={parseValueForUrl(src)}
                                isValid={isValid}
                                isLoading={isLoading}
                            />
                        </PreviewWrapper>
                    </>
                );
            }}
        </StyledForm>
    );
};

export default EmbeddedContentForm;
