import { Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { type FunctionRouter, useFunctionsClient } from '@@api/services/functions/client';
import { getQueryParams } from '@@api/utils/getQueryParams';
import Icon from '@@components/Icon';
import { parseValueForUrl } from '@@form/fields/UrlIframe';

const useCheckIframeAllowed = () => {
    const [isLoading, setLoading] = useState<boolean>(false);
    const [urlWarning, setUrlWarning] = useState('');
    const [isValid, setIsValid] = useState(true);

    const { t } = useTranslation();
    const { client: functionsClient, queryKeys: functionsKeys } = useFunctionsClient();
    const queryClient = functionsClient.useQueryClient();

    const validate = (value: string) => {
        const parsedValue = parseValueForUrl(value);

        if (parsedValue) {
            setLoading(true);

            const query = getQueryParams<FunctionRouter['checkIframeAllowed']>({
                url: parsedValue,
            });

            // t('embeddedcontent.iframe.error')
            // t('embeddedcontent.iframe.error.500')
            return queryClient.checkIframeAllowed
                .fetchQuery({
                    queryKey: functionsKeys.checkIframeAllowed({ query }),
                    queryData: {
                        query,
                    },
                })
                .then((data) => (data?.body.allowed ? undefined : ['embeddedcontent.iframe.error']))
                .catch(() => ['embeddedcontent.iframe.error.500'])
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const handleUrlFieldBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
        validate(e.target.value)?.then((response) => {
            if (Array.isArray(response)) {
                setUrlWarning(response[0]);
                setIsValid(false);
            } else {
                setUrlWarning('');
                setIsValid(true);
            }
        });
    };

    const UrlInputProps = urlWarning
        ? {
              endAdornment: (
                  <Tooltip color="warning" placement="right" title={t(urlWarning)}>
                      <Icon name="triangle-exclamation" color="warning" />
                  </Tooltip>
              ),
          }
        : {};

    return {
        isLoading,
        handleUrlFieldBlur,
        isValid,
        urlWarning,
        UrlInputProps,
    };
};

export default useCheckIframeAllowed;
