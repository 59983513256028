import { List as MuiList, styled } from '@mui/material';
import React, { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { type Components, Virtuoso, type VirtuosoHandle } from 'react-virtuoso';

import useMergedRef from '@@hooks/useMergedRef';

import EmptyListItem from './EmptyListItem';
import ListItem, { DEFAULT_HEIGHT } from './ListItem';
import { type ListContext, type ListProps } from './types';

type StyledVirtuosoProps = {
    $noBorder?: boolean;
    $noOverscroll?: boolean;
    $noScrollbar?: boolean;
};

// Looks like there is no other way to make a styled work with generics
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TypedStyledVirtuoso = <Data, Context>(
    props: React.ComponentPropsWithRef<typeof Virtuoso<Data, Context>> & StyledVirtuosoProps,
) => <Virtuoso {...props} />;

const StyledVirtuoso = styled(Virtuoso, {
    shouldForwardProp: (prop: string) => !prop.startsWith('$'),
})<StyledVirtuosoProps>(({ $noBorder, $noOverscroll, $noScrollbar, theme }) => ({
    borderTop: !$noBorder ? `1px solid ${theme.palette.divider}` : undefined,
    overscrollBehavior: $noOverscroll ? 'contain' : undefined,
    scrollbarWidth: $noScrollbar ? 'none' : undefined,
    '&::-webkit-scrollbar': $noScrollbar ? { display: 'none' } : undefined,
})) as typeof TypedStyledVirtuoso;

const createMuiComponents = <Data, Context extends ListContext<Data>>(): Components<
    Data,
    Context
> => ({
    EmptyPlaceholder: (props) => {
        const { context, ...rest } = props;
        const index = -1;
        const style: React.CSSProperties = {};
        const height = context?.itemSize ? context?.itemSize(index) : context?.fixedItemHeight;

        if (height != null) {
            style.height = height;
        }

        return context?.renderEmptyItem
            ? context.renderEmptyItem({
                  ...rest,
                  children: context?.emptyItemText,
                  style,
              })
            : null;
    },

    Header: (props) => {
        const { context } = props;

        return context?.renderHeader ? context.renderHeader(props) : null;
    },

    Footer: (props) => {
        const { context } = props;

        return context?.renderFooter ? context.renderFooter(props) : null;
    },

    List: React.forwardRef((props, ref) => {
        const { context, ...rest } = props;

        return <MuiList role={context?.role} {...rest} ref={ref} component="div" disablePadding />;
    }),

    Item: (props) => {
        const { context, ...rest } = props;
        const { 'data-item-index': index, item, style } = rest;
        const enhancedStyle: React.CSSProperties = { ...style };
        const height = context?.itemSize
            ? context?.itemSize(index, item)
            : context?.fixedItemHeight;

        if (height != null) {
            enhancedStyle.height = height;
        }

        const selected = context?.isItemSelected(item);

        return context?.renderItem
            ? context.renderItem({
                  ...rest,
                  index,
                  role: 'listitem',
                  selected,
                  style: enhancedStyle,
                  onClick: context?.onClickItem,
              })
            : null;
    },
});

const defaultIsItemSelected = () => false;
const defaultRenderEmptyItem = (props) => <EmptyListItem {...props} />;
const defaultRenderItem = (props) => <ListItem {...props} />;
const defaultRenderItemContent = (index, item) => item?.name;
const defaultComputeItemKey = (index, item) => item?.id ?? index;

export const calculateHeights = <Data,>(
    props: Pick<ListProps<Data>, 'defaultItemHeight' | 'fixedItemHeight' | 'itemSize'>,
) => {
    const defaultFixedItemHeight =
        // Only use `DEFAULT_HEIGHT` if no `fixedItemHeight` AND no `itemSize` is specified !
        props.itemSize ? undefined : DEFAULT_HEIGHT;

    const fixedItemHeight =
        props.fixedItemHeight === null
            ? undefined
            : props.fixedItemHeight ?? defaultFixedItemHeight;

    const defaultItemHeight =
        props.defaultItemHeight === null ? undefined : props.defaultItemHeight ?? fixedItemHeight;

    return { defaultItemHeight, fixedItemHeight };
};

const List = <Data,>(props: ListProps<Data>, ref: React.ForwardedRef<VirtuosoHandle>) => {
    const { t } = useTranslation();
    const {
        className,
        data,
        emptyItemText = t('list.noresult'),
        headerFooterTag,
        isItemSelected = defaultIsItemSelected,
        computeItemKey = defaultComputeItemKey,
        itemSize,
        noBorder,
        noOverscroll,
        noScrollbar,
        overscan,
        scrollToSelection,
        rangeChanged,
        renderEmptyItem = defaultRenderEmptyItem,
        renderHeader,
        renderItem = defaultRenderItem,
        renderItemContent = defaultRenderItemContent,
        renderFooter,
        role = 'list',
        scrollerRef,
        useWindowScroll,
        onClickItem,
        onEndReached,
    } = props;

    const { defaultItemHeight, fixedItemHeight } = calculateHeights(props);

    const internalRef = useRef<VirtuosoHandle | null>(null);
    const mergedRef = useMergedRef(ref, internalRef);
    const components = useMemo(() => createMuiComponents<Data, ListContext<Data>>(), []);
    const totalCount = data.length;
    const scrollToSelectionDone = useRef(false);
    const selectedIndex = useMemo(() => data.findIndex(isItemSelected), [data, isItemSelected]);

    const internalItemSize =
        itemSize &&
        ((el, field) => {
            if (field === 'offsetWidth') {
                return el.getBoundingClientRect().width;
            } else if (field === 'offsetHeight') {
                if (el.dataset.itemIndex) {
                    const index = Number(el.dataset.itemIndex);
                    const item = data[index];
                    const size = itemSize(index, item);

                    return size;
                }
            }

            return el.getBoundingClientRect().height;
        });

    useEffect(() => {
        // Try to scroll to `scrollIntoView` as soon as `data` has been changed,
        // if `scrollIntoView` not yet happend
        if (!scrollToSelectionDone.current && selectedIndex >= 0 && scrollToSelection) {
            internalRef.current?.scrollIntoView({ align: 'center', index: selectedIndex });

            scrollToSelectionDone.current = true;
        }
    }, [data, selectedIndex, scrollToSelection]);

    // ! Warning: when doing tests in JSDom, VirtualList height is 0 so it will stop rendering items after crossing a certain threshold
    return (
        <StyledVirtuoso<Data, ListContext<Data>>
            ref={mergedRef}
            $noBorder={noBorder}
            $noOverscroll={noOverscroll}
            $noScrollbar={noScrollbar}
            {...{
                className,
                components,
                computeItemKey,
                data,
                defaultItemHeight,
                fixedItemHeight,
                ...(headerFooterTag && { headerFooterTag }),
                ...(overscan && { overscan }),
                rangeChanged,
                scrollerRef,
                totalCount,
                useWindowScroll,
            }}
            context={{
                emptyItemText,
                fixedItemHeight,
                isItemSelected,
                itemSize,
                renderEmptyItem,
                renderHeader,
                renderItem,
                renderFooter,
                role,
                onClickItem,
            }}
            endReached={onEndReached}
            itemContent={renderItemContent}
            {...{ ...(internalItemSize && { itemSize: internalItemSize }) }}
            style={{ width: '100%', height: '100%' }}
        />
    );
};

export default React.forwardRef(List) as <Data>(
    props: ListProps<Data> & { ref?: React.ForwardedRef<VirtuosoHandle> },
) => ReturnType<typeof List>;
