import { identity, omit } from 'lodash';
import { type z } from 'zod';

import { type LocalVariants, type UnityVariants } from '@@api/utils/schemas/utils';
import createImage from '@@form/components/ImageEditor/createImage';
import { sanitizeHtml } from '@@utils/string';

export const deserializeVariants = <
    ZodType extends z.ZodType<AnyObject> | z.ZodType<string>,
    Variants extends UnityVariants<ZodType>,
    T extends UnknownObject,
>(
    variants: Variants = {} as Variants,
    iteratee: (variant: Variants[keyof Variants], variantId: number) => T = identity,
): Array<T & { variantId: number }> =>
    Object.entries(variants).map(([key, variant]) => {
        const result = iteratee(variant as Variants[keyof Variants], Number(key));

        return {
            ...result,
            variantId: Number(key),
        };
    });

export const serializeVariants = <
    Variants extends LocalVariants,
    T extends UnknownObject | string | null | Array<unknown>,
>(
    variants: Variants = [] as unknown as Variants,
    iteratee: (variant: Variants[number], variantId: number) => T = identity,
): Record<number, T> =>
    variants.reduce(
        (previousVariant, variant) =>
            Object.assign(previousVariant, {
                [variant.variantId!]: iteratee(
                    omit(variant, 'variantId') as Variants[number],
                    variant.variantId!,
                ),
            }),
        {},
    );

export const deserializeImage = (value, fieldName = 'image') => {
    const image = value?.[fieldName];

    if (!image) {
        return value;
    }

    // @ts-expect-error Only the editor is aware of `nameSource`
    const { caption, credit, name, nameSource, style, ...rest } = createImage(image);

    return {
        ...value,
        // This property is used for the automatic cropping feature, but is not provided by BE
        aspect: '',
        [fieldName]: {
            [fieldName]: rest,
            ...rest,
            caption,
            credit,
            name,
            nameSource,
            style,
        },
    };
};

export const serializeImage = (value, fieldName = 'image') => {
    if (!value?.[fieldName]) {
        return value;
    }

    const image = value[fieldName];

    return {
        // The `aspect` property is used for the automatic cropping feature, but should not be sent to BE
        ...omit(value, ['aspect']),
        [fieldName]: {
            ...omit(image, [fieldName]),
            ...image[fieldName],
            // We want to strip out html tags from the description, as they are illegal characters
            name: image.name && sanitizeHtml(image.name),
        },
    };
};
