import { type FocusEventHandler, useCallback, useEffect } from 'react';
import {
    type ControllerRenderProps,
    type FieldError,
    type FieldPath,
    type FieldValues,
    useController,
    type UseControllerProps,
    type UseControllerReturn,
} from 'react-hook-form';

import { useReactHookFormMetaContext } from '@@containers/ReactHookForm/ReactHookFormMetaContext';
import { asyncTransformValidate } from '@@form/utils/transformers';

import useCachedValidation, { type UseCachedValidationProps } from './useCachedValidation';
import useReactHookFormFieldError, { type FormFieldError } from './useReactHookFormFieldError';
import useTransform, { type UseTransformOptions } from './useTransform';

type LegacyUseFormFieldProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = UseCachedValidationProps &
    UseControllerProps<TFieldValues, TName> & {
        onChange?: (
            value: UseControllerReturn<TFieldValues, TName>['field']['value'],
            onFieldChange: UseControllerReturn<TFieldValues, TName>['field']['onChange'],
        ) => void;
        onBlur?: FocusEventHandler;
        transform?: UseTransformOptions<TFieldValues, TName>['transform'];
    };

type LegacyUserFormFieldReturn<
    TFieldValues extends FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<UseControllerReturn<TFieldValues, TName>, 'field'> & {
    field: Omit<ControllerRenderProps<TFieldValues, TName>, 'onChange' | 'onBlur'> & {
        onChange: UseControllerReturn<TFieldValues, TName>['field']['onChange'];
        onBlur: FocusEventHandler;
        error?: FormFieldError;
    };
};

type ZodUseFormFieldProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<UseControllerProps<TFieldValues, TName>, 'control'> & {
    control: NonNullable<UseControllerProps<TFieldValues, TName>['control']>;
    onChange?: (
        value: UseControllerReturn<TFieldValues, TName>['field']['value'],
        onFieldChange: UseControllerReturn<TFieldValues, TName>['field']['onChange'],
    ) => void;
    onBlur?: FocusEventHandler;
    required?: boolean;
    // Type narrowing
    transform?: never;
    requiredCustom?: never;
    validate?: never;
    novalidate?: never;
    validateCacheKey?: never;
};

type ZodUserFormFieldReturn<
    TFieldValues extends FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<UseControllerReturn<TFieldValues, TName>, 'field'> & {
    field: Omit<ControllerRenderProps<TFieldValues, TName>, 'onChange' | 'onBlur'> & {
        onChange: UseControllerReturn<TFieldValues, TName>['field']['onChange'];
        onBlur: FocusEventHandler;
        error?: FieldError;
    };
};

function useFormField<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
    props: LegacyUseFormFieldProps<TFieldValues, TName>,
): LegacyUserFormFieldReturn<TFieldValues, TName>;
function useFormField<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(props: ZodUseFormFieldProps<TFieldValues, TName>): ZodUserFormFieldReturn<TFieldValues, TName>;
// eslint-disable-next-line func-style
function useFormField<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
    props: ZodUseFormFieldProps<TFieldValues, TName> | LegacyUseFormFieldProps<TFieldValues, TName>,
): ZodUserFormFieldReturn<TFieldValues, TName> | LegacyUserFormFieldReturn<TFieldValues, TName> {
    const {
        name,
        control,
        novalidate,
        validate,
        validateCacheKey,
        required,
        requiredCustom,
        onChange,
        onBlur,
        transform,
        defaultValue,
    } = props;

    const { registerField, unregisterField } = useReactHookFormMetaContext();

    useEffect(() => {
        registerField(name);

        return () => {
            unregisterField(name);
        };
    }, []);

    const error = useReactHookFormFieldError(name);

    const validationProps = control
        ? {}
        : {
              novalidate,
              validate,
              validateCacheKey,
              required,
              requiredCustom,
          };

    const validationRules = useCachedValidation(asyncTransformValidate, validationProps, {
        validate: undefined,
    });

    // We are using the hook instead of the Controller component because it is much faster.
    // When this hook is wrapped around the editor, the Controller component creates a big performance
    // bottleneck.
    const { field, fieldState, formState } = useController<TFieldValues, TName>({
        rules: control ? undefined : { validate: validationRules },
        name,
        control,
        defaultValue,
    });

    const handleChange = useCallback(
        (e) => {
            // We want to allow external onChange handlers, other than the
            // one provided by React Hook Form.
            if (onChange) {
                onChange(e, field.onChange);
            } else if (!e?.defaultPrevented) {
                field.onChange(e);
            }
        },
        [field, onChange],
    );

    const handleBlur = useCallback(
        (e) => {
            field.onBlur();

            // We want to allow external onBlur handlers, other than the
            // one provided by React Hook Form.
            if (onBlur) {
                onBlur(e);
            }
        },
        [field, onBlur],
    );

    const { value: transformedValue, onChange: transformedOnChange } = useTransform<
        TFieldValues,
        TName
    >({
        value: field.value,
        onChange: handleChange,
        transform,
    });

    if (control) {
        return {
            field: {
                ...field,
                onChange: handleChange,
                onBlur: handleBlur,
                error: error as FieldError,
            },
            fieldState,
            formState,
        };
    }

    return {
        field: {
            ...field,
            value: transformedValue,
            onChange: transformedOnChange,
            onBlur: handleBlur,
            error: error as FormFieldError,
        },
        fieldState,
        formState,
    };
}

export type UseFormFieldProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = LegacyUseFormFieldProps<TFieldValues, TName> | ZodUseFormFieldProps<TFieldValues, TName>;
export default useFormField;
