import { MIME_TYPES } from '@@editor/helpers/Element';
import { type GenerateEmbedBlockData } from '@@editor/typings/Embed';

export const generateEmbedBlockData: GenerateEmbedBlockData = (data) => {
    const { embed, src } = data;
    const scriptPath = embed.src || src;

    return {
        mimetype: MIME_TYPES.FRONTEND_COMPONENT,
        embed,
        src: scriptPath,
    };
};
