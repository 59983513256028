import { styled } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import invariant from 'tiny-invariant';

import { IFRAME_DEFAULT_HEIGHT } from '@@constants/Embeds';

import { register, unregister } from './utils';

const Iframe = styled('iframe')<{ height?: number }>(({ height }) => ({
    display: 'block',
    width: '100%',
    border: 0,
    ...(height ? { height: `${height}px` } : {}),
}));

export const getDocHeight = (doc: Document) => {
    const customSpacing = 20;

    const body = doc.body;
    const html = doc.documentElement;
    const height = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight,
    );

    return height + customSpacing;
};

// this does only work on same origin, or CORS environments.
// the external embed has to provide either CORS, or the autofit message script.
// see: https://stackoverflow.com/questions/22086722/resize-cross-domain-iframe-height
export const getChildHeight = (domNode: HTMLIFrameElement | null) => {
    try {
        const doc = domNode?.contentWindow?.document;

        return doc ? getDocHeight(doc) : IFRAME_DEFAULT_HEIGHT;
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log(
            'Embed on frame origin has no CORS allowed, nor implemented the autofit script.',
        );

        return IFRAME_DEFAULT_HEIGHT;
    }
};

export type Props = {
    height?: number;
    src?: string;
    srcDoc?: string;
    title?: string;
    onLoad?: (...args) => void;
};

const AutofitIframe = ({ height, src, srcDoc, title = 'frame', onLoad = () => {} }: Props) => {
    invariant(src || srcDoc, 'Either src or srcDoc are required.');

    const ref = useRef<HTMLIFrameElement | null>(null);
    const [iframeHeight, setIframeHeight] = useState<number | undefined>(height);

    const handleOnLoad = (...args) => {
        // if no autofit messaging is used, try to determine the iframe's content height.
        // see notes on getChildHeight!
        if (!iframeHeight) {
            setIframeHeight(getChildHeight(ref.current));
        }

        onLoad(args);
    };

    useEffect(() => {
        const id = height
            ? null
            : register(
                  (height) => setIframeHeight(height),
                  (id) => {
                      if (ref.current?.contentWindow) {
                          ref.current.contentWindow.postMessage(
                              { type: 'setAutofit', iframeId: id },
                              '*',
                          );
                      }
                  },
              );

        return () => {
            if (id) {
                unregister(id);
            }
        };
    }, [height, setIframeHeight, ref.current]);

    const iFrameProps = src ? { src } : { srcDoc };

    return (
        <Iframe
            ref={ref}
            height={iframeHeight}
            title={title}
            onLoad={handleOnLoad}
            {...iFrameProps}
            // We use "allow-scripts" for the sandbox to make the basic JS-based websites work.
            // Do not add "allow-same-origin" as it would make the whole sandboxing useless
            // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/iframe#attr-sandbox
            sandbox="allow-scripts"
        />
    );
};

export default AutofitIframe;
