import { type UploadFile } from '@@api/hooks/useUploadFile';
import { type FilerepoFile } from '@@api/services/metadata/schemas';
import { BLOCK_META_STATES } from '@@editor/constants';
import { type Editor, type Node } from '@@editor/helpers';
import { type EmbedData, type ImageElement, NameSource } from '@@editor/helpers/Element';
import { validateImageFile } from '@@form/utils/validators/image';
import { type AssetPayload } from '@@utils/assets';

import { updateFileNodeData } from './utils';

type DataToUpdate = Pick<FilerepoFile, 'id' | 'title'> &
    Pick<FilerepoFile['metadata'], 'name' | 'caption' | 'credit'> & {
        src: FilerepoFile['_links']['data']['href'];
    } & Pick<EmbedData, 'nameSource' | 'naturalWidth' | 'naturalHeight'>;

type UpdateEmbedData = (data: DataToUpdate) => EmbedData;

type Options = {
    updateEmbedData: UpdateEmbedData;
    uploadFile: UploadFile;
    updateSource?: boolean;
};

export const uploadFileHelper =
    (options: Options) =>
    async (
        editor: Editor,
        block: Node,
        file: File | string | AssetPayload,
        previousNode?: Node,
    ) => {
        const { updateEmbedData, updateSource = true } = options;
        const errors = await validateImageFile(file);

        if (errors.length === 0) {
            return options
                .uploadFile({
                    file,
                    onUploadProgress: (progressEvent) => {
                        updateFileNodeData(editor, block, {
                            [BLOCK_META_STATES.PROGRESS]: progressEvent.lengthComputable
                                ? { value: progressEvent.loaded, max: progressEvent.total }
                                : { value: 0, max: progressEvent.total },
                            [BLOCK_META_STATES.UPLOADING_FILE]: true,
                            [BLOCK_META_STATES.LOADING_FILE]: false,
                        });
                    },
                })
                .then((payload) => {
                    const { id, title, _links, mediaType, metadata, height, width } = payload.body;
                    const payloadSrc = _links?.data.href;
                    const { src, mimetype, originalSrc } = (block as ImageElement).data;
                    const { caption, credit, name } = metadata;

                    updateFileNodeData(editor, block, {
                        mimetype: mimetype || mediaType,
                        src: updateSource ? payloadSrc : src,
                        originalSrc: typeof originalSrc === 'string' ? originalSrc : null,
                        embed: updateEmbedData({
                            src: payloadSrc,
                            id,
                            title,
                            caption,
                            credit,
                            name,
                            nameSource: NameSource.ASSETS,
                            naturalHeight: height,
                            naturalWidth: width,
                        }),
                        [BLOCK_META_STATES.UPLOADING_FILE]: false,
                        [BLOCK_META_STATES.LOADING_FILE]: true,
                    });
                })
                .catch(() => {
                    updateFileNodeData(editor, block, {
                        ...(previousNode?.data || {}),
                        [BLOCK_META_STATES.UPLOADING_FILE]: false,
                        [BLOCK_META_STATES.LOADING_FILE]: false,
                    });
                });
        }

        updateFileNodeData(editor, block, {
            ...(previousNode?.data || {}),
            [BLOCK_META_STATES.UPLOADING_FILE]: false,
            [BLOCK_META_STATES.LOADING_FILE]: false,
        });

        return Promise.resolve();
    };
