import { initContract } from '@ts-rest/core';
import { initTsrReactQuery } from '@ts-rest/react-query/v5';
import { useStore } from 'react-redux';
import { z } from 'zod';

import { commonResponses } from '@@api/commonResponses';
import { clientDefaults } from '@@api/constants/clientDefaults';
import { DefaultHeaders } from '@@api/headers';
import { Newsletter } from '@@api/services/newsletter/schemas';
import { deserializeNewsletter } from '@@api/services/newsletter/transformers';
import { getQueryKeys } from '@@api/utils/queryKeys';
import { SearchParams } from '@@api/utils/schemas/queryParams';
import config from '@@config';
import { type RootState } from '@@scripts/store/store';

const contract = initContract();

const router = contract.router(
    {
        newsletter: contract.router({
            getAll: {
                method: 'GET',
                path: '/newsletters',
                query: z.object({
                    ...SearchParams.pick({ q: true }).shape,
                }),
                responses: {
                    200: z.array(Newsletter.transform(deserializeNewsletter)),
                },
            },
            get: {
                method: 'GET',
                path: '/newsletters/:id',
                pathParams: z.object({
                    id: z.string().uuid(),
                }),
                responses: {
                    200: Newsletter.transform(deserializeNewsletter),
                },
            },
            put: {
                method: 'PUT',
                path: '/newsletters/:id',
                pathParams: z.object({
                    id: Newsletter.shape.id,
                }),
                body: Newsletter,
                responses: {
                    200: Newsletter.transform(deserializeNewsletter),
                },
            },
        }),
    },
    {
        baseHeaders: DefaultHeaders,
        commonResponses,
    },
);

export type NewsletterRouter = typeof router;

const newsletterClient = (state: RootState) => {
    const clientArgs = {
        baseUrl: config.apiEnvVars.newsletterUrl,
        ...clientDefaults(state),
    };

    const client = initTsrReactQuery(router, clientArgs);
    const queryKeys = getQueryKeys(router, clientArgs);

    return { client, queryKeys };
};

export const useNewsletterClient = () => {
    const store = useStore<RootState>();
    const state = store.getState();

    return newsletterClient(state);
};
