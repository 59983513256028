import deCH from './de-CH.json';
import enGB from './en-GB.json';
import frCH from './fr-CH.json';

export const locales = {
    'en-GB': enGB,
    'de-CH': deCH,
    'fr-CH': frCH,
} as const;

export default locales;
