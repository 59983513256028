import { CircularProgress, type CircularProgressProps, styled } from '@mui/material';
import React, { type ReactNode } from 'react';
import { createPortal } from 'react-dom';

export type LoaderProps = {
    isLoading?: boolean;
    keepChildrenInDom?: boolean;
    usePortal?: boolean;
    children?: ReactNode | ReactNode[];
    className?: string;
    size?: CircularProgressProps['size'];
};

const Wrapper = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
});

const Loader = ({
    className,
    keepChildrenInDom = false,
    usePortal,
    isLoading = true,
    children,
    size,
}: LoaderProps) => {
    const renderLoader = () => (
        <Wrapper className={className}>
            <CircularProgress size={size} />
        </Wrapper>
    );

    if (keepChildrenInDom) {
        if (usePortal) {
            return isLoading ? (
                <>
                    {renderLoader()}

                    {createPortal(
                        <div
                            style={{
                                position: 'absolute',
                                top: -99999,
                                left: -99999,

                                /* The promise of "createTweet" will never be fulfilled if we use "display: none"
                                 * instead of "visibility: hidden". Unfortunately visibility will introduce some flickering
                                 * for loading youtube embeds.
                                 */
                                visibility: 'hidden',
                            }}
                        >
                            {children}
                        </div>,
                        document.body,
                    )}
                </>
            ) : (
                children
            );
        }

        return (
            <div style={{ width: '100%' }} className={className}>
                {isLoading ? renderLoader() : null}

                {/* The promise of "createTweet" will never be fulfilled if we use "display: none"
                 * instead of "visibility: hidden". Unfortunately visibility will introduce some flickering
                 * for loading youtube embeds.
                 */}
                <div style={{ visibility: isLoading ? 'hidden' : 'visible' }}>{children}</div>
            </div>
        );
    } else if (isLoading) {
        return renderLoader();
    }

    if (React.Children.count(children) > 0) {
        return <>{children}</>;
    }

    return null;
};

export default Loader;
