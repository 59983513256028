import { MIME_TYPES } from '@@editor/helpers/Element';
import { type GenerateEmbedBlockData } from '@@editor/typings/Embed';
import { isTiktokUrl } from '@@utils/socialMedia/tiktok';

export const generateEmbedBlockData: GenerateEmbedBlockData = ({ originalSrc, url, dataset }) => {
    if (!isTiktokUrl(url)) {
        return;
    }

    return {
        mimetype: MIME_TYPES.TIKTOK,
        src: url?.href,
        originalSrc,
        options: dataset,
    };
};
