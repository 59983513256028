import { Box } from '@mui/material';
import React from 'react';

type Spacing = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
type Direction = 'h' | 'v';

type ExactlyOne<T, Keys extends keyof T = keyof T> = Keys extends any
    ? Pick<T, Keys> & Partial<Record<Exclude<keyof T, Keys>, undefined>>
    : never;

type Props = ExactlyOne<Record<Spacing, true>> & ExactlyOne<Record<Direction, true>>;

const spacings = ['xs', 'sm', 'md', 'lg', 'xl'] as const;
const directions = ['h', 'v'] as const;

const Spacer = (props: Props) => {
    const spacingProp = spacings.find((spacing) => props[spacing]);
    const directionProp = directions.find((direction) => props[direction]);

    const spacing = spacings.indexOf(spacingProp!) + 1;
    const sx = { [directionProp === 'v' ? 'pt' : 'pl']: spacing };

    return <Box sx={sx} />;
};

export default Spacer;
