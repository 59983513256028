import { initContract } from '@ts-rest/core';
import { initTsrReactQuery } from '@ts-rest/react-query/v5';
import { useStore } from 'react-redux';
import { z } from 'zod';

import { commonResponses } from '@@api/commonResponses';
import { clientDefaults } from '@@api/constants/clientDefaults';
import { DefaultHeaders } from '@@api/headers';
import {
    BreakingNews,
    NewBreakingNews,
    UnityBreakingNews,
} from '@@api/services/breakingnews/schemas';
import {
    deserializeBreakingnews,
    serializeBreakingnews,
    serializeNewBreakingnews,
} from '@@api/services/breakingnews/transformers';
import { getQueryKeys } from '@@api/utils/queryKeys';
import { PaginationParams, SearchParams } from '@@api/utils/schemas/queryParams';
import { paginated } from '@@api/utils/schemas/utils';
import config from '@@config';
import { type RootState } from '@@scripts/store/store';

const contract = initContract();

const router = contract.router(
    {
        breakingnews: contract.router({
            getAll: {
                method: 'GET',
                path: '/breakingnews',
                query: z.object({
                    ...SearchParams.omit({ contentType: true }).shape,
                    ...PaginationParams.shape,
                    useSearch: z.literal(true),
                }),
                responses: {
                    200: paginated(UnityBreakingNews.transform(deserializeBreakingnews)),
                },
            },
            get: {
                method: 'GET',
                path: '/breakingnews/:id',
                pathParams: z.object({
                    id: UnityBreakingNews.shape.id,
                }),
                responses: {
                    200: UnityBreakingNews.transform(deserializeBreakingnews),
                },
            },
            put: {
                method: 'PUT',
                path: '/breakingnews/:id',
                pathParams: z.object({
                    id: UnityBreakingNews.shape.id,
                }),
                body: BreakingNews.transform(serializeBreakingnews),
                responses: {
                    200: UnityBreakingNews.transform(deserializeBreakingnews),
                },
            },
            post: {
                method: 'POST',
                path: '/breakingnews',
                body: NewBreakingNews.transform(serializeNewBreakingnews),
                responses: {
                    201: UnityBreakingNews.transform(deserializeBreakingnews),
                },
            },
            delete: {
                method: 'DELETE',
                path: '/breakingnews/:id',
                pathParams: z.object({
                    id: BreakingNews.shape.id,
                }),
                body: z.undefined(),
                responses: {
                    204: z.never(),
                },
            },
        }),
    },
    {
        baseHeaders: DefaultHeaders,
        commonResponses,
    },
);

export type BreakingnewsRouter = typeof router;

const breakingNewsClient = (state: RootState) => {
    const clientArgs = {
        baseUrl: config.apiEnvVars.breakingnewsUrl,
        ...clientDefaults(state),
    };

    const client = initTsrReactQuery(router, clientArgs);
    const queryKeys = getQueryKeys(router, clientArgs);

    return { client, queryKeys };
};

export type BreakingNewsClient = ReturnType<typeof breakingNewsClient>;

export const useBreakingNewsClient = () => {
    const store = useStore<RootState>();
    const state = store.getState();

    return breakingNewsClient(state);
};
