import { useAiToolsClient } from '@@api/services/aiTools/client';

const usePostTickerSummary = () => {
    const { client: aiToolsClient } = useAiToolsClient();

    const { mutateAsync: generateTickerSummary } =
        aiToolsClient.tickerSummaryGeneration.post.useMutation();

    return generateTickerSummary;
};

export default usePostTickerSummary;
