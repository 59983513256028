import { Button, Stack } from '@mui/material';
import { castArray, compact, omit } from 'lodash';
import React, { useContext, useState } from 'react';
import { type FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import Icon from '@@components/Icon';
import Spacer from '@@components/Spacer';
import { ErrorPage } from '@@containers/App/ErrorPage';
import useReactHookFormContext from '@@containers/ReactHookForm/useReactHookFormContext';
import RelatedContentModal from '@@containers/RelatedContent/Modal';
import SwitchField from '@@form/fields/SwitchField';
import { type FormFieldError } from '@@form/hooks/useReactHookFormFieldError';
import IFrameContext from '@@hooks/IFrameContext';

import ContentItemListField from './ContentItemListField';

export type Props = {
    name: string;
    label?: string;
    required?: boolean;
    path?: string;
    pathname?: string;
    allowBundle?: boolean;
    buttonProps?: {
        children?: string;
        iconName?: string;
        disabled?: boolean;
    };
    listProps?: {
        orderable?: boolean;
        columns?: TableColumn[];
        withHeader?: boolean;
    };
    modalLeavePrompt?: () => boolean;
    modalProps?: {
        multiple?: boolean;
        params?: StringObject;
        columns?: TableColumn[];
        modalTitle?: string;
        width?: string;
        searchProps?: UnknownObject;
    };
    orderable?: boolean;
    useModal?: boolean;
    disabled?: boolean;
    forceContentTypes?: string[];
    isRowDisabled?: ({ id }: { id: number }) => boolean;
    error?: FormFieldError | FieldError;
};

const RelatedContentField = (props: Props) => {
    const {
        name,
        buttonProps,
        listProps,
        allowBundle,
        path,
        pathname,
        useModal,
        modalProps,
        modalLeavePrompt,
        forceContentTypes,
        isRowDisabled,
        ...otherProps
    } = props;
    const [isOpenModal, setIsOpenModal] = useState(false);
    const { setValue, watch } = useReactHookFormContext();
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();

    const contentIdsFieldName = allowBundle ? `${name}.contentIds` : name;
    const contentIds = watch(contentIdsFieldName);
    const iFrameContext = useContext(IFrameContext);
    const isModal = iFrameContext || useModal;

    const handleClickOnSelectRelatedContent = () => {
        openModal();
    };

    const handleSaveOfModal = (selectedContent) => {
        setValue(contentIdsFieldName, selectedContent);

        return selectedContent;
    };

    const handleCloseModal = () => {
        if (isModal) {
            setIsOpenModal(false);
        } else {
            navigate('.');
        }
    };

    const handleSubmitSuccess = () => {
        if (isModal) {
            setIsOpenModal(false);
        } else {
            navigate('.', { state: { ignoreBlocker: true } });
        }
    };

    const openModal = () => {
        if (isModal) {
            setIsOpenModal(true);
        } else {
            navigate({
                pathname,
                search: location.search,
            });
        }
    };

    const renderModal = (props) => (
        <RelatedContentModal
            {...modalProps}
            isOpen
            {...props}
            forceContentTypes={forceContentTypes}
            value={contentIds}
            onClose={handleCloseModal}
            onSubmit={handleSaveOfModal}
            onSubmitSuccess={handleSubmitSuccess}
            modalLeavePrompt={modalLeavePrompt}
            isRowDisabled={isRowDisabled}
        />
    );

    const nextButtonProps = {
        startIcon: <Icon name={buttonProps?.iconName ?? 'magnifying-glass'} />,
        children: t('relatedcontent.buttonLabel'),
        ...(omit(buttonProps, ['iconName']) || {}),
    };

    return (
        <React.Fragment>
            {
                // If the editor is wrapped by a label, this hidden checkbox will receive the
                // click event (if the label has been clicked).
                // This hidden checkbox prevents other elements (for example the select content
                // button) from receiving the click event (which would cause weird behaviour).
            }
            <input type="checkbox" style={{ display: 'none' }} />

            {isModal ? (
                renderModal({ isOpen: isOpenModal, disableUrlSync: true })
            ) : (
                <Routes>
                    <Route path={path} element={renderModal({})} errorElement={<ErrorPage />} />
                </Routes>
            )}

            <React.Fragment>
                <ContentItemListField {...otherProps} {...listProps} name={contentIdsFieldName} />

                {compact(castArray(contentIds)).length > 0 && <Spacer md v />}
            </React.Fragment>

            <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-start"
                position="relative"
                display="inline-flex"
                width="100%"
            >
                <Button
                    {...nextButtonProps}
                    variant="outlined"
                    onClick={handleClickOnSelectRelatedContent}
                />

                {allowBundle && (
                    <>
                        <Spacer md h />

                        <SwitchField
                            name={`${name}.displayAsBundle`}
                            label={t('relatedcontent.displayAsBundle')}
                            disabled={contentIds.length <= 0}
                        />
                    </>
                )}
            </Stack>
        </React.Fragment>
    );
};

RelatedContentField.defaultProps = {
    allowBundle: true,
    useModal: false,
};

export default RelatedContentField;
